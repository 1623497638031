import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import qs from "qs";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "./assets/font/font/iconfont.css";
import utils from "./utils";
import Foo from "./libs/Foo";
import webSocket from "./utils/webSocket";
import webSocket2 from "./utils/webSocket2";
import {registryToast} from "@/components/common/toast";
import {storePlugin} from "./utils/storeStorage";
import userConfig from "./assets/js/userManage/comment";
import dataConfig from "./assets/js/dataManage/comment";
import monitorConfig from "./assets/js/monitorManage/comment";
import commonConfig from "./assets/js/common/comment";
import "@/utils/auth.js";
import Rsa from "@/utils/rsa.js";

Vue.config.productionTip = false;

Vue.prototype.$utils = utils;
Vue.prototype.$Foo = Foo;
Vue.prototype.$webSocket = webSocket;
Vue.prototype.$webSocket2 = webSocket2;
Vue.prototype.$qs = qs;
Vue.prototype.$userConfig = userConfig;
Vue.prototype.$dataConfig = dataConfig;
Vue.prototype.$monitorConfig = monitorConfig;
Vue.prototype.$commonConfig = commonConfig;
Vue.prototype.Rsa = Rsa;
Vue.use(ElementUI);
Vue.use(registryToast);
Vue.use(storePlugin);


/** 
 * @description https://www.cnblogs.com/binmengxue/p/13920737.html 
 * vue 项目中监听 localStorage 或 sessionStorage 的变化 
 * @param { number } type 1 localStorage 2 sessionStorage
 * @param { string } key 键
 * @param { string } data 要存储的数据
 * @returns 
 */
Vue.prototype.$addStorageEvent = function (type, key, data) {
    if (type === 1) {
        // 创建一个StorageEvent事件
        var newStorageEvent = document.createEvent('StorageEvent');
        const storage = {
            setItem: function (k, val) {
                localStorage.setItem(k, val);
                // 初始化创建的事件
                newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null);
                // 派发对象
                window.dispatchEvent(newStorageEvent);
            }
        }
        return storage.setItem(key, data);
    } else {
        // 创建一个StorageEvent事件
        var newStorageEvent = document.createEvent('StorageEvent');
        const storage = {
            setItem: function (k, val) {
                sessionStorage.setItem(k, val);
                // 初始化创建的事件
                newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null);
                // 派发对象
                window.dispatchEvent(newStorageEvent);
            }
        }
        return storage.setItem(key, data);
    }
}


// 公共css
import "./assets/css/common.css";
import "./assets/css/user/style.less";
import "./assets/css/monitor/style.less";
import "./assets/css/common.less";
// 引入的公共组件
import SAASTable from "@/components/common/SAASTable";
import SAASModel from "@/components/common/SAASModel";
import SAASModelTable from "@/components/common/SAASModelTable";
import SAASForm from "@/components/common/SAASForm";
import SAASDialog from "@/components/common/SAASDialog";
import SAASTree from "@/components/common/SAASTree";
import SAASOrgTree from "@/components/common/SAASOrgTree";
import SAASTree2 from "@/components/common/SAASTree2";
import SAASTree4 from "@/components/common/SAASTree4";
import SAASEvidenceList from "@/components/common/SAASEvidenceList";
import SAASAMap from "@/components/common/SAASAMap";
import SAASAMap2 from "@/components/common/SAASAMap2";
import SAASBMap from "@/components/common/SAASBMap";
import SAASTab from "@/components/common/SAASTab";
import SAASAlarmTree from "@/components/common/SAASAlarmTree";
import SAASLogTree from "@/components/common/SAASLogTree";
import SAASExport from "@/components/common/SAASExport";
import SAASColSet from "@/components/common/SAASColSet";
import SAASColSet2 from "@/components/common/SAASColSet2";
import VehicleAssignment from "@/components/UserManage/VehicleAssignment";
import SAASRecursionTree from "@/components/common/SAASRecursionTree";
import SAASRecursionTree2 from "@/components/common/SAASRecursionTree2";
import SAASRecursionTree3 from "@/components/common/SAASRecursionTree3";
import VehicleTree from "@/components/common/VehicleTree";
import VehicleTree2 from "@/components/common/VehicleTree2";
import VehicleRecursionTree from "@/components/common/VehicleRecursionTree";
import VehicleRecursionTree2 from "@/components/common/VehicleRecursionTree2";
import VehicleTree2V3 from "@/components/common/VehicleTree2V3";
import SearchForm from "@/components/common/SearchForm";

/*公共组件*/
Vue.component("SAASTable", SAASTable); // 表格+分页
Vue.component("SAASModel", SAASModel); // 新增编辑页面
Vue.component("SAASModelTable", SAASModelTable); // 弹窗列表页面
Vue.component("SAASTab", SAASTab); // 新增编辑页面
Vue.component("SAASForm", SAASForm); // 公共form组件
Vue.component("SAASDialog", SAASDialog); // 公共弹框-自编辑
Vue.component("SAASTree4", SAASTree4); // 公共组织树-element
Vue.component("SAASTree", SAASTree); // 公共组织树-element
Vue.component("SAASEvidenceList", SAASEvidenceList); // 公共证据列表（翻页轮播）
Vue.component("SAASAMap", SAASAMap); // 高德地图-1
Vue.component("SAASAMap2", SAASAMap2); // 高德地图-2
Vue.component("SAASBMap", SAASBMap); // 高德地图-2
Vue.component("SAASAlarmTree", SAASAlarmTree); // 报警树
Vue.component("SAASLogTree", SAASLogTree); // 日志树
Vue.component("SAASExport", SAASExport); // 公共导出组件
Vue.component("SAASColSet", SAASColSet); // 公共列表配置组件
Vue.component("VehicleAssignment", VehicleAssignment); // 公共列表配置组件
Vue.component("SAASRecursionTree3", SAASRecursionTree3);
Vue.component("SAASRecursionTree", SAASRecursionTree);
Vue.component("SAASOrgTree", SAASOrgTree);
Vue.component("SAASTree2", SAASTree2);
Vue.component("VehicleTree", VehicleTree); // 公共组织树-element
Vue.component("VehicleTree2", VehicleTree2); // 公共组织树-element
Vue.component("SAASRecursionTree2", SAASRecursionTree2);
Vue.component("VehicleRecursionTree", VehicleRecursionTree);
Vue.component("VehicleRecursionTree2", VehicleRecursionTree2);
Vue.component("SAASColSet2", SAASColSet2); 
Vue.component("VehicleTree2V3", VehicleTree2V3); 
Vue.component("SearchForm", SearchForm); // 搜索组件

Vue.directive("clickoutside", {
    bind: function (el, binding, vnode) {
        //do someting
        function documentHandler(e) {
            if (el.contains(e.target)) {
                return false;
            }
            if (binding.expression) {
                binding.value(e);
            }
        }

        el._vueClickOutSide_ = documentHandler;
        document.addEventListener("click", documentHandler);
    },
    inserted: function (el, binding) {
        //do something
    },
    unbind: function (el, binding) {
        document.removeEventListener("click", el._vueClickOutSide_);
        delete el._vueClickOutSide_;
    }
});

// 两位小数
Vue.directive('positiveFloat', {
    inserted(el) {
        el.addEventListener('input', (e) => {
            let value = e.target.value;
            let temp = value.toString().replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1');
            e.target.value = temp;
            e.target.dispatchEvent(new Event('input')); // 更新v-model绑定的值
        });
    }
});
// 整数
Vue.directive('positiveInt', {
    inserted(el) {
        el.addEventListener('input', (e) => {
            let value = e.target.value; //ipt.value = Number(ipt.value.replace(/\D+/, ''))
            let temp = value.toString().replace(/\D+/, '');
            e.target.value = temp;
            e.target.dispatchEvent(new Event('input')); // 更新v-model绑定的值
        });
    }
});

var orignalSetItem = localStorage.setItem;
// 发版时清理缓存
const VUE_APP_VERSION = require("../package.json").version;
const vers = window.localStorage.getItem("appVersion");
if (VUE_APP_VERSION != vers) {
    // localStorage.clear()
    localStorage.removeItem("vuex");
    window.localStorage.setItem("appVersion", VUE_APP_VERSION);
    location.reload();
}

localStorage.setItem = function (key, newValue) {
    var setItemEvent = new Event("setItemEvent");
    setItemEvent.key = key;
    setItemEvent.newValue = newValue;
    window.dispatchEvent(setItemEvent);
    orignalSetItem.apply(this, arguments);
};

export default new Vue({
    router,
    store,
    render: (h) => h(App)
}).$mount("#app");