<template>
    <div class="saas-tree4" v-clickoutside="handleClose">
        <el-input
                ref="saasTree4Input"
                :placeholder="placeholder"
                class="suffix"
                v-model="formValue[formProps.label]"
                autocomplete="off"
                :disabled="disabled"
                clearable
                @focus="onResionIsShow"
                @clear="clear()"
                @input="treeInput"
                @mouseover.native="inputMouseover"
                @mouseout.native="inputMouseout"				
        >
            <i v-if="iconXialaShow" @click="isiTreeShow=true" class="input-icon iconfont iconxiala" slot="suffix"></i>
        </el-input>
        <el-tree 
                v-if="isTreeShow"
                :data="treeData"
                :props="treeProps"
                :default-expand-all="isExpandAll"
                :default-expanded-keys="expandedKeys"
                :current-node-key="currentNodeKey"
                node-key="value"
                ref='saasTree4'
                class="tree"
                :filter-node-method="filterNode"
                @node-click="handleClick"
        ></el-tree>
    </div>
</template>

<script>
    export default {
        name: "SAASTree4",
        props: {
            formValue: {
                required: true
            },
            treeProps: {
                type: Object,
                default: function () {
                    return {
                        label: "label",
                        value: "value",
                        children: "children"
                    };
                }
            },
            formProps: {
                type: Object,
                default: function () {
                    return {
                        label: "label",
                        value: "value"
                    };
                }
            },
            treeData: {
                required: true
            },
            placeholder: {
                required: true
            },
            disabled: {
                type: Boolean,
                default: false
            },
            isExpandAll: {
                type: Boolean,
                default: false
            },
            currentNodeKey: {
                required: false
            },
            expandedKeys: {
                type: Array,
                default: function () {
                    return ['-1'];
                }
            },
            notClick: {
                required: false
            },
			onlyChild: { // 有子节点时，点击父节点，不进行回调，必须选上子节点
				default: false,
				required: false
			}
        },
        watch: {
            "filterText"(val) {
                if (this.$refs.saasTree4) {
                    this.$refs.saasTree4.filter(val);
                }
            }
        },
        data() {
            return {
                filterText: "",
                isTreeShow: false,
                iconXialaShow: true,
                key: 'fdsfdf'
            };
        },
        mounted(){
            console.log(this.expandedKeys);
        },
        methods: {
            inputMouseover() {
                if (this.formValue[this.formProps.label]) {
                    this.iconXialaShow = false;
                }
            },
            inputMouseout() {
                if (!this.isTreeShow) {
                    this.iconXialaShow = true;
                }
            },
            treeInput(val) {
                this.filterText = val;
            },
            clear() {
                this.formValue[this.formProps.label] = "";
                this.formValue[this.formProps.value] = "";
                this.$forceUpdate();
                this.$emit("treeClickChange", {});
            },
            handleClose() {
                this.isTreeShow = false;
                this.iconXialaShow = true;
            },
            filterNode(value, data) {
                if (!value) return true;
                return data[this.treeProps.label].indexOf(value) !== -1;
            },
            onResionIsShow() {
                this.filterText = "";
                this.isTreeShow = true;
            },
            handleClick(data) {
                if (data) {
                    if (typeof this.notClick == "object") {
                        if (this.notClick.length && this.notClick.indexOf(data[this.treeProps.value]) != -1) {
                            return;
                        }
                        if (this.notClick.length == undefined && data[this.notClick.label] == this.notClick.value) {
                            return;
                        }
                    }
					// 只允许选中子节点或者数据本身不可选择
					if((this.onlyChild && data.children)||data.disabled){
						return;
					}
					
                    this.iconXialaShow = true;
                    this.isTreeShow = false;
                    this.formValue[this.formProps.label] = data[this.treeProps.label];
                    this.formValue[this.formProps.value] = data[this.treeProps.value];
                    this.$emit("treeClickChange", data);
                }
            }
        }
    };
</script>

<style lang="less">
    .saas-tree4 {
        .el-input__inner {
            height: 34px;
            padding-right: 25px;
        }

        .tree {
            width: 100%;
            height: 280px;
            overflow: auto;
            position: absolute;
            top: 34px;
            z-index: 10;
            -webkit-box-shadow: 0 0.1rem 0.6rem 0 rgba(0, 0, 0, .1);
            box-shadow: 0 0.1rem 0.6rem 0 rgba(0, 0, 0, .1);
        }

        .el-tree-node > .el-tree-node__children {
            overflow: inherit;
        }
    }
</style>
