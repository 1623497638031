<template>
    <div id="dialog">
        <el-dialog title="提示" :visible.sync="dialogVisible" width="18%" top="30vh"
                   :close-on-click-modal="false">
            <div class="dialog-hint"><i class="iconfont icontanhao tanhao-icon"></i><span>{{ confirmHint }}</span></div>
            <template slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="confirm">确 定</el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script>
	export default {
		name: "SAASDialog",
		props: ["confirmHint", "confirmType"],
		data() {
			return {
				dialogVisible: false
			};
		},
		methods: {
			confirm() {
				this.dialogVisible = false;
				this.$emit("handleClick", this.confirmType);
			}
		}
	};
</script>
<style lang="less">
    #dialog {
        .el-dialog__body {
            padding: 10px 20px;

            .dialog-hint {
                line-height: 20px;
                display: flex;
                align-items: center;

                .tanhao-icon {
                    color: rgba(229, 159, 66, 1);
                    margin-right: 12px;
                    font-size: 20px;
                }
            }

        }
    }
</style>
