<template>
	<div class="carTreeToolbar_setter" v-show="visible"> <!-- v-clickoutside="test" -->
		<div class="_title">车辆显示<span class="el-icon-circle-close" @click="visible=false"></span></div>
		<ul>
			<li class="_item">
				<el-radio v-model="treeParams.label" label="vehicleNo">车牌号码</el-radio>
			</li>
			<li class="_item">
				<el-radio v-model="treeParams.label" label="explains">自编号</el-radio>
			</li>
			<li class="_item">
				<el-checkbox v-model="treeParams.state">车辆状态</el-checkbox>
			</li>
			<li class="_item border-b-n">
				<el-checkbox v-model="treeParams.offTimes">离线时长</el-checkbox>
			</li>
			<li class="_item border-b-n">
				<el-checkbox v-model="treeParams.eType">2G/4G</el-checkbox>
			</li>
			<li class="_item border-b-n">
				<el-checkbox v-model="treeParams.area">所在地区</el-checkbox>
			</li>
		</ul>
		<!-- <div class="btnBox">
			<el-button type="info" plain @click="visible=false" size="small">关闭</el-button>
			<el-button type="primary" plain size="small" @click="confirm">应用</el-button>
		</div> -->
	</div>
</template>

<script>
	export default {
		name: "VehicleTreeSetter",
		data(){
			return {
				visible: false,
				treeParams: {}
			}
		},
        watch: {
            treeParams: {
				handler(newValue,oldValue){
					// 存在localStorage中和state中		
					this.$addStorageEvent(1, 'treeParamsShower', JSON.stringify(newValue));
					this.$store.commit('setTreeParamsShower',newValue);
				},
				deep: true
            }
        },		
		methods: {
			show(){
				let params = localStorage.getItem('treeParamsShower');
				this.treeParams = (params=='undefined'||params=='{}'||!params)?this.$store.state.treeParamsShower:{...JSON.parse(params)};
				if(!this.treeParams.label){
					this.treeParams.label = 'vehicleNo';
					this.$addStorageEvent(1, 'treeParamsShower', JSON.stringify(this.treeParams));
					this.$store.commit('setTreeParamsShower',this.treeParams);
				}

				this.visible = !this.visible;
			},
			hide(){
				this.visible = false;
			}
		},

	};
</script>

<style lang="less">
	.vehicle-tree-show{
		.carTreeToolbar_setter{
			width: 280px;
			right: -290px;
		}		
	}
	.vehicle-tree-hide{
		.carTreeToolbar_setter{
			width: 0;
			left: -300px;
		}
	}
	.carTreeToolbar_setter{		
	    position: absolute;
	    background-color: #fff;
	    border-width: 1px;
	    border-style: solid;
	    -webkit-box-shadow: 0 0 5px 0 rgba(0,0,0,.1);
	    box-shadow: 0 0 5px 0 rgba(0,0,0,.1);
	    overflow: hidden;	
		transition-property: left width;
		transition-duration: 0.2s;
		transition-timing-function: ease-out;		
		._title{
			width: 100%;
			background-color: #e8eaed;
			height: 30px;
			line-height: 30px;
			text-indent: 10px;
			position: relative;
			.el-icon-circle-close{
				position: absolute;
				right: 8px;
				top: 8px;
				cursor: pointer;
				font-size: 16px;
				&:hover{
					opacity: 0.6;
				}
			}
		}
		ul{
			width: 100%;
			._item{
				width: calc(33% + 1.5px);
				border-left-width: 1px;
				border-left-style: solid;
				border-left-color: #e8eaed;
				border-bottom-width: 1px;
				border-bottom-style: solid;
				border-bottom-color: #e8eaed;
				margin-left: -1px;
				overflow: hidden;
				text-overflow: ellipsis;
				float: left;
				box-sizing: border-box;
				padding-left: 10px;
				.el-radio__label,.el-checkbox__label{
					padding-left: 3px;
				}
				&.border-b-n{
					border-bottom: none;
				}
			}	
		}
		.btnBox{
			clear: both;
			padding: 5px;
			text-align: right;
		}

	}
</style>