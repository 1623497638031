<template>
    <SAASModel
            ref="saasModel"
            title="导出数据"
            confirm-btn-text="导 出"
            width="23rem"
            top="25vh"
            @handleClick="handleClick"
    >
        <div class="export-container">
            <div>
                <span>共{{pageIndexs}}页查询结果，当前第{{pageIndex}}页</span>
                <span class="hint">（单次导出最多支持5000条）</span>
            </div>
            <div class="export-option">
                导出第
                <div>
                    <input type="number" v-model="firstPageIndex" @input="totalChange">页
                </div>
                至
                <div>
                    <input type="number" v-model="lastPageIndex" @input="totalChange">页
                </div>
                <span>共{{currentTotal}}条数据</span>
                <a class="export-current" @click="handleClick('current')">导出当前页</a>
            </div>
        </div>
    </SAASModel>
</template>

<script>
	export default {
		name: "SAASExport",
		props: ["pageIndex", "pageIndexs", "pageSize", "total"],
		data() {
			return {
				firstPageIndex: "",
				lastPageIndex: "",
				currentTotal: 0,
				timer: ""
			};
		},
		methods: {
			init() {
				this.$refs.saasModel.dialogVisible = true;
				this.firstPageIndex = 1;
				this.lastPageIndex = this.pageIndex;
				this.currentTotal = this.pageIndex * this.pageSize;
				this.timer = "";
			},
			handleClick(type) {
				if (type == "current") {
					let params = {
						pageSize: this.pageSize,
						skipCount: (this.pageIndex - 1) * this.pageSize
					};
					this.$emit("handleClick", "exportData", params);
				} else {
					let params = {
						pageSize: this.currentTotal,
						skipCount: (this.firstPageIndex - 1) * this.pageSize
					};
					if (this.currentTotal == 0) {
						this.$toast("请输入页数进行导出");
						return;
					}
					if (this.currentTotal > 5000) {
						this.$toast("单次导出最多支持5000条数据");
						return;
					}
					this.$emit("handleClick", "exportData", params);
				}
				this.$refs.saasModel.dialogVisible = false;
			},
			totalChange() {
				let that = this;
				if (this.timer) {
					clearTimeout(this.timer);
				}
				this.timer = setTimeout(function () {
					if (!that.firstPageIndex || !that.lastPageIndex) {
						that.currentTotal = 0;
						return;
					}
					that.currentTotal = that.lastPageIndex * that.pageSize - (that.firstPageIndex - 1) * that.pageSize;
					that.currentTotal = that.currentTotal > that.total ? that.total : that.currentTotal;
				}, 500);

			}
		}
	};
</script>

<style scoped lang="less">
    .export-container {
        padding: 20px 22px;

        .hint {
            color: #FF3E3E;
        }

        .export-option {
            display: flex;
            align-items: center;
            margin-top: 20px;

            div {
                padding: 2px 5px;
                display: flex;
                align-items: center;
                height: 30px;
                width: 60px;
                margin: 0 5px;

                input {
                    width: 100%;
                    border: none;
                    height: 100%;
                    display: inline-block;
                    font-size: 12px;
                    background: transparent;
                }

                input::-webkit-outer-spin-button,
                input::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                }

                input[type="number"] {
                    -moz-appearance: textfield;
                }
            }

            .export-current {
                cursor: pointer;
                color: #549AFF;
                text-decoration: underline;
                padding-left: 5px;
            }
        }
    }
</style>