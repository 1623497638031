import Vue from 'vue'
import store from '../store'
/**权限指令**/
const has = Vue.directive('has', {
    bind: function(el, binding, vnode) {},
    inserted: function(el, binding, vnode) {
        // 获取页面按钮权限
        let btnPermissionsArr = '';
        if (binding.value) {
            // 如果指令传值，获取指令参数，根据指令参数和当前登录人按钮权限做比较。
            btnPermissionsArr = binding.value;
            if (!Vue.prototype.$_has(btnPermissionsArr)) {
                el.parentNode.removeChild(el);
            }
        }
    }
});
// 权限检查方法
Vue.prototype.$_has = function(value) {
    // 获取用户按钮权限
    let isExist = false;
    const menuInfos = store.state.loginInfo.menuInfos
    const authArr = menuInfos.map(v => {
        return v.menuId;
    });
    if (authArr.indexOf(value) > -1) {
        isExist = true;
    }
    return isExist;
};
export { has }