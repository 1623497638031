import {proCity} from "../common/area_data";
import utils from "../../../utils";

let intenseDrive1 = {
    type: "number",
    min: 1,
    max: 10,
    unit: "秒",
    style: {
        width: "25%"
    }
};
let intenseDrive2 = {
    type: "number",
    min: 1,
    max: 100,
    unit: "1/100g",
    style: {
        width: "25%"
    }
};
export default {
    terminalParams: {
        searchObj: {
            communiceConneceInput: [
                {
                    label: "终端心跳间隔:",
                    type: "input",
                    inputType: "number",
                    key: "xt7",
                    unit: "秒",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "TCP消息重传次数:",
                    type: "input",
                    key: "tcp100",
                    inputType: "number",
                    unit: "次",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "TCP消息应答超时:",
                    type: "input",
                    key: "tcp101",
                    inputType: "number",
                    unit: "秒",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "UDP消息应答超时:",
                    type: "input",
                    key: "udp102",
                    inputType: "number",
                    unit: "秒",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "UDP消息重传次数:",
                    type: "input",
                    key: "udp103",
                    inputType: "number",
                    unit: "次",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "SMS消息应答超时:",
                    type: "input",
                    key: "sms104",
                    inputType: "number",
                    unit: "秒",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "SMS消息重传次数:",
                    type: "input",
                    key: "sms105",
                    inputType: "number",
                    unit: "次",
                    style: {
                        width: "25%"
                    }
                }, {
                    id: "rules_management_alarm_level_setting_query",
                    label: "",
                    text: "发送",
                    type: "button",
                    key: "search",
                    itemType: "primary",
                    handler: "search",
                    auth: "user_center_serve_terminal_send",
                    style: {
                        marginLeft: "auto"
                    }
                }
            ],
            serverParamInput: [
                {
                    label: "主服务器APN:",
                    type: "input",
                    key: "apn3",
                    style: {
                        width: "50%"
                    }
                }, {
                    label: "备服务器APN:",
                    type: "input",
                    key: "apn28",
                    style: {
                        width: "50%"
                    }
                }, {
                    label: "主服务器拨号账号:",
                    type: "input",
                    key: "apn4",
                    style: {
                        width: "50%"
                    }
                }, {
                    label: "备服务器拨号账号:",
                    type: "input",
                    key: "apn29",
                    style: {
                        width: "50%"
                    }
                }, {
                    label: "主服务器拨号密码:",
                    type: "input",
                    key: "apn5",
                    style: {
                        width: "50%"
                    }
                }, {
                    label: "备服务器拨号密码:",
                    type: "input",
                    key: "apn30",
                    style: {
                        width: "50%"
                    }
                }, {
                    label: "主服务器地址:",
                    type: "input",
                    key: "zip",
                    style: {
                        width: "50%"
                    }
                }, {
                    label: "备服务器地址:",
                    type: "input",
                    key: "bip",
                    style: {
                        width: "50%"
                    }
                }, {
                    label: "服务器TCP端口:",
                    type: "input",
                    key: "zport",
                    inputType: "number",
                    style: {
                        width: "50%"
                    }
                }, {
                    label: "服务器UDP端口:",
                    type: "input",
                    key: "bport",
                    inputType: "number",
                    style: {
                        width: "50%"
                    }
                },
                {
                    id: "rules_management_alarm_level_setting_query",
                    label: "",
                    text: "发送",
                    type: "button",
                    key: "search",
                    itemType: "primary",
                    handler: "search",
                    auth: "user_center_serve_terminal_send",
                    style: {
                        marginLeft: "auto"
                    }
                }
            ],
            locationReportInput: [
                {
                    label: "汇报策略:",
                    type: "radio",
                    key: "f111",
                    handler: "reportStrate",
                    group: [{
                        label: 0,
                        name: "定时",
                        value: 0
                    },
                        {
                            label: 1,
                            name: "定距",
                            value: 1
                        },
                        {
                            label: 2,
                            name: "混合",
                            value: 2
                        }
                    ],
                    style: {
                        width: "50%"
                    }
                }, {
                label: "汇报方案:",
                type: "radio",
                key: "f112",
                group: [{
                        label: 0,
                        name: "ACC",
                        value: 0
                    },
                    {
                        label: 1,
                        name: "登录状态和ACC",
                        value: 1
                }],
                style: {
                    width: "50%"
                }
            }],
            alarmParamInput: [
                {
                    label: "超速报警速度:",
                    type: "input",
                    key: "a128",
                    unit: "km/h",
                    inputType: "number",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "超速持续时长:",
                    type: "input",
                    key: "a129",
                    inputType: "number",
                    unit: "秒",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "连续驾驶时间门限:",
                    type: "input",
                    key: "a130",
                    inputType: "number",
                    unit: "秒",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "当天驾驶时间门限:",
                    type: "input",
                    key: "a131",
                    inputType: "number",
                    unit: "秒",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "超速报警预警差值:",
                    type: "input",
                    key: "a300",
                    inputType: "number",
                    unit: "百米/h",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "疲劳驾驶预警差值:",
                    type: "input",
                    key: "a301",
                    inputType: "number",
                    unit: "秒",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "最小休息时间:",
                    type: "input",
                    key: "a132",
                    inputType: "number",
                    unit: "秒",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "最长停车时间:",
                    type: "input",
                    key: "a130",
                    inputType: "number",
                    unit: "秒",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "碰撞报警时间:",
                    type: "input",
                    key: "at407",
                    inputType: "number",
                    unit: "4毫秒",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "碰撞报警加速度:",
                    type: "input",
                    key: "aj407",
                    inputType: "number",
                    unit: "0.1G",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "侧翻报警角度:",
                    type: "input",
                    key: "a808",
                    inputType: "number",
                    unit: "度",
                    style: {
                        width: "25%"
                    }
                },
                {
                    id: "rules_management_alarm_level_setting_query",
                    label: "",
                    text: "发送",
                    type: "button",
                    key: "search",
                    itemType: "primary",
                    handler: "search",
                    auth: "user_center_serve_terminal_send",
                    style: {
                        marginLeft: "auto"
                    }
                }
            ],
            photoParamInput: [
                {
                    label: "图像/视频质量:",
                    type: "input",
                    key: "p136",
                    inputType: "number",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "饱和度:",
                    type: "input",
                    key: "p139",
                    inputType: "number",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "亮度:",
                    type: "input",
                    key: "p137",
                    inputType: "number",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "色度:",
                    type: "input",
                    key: "p140",
                    inputType: "number",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "加比度:",
                    type: "input",
                    key: "p138",
                    inputType: "number",
                    style: {
                        width: "25%"
                    }
                },
                {
                    id: "rules_management_alarm_level_setting_query",
                    label: "",
                    text: "发送",
                    type: "button",
                    key: "search",
                    itemType: "primary",
                    handler: "search",
                    auth: "user_center_serve_terminal_send",
                    style: {
                        marginLeft: "auto"
                    }
                }
            ],
            vehicleParamInput: [
                {
                    label: "车辆里程表读数:",
                    type: "input",
                    key: "v147",
                    inputType: "number",
                    style: {
                        width: "25%"
                    },
                    rules: [{
                        required: true,
                        message: "请输入三方企业名称",
                        trigger: "blur"
                    }]
                }, {
                    label: "车辆号码:",
                    type: "input",
                    key: "v41",
                    style: {
                        width: "25%"
                    },
                    rules: [{
                        required: true,
                        message: "请输入三方企业名称",
                        trigger: "blur"
                    }]
                }, {
                    label: "车辆所在区域:",
                    type: "cascader",
                    popperClass: "monitor-cascader",
                    key: "proCity",
                    options: proCity,
                    style: {
                        width: "25%"
                    },
                    rules: [{
                        required: true,
                        message: "请输入三方企业名称",
                        trigger: "change"
                    }]
                }, {
                    label: "车牌颜色:",
                    type: "select",
                    popperClass: "monitor-select",
                    key: "v42",
                    style: {
                        width: "25%"
                    },
                    options: JSON.parse(localStorage.getItem("vehiclePlateColorArr")),
                    rules: [{
                        required: true,
                        message: "请输入三方企业名称",
                        trigger: "change"
                    }]
                },
                {
                    id: "rules_management_alarm_level_setting_query",
                    label: "",
                    text: "发送",
                    type: "button",
                    key: "search",
                    itemType: "primary",
                    handler: "search",
                    auth: "user_center_serve_terminal_send",
                    style: {
                        marginLeft: "auto"
                    }
                }
            ],
            gnssParamInput: [
                {
                    label: "定位模式:",
                    type: "checkbox",
                    key: "gnssCheked",
                    options: [{
                        value: 1,
                        label: "GPS定位"
                    },
                        {
                            value: 2,
                            label: "北斗定位"
                        },
                        {
                            value: 4,
                            label: "GLONASS定位"
                        },
                        {
                            value: 8,
                            label: "Galileo定位"
                        }
                    ],
                    style: {
                        width: "50%"
                    }
                }, {
                    label: "定位模式:",
                    type: "input",
                    key: "gnssChekedCopy",
                    style: {
                        width: "50%",
                        display: "none"
                    }
                }, {
                    label: "输出频率:",
                    type: "radio",
                    key: "gnss403",
                    group: [{
                        label: 0,
                        name: "500ms"
                    },
                        {
                            label: 1,
                            name: "1000ms"
                        },
                        {
                            label: 2,
                            name: "2000ms"
                        },
                        {
                            label: 3,
                            name: "3000ms"
                        },
                        {
                            label: 4,
                            name: "4000ms"
                        }
                    ],
                    style: {
                        width: "50%"
                    }
                }, {
                    label: "输出频率:",
                    type: "input",
                    key: "gnss403Copy",
                    style: {
                        width: "50%",
                        display: "none"
                    }
                }, {
                    label: "上传方式:",
                    type: "radio",
                    key: "gnss815",
                    group: [{
                        label: 0,
                        name: "本地存储"
                    },
                        {
                            label: 1,
                            name: "时间间隔"
                        },
                        {
                            label: 2,
                            name: "距离间隔"
                        },
                        {
                            label: 11,
                            name: "累计时间"
                        },
                        {
                            label: 12,
                            name: "累计距离"
                        },
                        {
                            label: 13,
                            name: "累计条数"
                        }
                    ],
                    style: {
                        width: "62%"
                    }
                }, {
                    label: "上传方式:",
                    type: "input",
                    key: "gnss815Copy",
                    style: {
                        width: "62%",
                        display: "none"
                    }
                }, {
                    label: "采集频率:",
                    type: "input",
                    key: "gnss404",
                    inputType: "number",
                    unit: "秒",
                    style: {
                        width: "30%"
                    }
                }, {
                    label: "采集频率:",
                    type: "input",
                    key: "gnss404Copy",
                    style: {
                        width: "30%",
                        display: "none"
                    }
                }, {
                    label: "波特率:",
                    type: "radio",
                    key: "gnss402",
                    group: [{
                        label: 0,
                        name: "4800"
                    },
                        {
                            label: 1,
                            name: "9600"
                        },
                        {
                            label: 2,
                            name: "19200"
                        },
                        {
                            label: 3,
                            name: "38400"
                        },
                        {
                            label: 4,
                            name: "57600"
                        },
                        {
                            label: 5,
                            name: "115200"
                        }
                    ],
                    style: {
                        width: "62%"
                    }
                }, {
                    label: "上传设置:",
                    type: "select",
                    popperClass: "monitor-select",
                    key: "gnss816",
                    unit: "秒",
                    options: [{
                        label: "1",
                        value: "1"
                    }, {
                        label: "2",
                        value: "2"
                    }, {
                        label: "11",
                        value: "11"
                    }, {
                        label: "12",
                        value: "12"
                    }, {
                        label: "13",
                        value: "13"
                    }],
                    style: {
                        width: "30%"
                    }
                },
                {
                    id: "rules_management_alarm_level_setting_query",
                    label: "",
                    text: "发送",
                    type: "button",
                    key: "search",
                    itemType: "primary",
                    handler: "search",
                    auth: "user_center_serve_terminal_send",
                    style: {
                        marginLeft: "auto"
                    }
                }
            ]
        },
        localtionReportObj: {
            0: [{
                label: "驾驶员未登时间间隔:",
                type: "input",
                key: "f113",
                inputType: "number",
                unit: "秒",
                style: {
                    width: "25%"
                }
            }, {
                label: "休眠汇报时间间隔:",
                type: "input",
                key: "f114",
                inputType: "number",
                unit: "秒",
                style: {
                    width: "25%"
                }
            }, {
                label: "紧急报警时间间隔:",
                type: "input",
                key: "f115",
                inputType: "number",
                unit: "秒",
                style: {
                    width: "25%"
                }
            }, {
                label: "缺省汇报时间间隔:",
                type: "input",
                key: "f116",
                inputType: "number",
                unit: "秒",
                style: {
                    width: "25%"
                }
            }, {
                label: "拐点补偿角度:",
                type: "input",
                key: "f121",
                inputType: "number",
                unit: "度",
                style: {
                    width: "25%"
                }
            }, {
                label: "非法位移阈值:",
                type: "input",
                key: "f31",
                unit: "度",
                inputType: "number",
                style: {
                    width: "25%"
                }
            },
                {
                    id: "rules_management_alarm_level_setting_query",
                    label: "",
                    text: "发送",
                    type: "button",
                    key: "search",
                    itemType: "primary",
                    handler: "search",
                    auth: "user_center_serve_terminal_send",
                    style: {
                        marginLeft: "auto"
                    }
                }
            ],
            1: [{
                label: "驾驶员未登距离间隔:",
                type: "input",
                key: "f118",
                inputType: "number",
                unit: "米",
                style: {
                    width: "25%"
                }
            }, {
                label: "休眠汇报距离间隔:",
                type: "input",
                key: "f119",
                inputType: "number",
                unit: "米",
                style: {
                    width: "25%"
                }
            }, {
                label: "紧急报警距离间隔:",
                type: "input",
                key: "f120",
                inputType: "number",
                unit: "米",
                style: {
                    width: "25%"
                }
            }, {
                label: "缺省汇报距离间隔:",
                type: "input",
                key: "f117",
                inputType: "number",
                unit: "米",
                style: {
                    width: "25%"
                }
            }, {
                label: "拐点补偿角度:",
                type: "input",
                key: "f121",
                unit: "度",
                inputType: "number",
                style: {
                    width: "25%"
                }
            }, {
                label: "非法位移阈值:",
                type: "input",
                key: "f31",
                unit: "米",
                inputType: "number",
                style: {
                    width: "25%"
                }
            },
                {
                    id: "rules_management_alarm_level_setting_query",
                    label: "",
                    text: "发送",
                    type: "button",
                    key: "search",
                    itemType: "primary",
                    handler: "search",
                    auth: "user_center_serve_terminal_send",
                    style: {
                        marginLeft: "auto"
                    }
                }
            ],
            2: [{
                label: "驾驶员未登时间间隔:",
                type: "input",
                key: "f113",
                unit: "秒",
                inputType: "number",
                style: {
                    width: "25%"
                }
            }, {
                label: "驾驶员未登距离间隔:",
                type: "input",
                key: "f118",
                unit: "米",
                inputType: "number",
                style: {
                    width: "25%"
                }
            }, {
                label: "休眠汇报时间间隔:",
                type: "input",
                key: "f114",
                unit: "秒",
                inputType: "number",
                style: {
                    width: "25%"
                }
            }, {
                label: "休眠汇报距离间隔:",
                type: "input",
                key: "f119",
                unit: "米",
                inputType: "number",
                style: {
                    width: "25%"
                }
            }, {
                label: "紧急报警时间间隔:",
                type: "input",
                key: "f115",
                unit: "秒",
                inputType: "number",
                style: {
                    width: "25%"
                }
            }, {
                label: "紧急报警距离间隔:",
                type: "input",
                key: "f120",
                unit: "米",
                inputType: "number",
                style: {
                    width: "25%"
                }
            }, {
                label: "缺省汇报时间间隔:",
                type: "input",
                key: "f116",
                unit: "秒",
                inputType: "number",
                style: {
                    width: "25%"
                }
            }, {
                label: "缺省汇报距离间隔:",
                type: "input",
                key: "f117",
                unit: "米",
                inputType: "number",
                style: {
                    width: "25%"
                }
            }, {
                label: "拐点补偿角度:",
                type: "input",
                key: "f121",
                unit: "度",
                inputType: "number",
                style: {
                    width: "25%"
                }
            }, {
                label: "非法位移阈值:",
                type: "input",
                key: "f31",
                unit: "米",
                inputType: "number",
                style: {
                    width: "25%"
                }
            },
                {
                    id: "rules_management_alarm_level_setting_query",
                    label: "",
                    text: "发送",
                    type: "button",
                    key: "search",
                    itemType: "primary",
                    handler: "search",
                    auth: "user_center_serve_terminal_send",
                    style: {
                        marginLeft: "auto"
                    }
                }
            ]
        },
        listHeader: [
            {
                label: "车牌号码",
                prop: "name",
                render(row) {
                    return row.name || "--";
                }
            },
            {
                label: "终端标识",
                prop: "vid",
                render(row) {
                    return row.vid || "--";
                }
            },
            {
                label: "发送状态",
                prop: "sendStatus",
                width: utils.setFontSize(180),
                render(row) {
                    return row.sendStatus || "--";
                }
            }
        ],
        listSearchHeader: [
            {
                label: "响应状态",
                prop: "upStatus",
                width: utils.setFontSize(180),
                render(row) {
                    return row.upStatus || "--";
                }
            },
            {
                label: "查看",
                handler: "onDetail",
                prop: "terminalShow",
                tooltip: true,
                auth: "user_center_rule_arule_fatigue_delete",
                width: utils.setFontSize(180)
            }
        ],
        addItem: {
            1: [
                {
                    label: "终端心跳间隔:",
                    type: "input",
                    key: "xt7",
                    readonly: true,
                    unit: "秒",
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "TCP消息重传次数:",
                    type: "input",
                    key: "tcp100",
                    readonly: true,
                    unit: "次",
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "TCP消息应答超时:",
                    type: "input",
                    key: "tcp101",
                    readonly: true,
                    unit: "秒",
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "UDP消息应答超时:",
                    type: "input",
                    key: "udp102",
                    readonly: true,
                    unit: "秒",
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "UDP消息重传次数:",
                    type: "input",
                    key: "udp103",
                    readonly: true,
                    unit: "次",
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "SMS消息应答超时:",
                    type: "input",
                    key: "sms104",
                    readonly: true,
                    unit: "秒",
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "SMS消息重传次数:",
                    type: "input",
                    key: "sms105",
                    readonly: true,
                    unit: "次",
                    style: {
                        width: "100%"
                    }
                }],
            2: [
                {
                    label: "主服务器APN:",
                    type: "input",
                    key: "apn3",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "主服务器拨号账号:",
                    type: "input",
                    key: "apn4",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "主服务器拨号密码:",
                    type: "input",
                    key: "apn5",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "主服务器地址:",
                    type: "input",
                    key: "zip",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "备服务器APN:",
                    type: "input",
                    key: "apn28",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "备服务器拨号账号:",
                    type: "input",
                    key: "apn29",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "备服务器拨号密码:",
                    type: "input",
                    key: "apn30",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "备服务器地址:",
                    type: "input",
                    key: "bip",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "服务器TCP端口:",
                    type: "input",
                    key: "zport",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "服务器UDP端口:",
                    type: "input",
                    key: "bport",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }],
            30: [
                {
                    label: "汇报策略:",
                    type: "input",
                    key: "f111s",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "汇报方案:",
                    type: "input",
                    key: "f112s",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "驾驶员未登时间间隔:",
                    type: "input",
                    key: "f113",
                    readonly: true,
                    unit: "秒",
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "休眠汇报时间间隔:",
                    type: "input",
                    key: "f114",
                    unit: "秒",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "紧急报警时间间隔:",
                    type: "input",
                    key: "f115",
                    unit: "秒",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "缺省汇报时间间隔:",
                    type: "input",
                    key: "f116",
                    unit: "秒",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "拐点补偿角度:",
                    type: "input",
                    key: "f121",
                    unit: "度",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "非法位移阈值:",
                    type: "input",
                    key: "f31",
                    unit: "度",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }],
            4: [
                {
                    label: "超速报警速度:",
                    type: "input",
                    key: "a128",
                    unit: "km/h",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "超速持续时长:",
                    type: "input",
                    key: "a129",
                    unit: "秒",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "连续驾驶时间门限:",
                    type: "input",
                    key: "a130",
                    unit: "秒",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "当天驾驶时间门限:",
                    type: "input",
                    key: "a131",
                    unit: "秒",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "超速报警预警差值:",
                    type: "input",
                    key: "a300",
                    unit: "百米/h",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "疲劳驾驶预警差值:",
                    type: "input",
                    key: "a301",
                    unit: "秒",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "最小休息时间:",
                    type: "input",
                    key: "a132",
                    unit: "秒",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "最长停车时间:",
                    type: "input",
                    key: "a130",
                    unit: "秒",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "碰撞报警时间:",
                    type: "input",
                    key: "at407",
                    unit: "4毫秒",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "碰撞报警加速度:",
                    type: "input",
                    key: "aj407",
                    unit: "0.1G",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "侧翻报警角度:",
                    type: "input",
                    key: "a808",
                    unit: "度",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }],
            5: [
                {
                    label: "图像/视频质量:",
                    type: "input",
                    key: "p136",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "饱和度:",
                    type: "input",
                    key: "p139",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "亮度:",
                    type: "input",
                    key: "p137",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "色度:",
                    type: "input",
                    key: "p140",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "加比度:",
                    type: "input",
                    key: "p138",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }],
            6: [
                {
                    label: "车辆里程表读数:",
                    type: "input",
                    key: "v147",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "车辆号码:",
                    type: "input",
                    key: "v41",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "车辆所在区域:",
                    type: "input",
                    key: "proCitys",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "车牌颜色:",
                    type: "input",
                    key: "v42s",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }],
            7: [
                {
                    label: "定位模式:",
                    type: "input",
                    key: "gnssChekeds",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "输出频率:",
                    type: "input",
                    key: "gnss403s",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "上传方式:",
                    type: "input",
                    key: "gnss815s",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "采集频率:",
                    type: "input",
                    key: "gnss404",
                    readonly: true,
                    unit: "秒",
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "波特率:",
                    type: "input",
                    key: "gnss402s",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "上传设置:",
                    type: "input",
                    key: "gnss816s",
                    readonly: true,
                    unit: "秒",
                    style: {
                        width: "100%"
                    }
                }],
            31: [
                {
                    label: "汇报策略:",
                    type: "input",
                    key: "f111s",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "汇报方案:",
                    type: "input",
                    key: "f112s",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "驾驶员未登距离间隔:",
                    type: "input",
                    key: "f118",
                    unit: "米",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "休眠汇报距离间隔:",
                    type: "input",
                    key: "f119",
                    unit: "米",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "紧急报警距离间隔:",
                    type: "input",
                    key: "f120",
                    unit: "米",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "缺省汇报距离间隔:",
                    type: "input",
                    key: "f117",
                    unit: "米",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "拐点补偿角度:",
                    type: "input",
                    key: "f121",
                    unit: "度",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "非法位移阈值:",
                    type: "input",
                    key: "f31",
                    unit: "米",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }],
            32: [
                {
                    label: "汇报策略:",
                    type: "input",
                    key: "f111s",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "汇报方案:",
                    type: "input",
                    key: "f112s",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "驾驶员未登时间间隔:",
                    type: "input",
                    key: "f113",
                    unit: "秒",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "驾驶员未登距离间隔:",
                    type: "input",
                    key: "f118",
                    unit: "米",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "休眠汇报时间间隔:",
                    type: "input",
                    key: "f114",
                    unit: "秒",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "休眠汇报距离间隔:",
                    type: "input",
                    key: "f119",
                    unit: "米",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "紧急报警时间间隔:",
                    type: "input",
                    key: "f115",
                    unit: "秒",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "紧急报警距离间隔:",
                    type: "input",
                    key: "f120",
                    unit: "米",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "缺省汇报时间间隔:",
                    type: "input",
                    key: "f116",
                    unit: "秒",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "缺省汇报距离间隔:",
                    type: "input",
                    key: "f117",
                    unit: "米",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "拐点补偿角度:",
                    type: "input",
                    key: "f121",
                    unit: "度",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "非法位移阈值:",
                    type: "input",
                    key: "f31",
                    unit: "米",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }]
        }
    },
    recorder: {
        searchObj: {
            vehicleInfo: [
                {
                    label: "车架号:",
                    type: "input",
                    key: "vehicleCode",
                    style: {
                        width: "33.33%"
                    },
                    rules: [{validator: utils.checkOnly17Number, trigger: "blur"}]
                }, {
                    label: "车辆类型:",
                    type: "input",
                    key: "vehicleCategory",
                    style: {
                        width: "33.33%"
                    }
                }, {
                    label: "车牌号:",
                    type: "input",
                    key: "vehicleNo",
                    style: {
                        width: "33.33%"
                    },
                    rules: [{validator: utils.checkVehicle2, trigger: "blur"}]
                }, {
                    label: "消息头:",
                    type: "radio",
                    key: "head",
                    group: [{
                        label: true,
                        name: "带有消息头"
                    }, {
                        label: false,
                        name: "不带消息头"
                    }],
                    style: {
                        width: "50%"
                    }
                }, {
                    id: "rules_management_alarm_level_setting_query",
                    label: "",
                    text: "发送",
                    type: "button",
                    key: "search",
                    itemType: "primary",
                    handler: "search",
                    auth: "user_center_serve_terminal_send",
                    style: {
                        marginLeft: "auto"
                    }
                }
            ],
            firstInstallTime: [
                {
                    label: "安装时间:",
                    type: "datePicker",
                    dateType: "datetime",
                    popperClass: "monitor-date-picker",
                    key: "realTime",
                    style: {
                        width: "33.33%"
                    }
                }, {
                    label: "消息头:",
                    type: "radio",
                    key: "head",
                    group: [{
                        label: true,
                        name: "带有消息头"
                    }, {
                        label: false,
                        name: "不带消息头"
                    }],
                    style: {
                        width: "50%"
                    }
                }, {
                    id: "rules_management_alarm_level_setting_query",
                    label: "",
                    text: "发送",
                    type: "button",
                    key: "search",
                    itemType: "primary",
                    handler: "search",
                    auth: "user_center_serve_terminal_send",
                    style: {
                        marginLeft: "auto"
                    }
                }
            ],
            recorderTime: [
                {
                    label: "记录仪时间:",
                    type: "datePicker",
                    dateType: "datetime",
                    popperClass: "monitor-date-picker",
                    key: "realTime",
                    style: {
                        width: "33.33%"
                    }
                }, {
                    label: "消息头:",
                    type: "radio",
                    key: "head",
                    group: [{
                        label: true,
                        name: "带有消息头"
                    }, {
                        label: false,
                        name: "不带消息头"
                    }],
                    style: {
                        width: "50%"
                    }
                }, {
                    id: "rules_management_alarm_level_setting_query",
                    label: "",
                    text: "发送",
                    type: "button",
                    key: "search",
                    itemType: "primary",
                    handler: "search",
                    auth: "user_center_serve_terminal_send",
                    style: {
                        marginLeft: "auto"
                    }
                }
            ],
            pulseFactor: [
                {
                    label: "记录仪当前时间:",
                    type: "datePicker",
                    dateType: "datetime",
                    popperClass: "monitor-date-picker",
                    key: "currentTime",
                    style: {
                        width: "33.33%"
                    }
                }, {
                    label: "脉冲系数:",
                    type: "input",
                    inputType: "number",
                    key: "pulseFactor",
                    style: {
                        width: "33.33%"
                    },
                    rules: [{validator: utils.checkHighLow, trigger: "blur"}]
                }, {
                    label: "消息头:",
                    type: "radio",
                    key: "head",
                    group: [{
                        label: true,
                        name: "带有消息头"
                    }, {
                        label: false,
                        name: "不带消息头"
                    }],
                    style: {
                        width: "33.33%"
                    }
                }, {
                    id: "rules_management_alarm_level_setting_query",
                    label: "",
                    text: "发送",
                    type: "button",
                    key: "search",
                    itemType: "primary",
                    handler: "search",
                    auth: "user_center_serve_terminal_send",
                    style: {
                        marginLeft: "auto"
                    }
                }
            ],
            firstMileage: [
                {
                    key: "realTime",
                    label: "记录仪实时时间:",
                    type: "datePicker",
                    dateType: "datetime",
                    popperClass: "monitor-date-picker",
                    style: {
                        width: "33.33%"
                    }
                },
                {
                    key: "initTime",
                    label: "记录仪初次安装时间:",
                    type: "datePicker",
                    dateType: "datetime",
                    popperClass: "monitor-date-picker",
                    style: {
                        width: "33.33%"
                    }
                },
                {
                    label: "初始里程:",
                    type: "input",
                    key: "initMileage",
                    inputType: "number",
                    unit: "百米",
                    style: {
                        width: "33.33%"
                    }
                },
                {
                    label: "累计行驶里程:",
                    type: "input",
                    key: "cumulativeMileage",
                    inputType: "number",
                    unit: "百米",
                    style: {
                        width: "33.33%"
                    }
                },
                {
                    label: "消息头:",
                    type: "radio",
                    key: "head",
                    group: [{
                        label: true,
                        name: "带有消息头"
                    }, {
                        label: false,
                        name: "不带消息头"
                    }],
                    style: {
                        width: "50%"
                    }
                }, {
                    id: "rules_management_alarm_level_setting_query",
                    label: "",
                    text: "发送",
                    type: "button",
                    key: "search",
                    itemType: "primary",
                    handler: "search",
                    auth: "user_center_serve_terminal_send",
                    style: {
                        marginLeft: "auto"
                    }
                }
            ],
            quantityConfig: [
                {
                    label: "D0状态信号名称:",
                    type: "input",
                    key: "D0",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "D1状态信号名称:",
                    type: "input",
                    key: "D1",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "D2状态信号名称:",
                    type: "input",
                    key: "D2",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "D3状态信号名称:",
                    type: "input",
                    key: "D3",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "D4状态信号名称:",
                    type: "input",
                    key: "D4",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "D5状态信号名称:",
                    type: "input",
                    key: "D5",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "D6状态信号名称:",
                    type: "input",
                    key: "D6",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "D7状态信号名称:",
                    type: "input",
                    key: "D7",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "消息头:",
                    type: "radio",
                    key: "head",
                    group: [{
                        label: true,
                        name: "带有消息头"
                    }, {
                        label: false,
                        name: "不带消息头"
                    }],
                    style: {
                        width: "50%"
                    }
                }, {
                    id: "rules_management_alarm_level_setting_query",
                    label: "",
                    text: "发送",
                    type: "button",
                    key: "search",
                    itemType: "primary",
                    handler: "search",
                    auth: "user_center_serve_terminal_send",
                    style: {
                        marginLeft: "auto"
                    }
                }
            ],
            searchParams: [
                {
                    label: "消息头:",
                    type: "radio",
                    key: "head",
                    group: [{
                        label: true,
                        name: "带有消息头"
                    },
                        {
                            label: false,
                            name: "不带消息头"
                        }
                    ],
                    style: {
                        width: "50%"
                    }
                }, {
                    label: "最大单位数据块个数:",
                    type: "input",
                    key: "dataNum",
                    inputType: "number",
                    style: {
                        width: "50%"
                    }
                }, {
                    label: "开始时间:",
                    type: "datePicker",
                    dateType: "datetime",
                    key: "startTime",
                    style: {
                        width: "33.33%"
                    }
                }, {
                    label: "结束时间:",
                    type: "datePicker",
                    dateType: "datetime",
                    key: "endTime",
                    style: {
                        width: "33.33%"
                    }
                }, {
                    id: "rules_management_alarm_level_setting_query",
                    label: "",
                    text: "发送",
                    type: "button",
                    key: "search",
                    itemType: "primary",
                    handler: "search",
                    auth: "user_center_serve_terminal_send",
                    style: {
                        marginLeft: "auto"
                    }
                }
            ]
        },
        listHeader: [
            {
                label: "车牌号码",
                prop: "name",
                render(row) {
                    return row.name || "--";
                }
            },
            {
                label: "终端标识",
                prop: "vid",
                render(row) {
                    return row.vid || "--";
                }
            },
            {
                label: "发送状态",
                prop: "sendStatus",
                width: utils.setFontSize(180),
                render(row) {
                    return row.sendStatus || "--";
                }
            }
        ],
        listSearchHeader: [
            {
                label: "响应状态",
                prop: "upStatus",
                width: utils.setFontSize(180),
                render(row) {
                    return row.upStatus || "--";
                }
            },
            {
                label: "查看",
                handler: "onDetail",
                prop: "terminalShow",
                tooltip: true,
                auth: "user_center_rule_arule_fatigue_delete",
                width: utils.setFontSize(180)
            }
        ],
        addItem: {
            0: [
                {
                    label: "记录仪执行标准年号:",
                    type: "input",
                    key: "yearNumber",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "修改单号:",
                    type: "input",
                    key: "singleNumberDec",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }
            ],
            1: [
                {
                    label: "机动车驾驶证号码:",
                    type: "input",
                    key: "driverNumber",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }
            ],
            2: [
                {
                    label: "记录仪实时时间:",
                    type: "input",
                    key: "realTime",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }
            ],
            3: [
                {
                    label: "记录仪实时时间:",
                    type: "input",
                    key: "realTime",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "记录仪初次安装时间:",
                    type: "input",
                    key: "realTime",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "初始里程:",
                    type: "input",
                    key: "initMileage",
                    unit: "km",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "累计行驶里程:",
                    type: "input",
                    key: "cumulativeMileage",
                    unit: "km",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }
            ],
            4: [
                {
                    label: "记录仪当前时间:",
                    type: "input",
                    key: "currentTime",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "记录仪脉冲系数:",
                    type: "input",
                    key: "pulseCoefficient",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }
            ],
            5: [
                {
                    label: "车辆识别代号:",
                    type: "input",
                    key: "vehicleCode",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "车辆号码:",
                    type: "input",
                    key: "vehicleNo",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "车辆类型:",
                    type: "input",
                    key: "vehicleCategory",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }
            ],
            6: [
                {
                    label: "记录仪实时时间:",
                    type: "input",
                    key: "realTime",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "状态信号字节个数:",
                    type: "input",
                    key: "lightLength",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "D1状态信号名称:",
                    type: "input",
                    key: "D1",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "D2状态信号名称:",
                    type: "input",
                    key: "D2",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "D3状态信号名称:",
                    type: "input",
                    key: "D3",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "D4状态信号名称:",
                    type: "input",
                    key: "D4",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "D5状态信号名称:",
                    type: "input",
                    key: "D5",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "D6状态信号名称:",
                    type: "input",
                    key: "D6",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "D7状态信号名称:",
                    type: "input",
                    key: "D7",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }
            ],
            7: [
                {
                    label: "认证代码:",
                    type: "input",
                    key: "vendorCode",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "认证产品型号:",
                    type: "input",
                    key: "vendorModel",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "生产日期:",
                    type: "input",
                    key: "yearMonthDay",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "产品生产流水号:",
                    type: "input",
                    key: "vendorNumber",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }
            ],
            8: [
                {
                    label: "行驶速度:",
                    type: "input",
                    key: "travelSpeedTime",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "平均速度:",
                    type: "input",
                    key: "averageSpeed",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "D0:",
                    type: "input",
                    key: "D1",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "D2状态信号名称:",
                    type: "input",
                    key: "D2",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "D3状态信号名称:",
                    type: "input",
                    key: "D3",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "D4状态信号名称:",
                    type: "input",
                    key: "D4",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "D5状态信号名称:",
                    type: "input",
                    key: "D5",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "D6状态信号名称:",
                    type: "input",
                    key: "D6",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "D7状态信号名称:",
                    type: "input",
                    key: "D7",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }
            ],
            9: [
                {
                    label: "开始时间:",
                    type: "input",
                    key: "locationTime",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "位置信息:",
                    type: "input",
                    key: "altitude",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "平均速度:",
                    type: "input",
                    key: "averageSpeed",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "经度:",
                    type: "input",
                    key: "longitude",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "纬度:",
                    type: "input",
                    key: "latitude",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }
            ],
            10: [
                {
                    label: "行驶结束时间:",
                    type: "input",
                    key: "time",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "机动车驾驶证号码:",
                    type: "input",
                    key: "driverNumber",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "行驶结束时的速度:",
                    type: "input",
                    key: "averageSpeed",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "D0行驶结束时的状态信号:",
                    type: "input",
                    key: "D0",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "D1行驶结束时的状态信号:",
                    type: "input",
                    key: "D1",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "D2行驶结束时的状态信号:",
                    type: "input",
                    key: "D2",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "D3行驶结束时的状态信号:",
                    type: "input",
                    key: "D3",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "D4行驶结束时的状态信号:",
                    type: "input",
                    key: "D4",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "D5行驶结束时的状态信号:",
                    type: "input",
                    key: "D5",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "D6行驶结束时的状态信号:",
                    type: "input",
                    key: "D6",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "D7行驶结束时的状态信号:",
                    type: "input",
                    key: "D7",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }
            ],
            11: [
                {
                    label: "机动车驾驶证号码:",
                    type: "input",
                    key: "travelSpeedTime",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "连续驾驶开始时间:",
                    type: "input",
                    key: "averageSpeed",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "连续驾驶结束时间:",
                    type: "input",
                    key: "D1",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "D2状态信号名称:",
                    type: "input",
                    key: "D2",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "D3状态信号名称:",
                    type: "input",
                    key: "D3",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }
            ],
            12: [
                {
                    label: "事件发生时间:",
                    type: "input",
                    key: "driverTime",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "机动车驾驶证号码:",
                    type: "input",
                    key: "driverNumber",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "事件类型:",
                    type: "input",
                    key: "driverEventType",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }
            ],
            13: [
                {
                    label: "事件发生时间:",
                    type: "input",
                    key: "eventTime",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "事件类型:",
                    type: "input",
                    key: "eventType",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }
            ],
            14: [
                {
                    label: "事件发生时间:",
                    type: "input",
                    key: "eventTime",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "事件类型:",
                    type: "input",
                    key: "eventType",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }
            ],
            15: [
                {
                    label: "记录仪的速度状态:",
                    type: "input",
                    key: "travelSpeedTime",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "开始时间:",
                    type: "input",
                    key: "averageSpeed",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "结束时间:",
                    type: "input",
                    key: "D1",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "D1记录速度:",
                    type: "input",
                    key: "D2",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "D1参考速度:",
                    type: "input",
                    key: "D3",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "D2记录速度:",
                    type: "input",
                    key: "D2",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "D2参考速度:",
                    type: "input",
                    key: "D3",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "D3记录速度:",
                    type: "input",
                    key: "D2",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "D3参考速度:",
                    type: "input",
                    key: "D3",
                    readonly: true,
                    style: {
                        width: "100%"
                    }
                }
            ]
        }
    },
    activeSafe: {
        searchObj: {
            zheDrivingAsp10: {
                label: "报警使能:",
                type: "select",
                popperClass: "monitor-select",
                key: "asp10",
                options: [{
                    label: "频繁变道预警",
                    value: "4"
                }, {
                    label: "频繁变道报警",
                    value: "8"
                }, {
                    label: "车道偏离预警",
                    value: "16"
                }, {
                    label: "车道偏离报警",
                    value: "32"
                }, {
                    label: "前向碰撞预警",
                    value: "64"
                }, {
                    label: "前向碰撞报警",
                    value: "128"
                }, {
                    label: "行人碰撞预警",
                    value: "256"
                }, {
                    label: "行人碰撞报警",
                    value: "512"
                }, {
                    label: "车距过近预警",
                    value: "1024"
                }, {
                    label: "车距过近报警",
                    value: "2048"
                }, {
                    label: "路口快速通过预警",
                    value: "4096"
                }, {
                    label: "路口快速通过报警",
                    value: "8192"
                }, {
                    label: "道路标识超限警",
                    value: "65536"
                }],
                multiple: true,
                collapseTags: true,
                style: {
                    width: "37.5%"
                }
            },
            yueDriverAsp10: {
                label: "报警使能:",
                type: "select",
                popperClass: "monitor-select",
                key: "asps10",
                options: [{
                    label: "疲劳驾驶一级报警",
                    value: "1"
                }, {
                    label: "疲劳驾驶二级报警",
                    value: "2"
                }, {
                    label: "接打手持电话一级报警",
                    value: "4"
                }, {
                    label: "接打手持电话二级报警",
                    value: "8"
                }, {
                    label: "抽烟一级报警",
                    value: "16"
                }, {
                    label: "抽烟二级报警",
                    value: "32"
                }, {
                    label: "长时间不目视前方一级报警",
                    value: "64"
                }, {
                    label: "长时间不目视前方二级报警",
                    value: "128"
                }, {
                    label: "未检测到驾驶员一级报警",
                    value: "254"
                }, {
                    label: "未检测到驾驶员二级报警",
                    value: "512"
                }, {
                    label: "摄像头遮挡一级报警",
                    value: "1024"
                }, {
                    label: "摄像头遮挡二级报警",
                    value: "2048"
                }, {
                    label: "不系安全带一级报警",
                    value: "4096"
                }, {
                    label: "不系安全带二级报警",
                    value: "8192"
                }, {
                    label: "红外墨镜阻断失效一级报警",
                    value: "16384"
                }, {
                    label: "红外墨镜阻断失效二级报警",
                    value: "32768"
                }, {
                    label: "双脱把一级报警",
                    value: "65536"
                }, {
                    label: "双脱把二级报警",
                    value: "131072"
                }, {
                    label: "玩手机一级报警",
                    value: "232144"
                }, {
                    label: "玩手机二级报警",
                    value: "524288"
                }],
                multiple: true,
                collapseTags: true,
                style: {
                    width: "37.5%"
                }
            },
            zheDriverAsp10: {
                label: "报警使能:",
                type: "select",
                popperClass: "monitor-select",
                key: "asps10",
                options: [{
                    label: "疲劳驾驶预警",
                    value: "1"
                }, {
                    label: "疲劳驾驶报警",
                    value: "2"
                }, {
                    label: "接打手持电话预警",
                    value: "4"
                }, {
                    label: "接打手持电话报警",
                    value: "8"
                }, {
                    label: "抽烟预警",
                    value: "16"
                }, {
                    label: "抽烟报警",
                    value: "32"
                }, {
                    label: "分神驾驶预警",
                    value: "64"
                }, {
                    label: "分神驾驶报警",
                    value: "128"
                }, {
                    label: "驾驶员异常预警",
                    value: "254"
                }, {
                    label: "驾驶员异常报警",
                    value: "512"
                }, {
                    label: "摄像头遮挡预警",
                    value: "1024"
                }, {
                    label: "摄像头遮挡报警",
                    value: "2048"
                }],
                multiple: true,
                collapseTags: true,
                style: {
                    width: "37.5%"
                }
            },
            // 公共
            drivingAssist: [
                {
                    label: "报警判断速度阈值:",
                    type: "number",
                    key: "asp1",
                    unit: "km/h",
                    min: 0,
                    max: 60,
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "报警提示音量:",
                    type: "number",
                    key: "asp2",
                    min: 0,
                    max: 8,
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "主动拍照策略:",
                    type: "select",
                    popperClass: "monitor-select",
                    key: "asp3",
                    options: [{
                        label: "不开启",
                        value: "0"
                    }, {
                        label: "定时拍照",
                        value: "1"
                    }, {
                        label: "定距拍照",
                        value: "2"
                    }],
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "主动定时拍照时间间隔:",
                    type: "number",
                    key: "asp4",
                    unit: "秒",
                    min: 0,
                    max: 3600,
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "主动定距拍照距离间隔:",
                    type: "number",
                    key: "asp5",
                    min: 0,
                    max: 60000,
                    unit: "米",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "单次主动拍照张数:",
                    type: "number",
                    key: "asp6",
                    min: 1,
                    max: 10,
                    unit: "张",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "单次主动拍照时间间隔:",
                    type: "number",
                    key: "asp7",
                    min: 1,
                    max: 10,
                    unit: "100ms",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "拍照分辨率:",
                    type: "select",
                    popperClass: "monitor-select",
                    key: "asp8",
                    options: [{
                        label: "352x288",
                        value: "1"
                    }, {
                        label: "704x288",
                        value: "2"
                    }, {
                        label: "704x576",
                        value: "3"
                    }, {
                        label: "640x480",
                        value: "4"
                    }, {
                        label: "1280x720",
                        value: "5"
                    }, {
                        label: "1920x1080",
                        value: "6"
                    }],
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "视频录制分辨率:",
                    type: "select",
                    popperClass: "monitor-select",
                    key: "asp9",
                    options: [{
                        label: "CIF",
                        value: "1"
                    }, {
                        label: "HD1",
                        value: "2"
                    }, {
                        label: "D1",
                        value: "3"
                    }, {
                        label: "WD1",
                        value: "4"
                    }, {
                        label: "VGA",
                        value: "5"
                    }, {
                        label: "720P",
                        value: "6"
                    }, {
                        label: "1080P",
                        value: "7"
                    }],
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "报警使能:",
                    type: "select",
                    popperClass: "monitor-select",
                    key: "asp10",
                    options: [{
                        label: "障碍检测一级报警",
                        value: "1"
                    }, {
                        label: "障碍检测二级报警",
                        value: "2"
                    }, {
                        label: "频繁变道一级报警",
                        value: "4"
                    }, {
                        label: "频繁变道二级报警",
                        value: "8"
                    }, {
                        label: "车道偏离一级报警",
                        value: "16"
                    }, {
                        label: "车道偏离二级报警",
                        value: "32"
                    }, {
                        label: "前向碰撞一级报警",
                        value: "64"
                    }, {
                        label: "前向碰撞二级报警",
                        value: "128"
                    }, {
                        label: "行人碰撞一级报警",
                        value: "256"
                    }, {
                        label: "行人碰撞二级报警",
                        value: "512"
                    }, {
                        label: "车距过近一级报警",
                        value: "1024"
                    }, {
                        label: "车距过近二级报警",
                        value: "2048"
                    }, {
                        label: "道路标识超限报警",
                        value: "65536"
                    }],
                    multiple: true,
                    collapseTags: true,
                    style: {
                        width: "37.5%"
                    }
                }, {
                    label: "事件使能:",
                    type: "select",
                    popperClass: "monitor-select",
                    key: "asp11",
                    options: [{
                        label: "道路标识识别",
                        value: "1"
                    }, {
                        label: "主动拍照",
                        value: "2"
                    }],
                    multiple: true,
                    style: {
                        width: "37.5%"
                    }
                }, {
                    label: "障碍物报警距离阈值:",
                    type: "number",
                    key: "adas47Distance",
                    min: 10,
                    max: 50,
                    unit: "100ms",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "障碍物报警分级速度阈值:",
                    type: "number",
                    key: "adas47Speed",
                    min: 0,
                    max: 220,
                    unit: "km/h",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "障碍物报警前后视频录制时间:",
                    type: "number",
                    key: "adas47RecordTime",
                    min: 0,
                    max: 60,
                    unit: "秒",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "障碍物报警拍照张数:",
                    type: "number",
                    key: "adas47PhotoNum",
                    min: 0,
                    max: 10,
                    unit: "张",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "障碍物报警拍照时间间隔:",
                    type: "number",
                    key: "adas47Interval",
                    min: 1,
                    max: 10,
                    unit: "100ms",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "频繁变道报警判断时间段:",
                    type: "number",
                    key: "adas45JudgeTime",
                    min: 30,
                    max: 120,
                    unit: "秒",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "频繁变道报警判断次数:",
                    type: "number",
                    key: "adas45JudgeNum",
                    min: 3,
                    max: 10,
                    unit: "次",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "频繁变道报警分级速度阈值:",
                    type: "number",
                    key: "adas45Speed",
                    min: 0,
                    max: 220,
                    unit: "km/h",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "频繁变道报警前后视频录制时间:",
                    type: "number",
                    key: "adas45RecordTime",
                    min: 0,
                    max: 60,
                    unit: "秒",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "频繁变道报警拍照张数:",
                    type: "number",
                    key: "adas45PhotoNum",
                    min: 0,
                    max: 10,
                    unit: "张",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "频繁变道报警拍照间隔:",
                    type: "number",
                    key: "adas45Interval",
                    min: 1,
                    max: 10,
                    unit: "100ms",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "车道偏离报警分级速度阈值:",
                    type: "number",
                    key: "adas41Speed",
                    min: 0,
                    max: 220,
                    unit: "km/h",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "车道偏离报警前后视频录制时间:",
                    type: "number",
                    key: "adas41RecordTime",
                    min: 0,
                    max: 60,
                    unit: "秒",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "车道偏离报警拍照张数:",
                    type: "number",
                    key: "adas41PhotoNum",
                    min: 0,
                    max: 10,
                    unit: "张",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "车道偏离报警拍照间隔:",
                    type: "number",
                    key: "adas41Interval",
                    min: 1,
                    max: 10,
                    unit: "100ms",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "前向碰撞报警时间阈值:",
                    type: "number",
                    key: "adas40Time",
                    min: 10,
                    max: 50,
                    unit: "100ms",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "前向碰撞报警分级速度阈值:",
                    type: "number",
                    key: "adas40Speed",
                    min: 0,
                    max: 220,
                    unit: "km/h",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "前向碰撞报警前后视频录制时间:",
                    type: "number",
                    key: "adas40RecordTime",
                    min: 0,
                    max: 60,
                    unit: "秒",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "前向碰撞报警拍照张数:",
                    type: "number",
                    key: "adas40PhotoNum",
                    min: 0,
                    max: 10,
                    unit: "张",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "前向碰撞报警拍照间隔:",
                    type: "number",
                    key: "adas40Interval",
                    min: 1,
                    max: 10,
                    unit: "100ms",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "行人碰撞报警时间阈值:",
                    type: "number",
                    key: "adas44Time",
                    min: 10,
                    max: 50,
                    unit: "100ms",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "行人碰撞报警使能速度阈值:",
                    type: "number",
                    key: "adas44Speed",
                    min: 0,
                    max: 220,
                    unit: "km/h",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "行人碰撞报警前后视频录制时间:",
                    type: "number",
                    key: "adas44RecordTime",
                    min: 0,
                    max: 60,
                    unit: "秒",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "行人碰撞报警拍照张数:",
                    type: "number",
                    key: "adas44PhotoNum",
                    min: 0,
                    max: 10,
                    unit: "张",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "行人碰撞报警拍照间隔:",
                    type: "number",
                    key: "adas44Interval",
                    min: 1,
                    max: 10,
                    unit: "100ms",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "车距监控报警距离阈值:",
                    type: "number",
                    key: "adas43Distance",
                    min: 10,
                    max: 50,
                    unit: "100ms",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "车距监控报警分级速度阈值:",
                    type: "number",
                    key: "adas43Speed",
                    min: 0,
                    max: 220,
                    unit: "km/h",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "车距过近报警前后视频录制时间:",
                    type: "number",
                    key: "adas43RecordTime",
                    min: 0,
                    max: 60,
                    unit: "秒",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "车距过近报警拍照张数:",
                    type: "number",
                    key: "adas43PhotoNum",
                    min: 0,
                    max: 10,
                    unit: "张",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "车距过近报警拍照间隔:",
                    type: "number",
                    key: "adas43Interval",
                    min: 1,
                    max: 10,
                    unit: "100ms",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "道路标识识别拍照张数:",
                    type: "number",
                    key: "adas46PhotoNum",
                    min: 0,
                    max: 10,
                    unit: "张",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "道路标识识别拍照间隔:",
                    type: "number",
                    key: "adas46Interval",
                    min: 1,
                    max: 10,
                    unit: "100ms",
                    style: {
                        width: "25%"
                    }
                }
            ],
            // 苏标
            drivingAssist0: [
                {
                    id: "rules_management_alarm_level_setting_query",
                    label: "",
                    text: "发送",
                    type: "button",
                    key: "search",
                    itemType: "primary",
                    handler: "search",
                    auth: "user_center_serve_terminal_send",
                    style: {
                        marginLeft: "auto"
                    }
                }
            ],
            // 粤标
            drivingAssist1: [
                {
                    label: "实线变道报警分级速度阈值:",
                    type: "number",
                    key: "asp20",
                    min: 0,
                    max: 220,
                    unit: "km/h",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "实线变道报警前后视频录制时间:",
                    type: "number",
                    key: "asp21",
                    min: 0,
                    max: 60,
                    unit: "秒",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "实线变道报警拍照张数:",
                    type: "number",
                    key: "asp22",
                    min: 0,
                    max: 10,
                    unit: "张",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "实线变道报警拍照间隔:",
                    type: "number",
                    key: "asp23",
                    min: 0,
                    max: 10,
                    unit: "100ms",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "车厢过道行人检测报警分级速度阈值:",
                    type: "number",
                    key: "asp24",
                    min: 0,
                    max: 220,
                    unit: "km/h",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "车厢过道行人检测报警前后视频录制时间:",
                    type: "number",
                    key: "asp25",
                    min: 0,
                    max: 60,
                    unit: "秒",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "车厢过道行人检测报警拍照张数:",
                    type: "number",
                    key: "asp26",
                    min: 0,
                    max: 10,
                    unit: "张",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "车厢过道行人检测报警拍照间隔:",
                    type: "number",
                    key: "asp27",
                    min: 0,
                    max: 10,
                    unit: "100ms",
                    style: {
                        width: "25%"
                    }
                }, {
                    id: "rules_management_alarm_level_setting_query",
                    label: "",
                    text: "发送",
                    type: "button",
                    key: "search",
                    itemType: "primary",
                    handler: "search",
                    auth: "user_center_serve_terminal_send",
                    style: {
                        marginLeft: "auto"
                    }
                }
            ],
            // 浙标
            drivingAssist2: [
                {
                    label: "实线变道报警分级速度阈值:",
                    type: "number",
                    key: "asp20",
                    min: 0,
                    max: 220,
                    unit: "km/h",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "实线变道报警前后视频录制时间:",
                    type: "number",
                    key: "asp21",
                    min: 0,
                    max: 60,
                    unit: "秒",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "实线变道报警拍照张数:",
                    type: "number",
                    key: "asp22",
                    min: 0,
                    max: 10,
                    unit: "张",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "实线变道报警拍照间隔:",
                    type: "number",
                    key: "asp23",
                    min: 0,
                    max: 10,
                    unit: "100ms",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "车厢过道行人检测报警分级速度阈值:",
                    type: "number",
                    key: "asp24",
                    min: 0,
                    max: 220,
                    unit: "km/h",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "车厢过道行人检测报警前后视频录制时间:",
                    type: "number",
                    key: "asp25",
                    min: 0,
                    max: 60,
                    unit: "秒",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "车厢过道行人检测报警拍照张数:",
                    type: "number",
                    key: "asp26",
                    min: 0,
                    max: 10,
                    unit: "张",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "车厢过道行人检测报警拍照间隔:",
                    type: "number",
                    key: "asp27",
                    min: 0,
                    max: 10,
                    unit: "100ms",
                    style: {
                        width: "25%"
                    }
                }, {
                    id: "rules_management_alarm_level_setting_query",
                    label: "",
                    text: "发送",
                    type: "button",
                    key: "search",
                    itemType: "primary",
                    handler: "search",
                    auth: "user_center_serve_terminal_send",
                    style: {
                        marginLeft: "auto"
                    }
                }
            ],
            // 桂标
            drivingAssist3: [
                {
                    id: "rules_management_alarm_level_setting_query",
                    label: "",
                    text: "发送",
                    type: "button",
                    key: "search",
                    itemType: "primary",
                    handler: "search",
                    auth: "user_center_serve_terminal_send",
                    style: {
                        marginLeft: "auto"
                    }
                }
            ],
            // 鲁标
            drivingAssist4: [
                {
                    id: "rules_management_alarm_level_setting_query",
                    label: "",
                    text: "发送",
                    type: "button",
                    key: "search",
                    itemType: "primary",
                    handler: "search",
                    auth: "user_center_serve_terminal_send",
                    style: {
                        marginLeft: "auto"
                    }
                }
            ],
            driverStatus: [
                {
                    label: "报警判断速度阈值:",
                    type: "number",
                    key: "asp1",
                    unit: "km/h",
                    min: 0,
                    max: 60,
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "报警提示音量:",
                    type: "number",
                    key: "asp2",
                    min: 0,
                    max: 8,
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "主动拍照策略:",
                    type: "select",
                    popperClass: "monitor-select",
                    key: "asp3",
                    options: [{
                        label: "不开启",
                        value: "0"
                    }, {
                        label: "定时拍照",
                        value: "1"
                    }, {
                        label: "定距拍照",
                        value: "2"
                    }, {
                        label: "插卡触发",
                        value: "3"
                    }],
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "主动定时拍照时间间隔:",
                    type: "number",
                    key: "asp4",
                    unit: "秒",
                    min: 60,
                    max: 60000,
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "主动定距拍照距离间隔:",
                    type: "number",
                    key: "asp5",
                    min: 0,
                    max: 60000,
                    unit: "米",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "单次主动拍照张数:",
                    type: "number",
                    key: "asp6",
                    min: 1,
                    max: 10,
                    unit: "张",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "单次主动拍照时间间隔:",
                    type: "number",
                    key: "asp7",
                    min: 1,
                    max: 5,
                    unit: "100ms",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "拍照分辨率:",
                    type: "select",
                    popperClass: "monitor-select",
                    key: "asp8",
                    options: [{
                        label: "352x288",
                        value: "1"
                    }, {
                        label: "704x288",
                        value: "2"
                    }, {
                        label: "704x576",
                        value: "3"
                    }, {
                        label: "640x480",
                        value: "4"
                    }, {
                        label: "1280x720",
                        value: "5"
                    }, {
                        label: "1920x1080",
                        value: "6"
                    }],
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "视频录制分辨率:",
                    type: "select",
                    popperClass: "monitor-select",
                    key: "asp9",
                    options: [{
                        label: "CIF",
                        value: "1"
                    }, {
                        label: "HD1",
                        value: "2"
                    }, {
                        label: "D1",
                        value: "3"
                    }, {
                        label: "WD1",
                        value: "4"
                    }, {
                        label: "VGA",
                        value: "5"
                    }, {
                        label: "720P",
                        value: "6"
                    }, {
                        label: "1080P",
                        value: "7"
                    }],
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "报警使能:",
                    type: "select",
                    popperClass: "monitor-select",
                    key: "asps10",
                    options: [{
                        label: "疲劳驾驶一级报警",
                        value: "1"
                    }, {
                        label: "疲劳驾驶二级报警",
                        value: "2"
                    }, {
                        label: "接打手持电话一级报警",
                        value: "4"
                    }, {
                        label: "接打手持电话二级报警",
                        value: "8"
                    }, {
                        label: "抽烟一级报警",
                        value: "16"
                    }, {
                        label: "抽烟二级报警",
                        value: "32"
                    }, {
                        label: "长时间不目视前方一级报警",
                        value: "64"
                    }, {
                        label: "长时间不目视前方二级报警",
                        value: "128"
                    }, {
                        label: "未检测到驾驶员一级报警",
                        value: "512"
                    }],
                    multiple: true,
                    collapseTags: true,
                    style: {
                        width: "37.5%"
                    }
                }, {
                    label: "事件使能:",
                    type: "select",
                    popperClass: "monitor-select",
                    key: "asp11",
                    options: [{
                        label: "道路标识识别",
                        value: "1"
                    }, {
                        label: "主动拍照",
                        value: "2"
                    }],
                    multiple: true,
                    style: {
                        width: "37.5%"
                    }
                }, {
                    label: "疲劳驾驶报警分级速度阈值:",
                    type: "number",
                    key: "dsm89Speed",
                    min: 0,
                    max: 220,
                    unit: "km/h",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "疲劳驾驶报警前后视频录制时间:",
                    type: "number",
                    key: "dsm89RecordTime",
                    min: 0,
                    max: 60,
                    unit: "秒",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "疲劳驾驶报警拍照张数:",
                    type: "input",
                    key: "dsm89PhotoNum",
                    min: 0,
                    max: 10,
                    unit: "张",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "疲劳驾驶报警拍照间隔:",
                    type: "number",
                    key: "dsm89Interval",
                    min: 1,
                    max: 10,
                    unit: "100ms",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "接打电话报警分级速度阈值:",
                    type: "number",
                    key: "dsm80Speed",
                    min: 0,
                    max: 220,
                    unit: "km/h",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "接打电话报警前后视频录制时间:",
                    type: "number",
                    key: "dsm80RecordTime",
                    min: 0,
                    max: 60,
                    unit: "秒",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "接打电话报警拍驾驶员面部特征照片张数:",
                    type: "number",
                    key: "dsm80PhotoNum",
                    min: 1,
                    max: 10,
                    unit: "张",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "接打电话报警拍驾驶员面部特征照片间隔:",
                    type: "number",
                    key: "dsm80Interval",
                    min: 1,
                    max: 10,
                    unit: "100ms",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "接打电话报警判断时间间隔:",
                    type: "number",
                    key: "dsm80JudgeInterval",
                    min: 0,
                    max: 3600,
                    unit: "秒",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "抽烟报警分级速度阈值:",
                    type: "number",
                    key: "dsm81Speed",
                    min: 0,
                    max: 220,
                    unit: "km/h",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "抽烟报警前后视频录制时间:",
                    type: "number",
                    key: "dsm81RecordTime",
                    min: 0,
                    max: 60,
                    unit: "秒",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "抽烟报警拍驾驶员面部特征照片张数:",
                    type: "number",
                    key: "dsm81PhotoNum",
                    min: 1,
                    max: 10,
                    unit: "张",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "抽烟报警拍驾驶员面部特征照片间隔:",
                    type: "number",
                    key: "dsm81Interval",
                    min: 1,
                    max: 10,
                    unit: "100ms",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "抽烟报警判断时间间隔:",
                    type: "number",
                    key: "dsm81JudgeInterval",
                    min: 0,
                    max: 3600,
                    unit: "秒",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "分神驾驶报警分级速度阈值:",
                    type: "number",
                    key: "dsm90Speed",
                    min: 0,
                    max: 220,
                    unit: "km/h",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "分神驾驶报警前后视频录制时间:",
                    type: "number",
                    key: "dsm90RecordTime",
                    min: 0,
                    max: 60,
                    unit: "秒",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "分神驾驶报警拍照张数:",
                    type: "number",
                    key: "dsm90PhotoNum",
                    min: 1,
                    max: 10,
                    unit: "张",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "分神驾驶报警拍照间隔:",
                    type: "number",
                    key: "dsm90Interval",
                    min: 1,
                    max: 10,
                    unit: "100ms",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "驾驶行为异常分级速度阈值:",
                    type: "number",
                    key: "dsm91Speed",
                    min: 0,
                    max: 220,
                    unit: "km/h",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "驾驶行为异常前后视频录制时间:",
                    type: "number",
                    key: "dsm91RecordTime",
                    min: 0,
                    max: 60,
                    unit: "秒",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "驾驶行为异常抓拍照片张数:",
                    type: "number",
                    key: "dsm91PhotoNum",
                    min: 1,
                    max: 10,
                    unit: "张",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "驾驶行为异常拍照间隔:",
                    type: "number",
                    key: "dsm91Interval",
                    min: 1,
                    max: 10,
                    unit: "100ms",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "驾驶员身份识别触发:",
                    type: "select",
                    popperClass: "monitor-select",
                    key: "dsm91Identification",
                    options: [{
                        label: "不开启",
                        value: "0"
                    }, {
                        label: "定时触发",
                        value: "1"
                    }, {
                        label: "定距触发",
                        value: "2"
                    }, {
                        label: "插卡开始行驶触发",
                        value: "3"
                    }],
                    style: {
                        width: "25%"
                    }
                }
            ],
            driverStatus0: [
                {
                    id: "rules_management_alarm_level_setting_query",
                    label: "",
                    text: "发送",
                    type: "button",
                    key: "search",
                    itemType: "primary",
                    handler: "search",
                    auth: "user_center_serve_terminal_send",
                    style: {
                        marginLeft: "auto"
                    }
                }
            ],
            driverStatus1: [
                {
                    label: "摄像头遮挡报警分级速度阈值:",
                    type: "number",
                    key: "asp30",
                    min: 0,
                    max: 220,
                    unit: "km/h",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "不系安全带报警分级速度阈值:",
                    type: "number",
                    key: "asp31",
                    min: 0,
                    max: 220,
                    unit: "km/h",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "不系安全带报警前后视频录制时间:",
                    type: "number",
                    key: "asp32",
                    min: 0,
                    max: 60,
                    unit: "秒",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "不系安全带报警抓拍照片张数:",
                    type: "number",
                    key: "asp33",
                    min: 1,
                    max: 10,
                    unit: "张",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "不系安全带报警抓拍照片间隔时间:",
                    type: "number",
                    key: "asp34",
                    min: 1,
                    max: 5,
                    unit: "100ms",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "红外墨镜阻断失效报警分级速度阈值:",
                    type: "number",
                    key: "asp35",
                    min: 0,
                    max: 220,
                    unit: "km/h",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "红外墨镜阻断失效报警前后视频录制时间:",
                    type: "number",
                    key: "asp36",
                    min: 0,
                    max: 60,
                    unit: "秒",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "红外墨镜阻断失效报警抓拍照片张数:",
                    type: "number",
                    key: "asp37",
                    min: 1,
                    max: 10,
                    unit: "张",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "红外墨镜阻断失效报警抓拍照片间隔时间:",
                    type: "number",
                    key: "asp38",
                    min: 1,
                    max: 5,
                    unit: "100ms",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "双脱把报警分级速度阈值:",
                    type: "number",
                    key: "asp39",
                    min: 0,
                    max: 220,
                    unit: "km/h",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "双脱把报警前后视频录制时间:",
                    type: "number",
                    key: "asp40",
                    min: 0,
                    max: 60,
                    unit: "秒",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "双脱把报警抓拍照片张数:",
                    type: "number",
                    key: "asp41",
                    min: 1,
                    max: 10,
                    unit: "张",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "双脱把报警抓拍照片间隔时间:",
                    type: "number",
                    key: "asp42",
                    min: 1,
                    max: 5,
                    unit: "100ms",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "玩手机报警分级速度阈值:",
                    type: "number",
                    key: "asp43",
                    min: 0,
                    max: 220,
                    unit: "km/h",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "玩手机报警前后视频录制时间:",
                    type: "number",
                    key: "asp44",
                    min: 0,
                    max: 60,
                    unit: "秒",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "玩手机报警抓拍照片张数:",
                    type: "number",
                    key: "asp45",
                    min: 1,
                    max: 10,
                    unit: "张",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "玩手机报警拍抓拍照片间隔时间:",
                    type: "number",
                    key: "asp46",
                    min: 1,
                    max: 5,
                    unit: "100ms",
                    style: {
                        width: "25%"
                    }
                }, {
                    id: "rules_management_alarm_level_setting_query",
                    label: "",
                    text: "发送",
                    type: "button",
                    key: "search",
                    itemType: "primary",
                    handler: "search",
                    auth: "user_center_serve_terminal_send",
                    style: {
                        marginLeft: "auto"
                    }
                }
            ],
            driverStatus2: [
                {
                    label: "换人驾驶报警触发车速阈值:",
                    type: "number",
                    key: "asp30",
                    min: 0,
                    max: 220,
                    unit: "km/h",
                    style: {
                        width: "25%"
                    }
                },
                {
                    label: "换人驾驶报警前后视频录制时间:",
                    type: "number",
                    key: "asp31",
                    min: 0,
                    max: 60,
                    unit: "秒",
                    style: {
                        width: "25%"
                    }
                },
                {
                    label: "换人驾驶报警拍照张数:",
                    type: "number",
                    key: "asp32",
                    min: 1,
                    max: 10,
                    unit: "张",
                    style: {
                        width: "25%"
                    }
                },
                {
                    label: "换人驾驶报警拍照间隔时间:",
                    type: "number",
                    key: "asp33",
                    min: 1,
                    max: 5,
                    unit: "100ms",
                    style: {
                        width: "25%"
                    }
                },
                {
                    label: "超时驾驶报警触发车速阈值:",
                    type: "number",
                    key: "asp34",
                    min: 0,
                    max: 220,
                    unit: "km/h",
                    style: {
                        width: "25%"
                    }
                },
                {
                    label: "超时驾驶报警前后视频录制时间:",
                    type: "number",
                    key: "asp35",
                    min: 0,
                    max: 60,
                    unit: "秒",
                    style: {
                        width: "25%"
                    }
                },
                {
                    label: "超时驾驶报警拍照张数:",
                    type: "number",
                    key: "asp36",
                    min: 1,
                    max: 10,
                    unit: "张",
                    style: {
                        width: "25%"
                    }
                },
                {
                    label: "超时驾驶报警拍照间隔时间:",
                    type: "number",
                    key: "asp37",
                    min: 1,
                    max: 5,
                    unit: "100ms",
                    style: {
                        width: "25%"
                    }
                },
                {
                    label: "日间连续驾驶时间阈值:",
                    type: "number",
                    key: "asp38",
                    min: 0,
                    max: 24,
                    unit: "h",
                    style: {
                        width: "25%"
                    }
                },
                {
                    label: "夜间连续驾驶时间阈值:",
                    type: "number",
                    key: "asp39",
                    min: 0,
                    max: 24,
                    unit: "h",
                    style: {
                        width: "25%"
                    }
                },
                {
                    label: "最小停车休息时间阈值:",
                    type: "number",
                    key: "asp40",
                    min: 0,
                    max: 1440,
                    unit: "min",
                    style: {
                        width: "25%"
                    }
                },
                {
                    label: "夜间定义时段:",
                    type: "number",
                    key: "asp41",
                    min: 0,
                    max: 24,
                    unit: "h",
                    style: {
                        width: "25%"
                    }
                },
                {
                    label: "24小时累计驾驶时间阈值:",
                    type: "number",
                    key: "asp42",
                    min: 0,
                    max: 24,
                    unit: "h",
                    style: {
                        width: "25%"
                    }
                }, {
                    id: "rules_management_alarm_level_setting_query",
                    label: "",
                    text: "发送",
                    type: "button",
                    key: "search",
                    itemType: "primary",
                    handler: "search",
                    auth: "user_center_serve_terminal_send",
                    style: {
                        marginLeft: "auto"
                    }
                }
            ],
            driverStatus3: [
                {
                    id: "rules_management_alarm_level_setting_query",
                    label: "",
                    text: "发送",
                    type: "button",
                    key: "search",
                    itemType: "primary",
                    handler: "search",
                    auth: "user_center_serve_terminal_send",
                    style: {
                        marginLeft: "auto"
                    }
                }
            ],
            driverStatus4: [
                {
                    id: "rules_management_alarm_level_setting_query",
                    label: "",
                    text: "发送",
                    type: "button",
                    key: "search",
                    itemType: "primary",
                    handler: "search",
                    auth: "user_center_serve_terminal_send",
                    style: {
                        marginLeft: "auto"
                    }
                }
            ],
            tirePressure: [
                {
                    label: "轮胎规格型号:",
                    type: "input",
                    key: "tpms1",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "轮胎规格型号:",
                    type: "input",
                    key: "tpms1Copy",
                    style: {
                        width: "25%",
                        display: "none"
                    }
                }, {
                    label: "轮胎规格型号:",
                    type: "input",
                    key: "tpms1Copy",
                    style: {
                        width: "25%",
                        display: "none"
                    }
                }, {
                    label: "胎压单位:",
                    type: "radio",
                    key: "tpms2",
                    group: [
                        {
                            label: "0",
                            name: "kg/cm2"
                        },
                        {
                            label: "1",
                            name: "bar"
                        },
                        {
                            label: "2",
                            name: "Kpa"
                        },
                        {
                            label: "3",
                            name: "PSI"
                        }
                    ],
                    style: {
                        width: "75%"
                    }
                }, {
                    label: "正常胎压值:",
                    type: "number",
                    key: "tpms3",
                    inputType: "number",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "胎压不平衡门限:",
                    type: "number",
                    key: "tpms4",
                    min: 0,
                    max: 100,
                    unit: "%",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "慢漏气门限:",
                    type: "number",
                    key: "tpms5",
                    min: 0,
                    max: 100,
                    unit: "%",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "低压阈值:",
                    type: "number",
                    key: "tpms6",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "高压阈值:",
                    type: "number",
                    key: "tpms7",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "高温阈值:",
                    type: "number",
                    key: "tpms8",
                    unit: "℃",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "电压阈值:",
                    type: "number",
                    key: "tpms9",
                    unit: "%",
                    min: 0,
                    max: 100,
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "定时上报时间间隔:",
                    type: "number",
                    key: "tpms10",
                    min: 0,
                    max: 3600,
                    unit: "秒",
                    style: {
                        width: "25%"
                    }
                }, {
                    id: "rules_management_alarm_level_setting_query",
                    label: "",
                    text: "发送",
                    type: "button",
                    key: "search",
                    itemType: "primary",
                    handler: "search",
                    auth: "user_center_serve_terminal_send",
                    style: {
                        marginLeft: "auto"
                    }
                }
            ],
            deadZone: [
                {
                    label: "后方接近报警时间阈值:",
                    type: "number",
                    key: "bsd1",
                    min: 1,
                    max: 10,
                    unit: "秒",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "侧后方接近报警时间阈值:",
                    type: "number",
                    key: "bsd2",
                    min: 1,
                    max: 10,
                    unit: "秒",
                    style: {
                        width: "25%"
                    }
                }, {
                    id: "rules_management_alarm_level_setting_query",
                    label: "",
                    text: "发送",
                    type: "button",
                    key: "search",
                    itemType: "primary",
                    handler: "search",
                    auth: "user_center_serve_terminal_send",
                    style: {
                        marginLeft: "auto"
                    }
                }
            ],
            intenseDrive: [
                {
                    label: "激烈驾驶报警使能:",
                    type: "select",
                    key: "idfp1",
                    multiple: true,
                    collapseTags: true,
                    options: [
                        {
                            label: "急加速报警",
                            value: "1"
                        },
                        {
                            label: "急减速报警",
                            value: "2"
                        },
                        {
                            label: "急转弯报警",
                            value: "4"
                        },
                        {
                            label: "怠速报警",
                            value: "8"
                        },
                        {
                            label: "异常熄火报警",
                            value: "16"
                        },
                        {
                            label: "空档滑行报警",
                            value: "32"
                        },
                        {
                            label: "发动机超转报警",
                            value: "64"
                        }
                    ],
                    style: {
                        width: "50%"
                    }
                }, {
                    label: "急加速报警时间阈值:",
                    key: "idfp2",
                    ...intenseDrive1
                }, {
                    label: "急加速报警重力加速度阈值:",
                    key: "idfp3",
                    ...intenseDrive2
                }, {
                    label: "急减速报警时间阈值:",
                    key: "idfp4",
                    ...intenseDrive1
                }, {
                    label: "急减速报警重力加速度阈值:",
                    key: "idfp5",
                    ...intenseDrive2
                }, {
                    label: "急转弯报警时间阈值:",
                    key: "idfp6",
                    ...intenseDrive1
                }, {
                    label: "急转弯报警重力加速度阈值:",
                    key: "idfp7",
                    ...intenseDrive2
                }, {
                    label: "怠速报警时间阈值:",
                    key: "idfp8",
                    type: "number",
                    min: 1,
                    max: 600,
                    unit: "秒",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "怠速报警车速阈值:",
                    key: "idfp9",
                    type: "number",
                    min: 1,
                    max: 30,
                    unit: "km/h",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "怠速报警发动机转速阈值:",
                    key: "idfp10",
                    type: "number",
                    min: 1,
                    max: 2000,
                    unit: "rpm",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "异常熄火报警时间阈值:",
                    key: "idfp11",
                    type: "number",
                    min: 1,
                    max: 30,
                    unit: "秒",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "异常熄火报警车速阈值:",
                    key: "idfp12",
                    type: "number",
                    min: 10,
                    max: 200,
                    unit: "km/h",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "异常熄火报警发动机转速阈值:",
                    key: "idfp13",
                    type: "number",
                    min: 1,
                    max: 1000,
                    unit: "rpm",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "空挡滑行报警时间阈值:",
                    type: "number",
                    key: "idfp14",
                    min: 1,
                    max: 30,
                    unit: "秒",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "空挡滑行报警车速阈值:",
                    type: "number",
                    key: "idfp15",
                    min: 10,
                    max: 200,
                    unit: "km/h",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "空档滑行报警发动机转速阈值:",
                    type: "number",
                    key: "idfp16",
                    min: 1,
                    max: 2000,
                    unit: "rpm",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "发动机超转报警时间阈值:",
                    type: "number",
                    key: "idfp17",
                    min: 1,
                    max: 60,
                    unit: "秒",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "发动机超转报警车速阈值:",
                    type: "number",
                    key: "idfp18",
                    min: 10,
                    max: 200,
                    unit: "km/h",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "发动机超转报警发动机转速阈值:",
                    type: "number",
                    key: "idfp19",
                    min: 1000,
                    max: 6000,
                    unit: "rpm",
                    style: {
                        width: "25%"
                    }
                }, {
                    id: "rules_management_alarm_level_setting_query",
                    label: "",
                    text: "发送",
                    type: "button",
                    key: "search",
                    itemType: "primary",
                    handler: "search",
                    auth: "user_center_serve_terminal_send",
                    style: {
                        marginLeft: "auto"
                    }
                }
            ]
        },
        listHeader: [
            {
                label: "车牌号码",
                prop: "name",
                render(row) {
                    return row.name || "--";
                }
            },
            {
                label: "终端标识",
                prop: "vid",
                render(row) {
                    return row.vid || "--";
                }
            },
            {
                label: "发送状态",
                prop: "sendStatus",
                width: utils.setFontSize(180),
                render(row) {
                    return row.sendStatus || "--";
                }
            }
        ],
        listSearchHeader: [
            {
                label: "响应状态",
                prop: "upStatus",
                width: utils.setFontSize(180),
                render(row) {
                    return row.upStatus || "--";
                }
            },
            {
                label: "查看",
                handler: "onDetail",
                prop: "terminalShow",
                tooltip: true,
                auth: "user_center_rule_arule_fatigue_delete",
                width: utils.setFontSize(180)
            }
        ]
    },
    terminalControl: {
        searchObj: {
            terminalSwitch: [
                {
                    label: "",
                    type: "radio",
                    key: "limiSwitch",
                    group: [{
                        label: 3,
                        name: "关机"
                    }, {
                        label: 4,
                        name: "复位"
                    }, {
                        label: 5,
                        name: "恢复出厂设置"
                    }, {
                        label: 6,
                        name: "关闭数据通信"
                    }, {
                        label: 7,
                        name: "关闭全部通信"
                    }],
                    style: {
                        width: "80%"
                    }
                }, {
                    id: "rules_management_alarm_level_setting_query",
                    label: "",
                    text: "发送",
                    type: "button",
                    key: "send",
                    itemType: "primary",
                    handler: "send",
                    auth: "user_center_serve_terminal_send",
                    style: {
                        marginLeft: "auto"
                    }
                }
            ],
            unlimitedUpgrade: [
                {
                    label: "URL地址:",
                    type: "input",
                    key: "ulug1",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "拨号用户:",
                    type: "input",
                    key: "ulug2",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "APN:",
                    type: "input",
                    key: "ulug3",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "拨号密码:",
                    type: "input",
                    key: "ulug4",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "服务器地址:",
                    type: "input",
                    key: "ulug5",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "制造商ID:",
                    type: "input",
                    key: "ulug6",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "TCP端口:",
                    type: "number",
                    key: "ulug7",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "硬件版本:",
                    type: "input",
                    key: "ulug8",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "UDP端口:",
                    type: "number",
                    key: "ulug9",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "固件版本:",
                    type: "input",
                    key: "ulug10",
                    style: {
                        width: "25%"
                    }
                }, {
                    label: "连接服务器时限:",
                    type: "input",
                    key: "ulug11",
                    unit: "分",
                    style: {
                        width: "25%"
                    }
                }, {
                    id: "rules_management_alarm_level_setting_query",
                    label: "",
                    text: "查询",
                    type: "button",
                    key: "search",
                    itemType: "primary",
                    handler: "search",
                    auth: "user_center_serve_terminal_send",
                    style: {
                        marginLeft: "auto",
                        marginRight: "20px"
                    }
                }, {
                    id: "rules_management_alarm_level_setting_query",
                    label: "",
                    text: "发送",
                    type: "button",
                    key: "send",
                    itemType: "primary",
                    handler: "send",
                    auth: "user_center_serve_terminal_send"
                }
            ]
        },
        listHeader: [
            {
                label: "车牌号码",
                prop: "name",
                render(row) {
                    return row.name || "--";
                }
            },
            {
                label: "终端标识",
                prop: "vid",
                render(row) {
                    return row.vid || "--";
                }
            },
            {
                label: "发送状态",
                prop: "sendStatus",
                render(row) {
                    return row.sendStatus || "--";
                }
            }
        ],
        listSearchHeader: [
            {
                label: "升级状态",
                prop: "upgradeStatus",
                render(row) {
                    let upgradeStatus = { // 升级状态
                        1: "固件下载中",
                        2: "固件下载成功",
                        3: "固件下载失败",
                        4: "固件安装中",
                        5: "安装成功",
                        6: "安装失败",
                        7: "未找到目标设备",
                        8: "硬件型号不支持",
                        9: "软件版本相同",
                        10: "软件版本不支持",
                        11: "其他"
                    };
                    return upgradeStatus[row.upgradeStatus] || "--";
                }
            }, {
                label: "升级类型",
                prop: "upgradeType",
                render(row) {
                    let upgradeType = { // 升级类型
                        0: "终端",
                        12: "道路运输证IC卡读卡器",
                        52: "北斗定位模块",
                        100: "驾驶辅助功能模块",
                        101: "驾驶员行为监测模块",
                        102: "轮胎状态监测模块",
                        103: "盲区监测模块"
                    };
                    return upgradeType[row.upgradeType] || "--";
                }
            }, {
                label: "升级进度(%)",
                prop: "upgradeProcess",
                render(row) {
                    return row.upgradeProcess || "--";
                }
            }, {
                label: "升级结果",
                prop: "upgradeResult",
                render(row) {
                    let upgradeResult = { // 升级结果
                        0: "成功",
                        1: "失败",
                        2: "取消",
                        16: "未找到目标设备",
                        17: "硬件型号不支持",
                        18: "软件版本相同",
                        19: "软件版本不支持"
                    };
                    return upgradeResult[row.upgradeResult] || "--";
                }
            }
        ],
        listSuSearchHeader: [
            {
                label: "升级类型",
                prop: "upgradeType",
                render(row) {
                    let upgradeType = { // 升级类型
                        0: "终端",
                        12: "道路运输证IC卡读卡器",
                        52: "北斗定位模块",
                        100: "驾驶辅助功能模块",
                        101: "驾驶员行为监测模块",
                        102: "轮胎状态监测模块",
                        103: "盲区监测模块"
                    };
                    return upgradeType[row.upgradeType] || "--";
                }
            }, {
                label: "升级结果",
                prop: "upgradeResult",
                render(row) {
                    let upgradeResult = { // 升级结果
                        "-1": "等待中",
                        0: "成功",
                        1: "失败",
                        2: "取消",
                        16: "未找到目标设备",
                        17: "硬件型号不支持",
                        18: "软件版本相同",
                        19: "软件版本不支持"
                    };
                    return upgradeResult[row.upgradeResult] || "--";
                }
            }
        ]
    },
    locationOrder: {
        searchObj: {
            callLocation: [
                {
                    id: "rules_management_alarm_level_setting_query",
                    label: "",
                    text: "发送",
                    type: "button",
                    key: "search",
                    itemType: "primary",
                    handler: "search",
                    auth: "user_center_serve_terminal_send"
                }
            ],
            temporaryTrack: [
                {
                    label: "发送类型",
                    type: "radio",
                    key: "sendType",
                    group: [{
                        label: 1,
                        name: "开始跟踪"
                    }, {
                        label: 0,
                        name: "停止跟踪"
                    }],
                    style: {
                        width: "30%"
                    },
                    handler: "onSendType"
                }, {
                    label: "定位间隔",
                    type: "number",
                    key: "0",
                    max: 65535,
                    min: 0,
                    unit: "秒",
                    style: {
                        width: "30%"
                    }
                }, {
                    label: "跟踪时长",
                    type: "number",
                    key: "1",
                    max: Math.pow(2, 32) - 1,
                    min: -1,
                    unit: "秒",
                    style: {
                        width: "30%"
                    }
                }, {
                    id: "rules_management_alarm_level_setting_query",
                    label: "",
                    text: "发送",
                    type: "button",
                    key: "search",
                    itemType: "primary",
                    handler: "search",
                    auth: "user_center_serve_terminal_send",
                    style: {
                        marginLeft: "auto"
                    }
                }
            ]
        },
        listHeader: [
            {
                label: "车牌号码",
                prop: "name",
                render(row) {
                    return row.name || "--";
                }
            },
            {
                label: "终端标识",
                prop: "vid",
                render(row) {
                    return row.vid || "--";
                }
            },
            {
                label: "发送状态",
                prop: "sendStatus",
                width: utils.setFontSize(180),
                render(row) {
                    return row.sendStatus || "--";
                }
            }
        ],
        listSearchHeader: [
            {
                label: "响应状态",
                prop: "upStatus",
                width: utils.setFontSize(180),
                render(row) {
                    return row.upStatus || "--";
                }
            },
            {
                label: "查看",
                handler: "onDetail",
                prop: "terminalShow",
                tooltip: true,
                auth: "user_center_rule_arule_fatigue_delete",
                width: utils.setFontSize(180)
            }
        ]
    },
    informationOrder: {
        searchObj: {
            // textIssue2019和textIssue全并，统一用textIssue2019
            textIssue2019: [
                // {
                //     label: "发送类型",
                //     type: "radio",
                //     key: "category",
                //     group: [{
                //         label: 1,
                //         name: "服务"
                //     }, {
                //         label: 2,
                //         name: "紧急"
                //     }, {
                //         label: 3,
                //         name: "通知"
                //     }],
                //     style: {
                //         width: "30%"
                //     }
                // }, 
                {
                    label: "发送类型",
                    type: "checkbox",
                    key: "sendType",
                    options: [
                        {
                            value: 4,
                            label: "显示屏显示"
                        }, {
                            value: 8,
                            label: "TTS语音"
                        }, 
                        // {
                        //     value: 16,
                        //     label: "广告屏显示"
                        // }
                    ],
                    style: {
                        width: "30%"
                    }
                }, 
                // {
                //     label: "",
                //     type: "radio",
                //     key: "centerType",
                //     group: [{
                //         label: 0,
                //         name: "中心导航信息"
                //     }, {
                //         label: 32,
                //         name: "CAN故障码信息"
                //     }],
                //     style: {
                //         width: "40%"
                //     }
                // }, 
                // {
                //     label: "文本类型",
                //     type: "radio",
                //     key: "textType",
                //     group: [{
                //         label: 1,
                //         name: "通知"
                //     }, {
                //         label: 2,
                //         name: "服务"
                //     }],
                //     style: {
                //         width: "100%"
                //     }
                // }, 
                {
                    label: "文本内容",
                    type: "input",
                    key: "textMsg",
                    style: {
                        width: "80%"
                    }
                }, {
                    label: "文本内容",
                    type: "input",
                    key: "textMsgCopy",
                    style: {
                        width: "80%",
                        display: "none"
                    }
                }, {
                    label: "文本内容",
                    type: "input",
                    key: "textMsgCopy",
                    style: {
                        width: "80%",
                        display: "none"
                    }
                },
                {
                    id: "rules_management_alarm_level_setting_query",
                    label: "",
                    text: "发送",
                    type: "button",
                    key: "send",
                    itemType: "primary",
                    handler: "send",
                    auth: "user_center_serve_terminal_send",
                    style: {
                        marginLeft: "auto"
                    }
                }
            ],
            textIssue: [
                {
                    label: "发送类型",
                    type: "checkbox",
                    key: "signBit",
                    options: [
                        {
                            value: 2,
                            label: "紧急"
                        }, 
                        {
                            value: 4,
                            label: "显示屏显示"
                        }, {
                            value: 8,
                            label: "TTS语音"
                        }, 
                        {
                            value: 16,
                            label: "广告屏显示"
                        }
                    ],
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "文本内容",
                    type: "input",
                    key: "textMsg",
                    style: {
                        width: "80%"
                    }
                }, {
                    label: "文本内容",
                    type: "input",
                    key: "textMsgCopy",
                    style: {
                        width: "80%",
                        display: "none"
                    }
                },
                {
                    id: "rules_management_alarm_level_setting_query",
                    label: "",
                    text: "发送",
                    type: "button",
                    key: "send",
                    itemType: "primary",
                    handler: "send",
                    auth: "user_center_serve_terminal_send",
                    style: {
                        marginLeft: "auto"
                    }
                }
            ],
            askIssue: [
                {
                    label: "发送类型",
                    type: "checkbox",
                    key: "signBit",
                    options: [
                        // {
                        //     value: 2,
                        //     label: "紧急"
                        // }, 
                        {
                            value: 4,
                            label: "显示屏显示"
                        }, {
                            value: 8,
                            label: "TTS语音"
                        }, 
                        // {
                        //     value: 16,
                        //     label: "广告屏显示"
                        // }
                    ],
                    style: {
                        width: "100%"
                    }
                }, {
                    label: "问题内容",
                    type: "input",
                    key: "issueCnt",
                    style: {
                        width: "80%"
                    }
                }, {
                    label: "候选答案一",
                    type: "input",
                    key: "answerCnt1",
                    style: {
                        width: "80%"
                    }
                }, {
                    label: "候选答案二",
                    type: "input",
                    key: "answerCnt2",
                    style: {
                        width: "80%"
                    }
                }, {
                    label: "候选答案三",
                    type: "input",
                    key: "answerCnt3",
                    style: {
                        width: "80%"
                    }
                }, {
                    label: "候选答案三",
                    type: "input",
                    key: "answerCnt3Copy",
                    style: {
                        width: "80%",
                        display: "none"
                    }
                }, {
                    label: "候选答案三",
                    type: "input",
                    key: "answerCnt3Copy",
                    style: {
                        width: "80%",
                        display: "none"
                    }
                }, {
                    label: "候选答案三",
                    type: "input",
                    key: "answerCnt3Copy",
                    style: {
                        width: "80%",
                        display: "none"
                    }
                }, {
                    label: "候选答案三",
                    type: "input",
                    key: "answerCnt3Copy",
                    style: {
                        width: "80%",
                        display: "none"
                    }
                }, {
                    label: "候选答案三",
                    type: "input",
                    key: "answerCnt3Copy",
                    style: {
                        width: "80%",
                        display: "none"
                    }
                }, {
                    label: "候选答案三",
                    type: "input",
                    key: "answerCnt3Copy",
                    style: {
                        width: "80%",
                        display: "none"
                    }
                }, {
                    label: "候选答案三",
                    type: "input",
                    key: "answerCnt3Copy",
                    style: {
                        width: "80%",
                        display: "none"
                    }
                }, {
                    label: "候选答案三",
                    type: "input",
                    key: "answerCnt3Copy",
                    style: {
                        width: "80%",
                        display: "none"
                    }
                }, {
                    label: "候选答案三",
                    type: "input",
                    key: "answerCnt3Copy",
                    style: {
                        width: "80%",
                        display: "none"
                    }
                }, {
                    label: "候选答案三",
                    type: "input",
                    key: "answerCnt3Copy",
                    style: {
                        width: "80%",
                        display: "none"
                    }
                }, {
                    label: "候选答案三",
                    type: "input",
                    key: "answerCnt3Copy",
                    style: {
                        width: "80%",
                        display: "none"
                    }
                }, {
                    label: "候选答案三",
                    type: "input",
                    key: "answerCnt3Copy",
                    style: {
                        width: "80%",
                        display: "none"
                    }
                }, {
                    id: "rules_management_alarm_level_setting_query",
                    label: "",
                    text: "发送",
                    type: "button",
                    key: "send",
                    itemType: "primary",
                    handler: "send",
                    auth: "user_center_serve_terminal_send",
                    style: {
                        marginLeft: "auto"
                    }
                }
            ],
            eventSetting: [
                {
                    label: "发送类型",
                    type: "radio",
                    key: "action",
                    group: [{
                        label: 0,
                        name: "删除所有事件"
                    }, {
                        label: 1,
                        name: "先删除再更新新事件"
                    }, {
                        label: 2,
                        name: "追加事件"
                    }, {
                        label: 3,
                        name: "修改事件"
                    }, {
                        label: 4,
                        name: "删除指定事件"
                    }],
                    style: {
                        width: "70%"
                    },
                    handler: "onEvent"
                }, {
                    id: "rules_management_alarm_level_setting_query",
                    label: "",
                    text: "发送",
                    type: "button",
                    key: "send",
                    itemType: "primary",
                    handler: "send",
                    auth: "user_center_serve_terminal_send",
                    style: {
                        marginLeft: "auto"
                    }
                }
            ],
            infoMenuSetting: [
                {
                    label: "发送类型",
                    type: "radio",
                    key: "action",
                    group: [{
                        label: 0,
                        name: "删除所有菜单"
                    }, {
                        label: 1,
                        name: "更新已有菜单"
                    }, {
                        label: 2,
                        name: "追加菜单"
                    }, {
                        label: 3,
                        name: "修改菜单"
                    }],
                    style: {
                        width: "70%"
                    },
                    handler: "onInfoMenu"
                }, {
                    id: "rules_management_alarm_level_setting_query",
                    label: "",
                    text: "发送",
                    type: "button",
                    key: "send",
                    itemType: "primary",
                    handler: "send",
                    auth: "user_center_serve_terminal_send",
                    style: {
                        marginLeft: "auto"
                    }
                }
            ]
        },
        textIssueManage: {
            listHeader: [
                {
                    label: "短语内容",
                    prop: "phraseContent",
                    render(row) {
                        return row.phraseContent || "--";
                    }
                }
            ],
            addTextIssue: [
                {
                    label: "短语内容",
                    type: "input",
                    key: "phraseContent",
                    style: {
                        width: "100%"
                    },
                    rules: [{required: true, message: "请选择短语内容", trigger: "blur"}]
                }
            ]
        },
        eventSetteManage: {
            listHeader: [{
                label: "事件ID",
                prop: "eventId",
                render(row) {
                    return row.eventId || "--";
                }
            }, {
                label: "事件内容",
                prop: "eventContent",
                render(row) {
                    return row.eventContent || "--";
                }
            }, {
                label: "操作",
                option: [{
                    label: "修改",
                    handler: "onEdit",
                }, {
                    label: "删除",
                    handler: "onDelete",
                }]
            }],
            addItem: [{
                label: "事件ID",
                type: "number",
                min: 0,
                max: 255,
                key: "eventId",
                style: {
                    width: "100%"
                },
                rules: [{required: true, message: "请选择事件ID", trigger: "blur"}]
            }, {
                label: "事件内容",
                type: "input",
                key: "eventContent",
                style: {
                    width: "100%"
                },
                rules: [{required: true, message: "请选择事件内容", trigger: "blur"}]
            }]
        },
        infoMenuManage: {
            listHeader: [{
                label: "菜单ID",
                prop: "menuId",
                render(row) {
                    return row.menuId || "--";
                }
            }, {
                label: "菜单内容",
                prop: "menuContent",
                render(row) {
                    return row.menuContent || "--";
                }
            }, {
                label: "操作",
                option: [{
                    label: "修改",
                    handler: "onEdit",
                }, {
                    label: "删除",
                    handler: "onDelete",
                }]
            }],
            addItem: [{
                label: "菜单ID",
                type: "number",
                min: 0,
                max: 255,
                key: "menuId",
                style: {
                    width: "100%"
                },
                rules: [{required: true, message: "请选择事件ID", trigger: "blur"}]
            }, {
                label: "菜单内容",
                type: "input",
                key: "menuContent",
                style: {
                    width: "100%"
                },
                rules: [{required: true, message: "请选择事件内容", trigger: "blur"}]
            }]
        },
        listHeader: [
            {
                label: "车牌号码",
                prop: "name",
                render(row) {
                    return row.name || "--";
                }
            },
            {
                label: "终端标识",
                prop: "vid",
                render(row) {
                    return row.vid || "--";
                }
            },
            {
                label: "发送状态",
                prop: "sendStatus",
                // width: utils.setFontSize(180),
                render(row) {
                    return row.sendStatus || "--";
                }
            }
        ]
    },
    videoOrder: {
        searchObj: {
            attrQuery: [
                {
                    id: "rules_management_alarm_level_setting_query",
                    label: "",
                    text: "发送指令",
                    type: "button",
                    key: "send",
                    itemType: "primary",
                    handler: "send",
                    auth: "user_center_serve_terminal_send",
                    style: {
                        margin: "0 auto"
                    }
                }
            ],
            paramsSetting: [
                {
                    label: "实时流编码模式",
                    type: "select",
                    popperClass: "monitor-select",
                    key: "realMode",
                    options: [{
                        value: 0,
                        label: "CBR(固定码率)"
                    }, {
                        value: 1,
                        label: "VBR(可变码率)"
                    }, {
                        value: 2,
                        label: "CBR(平均码率)"
                    }],
                    style: {
                        width: "33.3%"
                    }
                }, {
                    label: "实时流分辨率:",
                    type: "select",
                    popperClass: "monitor-select",
                    key: "realDpi",
                    options: [{
                        value: 0,
                        label: "QCIF"
                    }, {
                        value: 1,
                        label: "CIF"
                    }, {
                        value: 2,
                        label: "WCIF"
                    }, {
                        value: 3,
                        label: "DI"
                    }, {
                        value: 4,
                        label: "WDI"
                    }, {
                        value: 5,
                        label: "720P"
                    }, {
                        value: 6,
                        label: "1080P"
                    }],
                    style: {
                        width: "33.3%"
                    }
                }, {
                    label: "实时流关键帧间隔:",
                    type: "input",
                    key: "realItvl",
                    inputType: "number",
                    max: 1000,
                    min: 1,
                    unit: "帧",
                    style: {
                        width: "33.3%"
                    }
                }, {
                    label: "实时流目标帧率:",
                    type: "input",
                    key: "realFrame",
                    inputType: "number",
                    max: 120,
                    min: 1,
                    unit: "帧/秒",
                    style: {
                        width: "33.3%"
                    }
                }, {
                    label: "实时流目标码率:",
                    type: "input",
                    key: "realCode",
                    unit: "kbps",
                    style: {
                        width: "33.3%"
                    }
                }, {
                    label: "存储流编码模式:",
                    type: "select",
                    popperClass: "monitor-select",
                    key: "storeMode",
                    options: [{
                        value: 0,
                        label: "CBR(固定码率)"
                    }, {
                        value: 1,
                        label: "VBR(可变码率)"
                    }, {
                        value: 2,
                        label: "CBR(平均码率)"
                    }],
                    style: {
                        width: "33.3%"
                    }
                }, {
                    label: "存储流分辨率:",
                    type: "select",
                    key: "storeDpi",
                    popperClass: "monitor-select",
                    options: [{
                        value: 0,
                        label: "QCIF"
                    }, {
                        value: 1,
                        label: "CIF"
                    }, {
                        value: 2,
                        label: "WCIF"
                    }, {
                        value: 3,
                        label: "DI"
                    }, {
                        value: 4,
                        label: "WDI"
                    }, {
                        value: 5,
                        label: "720P"
                    }, {
                        value: 6,
                        label: "1080P"
                    }],
                    style: {
                        width: "33.3%"
                    }
                }, {
                    label: "存储流关键帧间隔:",
                    type: "input",
                    key: "storeItvl",
                    inputType: "number",
                    max: 1000,
                    min: 1,
                    unit: "帧",
                    style: {
                        width: "33.3%"
                    }
                }, {
                    label: "存储流目标帧率:",
                    type: "input",
                    key: "storeFrame",
                    inputType: "number",
                    max: 120,
                    min: 1,
                    unit: "帧/秒",
                    style: {
                        width: "33.3%"
                    }
                }, {
                    label: "存储流目标码流:",
                    type: "input",
                    key: "storeCode",
                    unit: "kbps",
                    style: {
                        width: "33.3%"
                    }
                }, {
                    label: "OSD字幕叠加设置:",
                    type: "select",
                    key: "osdSet",
                    multiple: true,
                    collapseTags: true,
                    popperClass: "monitor-select",
                    options: [{
                        value: 1,
                        label: "日期和时间"
                    }, {
                        value: 2,
                        label: "车牌号码"
                    }, {
                        value: 4,
                        label: "逻辑通道号"
                    }, {
                        value: 8,
                        label: "经纬度"
                    }, {
                        value: 16,
                        label: "行驶记录速度"
                    }, {
                        value: 32,
                        label: "卫星定位速度"
                    }, {
                        value: 64,
                        label: "连续驾驶时间"
                    }],
                    style: {
                        width: "33.3%"
                    }
                }, {
                    label: "音频输出:",
                    type: "radio",
                    key: "audioFlag",
                    group: [{
                        label: 0,
                        name: "停用"
                    }, {
                        label: 1,
                        name: "启用"
                    }],
                    style: {
                        width: "33.3%"
                    }
                }, {
                    id: "rules_management_alarm_level_setting_query",
                    label: "",
                    text: "发送",
                    type: "button",
                    key: "send",
                    itemType: "primary",
                    handler: "send",
                    auth: "user_center_serve_terminal_send",
                    style: {
                        marginLeft: "auto"
                    }
                }
            ],
            channelList: [
                {
                    id: "rules_management_alarm_level_setting_query",
                    label: "",
                    text: "发送",
                    type: "button",
                    key: "send",
                    itemType: "primary",
                    handler: "send",
                    auth: "user_center_serve_terminal_send",
                    style: {
                        marginLeft: "auto"
                    }
                }
            ],
            alonechannel: [
                {
                    id: "rules_management_alarm_level_setting_query",
                    label: "",
                    text: "发送",
                    type: "button",
                    key: "send",
                    itemType: "primary",
                    handler: "send",
                    auth: "user_center_serve_terminal_send",
                    style: {
                        marginLeft: "auto"
                    }
                }
            ],
            specialAlarm: [
                {
                    label: "特殊报警录像存储阈值:",
                    type: "input",
                    key: "storeValve",
                    inputType: "number",
                    max: 99,
                    min: 1,
                    unit: "%",
                    style: {
                        width: "50%"
                    }
                }, {
                    label: "特殊报警录像持续时间:",
                    type: "input",
                    key: "duration",
                    unit: "分钟",
                    style: {
                        width: "50%"
                    }
                }, {
                    label: "特殊报警录像起始时间:",
                    type: "input",
                    key: "startTime",
                    unit: "分钟",
                    style: {
                        width: "50%"
                    }
                }, {
                    id: "rules_management_alarm_level_setting_query",
                    label: "",
                    text: "发送",
                    type: "button",
                    key: "send",
                    itemType: "primary",
                    handler: "send",
                    auth: "user_center_serve_terminal_send",
                    style: {
                        marginLeft: "auto"
                    }
                }
            ],
            alarmMask: [
                {
                    label: "视频信号丢失报警:",
                    type: "checkbox",
                    key: "alarmMask1",
                    options: [{
                        value: 1,
                        label: "屏蔽"
                    }],
                    style: {
                        width: "50%"
                    }
                }, {
                    label: "客车超员报警:",
                    type: "checkbox",
                    key: "alarmMask2",
                    options: [{
                        value: 16,
                        label: "屏蔽"
                    }],
                    style: {
                        width: "50%"
                    }
                }, {
                    label: "视频信号遮挡报警:",
                    type: "checkbox",
                    key: "alarmMask3",
                    options: [{
                        value: 2,
                        label: "屏蔽"
                    }],
                    style: {
                        width: "50%"
                    }
                }, {
                    label: "异常驾驶行为报警:",
                    type: "checkbox",
                    key: "alarmMask4",
                    options: [{
                        value: 32,
                        label: "屏蔽"
                    }],
                    style: {
                        width: "50%"
                    }
                }, {
                    label: "存储单元故障报警:",
                    type: "checkbox",
                    key: "alarmMask5",
                    options: [{
                        value: 4,
                        label: "屏蔽"
                    }],
                    style: {
                        width: "50%"
                    }
                }, {
                    label: "特殊录像达到存储阈值报警:",
                    type: "checkbox",
                    key: "alarmMask6",
                    options: [{
                        value: 64,
                        label: "屏蔽"
                    }],
                    style: {
                        width: "50%"
                    }
                }, {
                    label: "其他视频设备故障报警:",
                    type: "checkbox",
                    key: "alarmMask7",
                    options: [{
                        value: 8,
                        label: "屏蔽"
                    }],
                    style: {
                        width: "50%"
                    }
                }, {
                    id: "rules_management_alarm_level_setting_query",
                    label: "",
                    text: "发送",
                    type: "button",
                    key: "send",
                    itemType: "primary",
                    handler: "send",
                    auth: "user_center_serve_terminal_send",
                    style: {
                        marginLeft: "auto"
                    }
                }
            ],
            imgAnalysisAlarm: [
                {
                    label: "车辆核载人数:",
                    type: "input",
                    key: "maxMan",
                    style: {
                        width: "50%"
                    }
                }, {
                    label: "疲劳程度阈值:",
                    type: "input",
                    key: "fatigueValve",
                    style: {
                        width: "50%"
                    }
                }, {
                    id: "rules_management_alarm_level_setting_query",
                    label: "",
                    text: "发送",
                    type: "button",
                    key: "send",
                    itemType: "primary",
                    handler: "send",
                    auth: "user_center_serve_terminal_send",
                    style: {
                        marginLeft: "auto"
                    }
                }
            ],
            terminalSleep: [
                {
                    label: "休眠唤醒模式:",
                    type: "checkbox",
                    key: "wakeupMode",
                    options: [{
                        value: 1,
                        label: "条件唤醒"
                    }, {
                        value: 2,
                        label: "定时唤醒"
                    }, {
                        value: 4,
                        label: "手动唤醒"
                    }],
                    style: {
                        width: "70%"
                    },
                    handler: "onWakeupMode"
                }, {
                    label: "唤醒条件类型:",
                    type: "checkbox",
                    key: "wakeConType",
                    disabled: true,
                    options: [{
                        value: 1,
                        label: "紧急报警"
                    }, {
                        value: 2,
                        label: "碰撞侧翻报警"
                    }, {
                        value: 4,
                        label: "车辆开门"
                    }],
                    style: {
                        width: "70%"
                    }
                }, {
                    label: "定时唤醒日设置:",
                    type: "checkbox",
                    key: "wakeDaySet",
                    disabled: true,
                    options: [{
                        value: 1,
                        label: "周一"
                    }, {
                        value: 2,
                        label: "周二"
                    }, {
                        value: 4,
                        label: "周三"
                    }, {
                        value: 8,
                        label: "周四"
                    }, {
                        value: 16,
                        label: "周五"
                    }, {
                        value: 32,
                        label: "周六"
                    }, {
                        value: 64,
                        label: "周日"
                    }],
                    style: {
                        width: "70%"
                    }
                }, {
                    label: "日定时唤醒时间段:",
                    type: "checkbox",
                    key: "wakeFlag",
                    disabled: true,
                    options: [{
                        value: 1,
                        label: "时间段1"
                    }, {
                        value: 2,
                        label: "时间段2"
                    }, {
                        value: 4,
                        label: "时间段3"
                    }, {
                        value: 8,
                        label: "时间段4"
                    }],
                    style: {
                        width: "70%"
                    },
                    handler: "onWakeFlag"
                }, {
                    label: "时间段1 唤醒时间:",
                    type: "time",
                    key: "wakeTime0",
                    disabled: true,
                    format: "HH:mm",
                    popperClass: "monitor-date-picker form-data-picker",
                    style: {
                        width: "50%"
                    }
                }, {
                    label: "关闭时间:",
                    type: "time",
                    key: "shutTime0",
                    disabled: true,
                    format: "HH:mm",
                    popperClass: "monitor-date-picker form-data-picker",
                    style: {
                        width: "50%"
                    }
                }, {
                    label: "时间段2 唤醒时间:",
                    type: "time",
                    key: "wakeTime1",
                    disabled: true,
                    format: "HH:mm",
                    popperClass: "monitor-date-picker form-data-picker",
                    style: {
                        width: "50%"
                    }
                }, {
                    label: "关闭时间:",
                    type: "time",
                    key: "shutTime1",
                    disabled: true,
                    format: "HH:mm",
                    popperClass: "monitor-date-picker form-data-picker",
                    style: {
                        width: "50%"
                    }
                }, {
                    label: "时间段3 唤醒时间:",
                    type: "time",
                    key: "wakeTime2",
                    disabled: true,
                    format: "HH:mm",
                    popperClass: "monitor-date-picker form-data-picker",
                    style: {
                        width: "50%"
                    }
                }, {
                    label: "关闭时间:",
                    type: "time",
                    key: "shutTime2",
                    disabled: true,
                    format: "HH:mm",
                    popperClass: "monitor-date-picker form-data-picker",
                    style: {
                        width: "50%"
                    }
                }, {
                    label: "时间段4 唤醒时间:",
                    type: "time",
                    key: "wakeTime3",
                    disabled: true,
                    format: "HH:mm",
                    popperClass: "monitor-date-picker form-data-picker",
                    style: {
                        width: "50%"
                    }
                }, {
                    label: "关闭时间:",
                    type: "time",
                    key: "shutTime3",
                    disabled: true,
                    format: "HH:mm",
                    popperClass: "monitor-date-picker form-data-picker",
                    style: {
                        width: "50%"
                    }
                }, {
                    id: "rules_management_alarm_level_setting_query",
                    label: "",
                    text: "发送",
                    type: "button",
                    key: "send",
                    itemType: "primary",
                    handler: "send",
                    auth: "user_center_serve_terminal_send",
                    style: {
                        marginLeft: "auto"
                    }
                }
            ]
        },
        channelListManage: {
            listHeader: [{
                label: "物理通道号",
                prop: "logicChannel",
                render(row) {
                    return row.logicChannel || "--";
                }
            }, {
                label: "逻辑通道号",
                prop: "physiChannel",
                render(row) {
                    return row.physiChannel || "--";
                }
            }, {
                label: "通道类型",
                prop: "type",
                render(row) {
                    let type = "--";
                    if (row.type === 0) {
                        type = "音视频";
                    } else if (row.type === 1) {
                        type = "音频";
                    } else if (row.type === 2) {
                        type = "视频";
                    }
                    return type;
                }
            }, {
                label: "是否连接云台",
                prop: "conSrvFlag",
                render(row) {
                    let conSrvFlag = "--";
                    if (row.conSrvFlag === 0) {
                        conSrvFlag = "未连接";
                    } else if (row.conSrvFlag == 1) {
                        conSrvFlag = "连接";
                    }
                    return conSrvFlag;
                }
            }, {
                label: "操作",
                option: [{
                    label: "修改",
                    handler: "onEdit",
                }, {
                    label: "删除",
                    handler: "onDelete",
                }]
            }],
            addItem: [{
                label: "物理通道号",
                type: "input",
                key: "logicChannel",
                style: {
                    width: "100%"
                },
                rules: [{required: true, message: "请选择物理通道号", trigger: "change"}]
            }, {
                label: "逻辑通道号",
                type: "input",
                key: "physiChannel",
                style: {
                    width: "100%"
                },
                rules: [{required: true, message: "请选择逻辑通道号", trigger: "change"}]
            }, {
                label: "通道类型",
                type: "select",
                key: "type",
                style: {
                    width: "100%"
                },
                options: [{
                    value: 0,
                    label: "音视频"
                }, {
                    value: 1,
                    label: "音频"
                }, {
                    value: 2,
                    label: "视频"
                }],
                rules: [{required: true, message: "请选择通道类型", trigger: "change"}]
            }, {
                label: "是否连接云台",
                type: "select",
                key: "conSrvFlag",
                style: {
                    width: "100%"
                },
                options: [{
                    value: 0,
                    label: "未连接"
                }, {
                    value: 1,
                    label: "连接"
                }],
                rules: [{required: true, message: "请选择是否连接云台", trigger: "change"}]
            }]
        },
        alonechannelManage: {
            listHeader: [{
                label: "逻辑通道号",
                prop: "logicChannel",
                width: utils.setFontSize(150),
                render(row) {
                    let logicChannel = "--";
                    if (row.logicChannel === 1) {
                        logicChannel = "通道1";
                    } else if (row.logicChannel === 2) {
                        logicChannel = "通道2";
                    } else if (row.logicChannel === 3) {
                        logicChannel = "通道3";
                    }
                    return logicChannel;
                }
            }, {
                label: "实时流编码模式:",
                prop: "realMode",
                width: utils.setFontSize(180),
                render(row) {
                    let realMode = "--";
                    if (row.realMode === 0) {
                        realMode = "CBR(固定码率)";
                    } else if (row.realMode === 1) {
                        realMode = "VBR(可变码率)";
                    } else if (row.realMode === 2) {
                        realMode = "CBR(平均码率)";
                    }
                    return realMode;
                }
            }, {
                label: "实时流分辨率",
                prop: "realDpi",
                width: utils.setFontSize(180),
                render(row) {
                    let realDpi = "--";
                    if (row.realDpi === 0) {
                        realDpi = "QCIF";
                    } else if (row.realDpi === 1) {
                        realDpi = "CIF";
                    } else if (row.realDpi === 2) {
                        realDpi = "WCIF";
                    } else if (row.realDpi === 3) {
                        realDpi = "DI";
                    } else if (row.realDpi === 4) {
                        realDpi = "WDI";
                    } else if (row.realDpi === 5) {
                        realDpi = "720P";
                    } else if (row.realDpi === 6) {
                        realDpi = "1080P";
                    }
                    return realDpi;
                }
            }, {
                label: "实时流关键帧间隔",
                prop: "realItvl",
                width: utils.setFontSize(180),
                render(row) {
                    return row.realItvl || "--";
                }
            }, {
                label: "实时流目标帧率",
                prop: "realFrame",
                width: utils.setFontSize(180),
                render(row) {
                    return row.realFrame || "--";
                }
            }, {
                label: "实时流目标码率",
                prop: "realCode",
                width: utils.setFontSize(180),
                render(row) {
                    return row.realCode || "--";
                }
            }, {
                label: "存储流编码模式",
                prop: "storeMode",
                width: utils.setFontSize(180),
                render(row) {
                    let storeMode = "--";
                    if (row.storeMode === 0) {
                        storeMode = "CBR(固定码率)";
                    } else if (row.storeMode === 1) {
                        storeMode = "VBR(可变码率)";
                    } else if (row.storeMode === 2) {
                        storeMode = "CBR(平均码率)";
                    }
                    return storeMode;
                }
            }, {
                label: "存储流分辨率",
                prop: "storeDpi",
                width: utils.setFontSize(180),
                render(row) {
                    let storeDpi = "--";
                    if (row.storeDpi === 0) {
                        storeDpi = "QCIF";
                    } else if (row.storeDpi === 1) {
                        storeDpi = "CIF";
                    } else if (row.storeDpi === 2) {
                        storeDpi = "WCIF";
                    } else if (row.storeDpi === 3) {
                        storeDpi = "DI";
                    } else if (row.storeDpi === 4) {
                        storeDpi = "WDI";
                    } else if (row.storeDpi === 5) {
                        storeDpi = "720P";
                    } else if (row.storeDpi === 6) {
                        storeDpi = "1080P";
                    }
                    return storeDpi;
                }
            }, {
                label: "存储流关键帧间隔",
                prop: "storeItvl",
                width: utils.setFontSize(180),
                render(row) {
                    return row.storeItvl || "--";
                }
            }, {
                label: "存储流目标帧率",
                prop: "storeFrame",
                width: utils.setFontSize(180),
                render(row) {
                    return row.storeFrame || "--";
                }
            }, {
                label: "存储流目标码率",
                prop: "storeCode",
                width: utils.setFontSize(180),
                render(row) {
                    return row.storeCode || "--";
                }
            }, {
                label: "OSD字母叠加设置",
                prop: "osdSet",
                width: utils.setFontSize(180),
                render(row) {
                    let arr = [];
                    if (row.osdSet.indexOf(1) != -1) {
                        arr.push("日期和时间");
                    }
                    if (row.osdSet.indexOf(2) != -1) {
                        arr.push("车牌号码");
                    }
                    if (row.osdSet.indexOf(4) != -1) {
                        arr.push("逻辑通道号");
                    }
                    if (row.osdSet.indexOf(8) != -1) {
                        arr.push("经纬度");
                    }
                    if (row.osdSet.indexOf(16) != -1) {
                        arr.push("行驶记录速度");
                    }
                    if (row.osdSet.indexOf(32) != -1) {
                        arr.push("卫星定位速度");
                    }
                    if (row.osdSet.indexOf(64) != -1) {
                        arr.push("连续驾驶时间");
                    }
                    return arr.length == 0 ? "--" : arr.join(";");
                }
            }, {
                label: "操作",
                width: utils.setFontSize(120),
                option: [{
                    label: "修改",
                    handler: "onEdit",
                }, {
                    label: "删除",
                    handler: "onDelete",
                }]
            }],
            addItem: [{
                label: "逻辑通道号",
                type: "select",
                key: "logicChannel",
                popperClass: "monitor-select",
                options: [{
                    value: 1,
                    label: "通道1"
                }, {
                    value: 2,
                    label: "通道2"
                }, {
                    value: 3,
                    label: "通道3"
                }],
                style: {
                    width: "50%"
                },
                rules: [{required: true, message: "请选择逻辑通道号", trigger: "blur"}]
            }, {
                label: "实时流编码模式",
                type: "select",
                popperClass: "monitor-select",
                key: "realMode",
                options: [{
                    value: 0,
                    label: "CBR(固定码率)"
                }, {
                    value: 1,
                    label: "VBR(可变码率)"
                }, {
                    value: 2,
                    label: "CBR(平均码率)"
                }],
                style: {
                    width: "50%"
                },
                rules: [{required: true, message: "请选择实时流编码模式", trigger: "blur"}]
            }, {
                label: "实时流分辨率",
                type: "select",
                popperClass: "monitor-select",
                key: "realDpi",
                options: [{
                    value: 0,
                    label: "QCIF"
                }, {
                    value: 1,
                    label: "CIF"
                }, {
                    value: 2,
                    label: "WCIF"
                }, {
                    value: 3,
                    label: "DI"
                }, {
                    value: 4,
                    label: "WDI"
                }, {
                    value: 5,
                    label: "720P"
                }, {
                    value: 6,
                    label: "1080P"
                }],
                style: {
                    width: "50%"
                },
                rules: [{required: true, message: "请选择实时流分辨率", trigger: "blur"}]
            }, {
                label: "实时流关键帧间隔",
                type: "input",
                key: "realItvl",
                inputType: "number",
                max: 1000,
                min: 1,
                unit: "帧",
                style: {
                    width: "50%"
                },
                rules: [{required: true, message: "请输入实时流关键帧间隔", trigger: "blur"}]
            }, {
                label: "实时流目标帧率",
                type: "input",
                key: "realFrame",
                inputType: "number",
                max: 120,
                min: 1,
                unit: "帧/秒",
                style: {
                    width: "50%"
                },
                rules: [{required: true, message: "请输入实时流目标帧率", trigger: "blur"}]
            }, {
                label: "实时流目标码率",
                type: "input",
                key: "realCode",
                unit: "kbps",
                style: {
                    width: "50%"
                },
                rules: [{required: true, message: "请输入实时流目标码率", trigger: "blur"}]
            }, {
                label: "存储流编码模式",
                type: "select",
                popperClass: "monitor-select",
                key: "storeMode",
                options: [{
                    value: 0,
                    label: "CBR(固定码率)"
                }, {
                    value: 1,
                    label: "VBR(可变码率)"
                }, {
                    value: 2,
                    label: "CBR(平均码率)"
                }],
                style: {
                    width: "50%"
                },
                rules: [{required: true, message: "请选择存储流编码模式", trigger: "blur"}]
            }, {
                label: "存储流分辨率",
                type: "select",
                key: "storeDpi",
                popperClass: "monitor-select",
                options: [{
                    value: 0,
                    label: "QCIF"
                }, {
                    value: 1,
                    label: "CIF"
                }, {
                    value: 2,
                    label: "WCIF"
                }, {
                    value: 3,
                    label: "DI"
                }, {
                    value: 4,
                    label: "WDI"
                }, {
                    value: 5,
                    label: "720P"
                }, {
                    value: 6,
                    label: "1080P"
                }],
                style: {
                    width: "50%"
                },
                rules: [{required: true, message: "请选择存储流分辨率", trigger: "blur"}]
            }, {
                label: "存储流关键帧间隔",
                type: "input",
                key: "storeItvl",
                inputType: "number",
                max: 1000,
                min: 1,
                unit: "帧",
                style: {
                    width: "50%"
                },
                rules: [{required: true, message: "请输入存储流关键帧间隔", trigger: "blur"}]
            }, {
                label: "存储流目标帧率",
                type: "input",
                key: "storeFrame",
                inputType: "number",
                max: 120,
                min: 1,
                unit: "帧/秒",
                style: {
                    width: "50%"
                },
                rules: [{required: true, message: "请输入存储流目标帧率", trigger: "blur"}]
            }, {
                label: "存储流目标码率",
                type: "input",
                key: "storeCode",
                unit: "kbps",
                style: {
                    width: "50%"
                },
                rules: [{required: true, message: "请输入存储流目标码率", trigger: "blur"}]
            }, {
                label: "OSD字幕叠加设置",
                type: "select",
                key: "osdSet",
                multiple: true,
                collapseTags: true,
                popperClass: "monitor-select",
                options: [{
                    value: 1,
                    label: "日期和时间"
                }, {
                    value: 2,
                    label: "车牌号码"
                }, {
                    value: 4,
                    label: "逻辑通道号"
                }, {
                    value: 8,
                    label: "经纬度"
                }, {
                    value: 16,
                    label: "行驶记录速度"
                }, {
                    value: 32,
                    label: "卫星定位速度"
                }, {
                    value: 64,
                    label: "连续驾驶时间"
                }],
                style: {
                    width: "50%"
                },
                rules: [{required: true, message: "请选择OSD字幕叠加设置", trigger: "blur"}]
            }]
        },
        listHeader: [
            {
                label: "车牌号码",
                prop: "name",
                render(row) {
                    return row.name || "--";
                }
            },
            {
                label: "终端标识",
                prop: "vid",
                render(row) {
                    return row.vid || "--";
                }
            },
            {
                label: "发送状态",
                prop: "sendStatus",
                // width: utils.setFontSize(180),
                render(row) {
                    return row.sendStatus || "--";
                }
            }
        ],
        listSearchHeader: [
            {
                label: "响应状态",
                prop: "upStatus",
                width: utils.setFontSize(180),
                render(row) {
                    return row.upStatus || "--";
                }
            },
            {
                label: "查看",
                handler: "onDetail",
                prop: "terminalShow",
                tooltip: true,
                auth: "user_center_rule_arule_fatigue_delete",
                width: utils.setFontSize(180)
            }
        ]
    }
};