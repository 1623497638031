<template>
    <SAASModel
            ref="saasModel"
            title="列配置"
            confirm-btn-text="保 存"
            width="30rem"
            top="20vh"
            @handleClick="handleClick"
    >
        <div class="saas-col-set">
            <div class="hide-col">
                <div class="col-set-title">隐藏字段</div>
                <div class="col-set-content">
                    <el-checkbox
                            class="checkbox-all"
                            :indeterminate="isHideAll"
                            v-model="checkHideAll"
                            @change="handleCheckHideAll"
                    >全选
                    </el-checkbox>
                    <el-checkbox-group v-model="checkedHideCol" @change="handleCheckedHideCol">
                        <el-checkbox
                                v-for="item in tableHeader"
                                v-if="item.display=='none'"
                                :label="item.prop"
                                :key="item.prop"
                        >
                            {{item.label}}
                        </el-checkbox>
                    </el-checkbox-group>
                </div>
            </div>
            <!--            <div class="col-set-btns">-->
            <!--                <el-button type="primary" icon="el-icon-arrow-left" @click="showHideCheck('hide')"></el-button>-->
            <!--                <el-button type="primary" icon="el-icon-arrow-right" @click="showHideCheck('show')"></el-button>-->
            <!--            </div>-->
            <div class="col-set-btns">
                <div class="iconfont iconzuo1" @click="showHideCheck('hide')"></div>
                <div class="iconfont iconyou1" @click="showHideCheck('show')"></div>
            </div>
            <div class="show-col">
                <div class="col-set-title">显示字段</div>
                <div class="col-set-content">
                    <el-checkbox
                            class="checkbox-all"
                            :indeterminate="isShowAll"
                            v-model="checkShowAll"
                            @change="handleCheckShowAll"
                    >全选
                    </el-checkbox>
                    <el-checkbox-group v-model="checkedShowCol" @change="handleCheckedShowCol">
                        <draggable
                                :list="tableHeader"
                                :options="draggableOption"
                                class="list-group"
                        >
                            <el-checkbox
                                    v-for="item in tableHeader"
                                    v-show="item.display=='block'"
                                    :label="item.prop"
                                    :key="item.prop"
                                    :disabled="item.colDisabled"
                            >
                                {{item.label}}
                            </el-checkbox>
                        </draggable>
                    </el-checkbox-group>
                </div>
            </div>
        </div>
    </SAASModel>
</template>

<script>
	import draggable from "vuedraggable";

	export default {
		name: "SAASColSet",
		props: ["currentPage"],
		components: {draggable},
		data() {
			return {
				draggableOption: {
					group: "people",
					animation: 150,
					ghostClass: "sortable-ghost",
					chosenClass: "chosenClass",
					scrollSensitivity: 100
				},
				tableHeader: [],
				hideCol: [],
				showCol: [],
				checkedHideCol: [],
				checkedShowCol: [],
				isHideAll: false,
				isShowAll: false,
				checkHideAll: false,
				checkShowAll: false
			};
		},
		computed: {
			showHideCol() {
				return this.$store.state.showHideCol;
			}
		},
		methods: {
			init(tableHeader) {
				this.showCol = [];
				this.hideCol = [];
				this.tableHeader = this.$utils.deepClone(tableHeader);
				this.$refs.saasModel.dialogVisible = true;
			},
			handleClick() {
				this.showHideCol[this.currentPage] = this.tableHeader;
				this.$store.commit("setShowHideCol", this.showHideCol);
				this.$refs.saasModel.dialogVisible = false;
			},
			showHideCheck(type) {
				if (type == "hide") {
					for (let i = 0; i < this.tableHeader.length; i++) {
						if (this.checkedShowCol.indexOf(this.tableHeader[i].prop) != -1) {
							this.tableHeader[i].display = "none";
						}
					}
					this.isShowAll = false;
					this.checkShowAll = false;
					this.checkedShowCol = [];
				} else {
					for (let i = 0; i < this.tableHeader.length; i++) {
						if (this.checkedHideCol.indexOf(this.tableHeader[i].prop) != -1) {
							this.tableHeader[i].display = "block";
						}
					}
					this.isHideAll = false;
					this.checkHideAll = false;
					this.checkedHideCol = [];
				}
			},
			handleCheckHideAll(val) {
				let hideCol = [];
				this.tableHeader.forEach(item => {
					if (item.display == "none" && item.prop) {
						hideCol.push(item.prop);
					}
				});
				this.checkedHideCol = val ? hideCol : [];
				this.isHideAll = false;
			},
			handleCheckShowAll(val) {
				let showCol = [];
				this.tableHeader.forEach(item => {
					if (item.display == "block" && item.prop && !item.colDisabled) {
						showCol.push(item.prop);
					}
				});
				this.checkedShowCol = val ? showCol : [];
				this.isShowAll = false;
			},
			handleCheckedHideCol(val) {
				let checkedCount = val.length;
				let hideLength = 0;
				this.tableHeader.forEach(item => {
					if (item.display == "none") {
						return hideLength++;
					}
				});
				this.checkHideAll = checkedCount === hideLength;
				this.isHideAll = checkedCount > 0 && checkedCount < hideLength;
			},
			handleCheckedShowCol(val) {
				let checkedCount = val.length;
				let showLength = 0;
				this.tableHeader.forEach(item => {
					if (item.display == "block") {
						return showLength++;
					}
				});
				this.checkShowAll = checkedCount === showLength;
				this.isShowAll = checkedCount > 0 && checkedCount < showLength;
			}
		}
	};
</script>

<style lang="less">
    .saas-col-set {
        height: 360px;
        display: flex;
        align-items: center;

        .hide-col, .show-col {
            width: calc(100% - 50px);
            height: 100%;

            .col-set-title {
                font-size: 14px;
                text-align: center;
                height: 20px;
                margin-bottom: 10px;
            }

            .col-set-content {
                height: calc(100% - 30px);
                overflow: auto;
                padding: 10px 18px;
            }

            .checkbox-all {
                line-height: 20px !important;
                padding-bottom: 4px;
            }

            .el-checkbox {
                display: block;
                line-height: 28px;
            }
        }

        .col-set-btns {
            width: 100px;

            div {
                line-height: 40px;
                font-size: 24px;
                text-align: center;
                color: #1F55F7;
            }
        }
    }
</style>