<template>
    <div class="saas-alarm-tree">
        <el-input
                placeholder=""
                class="suffix"
                v-model="formValueLabel"
                autocomplete="off"
                :disabled="isAlarmTypeDisabled"
                clearable
                @clear="clear()"
                @input="alarmTreeInput"
                @mouseover.native="inputMouseover"
                @mouseout.native="inputMouseout"
        >
            <i v-if="iconXialaShow" @click="isTreeShow = true" class="input-icon iconfont iconxiala" slot="suffix"></i>
        </el-input>
        <el-tree
                v-if="isTreeShow"
                v-loading="loading"
                :data="alarmData"
                :show-checkbox="formProps.showCheckbox"
                node-key="alarmCode"
                ref='tree'
                :filter-node-method="filterNode"
                class="tree"
                @node-click="handleClick"
                @check="handleCheckChange"
        >
                <span :class="[data.class?data.class:'','custom-tree-node']" slot-scope="{ node, data }">
                    <span v-if="data[formProps.parentLabel]" :title="data[formProps.parentLabel]">
                        {{data[formProps.parentLabel]}}
                    </span>
                    <span :title="data[formProps.label]" v-else>
                        {{data[formProps.label]}}
                    </span>
                </span>
        </el-tree>
    </div>
</template>

<script>
    import {queryTreeOwn} from "@/api/userManage";

    export default {
        name: "SAASAlarmTree",
        props: ["formValue", "formProps", "isAlarmTypeDisabled", "category"],
        watch: {
            "filterText"(val) {
                if (this.$refs.tree) {
                    this.$refs.tree.filter(val);
                }
            }
        },
        data() {
            return {
                formValueLabel: "",
                alarmData: [],
                alarmName: "",
                filterText: "",
                alarmIdArr: [],
                alarmNameArr: [],
                isTreeShow: false,
                iconXialaShow: true,
                loading: true
            };
        },
        methods: {
            inputMouseover() {
                if (!this.isAlarmTypeDisabled && this.formValueLabel) {
                    this.iconXialaShow = false;
                }
            },
            inputMouseout() {
                if (!this.isAlarmTypeDisabled && !this.isTreeShow) {
                    this.iconXialaShow = true;
                }
            },
            alarmTreeInput(val) {
                this.filterText = val;
            },
            clear() {
                this.iconXialaShow = true;
                this.formValue.alarmName = "";
                this.formValue.alarmCode = "";
                this.alarmIdArr = [];
                this.alarmNameArr = [];
                this.$emit("handleClick", "alarmTree");
            },
            handleClose(e) {
                if (!$(".saas-alarm-tree").length) {
                    return;
                }
                if (!$(".saas-alarm-tree")[0].contains(e.target)) {
                    this.isTreeShow = false;
                    this.iconXialaShow = true;
                } else {
                    this.isTreeShow = true;
                    this.$nextTick(() => {
                        this.$refs.tree && this.$refs.tree.setCheckedKeys(this.alarmIdArr);
                    });
                }
            },
            filterNode(value, data) {
                if (!value) return true;
                return data[this.formProps.label] ? data[this.formProps.label].indexOf(value) !== -1 : data[this.formProps.parentLabel].indexOf(value) !== -1;
            },
            getTree() {
                this.alarmData = [];
                queryTreeOwn({category: this.category}).then(res => {
                    if (!res.code) {
                        this.alarmData = res.result;
                    }
                    this.loading = false;
                });
            },
            handleClick(data) {
                if (this.formProps.showCheckbox) {
                    return;
                }
                if (data) {
                    this.formValueLabel = data[this.formProps.label] ? data[this.formProps.label] : this.formValueLabel;
                    if (data[this.formProps.label]) {
                        this.isTreeShow = false;
                        this.formValue[this.formProps.label] = data[this.formProps.label];
                        this.formValue[this.formProps.value] = data[this.formProps.value];
                        this.$emit("handleClick", "alarmTree");
                    }
                }
            },
            handleCheckChange(currentNode, resolve) {
                if (currentNode) {
                    let alarmIdArr = [], alarmNameArr = [];
                    resolve.checkedNodes.map((item) => {
                        if (!item[this.formProps.parentValue]) {
                            alarmNameArr.push(item[this.formProps.label]);
                            alarmIdArr.push(item[this.formProps.value]);
                        }
                    });
                    this.alarmNameArr = alarmNameArr;
                    this.alarmIdArr = alarmIdArr;
                    this.$refs.tree && this.$refs.tree.setCheckedKeys(this.alarmIdArr);
                    this.alarmName = this.alarmNameArr.join(",");
                    this.formValueLabel = this.alarmName;
                    this.$emit("treeCheckChange", this.alarmIdArr);
                }
            }
        },
        created(){
            this.getTree();
        },
        mounted() {            
            this.formValueLabel = this.formValue[this.formProps.label];
            let that = this;
            document.addEventListener('mousedown', (event) => {
                that.handleClose(event);
            })
        }
    };
</script>

<style lang="less">
    .saas-alarm-tree {
        .el-input--suffix .el-input__inner {
            padding-right: 25px;
        }

        .el-input__inner {
            height: 34px;
        }

        .tree {
            height: 320px;
            z-index: 10;
            overflow: auto;
            -webkit-box-shadow: 0 0.1rem 0.6rem 0 rgba(0, 0, 0, .1);
            box-shadow: 0 0.1rem 0.6rem 0 rgba(0, 0, 0, .1);

            .el-tree-node:focus > .el-tree-node__content, .el-tree-node__content:hover {
                background: rgba(255, 255, 255, 0.2);
            }
        }

        .el-tree-node > .el-tree-node__children {
            overflow: inherit;
        }
    }
</style>
