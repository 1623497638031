import Vue from "vue";
import Vuex from "vuex";
import persistedState from "vuex-persistedstate";

Vue.use(Vuex);
let paths = [
    "isRememberPwd",
    "headMenu",
    "monitorCrumbsList",
    "monitorKeepAlive",
    "dataCrumbsList",
    "dataKeepAlive",
    "monitorAlarmSound",
    "monitorAlarmDialog",
    "userConfig",
    "showHideCol",
    "isLogin"
];
var state = {
    isRememberPwd: false,
    headMenu: "",
    monitorCrumbsList: [],
    monitorKeepAlive: [],
    userCrumbsList: [],
    userKeepAlive: [],
    dataCrumbsList: [],
    dataKeepAlive: [],
    realtimeTreeList: {
        text: "",
        entId: "",
        extend: true,
        checkBox: true,
        isChildren: true,
        isShowOnline: true,
        children: []
    },
    monitorAlarmSound: true,
    monitorAlarmDialog: true,
    userConfig: {
        monitorSkin: "tiankongzhilan",//heiseshangwu
        userSkin: "baisejianyue",
        projectName: "车辆监控管理平台",
        // logoImgUrl: require("../assets/images/common/white-logo.png"),
        companyName: "xxx",
        varcharCode: "京ICP备21032909号",
        versionsCode: "2.4.0",
        // servicePhone: "010-64933932",
        linkAddress: "https://www.zgjiyi.com/#/largeScreen",
        loginImg: "1"
    },
    showHideCol: {
        trackPlayDetail: [],
        trackPlayPark: [],
        trackPlayAlarm: [],
        trackPlayDriver: [],
        vehicleManage: [],
        realtimeMonitor: [],
        activeSecalarm: [],
        alarmDetail: [],
        alarmAeb: [],
        firmOfflineManage: [],
        alarmDayManage: [],
        speedAlarmManage: [],
        fatigueDriverManage: [],
        speedDayAlarmManage: [],
        fatigueDayDriveManage: [],
        speedAlarmMonth: [],
        fatigueDriveMonth: [],
        thirdPartyManage: [],
        guangdongManage: [],
        mileageDaily: [],
        mileageMonthly: [],
        mileageHarvester: [],
        vehicleTrack: [],
        offlineStatistics: [],
        vehOnOffStaticManage: [],
        vehOnOffDetailManage: [],
        vehOfflineStaticManage: [],
        vehMonthStaticManage: [],
        comMonthStaticManage: [],
        drivingStaticManage: [],
        parkingStaticManage: [],
        driveCollect: [],
        parkCollect: [],
        driverCardManage: [],
        vehicleOnlineManage: [],
        comMonthStaticManage2: [],
        tarckRateManage: [],
        accessRateManage: [],
        dataPassRateManage: [],
        driftRateManage: [],
        inspectionNotice: [],
        smsissuedManage: [],
        comMonthAlarmManage: [],
        comMonthReportManage: []
    },
    isLogin: false,
    monitorVehicleTree: [],
    vehicleTreeList: [],
    suVehicleTreeList: [],
    yueVehicleTreeList: [],
    monitorTree: [],
    onlineNumberTree: [],
    trackPlayTree: [],
    playBackTree: [],
    videoPollTree: [],
    orgTreeList: [],
    allAlarmType: {},
    dictionaryOem: [],
    vehicleBrandArr: [],
    iconList: [],
    loginInfo: "",
    treeLoading: false,
    treeLoading2: false,
    vehicleTreeLoading: false,
    alarmCodes: [],
    alarmTree: [],
    lockList: [],
	userSiderMenus: [],
	dataSiderMenus: [],
	monitorSiderMenus: [],
	treeParamsShower: { // 在树型图中是否显示的字段
        label: 'vehicleNo', //explains
		state: true,
		offTimes: true,
		eType: false,
        area: false
	},
    vehicles: {}, // 每个组织中所有的车辆，{entid: [车1,车2]}
    orgData1: [], //所有组织权限(可查看，可编辑))，数组格式
    orgData2: [], // 只可编辑组织
    orgData1Object: {}, //所有组织权限(可查看，可编辑))，以value为key的对象格式
    vehicleTerminal: {}, // 每辆车对应的终端信息
};
export default new Vuex.Store({
    state,
    mutations: {
        setIsRememberPws: (state, isRememberPwd) => {
            state.isRememberPwd = isRememberPwd;
        },
        updateMenu: (state, payload) => {
            let {headMenu} = payload;
            state.headMenu = headMenu;
        },
        setMonitorCrumbsList(state, crumbsList) {
            state.monitorCrumbsList = crumbsList;
        },
        setMonitorKeepAlive(state, keepAlive) {
            state.monitorKeepAlive = keepAlive;
        },
        setUserCrumbsList(state, crumbsList) {
            state.userCrumbsList = crumbsList;
        },
        setUserKeepAlive(state, keepAlive) {
            state.userKeepAlive = keepAlive;
        },
        setDataCrumbsList(state, crumbsList) {
            state.dataCrumbsList = crumbsList;
        },
        setDataKeepAlive(state, keepAlive) {
            state.dataKeepAlive = keepAlive;
        },
        setRealTreeList(state, realtimeTreeList) {
            state.realtimeTreeList = realtimeTreeList;
        },
        setMonitorAlarm(state, payload) {
            let {monitorAlarmSound, monitorAlarmDialog} = payload;
            state.monitorAlarmSound = monitorAlarmSound;
            state.monitorAlarmDialog = monitorAlarmDialog;
        },
        setUserConfig(state, payload) {
            state.userConfig = {...payload};
        },
        setShowHideCol(state, showHideCol) {
            state.showHideCol = showHideCol;
        },
        setIsLogin(state, isLogin) {
            state.isLogin = isLogin;
        },
        setMonitorVehicleTree(state, monitorVehicleTree) {
            state.monitorVehicleTree = monitorVehicleTree;
        },
        setVehicleTreeList(state, vehicleTreeList) {
            state.vehicleTreeList = vehicleTreeList;
        },
        setSuVehicleTreeList(state, suVehicleTreeList) {
            state.suVehicleTreeList = suVehicleTreeList;
        },
        setYueVehicleTreeList(state, yueVehicleTreeList) {
            state.yueVehicleTreeList = yueVehicleTreeList;
        },
        setMonitorTree(state, monitorTree) {
            state.monitorTree = monitorTree;
        },
        setOnlineNumberTree(state, onlineNumberTree) {
            state.onlineNumberTree = onlineNumberTree;
        },
        setTrackPlayTree(state, trackPlayTree) {
            state.trackPlayTree = trackPlayTree;
        },
        setPlaybackTree(state, playBackTree) {
            state.playBackTree = playBackTree;
        },
        setVideoPollTree(state, videoPollTree) {
            state.videoPollTree = videoPollTree;
        },
        setOrgTreeList(state, orgTreeList) {
            state.orgTreeList = orgTreeList;
        },
        setAllAlarmType(state, allAlarmType) {
            state.allAlarmType = allAlarmType;
        },
        setDictionaryOem(state, dictionaryOem) {
            state.dictionaryOem = dictionaryOem;
        },
        setVehicleBrandArr(state, vehicleBrandArr) {
            state.vehicleBrandArr = vehicleBrandArr;
        },
        setIconList(state, iconList) {
            state.iconList = iconList;
        },
        setLoginInfo(state, loginInfo) {
            state.loginInfo = loginInfo;
        },
        setAlarmCodes(state, alarmCodes) {
            state.alarmCodes = alarmCodes;
        },
        setAlarmTree(state, alarmTree) {
            state.alarmTree = alarmTree;
        },
        setLockList(state, lockList) {
            state.lockList = lockList;
        },
		setUserSiderMenus(state, menus){
			state.userSiderMenus = [...menus];
		},
		setDataSiderMenus(state, menus){
			state.dataSiderMenus = [...menus];
		},
		setmMonitorSiderMenus(state, menus){
			state.monitorSiderMenus = [...menus];
		},
		setTreeParamsShower(state, params){
			state.treeParamsShower = params;
		},
        setVehicleTree(state, vehicles){
			state.vehicles = vehicles;
		},
        setVehicleTerminal(state, vehicleTerminal){
			state.vehicleTerminal = vehicleTerminal;
		},
        
        setOrgData1(state, orgData1){
			state.orgData1 = orgData1;
		},        
        setOrgData2(state, orgData2){
            state.orgData2 = orgData2;
		},
        setOrgData1Object(state, orgData1Object){
			state.orgData1Object = orgData1Object;
		},         
        
    },
    actions: {
        // 前端 登出
        setLogout() {
            return new Promise(resolve => {
                localStorage.setItem("isLogin", "false");
                localStorage.removeItem("Authorization");
                resolve();
            });
        }
    },
    plugins: [persistedState({
        paths: paths,
        storage: window.localStorage
    })]
});