<template>
    
    <div class="table_search_v2">
        <el-form :inline="true" :model="formValue" class="input_custom" :label-width="labelWidth" :size="formSize">
            <slot name="header"></slot>           
            <template v-for="(item, index) in formItem" >					
                <el-form-item
                        v-show="index<total||total===-1"
                        :name="item.key + 'Item'"
                        :label="item.label"
                        :prop="item.key"
                        :ref="item.key"
                        :rules="item.rules">
                    <el-input
                            v-if="item.type === 'input'"
                            :id="item.key"
                            v-model="formValue[item.key]"
                            :name="item.key"
                            :readonly="item.readonly"
                            :disabled="item.disabled"
                            :show-password="item.showPassword"
                            :type="item.inputType"
                            autocomplete="off"
                            :maxlength="item.maxLength"
                            :minlength="item.minLength"
                            :max="item.max"
                            :min="item.min"
                            :placeholder="item.placeholder"
                            show-word-limit
                            @input="(val) => {
                                handleInput(val, item.handler)
                            }"
                            @focus="(val) => {
                                handleFocus(val, item.handler)
                            }"
                    ></el-input>
                    <el-input-number
                            v-if="item.type === 'number'"
                            :id="item.key"
                            v-model="formValue[item.key]"
                            :name="item.key"
                            :disabled="item.disabled"
                            controls-position="right"
                            :min="item.min"
                            :max="item.max"
                    ></el-input-number>
                    <div v-if="item.type == 'orgTree'">
                        <SAASOrgTree
                                ref="saasOrgTree"
                                :elName="elName"
                                :formValue='formValue'
                                :treeData="orgTreeList"
                                :treeProps="formConfig.treeProps"
                                :formProps="formConfig.formProps || formConfig.treeProps"
                                :notClick="formConfig.notClick"
                                :disabled="formConfig.disabled"
                                :isExpandAll="formConfig.isExpandAll"
                                :expandedKeys="formConfig.expandedKeys"
                                :placeholder="item.placeholder || '请选择组织'"
                                :pageType="item.pageType"
                                @orgTreeClick="(val) => {orgTreeClick(val, item)}"
                        />
                    </div>
                    <div v-if="item.type === 'alarmTree'">
                        <SAASAlarmTree
                                ref="alarmTree"
                                :formValue='formValue'
                                :formProps="formProps"
                                :category='item.category'
                                :placeholder="item.placeholder || '输入进行查询'"
                                @handleClick="handleClick"
                                @treeCheckChange="alarmTreeCheck"
                        />
                    </div>
                    <div v-if="item.type === 'logTree'">
                        <SAASLogTree
                                ref="logTree"
                                :formValue='formValue'
                                :formProps="formProps"
                                :category='item.category'
                                :placeholder="item.placeholder || '输入进行查询'"
                                @handleClick="handleClick"
                                @treeCheckChange="logTreeCheck"
                        />
                    </div>
                    <div v-if="item.type == 'uploadFile'">
                        <el-input
                                :id="item.key"
                                v-model="formValue[item.key]"
                                :name="item.key"
                                :readonly="item.readonly"
                                :disabled="item.disabled"
                                :show-password="item.showPassword"
                                :type="item.inputType"
                                autocomplete="off"
                                :maxlength="item.maxLength"
                                :max="item.max"
                                :min="item.min"
                                :placeholder="item.placeholder"
                                show-word-limit
                                @input="(val) => {
                                handleInput(val, item.handler)
                            }"
                        ></el-input>
                        <input v-if="!item.disabled" class="uploadFile" type="file" @change="uploadFile"/>
                    </div>
                    <el-radio-group
                            v-if="item.type === 'radio'"
                            :id="item.key"
                            v-model="formValue[item.key]"
                            :name="item.key"
                            :readonly="item.readonly"
                            :disabled="item.disabled"
                            @change="handleChange($event, item.handler)"
                    >
                        <el-radio
                                v-for="groupItem,index in item.group"
                                :label="groupItem.label"
                                :key="item.label+index"
                        >{{ groupItem.name }}
                        </el-radio>
                    </el-radio-group>
                    <el-select
                            class="selectName"
                            v-if="item.type === 'select'"
                            :id="item.key"
                            :ref="item.key"
                            v-model="formValue[item.key]"
                            :name="item.key"
                            :class="item.key"
                            :placeholder="item.placeholder || '请选择'"
                            :disabled="item.disabled"
                            :multiple="item.multiple"
                            :collapse-tags="item.collapseTags"
                            :readonly="item.readonly"
                            :allow-create="item.allowCreate"
                            :popper-class="item.popperClass"
                            filterable
                            :clearable="!item.clearable"
                            @change="(val) => {handleChange(val, item.handler)}"
                            @visible-change="(val) => {handleVisibleChange(val, item.handler)}"
                    >
                        <el-option
                                v-for="items in item.options"
                                :key="items.value+''"
                                :label="items.label"
                                :value="items.value"
                        >
                            <span v-if="item.leftLabel" style="float: left" :style="items.style">{{items.label}}</span>
                            <span v-if="item.rightValue" style="float: right">{{items.count}}</span>
                        </el-option>
                    </el-select>
                    <el-cascader
                            v-if="item.type === 'cascader'"
                            :ref="item.key"
                            :key="item.key"
                            v-model="formValue[item.key]"
                            :name="item.key"
                            :options="item.options"
                            :disabled="item.disabled"
                            :popper-class="item.popperClass"
                            :placeholder="item.placeholder?item.placeholder:'请输入地区'"
                            clearable
                            :filterable="item.filterable||false"
                            :props="{ expandTrigger: 'hover',checkStrictly: item.checkStrictly||false }"
                            @change="((val)=>{handleChange(val, item.handler, item.key)})"
                    ></el-cascader>
                    <!-- noNeedTime可不传，不需要时间 -->
                    <el-date-picker
                            v-if="item.type === 'dateRange'"
                            v-model="formValue[item.key]"
                            :name="item.key"
                            :type="item.noNeedTime?'daterange':'datetimerange'" 
                            :format='item.format'
                            :value-format="item.format"						
                            range-separator="-"
                            start-placeholder="开始时间"
                            end-placeholder="结束时间"
                            class="saas-date-picker"
                            :clearable="item.clearable"
                            :popper-class="item.popperClass"
                            :picker-options="item.pickerOptions"
                            :disabled="item.disabled"
                            @change="(val) => {handleChange(val, item.handler)}"
                    ></el-date-picker>
                    <el-date-picker
                            v-if="item.type === 'datePicker'"
                            v-model="formValue[item.key]"
                            :name="item.key"
                            :type="item.dateType"
                            placeholder="请选择时间"
                            range-separator="-"
                            start-placeholder="开始时间"
                            end-placeholder="结束时间"
                            class="saas-date-picker"
                            :format='item.format'
                            :value-format="item.format"
                            :popper-class="item.popperClass"
                            :picker-options="item.pickerOptions"
                            :disabled="item.disabled"
                            :clearable="item.clearable?true:false"
                            @change="(val) => {handleChange(val, item.handler)}"
                    ></el-date-picker>
                    <el-date-picker v-if="item.type === 'datePickerWeek'"
                        v-model="formValue[item.key]"
                        class="saas-date-picker"
                        type="week"
                        format="yyyy 第 WW 周"
                        placeholder="选择周"
                        :clearable="item.clearable?true:false"
                        :picker-options="item.pickerOptions"
                        @change="(val) => {handleChange(val, item.handler)}">
                    </el-date-picker>						
                    <el-time-picker
                            v-if="item.type === 'time'"
                            v-model="formValue[item.key]"
                            :name="item.key"
                            :type="item.dateType"
                            class="saas-date-picker"
                            :value-format="item.format"
                            :format='item.format'
                            :popper-class="item.popperClass"
                            :picker-options="item.pickerOptions"
                            :disabled="item.disabled"
                            @change="(val) => {handleChange(val, item.handler)}"
                    ></el-time-picker>
                    <el-input
                            v-if="item.type === 'textarea'"
                            v-model="formValue[item.key]"
                            :name="item.key"
                            type="textarea"
                            :rows="item.rows || 2"
                            :disabled="item.disabled"
                            :readonly="item.readonly"
                            :maxlength="item.maxLength"
                            show-word-limit
                            :placeholder="item.placeholder || '请输入内容'"
                    >
                    </el-input>
                    <el-checkbox-group
                            v-if="item.type === 'checkbox'"
                            v-model="formValue[item.key]"
                            :name="item.key"
                            :disabled="item.disabled"
                            :readonly="item.readonly"
                            @change="handleChange($event, item.handler)"
                    >
                        <el-checkbox
                                v-for="op in item.options"
                                :key="op.value"
                                :label="op.value"
                        >{{op.label}}
                        </el-checkbox>
                    </el-checkbox-group>
                    <span
                            v-if="item.unit"
                            class="unit"
                            :style="{position: item.unit ? 'absolute' : ''}"
                    >{{item.unit}}</span>
                    <el-select
                        v-if="item.type === 'searchinput'"
                        filterable
                        remote
                        reserve-keyword
                        clearable
                        v-model="formValue[item.key]"
                        :placeholder="item.placeholder"
                        :remote-method="remoteMethod"
                        :loading="loading">
                        <el-option
                            v-for="v in item.options"
                            :key="v.value"
                            :label="v.label"
                            :value="v.value">
                        </el-option>
                    </el-select>
                    <el-select
                        v-if="item.type === 'searchinputv2'"
                        filterable
                        clearable
                        v-model="formValue[item.key]"
                        :placeholder="item.placeholder"
                        remote
                        :remote-method="remoteMethod"
                        @clear="handleClear(item.handler)"
                        @change="(val) => {handleChange(val, item.handler)}">
                        <el-option
                            v-for="v in item.options"
                            :key="v.value"
                            :label="v.label"
                            :value="v.value">
                        </el-option>
                    </el-select>
                </el-form-item></template>
            <span v-if="isShowMore" class="upAndDown" @click="showAndHide">{{ arrowDown?'展开筛选∨':'收起筛选∧' }}</span>
            <div v-if="formBtns && formBtns.length" class="form_btns_box">
                <template v-for="item in formBtns">
                    <el-button  v-if="item.key!=='moreActions'"
                        :key="item.key"
                        :prop="item.key"
                        v-has="item.auth"
                        :type="item.type"
                        :plain="item.plain"
                        :disabled="item.disabled"
                        @click.native="handleClick(item.handler)" class="saas-button">
                        {{ item.text }}
                    </el-button>
                    <el-dropdown v-if="item.key=='moreActions'">
                        <el-button type="primary" 
                        :size="formSize" 
                        :type="item.type"
                        :plain="item.plain" class="saas-button">
                        {{ item.text }}<i class="el-icon-arrow-down el-icon--right"></i>
                        </el-button>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item v-for="child in item.options"
                             v-has="child.auth"
                             @click.native="handleClick(child.handler)">{{ child.text }}</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </template>
            </div>
            <slot name="footer"></slot>   
        </el-form>
    </div>

</template>

<script>
    export default {
        name: "SeachForm",
        props: {
            formSize: {
                required: false,
                type: String,
                default: 'mini'
            },
            labelWidth: {
                required: false,
                type: String,
                default: '100px'
            },
            formItem: {
                required: true,
                type: Array
            },
            formBtns: {
                required: false,
                type: Array
            },
            formValue: {
                required: false
            },
            formConfig: {
                type: Object,
                default: function () {
                    return {};
                }
            },
            orgTreeList: {
                required: false
            },
            elName: {
                required: false
            },
            isShowMore: { //展开或收起筛选
                type: Boolean,
                default: false
            },
            totalShow: { // 默认显示多少个搜索条件，-1的话为不限制，全部显示，大于0就显示前几条
                type: Number,
                default: -1
            }
        },

		computed: {
		},
        created(){
            this.total = this.totalShow;
        },
        data() {
            return {
                arrowDown: true,
                total: -1
            };
        },
        methods: {            
            uploadFile(e) {
                let obj = e.target;
                if (!obj.files) {
                    return;
                }
                var f = obj.files[0];
                this.$emit("handleClick", "uploadFile", f);
            },
            handleInput(val, handler) {
                this.$forceUpdate();
                this.$emit("handleInput", handler, val);
            },
            handleFocus(val, handler) {
                this.$emit("handleFocus", handler, val);
            },
            handleClick(handler) {
                this.$emit("handleClick", handler);
            },
            handleChange(value, handler, key) {
                this.$forceUpdate();
                if (key == "regions") {
                    this.formValue.provinceCode = value[0];
                    this.formValue.cityCode = value[1];
                    this.formValue.countyCode = value[2] ? value[2] : "";
                } else if (value && key == "proCity") {
                    this.formValue.provinceCode = value[0];
                    this.formValue.cityCode = value[1];
                }
                if (handler) {
                    this.$emit("handleChange", handler, value);
                }
            },
            numberChange(currentValue, oldValue, key, min, max) {
                if (!currentValue && min !== undefined) {
                    this.formValue[key] = min;
                }
                if (!currentValue && min !== undefined) {
                    this.formValue[key] = max;
                }
            },
            handleVisibleChange(value, handler) {
                if (handler) {
                    this.$emit("handleVisibleChange", handler, value);
                }
            },
            treeClickChange(val, item) {
                this.$emit("handleClick", "saasTree4", val, item);
            },
            vehicleTreeCheck(handler, item) {
                this.$emit("handleClick", "vehicleTree", item);
            },
            orgTreeClick(val, item) {
                this.$emit("handleClick", "orgTree", val, item);
            },
            alarmTreeCheck(data) {
                this.$emit("handleClick", "alarmTree", data);
            },
            logTreeCheck(data, parentName) {
                this.$emit("handleClick", "logTree", data, parentName);
            },
			remoteMethod(query) {
				this.$emit('remoteMethod',query);			
			},		
			showloading(flag){
				this.loading = flag;
			},
			handleClear(handler){
				this.$emit("handleClear", handler);
			},	
            showAndHide(){
                this.total = this.arrowDown? -1: this.totalShow;
                this.arrowDown = !this.arrowDown;
                this.$emit('showOrHide',this.arrowDown);
            }
			
        }    
	};
</script>

<style lang="less">
.table_search_v2 {
    padding: 0 20px;
    .input_custom{
        width: 100%;
        .el-form-item{
            width: 20%;
            margin-right: 0;
            box-sizing: border-box;
            float: left;
            display: flex;
            .el-form-item__content{
                flex: 1
            }
            
            
        }
        .el-input__inner{
            height: 30px;
            line-height: 30px;
        }
        .el-input, .el-input__inner, .el-select, .el-form-item__label, .el-form-item__content, .el-textarea, .el-textarea__inner, .el-input-number, .el-cascader{
            width: 100%;
        }
        .el-form-item__label{padding-right: 5px;}
    }

    .form_btns_box{
        float: right; 
        height: 34px;
        padding-bottom: 8px;
        box-sizing: content-box;
        .el-dropdown{
            height:100%;
            margin-left: 10px;
        }
    }
    .upAndDown{
        margin-left: 50px;
        line-height: 30px;
        cursor: pointer;
        font-size: 12px;
        color: #2064D1;
    }
    .saas-button {
        font-size: 13px;
        padding: 0 15px;
        background: #306DEB;
        height: 100%;
        &:hover {
            background: #2064D1;
        }
    }
}
.tiankongzhilan,.baisejianyue{
    .table_search_v2{
        .form_btns_box {
            .el-dropdown{
                height: 28px;
            }
        }
    }
}
</style>
