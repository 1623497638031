import request from "@/utils/request";
import request2 from "@/utils/request2";

let velService = "vehicle-service/";
let alarmService = "alarm-service/";
let dispatcherService = "dispatcher-service/";
let trackService = "track-service/";

// 获取车辆位置信息（精简版）
// /saas/api/vehicle-service/vehicle/monitor/location/v2
export function vehicleLocationV2(data) {
    return request({
        url: velService + "vehicle/monitor/location/v2",
        method: "post",
        data
    });
}

// 查询车辆最新位置及车辆详情信息（分页查询）
// /saas/api/vehicle-service/vehicle/monitor/details
export function vehicleDetails(data) {
    return request({
        url: velService + "vehicle/monitor/details",
        method: "post",
        data
    });
}

// 查询车辆最新位置及车辆详情信息（分页查询-精简版）
// /saas/api/vehicle-service/vehicle/monitor/details/v2
export function vehicleDetailsV2(data) {
    return request({
        url: velService + "vehicle/monitor/details/v2",
        method: "post",
        data
    });
}

// 查询车辆最新位置及车辆详情信息（分页查询）
// /saas/api/vehicle-service/vehicle/monitor/details
export function vehicleDetailVid(id) {
    return request({
        url: velService + `vehicle/vehicleDetail/${id}`,
        method: "get"
    });
}

// 根据多个vid查询车辆详情
export function vehicleDetailList(data) {
    return request({
        url: velService + `vehicle/vehicleDetail/list?hasOnlineStatus=true`,
        method: "post",
        data
    });
}

// 根据多个vid查询车辆详情和视频服务地址
export function vehicleServerList(data) {
    return request({
        url: velService + `vehicle/vehicleDetail/videoServer/list?hasOnlineStatus=true`,
        method: "post",
        data
    });
}

// 轨迹明细列表
// /saas/api/track-service/trajectory/queryVehicleTrajectory
export function queryVehicleTrajectory(data, showMask) {
    return request({
        url: trackService + "trajectory/queryVehicleTrajectory",
        method: "post",
        data,
        showMask
    });
}

// 轨迹明细导出
// /saas/api/track-service/trajectory/exportVehicleTrajectory
export function exportVehicleTrajectory(data, showMask) {
    return request({
        url: trackService + "trajectory/exportVehicleTrajectory",
        method: "post",
        data,
        responseType: "blob",
        contentType: "application/vnd.ms-excel"
    });
}

// 停车分析列表
// /saas/api/track-service/trajectory/analysis/queryVehicleParkSpots
export function queryVehicleParkSpots(data) {
    return request({
        url: trackService + "trajectory/analysis/queryVehicleParkSpots",
        method: "post",
        data
    });
}

// 停车分析导出
// /saas/api/track-service/trajectory/analysis/exportVehicleParkSpots
export function exportVehicleParkSpots(data) {
    return request({
        url: trackService + "trajectory/analysis/exportVehicleParkSpots",
        method: "post",
        data,
        responseType: "blob",
        contentType: "application/vnd.ms-excel"
    });
}

// 按单车查询全部报警明细
// /saas/api/alarm-service/alarmQuery/detail/list
export function alarmDetailList(data) {
    return request({
        url: alarmService + "alarmQuery/detail/list",
        method: "post",
        data
    });
}

// 按单车导出全部报警明细
// /saas/api/alarm-service/alarmQuery/detail/export
export function alarmDetailExport(data) {
    return request({
        url: alarmService + "alarmQuery/detail/export",
        method: "post",
        data,
        responseType: "blob",
        contentType: "application/vnd.ms-excel"
    });
}

// 获取司机刷卡记录
export function getDriverCollect(data) {
    return request({
        url: alarmService + `driverCollect/list`,
        method: "post",
        data,
    });
}

// 导出司机刷卡记录
export function exportDriverCollect(params) {
    return request({
        url: alarmService + `driverCollect/export`,
        method: "get",
        params,
        responseType: "blob",
        contentType: "application/vnd.ms-excel",
    });
}

// 警情监管-报警车辆
// /saas/api/alarm-service/alarmInfo/queryAlarmCarGroup
export function queryAlarmCarGroup(data) {
    return request({
        url: alarmService + "alarmInfo/queryAlarmCarGroup",
        method: "post",
        data
    });
}

// 警情监管-分页查询
// /saas/api/alarm-service/alarmInfo/queryNearestAlarm
export function queryNearestAlarm(data) {
    return request({
        url: alarmService + "alarmInfo/queryNearestAlarm",
        method: "post",
        data
    });
}

// 处理报警
// /saas/api/alarm-service/alarmInfo/dealAlarm
export function dealAlarm(data) {
    return request({
        url: alarmService + "alarmInfo/dealAlarm",
        method: "post",
        data
    });
}

// 报警导出
// /saas/api/alarm-service/alarmInfo/export
export function alarmExport(params) {
    return request({
        url: alarmService + "alarmInfo/export",
        method: "get",
        params,
        responseType: "blob",
        contentType: "application/vnd.ms-excel"
    });
}

// 查询常用短语
// /saas/api/alarm-service/alarmPrompt/list
export function alarmPromptList(params) {
    return request({
        url: alarmService + "alarmPrompt/list",
        method: "get",
        params
    });
}

// 新增/修改自定义短语
// /saas/api/alarm-service/alarmPrompt/add
export function alarmPromptAdd(data) {
    return request({
        url: alarmService + "alarmPrompt/" + data.type,
        method: "post",
        data: data.data
    });
}

// 删除自定义短语
// /saas/api/alarm-service/alarmPrompt/delete
export function alarmPromptDelete(data) {
    return request({
        url: alarmService + "alarmPrompt/delete",
        method: "post",
        data
    });
}

// 修改短语显示隐藏状态
// /saas/api/alarm-service/alarmPrompt/updateStatus
export function alarmPromptUpdateStatus(data) {
    return request({
        url: alarmService + "alarmPrompt/updateStatus",
        method: "post",
        data
    });
}

// 批量查询位置
// https://restapi.amap.com/v3/geocode/regeo?parameters
export function gaodeLocation(params) {
    return request2({
        url: "geocode/regeo",
        method: "get",
        params
    });
}

// 录像回放下载记录
export function uploadVideo(data) {
    return request({
        url: dispatcherService + "uploadVideo/records",
        method: "post",
        data
    });
}

// 录像回放获取视频文件
export function getDownloadUrl(params) {
    return request({
        url: dispatcherService + "ftpFile/getDownloadUrl",
        method: "get",
        params
    });
}

// /saas/api/dispatcher-service/check/uploading/ftpFileSize
// 录像回放轮询上传进度
export function ftpFileSize(data) {
    return request({
        url: dispatcherService + "check/uploading/ftpFileSize",
        method: "post",
        data
    });
}
// 文本信息下发-车辆调度
// /saas/api/dispatcher-service/command/textInformation/send
export function textInformationSend(data) {
    return request({
        url: dispatcherService + "command/textInformation/send",
        method: "post",
        data
    });
}

// 文本信息下发状态轮询
// /saas/api/dispatcher-service/command/textInformation/list
export function textInformationList(data) {
    return request({
        url: dispatcherService + "command/textInformation/list",
        method: "post",
        data
    });
}

// 拍照指令下发
// /saas/api/dispatcher-service/command/multimedia/photograph/send
export function photographSend(data) {
    return request({
        url: dispatcherService + "command/multimedia/photograph/send",
        method: "post",
        data
    });
}

// 拍照指令轮询
// /saas/api/dispatcher-service/command/multimedia/photograph/query/list
export function photographList(data) {
    return request({
        url: dispatcherService + "command/multimedia/photograph/query/list",
        method: "post",
        data
    });
}

// 添加围栏和分类
export function addFenceUrl(data) {
    return request({
        url: velService + "fence/addFence",
        method: "post",
        data
    });
}
// 删除围栏信息
export function clearFenceUrl(params) {
    return request({
        url: velService + "fence/clearFence",
        method: "delete",
        params
    });
}
// 修改围栏和分类
export function modifyFenceUrl(data) {
    return request({
        url: velService + "fence/modifyFence",
        method: "put",
        data
    });
}
// 查询围栏信息详情
export function queryFenceDetailUrl(params) {
    return request({
        url: velService + "fence/queryFenceDetail",
        method: "get",
        params
    });
}
// 查询围栏信息树
export function queryFenceTreeUrl(params) {
    return request({
        url: velService + `fence/${params}/queryFenceTree`,
        method: "get",
    });
}

// 增加线路路段信息
// /saas/api/vehicle-service/road/section/addOrUpdateBatchSections
export function addOrUpdateBatchSections(data) {
    return request({
        url: velService + "road/section/addOrUpdateBatchSections",
        method: "post",
        data
    });
}

// 查询线路路段信息
// /saas/api/vehicle-service/road/section/{fenceId}/queryRoadSection
export function queryRoadSection(fenceId) {
    return request({
        url: velService + `road/section/${fenceId}/queryRoadSection`,
        method: "get",
    });
}

// 删除线路路段信息
// /saas/api/vehicle-service/road/section/{fenceId}/clearSection
export function clearSection(fenceId) {
    return request({
        url: velService + `road/section/${fenceId}/clearSection`,
        method: "delete",
    });
}

// 增加线路站点信息
// /saas/api/vehicle-service/line/site/addOrUpdateBatchLineSite
export function addOrUpdateBatchLineSite(data) {
    return request({
        url: velService + "line/site/addOrUpdateBatchLineSite",
        method: "post",
        data
    });
}

// 删除线路站点信息
// /saas/api/vehicle-service/line/site/{fenceId}/queryLineSite
export function queryLineSite(fenceId) {
    return request({
        url: velService + `line/site/${fenceId}/queryLineSite`,
        method: "get",
    });
}

// 删除线路站点信息
// /saas/api/vehicle-service/line/site/{fenceId}/clearSite
export function clearSite(fenceId) {
    return request({
        url: velService + `line/site/${fenceId}/clearSite`,
        method: "delete",
    });
}

// 批量删除线路站点信息
// /saas/api/vehicle-service/line/site/clearBatchSite
export function clearBatchSite(data) {
    return request({
        url: velService + 'line/site/clearBatchSite',
        method: "delete",
        data
    });
}


// 获取车辆位置信息（精简版）
export function vehicleLocationV3(data) {
    return request({
        url: velService + "vehicle/monitor/location/v3",
        method: "post",
        data
    });
}
// 查询车辆最新位置及车辆详情信息（分页查询-精简版）
export function vehicleDetailsV3(data) {
    return request({
        url: velService + "vehicle/monitor/details/v3",
        method: "post",
        data
    });
}