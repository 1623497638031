import ca from "element-ui/src/locale/lang/ca";

function checkCapitalNumber(rule, value, callback) {
    if (!value) {
        callback();
        return;
    }
    let reg = /^[A-Z0-9]+$/;
    if (reg.test(value)) {
        callback();
    } else {
        callback(new Error("请输入大写字母或数字"));
    }
}

function checkNumberLetter(rule, value, callback) {
    if (!value) {
        callback();
        return;
    }
    let reg = /^[A-Za-z0-9]+$/;
    if (reg.test(value)) {
        callback();
    } else {
        callback(new Error("请输入大小写字母或数字"));
    }
}

function checkOnly5Number(rule, value, callback) {
    if (!value) {
        callback();
        return;
    }
    let reg = /^[0-9]{5}$/;
    if (reg.test(value)) {
        callback();
    } else {
        callback(new Error("请输入5位数字"));
    }
}

function check711Number(rule, value, callback) {
    if (!value) {
        callback();
        return;
    }
    let reg = /^[a-zA-Z0-9]{7}$/;
    let reg2 = /^[a-zA-Z0-9]{11}$/;
    if (reg.test(value) || reg2.test(value)) {
        callback();
    } else {
        callback(new Error("请输入7位或11位字符"));
    }
}

function check30Number(rule, value, callback) {
    if (!value) {
        callback();
        return;
    }
    let reg = /^[a-zA-Z0-9]{30}$/;
    if (reg.test(value)) {
        callback();
    } else {
        callback(new Error("请输入7位或11位字符"));
    }
}


function checkOnly17Number(rule, value, callback) {
    if (!value) {
        callback();
        return;
    }
    let reg = /^[A-Z0-9]{17}$/;
    if (reg.test(value)) {
        callback();
    } else {
        callback(new Error("请输入17位字符"));
    }
}

function checkOnly1113Number(rule, value, callback) {
    if (!value) {
        callback();
        return;
    }
    let reg = /^[\d]{11}$/;
    let reg2 = /^[\d]{13}$/;
    if (reg.test(value) || reg2.test(value)) {
        callback();
    } else {
        callback(new Error("请输入11位或13位数字"));
    }
}

function checkNumber(rule, value, callback) {
    if (!value) {
        callback();
        return;
    }
    let reg = /^[0-9]{1,100}$/;
    if (reg.test(value)) {
        callback();
    } else {
        callback(new Error("请输入数字"));
    }
}

function checkVehicle(rule, value, callback) {
    if (!value) {
        callback();
        return;
    }
    if (value.length == 7 || value.length == 8) {
        // let reg = /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-Z0-9]{4,5}[A-Z0-9挂学警港澳]{1}$/;
        let reg = /^([测京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z][A-Z](?:(?:[ABCDF][A-Z0-9][0-9]{4})|(?:[0-9]{5}[ABCDF]))|[测京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z][A-Z][A-Z0-9]{4}[A-Z0-9挂学警港澳])$/;
        if (reg.test(value)) {
            callback();
        } else {
            callback(new Error("请输入正确的车牌号或车架号"));
        }
    } else if (value.length == 17) {
        let reg2 = /^[A-Z0-9]{17}$/;
        if (reg2.test(value)) {
            callback();
        } else {
            callback(new Error("请输入正确的车牌号或车架号"));
        }
    } else {
        callback(new Error("请输入正确的车牌号或车架号"));
    }
}

function checkVehicle2(rule, value, callback) {
    if (!value) {
        callback();
        return;
    }
    if (value.length == 7) {
        let reg = /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-Z0-9]{4}[A-Z0-9挂学警港澳]{1}$/;
        if (reg.test(value)) {
            callback();
        } else {
            callback(new Error("请输入正确的车牌号或车架号"));
        }
    } else {
        callback(new Error("请输入正确的车牌号或车架号"));
    }
}

function checkHighLow(rule, value, callback) {
    if (!value) {
        callback();
        return;
    }
    if (value < 1 || value > 65535) {
        callback(new Error("请输入1到65535之间的数字"));
    } else {
        callback();
    }
}

function validateIP(rule, value, callback) {
    const reg = /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/;
    if ((!reg.test(value)) && value != "") {
        callback(new Error("请输入正确的IP地址"));
    } else {
        callback();
    }
}

function validateHttp(rule, value, callback) {
    const reg = /http[s]{0,1}:\/\/([\w.]+\/?)\S*/;
    if ((!reg.test(value)) && value != "") {
        callback(new Error("请输入正确的http地址"));
    } else {
        callback();
    }
}

function validateUrl(rule, value, callback) {
    const reg = /^([a-zA-Z0-9]([a-zA-Z0-9\-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,6}/;
    const reg2 = /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/;
    if (!reg.test(value) && !reg2.test(value) && value != "") {
        callback(new Error(rule.message));
    } else {
        callback();
    }
}

function validateUser(rule, value, callback) {
    const reg = /^[a-zA-Z0-9]{1,20}$/;
    if ((!reg.test(value)) && value != "") {
        callback(new Error("请输入数字、字母"));
    } else {
        callback();
    }
}

function checkPwd(rule, value, callback) {
    // 由数字，字母组成
    let reg = /^[a-zA-Z0-9\~\!@#$%\^&*\-]{1,8}$/;
    if (reg.test(value)) {
        callback();
    } else {
        callback(new Error("请输入数字、字母或特殊字符"));
    }
}

function numberScope(rule, value, callback) {
    let reg = /^[0-9]{1,100}$/;
    if (value <= 2147483647) {
        callback();
    } else {
        callback(new Error("请输入小于2147483647的数字"));
    }
}

function checkDateBefore(rule, value, callback) {
}

function checkDateAfter(rule, value, callback, expireDate) {
    if (!value) {
        callback();
        return;
    }
    value = typeof value == "number" ? value : value.getTime();
    if (value > expireDate) {
        callback(new Error(rule.message));
    } else {
        callback();
    }
}

function checkCharLen(rule, value, callback) {
    if (!value) {
        callback();
        return;
    }
    if (value > rule.max) {
        callback(new Error(rule.message));
    } else if (value < rule.min) {
        callback(new Error(rule.message));
    } else {
        callback();
    }
}

function checkICP(rule, value, callback) {
    if (!value) {
        callback();
        return;
    }
    let reg = /^[京津晋冀蒙辽吉黑沪苏浙皖闽赣鲁豫鄂湘粤桂琼渝川贵云藏陕甘青宁新]ICP备\d{8}号(-[1-9]\d?)?$/;
    if (reg.test(value)) {
        callback();
    } else {
        callback(new Error("请输入正确的备案号"));
    }
}

function validateOrgName(rule, value, callback) {
    if (!value) {
        callback();
        return;
    }
    //let reg = /^[\u4e00-\u9fa5]{2,40}[\u4e00-\u9fa5()（）]{0,40}[0-9]{0,3}$/;
	let reg = /^[\u4e00-\u9fa5_a-zA-Z0-9\(\)（）]{2,40}$/;
    if (reg.test(value)) {
        callback();
    } else {
        callback(new Error(rule.message));
    }
}

function validateNoSpecial(rule, value, callback) {
    if (!value) {
        callback();
        return;
    }
    let reg = /^[\u4e00-\u9fa5A-Za-z0-9\-_]+$/;
    if (reg.test(value)) {
        callback();
    } else {
        callback(new Error(rule.message));
    }
}

function checkPhoneNumber(rule, value, callback) {
    if (!value) {
        callback();
        return;
    }
    let reg = /^[1]\d{10}$/;
    if (reg.test(value)) {
        callback();
    } else {
        callback(new Error(rule.message));
    }
}

export default {
    checkCapitalNumber,
    checkNumberLetter,
    checkOnly5Number,
    check711Number,
    checkOnly1113Number,
    checkNumber,
    checkVehicle,
    checkVehicle2,
    checkOnly17Number,
    checkHighLow,
    validateIP,
    validateHttp,
    validateUrl,
    checkPwd,
    validateUser,
    numberScope,
    checkDateBefore,
    checkDateAfter,
    checkCharLen,
    checkICP,
    validateOrgName,
    validateNoSpecial,
    checkPhoneNumber
};