import utils from "../utils";
import {gaodeLocation} from "../api/monitorManage";
import {simpleVehicle, refreshStatus, orgVehCountTree,queryAllOrgTree,queryAllVehicleTree,queryAllTerminalTree} from "../api/index";

let treeDataObject = {};

let vehicles = {};
let vehicleTerminalMap = new Map();
let vehicleTreeRefreshLoading = false,getVehicleStatusLoading=false,vehicleStatusRefreshLoading = false;
// zyzhpt.com 山西平台
const aMapKey = location.hostname==="zyzhpt.com"?'7f36e1a36f4b6a4fb3352f8ce26e5ec1':'924493f6317443755725f07113410268';
let socketName = location.host.indexOf('127.0.0.1')>-1?'wss://':location.protocol == "http:" ? "ws://" : "wss://";
const ws = `${socketName + testhost}` + "/saas/ws/saas/api/dispatcher-service/web/ws/websocket/";

function getGaodeLocation(page, result, callback) {
    let arr = [];
    let str = "";
    let strIndex = 0;
    result.forEach((item, index) => {
        if (page == "alarmManage") {
            item.mapLon = item.startMapLon;
            item.mapLat = item.startMapLat;
        } else if (page == "realtimeMonitor") {
            item.address = "";
            item.t3 = utils.formatDate(item.t3 * 1);
            item.ct = utils.formatDate(item.ct * 1000);
            // item.t2 = item.t2 && item.t2 / 10 + "";
            item.t2 = parseInt(item.t2 / 10);//不要小数点
            item.t9 = item.t9 && item.t9 / 10 + "";
            item.mapLon = item.m2;
            item.mapLat = item.m1;
        } else if (page == "mileageDaily") {
            item.startAddress = "";
            item.mapLon = item.startMapLon;
            item.mapLat = item.startMapLat;
        } else if (page == "mileageDailyCopy") {
            item.endAddress = "";
            item.mapLon = item.endMapLon;
            item.mapLat = item.endMapLat;
        } else if (page == "lastLngLat") {
            item.mapLon = item.lastMapLon;
            item.mapLat = item.lastMapLat;
        } else {
            item.address = "";
        }
        if (strIndex >= 20) {
            strIndex = 0;
            arr.push(str);
            str = "";
        }
        strIndex++;
        str += item.mapLon / 600000 + "," + item.mapLat / 600000 + "|";
        // if (index == result.length - 1) {
        //     arr.push(str);
        // }
    });
    if(str.length){
        arr.push(str);    
        let gaodeIndex = 0;
        arr.forEach((item, index) => {
            let params = {
                key: aMapKey,
                location: item,
                batch: true,
                arrIndex: index
            };
            gaodeLocation(params).then(res => {
                gaodeIndex++;
                if (res.data && res.data.status == 1) {
                    let regeocodes = res.data.regeocodes;
                    regeocodes && regeocodes.forEach((regeocode, regeocodeIndex) => {
                        if (page == "mileageDaily") {
                            result[regeocodeIndex + res.config.params.arrIndex * 20].startAddress = regeocode.formatted_address;
                        } else if (page == "mileageDailyCopy") {
                            result[regeocodeIndex + res.config.params.arrIndex * 20].endAddress = regeocode.formatted_address;
                        } else {
                            result[regeocodeIndex + res.config.params.arrIndex * 20].address = regeocode.formatted_address;
                        }
                    });
                }
                if (gaodeIndex == arr.length) {
                    callback(result);
                }
            });
        });
    }
}

function getGaodeLocation2(result = [], option, callback) {
    if (!result || result.length == 0) {
        callback([]);
        return;
    }
    let {mapLon = "mapLon", mapLat = "mapLat", address = "address"} = option;
    let arr = [];
    let str = "";
    let strIndex = 0;
    let errResult = [];
    result.forEach((item, index) => {
        if (strIndex > 19) {
            strIndex = 0;
            arr.push(str);
            str = "";
        }
        strIndex++;
        let lonDecimal = Number(item[mapLon]) / 600000;
        let latDecimal = Number(item[mapLat]) / 600000;
        if (parseInt(lonDecimal) < 70 || parseInt(lonDecimal)> 140 || parseInt(latDecimal) < 3 || parseInt(latDecimal) > 60) {
            errResult.push(index); // 记录异常定位
        }else{
            str += lonDecimal.toFixed(6) + "," + latDecimal.toFixed(6) + "|";
        }  
           
    });
    if(str.length){
        arr.push(str);
        let gaodeIndex = 0;
        arr.forEach((item, index) => {
            console.log(item.length);
            let strTmp = item.substring(0,item.length-1);
            let params = {
                key: aMapKey,
                location: strTmp,
                batch: true, // batch=true为批量查询
                arrIndex: index 
            };
            gaodeLocation(params).then(res => {
                gaodeIndex++;
                if (res.data && res.data.status == '1') {
                    let regeocodes = res.data.regeocodes;
                    if(errResult.length){
                        errResult.forEach(v=>{
                            //插入功能， 第一个参数（插入位置），第二个参数删除（0），第三个参数（插入的项）
                            regeocodes.splice(v,0,{formatted_address:'未知位置'});
                        });       
                    }
    
                    regeocodes && regeocodes.forEach((regeocode, regeocodeIndex) => {
                        result[regeocodeIndex + res.config.params.arrIndex * 20][address] = regeocode.formatted_address;
                    });
                    if (gaodeIndex == arr.length) {
                        callback(result);
                    }
                }else if (res.data && res.data.status == '0'){
                    // 批量转换地址出错后，进行单个地址转换
                    let comRegeocodes = [],locArr = strTmp.split('|');
                    locArr.forEach((loc,index)=>{
                        gaodeLocation({
                            key: aMapKey,
                            location: loc,
                            arrIndex: 0
                        }).then(singleRes=>{
                            // 解析错误时，formatted_address会返加空数组，
                            // 解析正确时，formatted_address会返加地址的字符串，
                            let formatted_address = singleRes.data.regeocode.formatted_address;
                            let isErr = formatted_address instanceof Array;
                            comRegeocodes.push({formatted_address:isErr? '未知位置':formatted_address});
                            // 到最后一个时，进行回调
                            if (gaodeIndex == arr.length && index == locArr.length-1) {
                                if(errResult.length){
                                    errResult.forEach(v=>{
                                        //插入功能， 第一个参数（插入位置），第二个参数删除（0），第三个参数（插入的项）
                                        comRegeocodes.splice(v,0,{formatted_address:'未知位置'});
                                    });       
                                }
                
                                comRegeocodes && comRegeocodes.forEach((regeocode, regeocodeIndex) => {
                                    result[regeocodeIndex + singleRes.config.params.arrIndex * 20][address] = regeocode.formatted_address;
                                });
                                callback(result);
                            }
                        });
                    });

                }


            });
        });
    }

}

function getVehicleSocket(that, flag = true) {
    let vehicleList = {},
        entTree = [];
    try {
        that.$webSocket2.ws({
            type: "orgvehtree",
            onopened: (data) => {
                let params = {
                    type: "orgvehtree",
                    state: 1,
                    serialId: that.$utils.uuid32(),
                    data: {isCache: flag, removeFreeNode: true}
                };
                that.$orgvehtree.send(JSON.stringify(params));
                that.$orgvehtree.onmessage = (data) => {
                    let res = JSON.parse(data.data);
                    if (res.type == "orgvehtree") {
                        if (res.state == 1) {
                            if (Array.isArray(res.data)) {
                                entTree = res.data;
                                getEntTree(entTree, that);
                            } else {
                                for (let key in res.data) {
                                    if (res.data[key].length > 0) {
                                        vehicleList[key] = res.data[key];
                                    }
                                }
                            }
                        } else if (res.state == 2) {
                            getVehicleTree(entTree, vehicleList, that);
                            vehicleList = null;
                            that.$orgvehtree.close();
                        }
                    }
                };
            }
        });
    } catch (e) {
    }
}

function getVehicleSocketV2(that, flag = true) {
    let vehicleList = {},
        entTree = [];
    that.$webSocket2.ws({
        type: "orgvehtreeV2",
        onopened: (data) => {
            let params = {
                type: "orgvehtreeV2",
                state: 1,
                serialId: that.$utils.uuid32(),
                data: {isCache: flag, removeFreeNode: true}
            };
            that.$orgvehtreeV2.send(JSON.stringify(params));
            that.$orgvehtreeV2.onmessage = (data) => {
                let res = JSON.parse(data.data);
                if (res.type == "orgvehtreeV2") {
                    if (res.state == 1) {
                        if (Array.isArray(res.data)) {
                            entTree = res.data;
                            getEntTreeV2(entTree, that);
                        } else {
                            for (let key in res.data) {
                                if (res.data[key].length > 0) {
                                    vehicleList[key] = res.data[key];
                                }
                            }
                        }
                    } else if (res.state == 2) {
                        getVehicleTreeV2(entTree, vehicleList, that);
                        vehicleList = null;
                        that.$orgvehtreeV2.close();
                    }
                }
            };
        }
    });
}

function getEntTree(entTree, that) {
    that.$store.state.treeLoading = true;
    let monitorTree = [{
            label: "监控中心",
            value: "-1",
            extend: true,
            type: "ent",
            children: []
        }],
        trackPlayTree = [{
            label: "监控中心",
            value: "-1",
            extend: true,
            type: "ent",
            children: []
        }],
        playBackTree = [{
            label: "监控中心",
            value: "-1",
            extend: true,
            type: "ent",
            children: []
        }],
        videoPollTree = [{
            label: "监控中心",
            value: "-1",
            extend: true,
            type: "ent",
            children: []
        }];

    function recVehicleTree(data, parentItem) {
        let arr = [],
            arr3 = [],
            arr4 = [],
            arr5 = [],
            entObj, entObj3, entObj4, entObj5;
        data.forEach(item => {
            entObj = {
                value: item.o1,
                label: item.o2,
                type: "ent",
                extend: false,
                pathIds: parentItem ? [...parentItem.pathIds, parentItem.value] : ["-1"],
                children: [],
                o9: item.o9,
                o7: item.o7
            };
            entObj3 = {
                value: item.o1,
                label: item.o2,
                type: "ent",
                extend: false,
                pathIds: parentItem ? [...parentItem.pathIds, parentItem.value] : ["-1"],
                children: []
            };
            entObj4 = {
                value: item.o1,
                label: item.o2,
                extend: true,
                type: "ent",
                pathIds: parentItem ? [...parentItem.pathIds, parentItem.value] : ["-1"],
                children: []
            };
            entObj5 = {
                value: item.o1,
                label: item.o2,
                extend: true,
                type: "ent",
                pathIds: parentItem ? [...parentItem.pathIds, parentItem.value] : ["-1"],
                children: []
            };
            if (item.c && item.c.length > 0) {
                let {entArr, entArr3, entArr4, entArr5} = recVehicleTree(item.c, entObj);
                entObj.children = entArr;
                entObj3.children = entArr3;
                entObj4.children = entArr4;
                entObj5.children = entArr5;
                entArr = entArr3 = entArr4 = entArr5 = null;
            }
            arr.push(entObj);
            arr3.push(entObj3);
            arr4.push(entObj4);
            arr5.push(entObj5);
            entObj = entObj3 = entObj4 = entObj5 = null;
        });
        return {entArr: arr, entArr3: arr3, entArr4: arr4, entArr5: arr5};
    }

    let {entArr, entArr3, entArr4, entArr5} = recVehicleTree(entTree);
    monitorTree[0].children = entArr;
    trackPlayTree[0].children = entArr3;
    playBackTree[0].children = entArr4;
    videoPollTree[0].children = entArr5;
    that.$store.commit("setMonitorTree", monitorTree);
    that.$store.commit("setTrackPlayTree", trackPlayTree);
    that.$store.commit("setPlaybackTree", playBackTree);
    that.$store.commit("setVideoPollTree", videoPollTree);
    entArr = entArr3 = entArr4 = entArr5 = null;
}

function getVehicleTree(entTree, vehicleTree, that) {
    that.$store.state.treeLoading = false;
    try {
        // let monitorTree = that.$utils.deepClone(that.$store.state.monitorTree),
        //     onlineNumberTree = [],
        //     trackPlayTree = that.$utils.deepClone(that.$store.state.trackPlayTree),
        //     playBackTree = that.$utils.deepClone(that.$store.state.playBackTree),
        //     videoPollTree = that.$utils.deepClone(that.$store.state.videoPollTree);
        let monitorTree = JSON.parse(JSON.stringify(that.$store.state.monitorTree)),
            onlineNumberTree = [],
            trackPlayTree = JSON.parse(JSON.stringify(that.$store.state.trackPlayTree)),
            playBackTree = JSON.parse(JSON.stringify(that.$store.state.playBackTree)),
            videoPollTree = JSON.parse(JSON.stringify(that.$store.state.videoPollTree));

        function recursionTree(data, tree1, tree2, tree3, tree4) {
            let arr2 = [],
                obj1, obj2, obj3, obj4, obj5, entObj, entObj2, entObj3, entObj4, entObj5, item;
            for (let index = 0, playbackIndex = 0, videoPollIndex = 0; index < data.length; index++, playbackIndex++, videoPollIndex++) {
                item = data[index];
                entObj = tree1[index];
                entObj2 = {
                    o1: item.o1,
                    c: item.c,
                    o9: item.o9,
                    o7: item.o7
                };
                entObj3 = tree2[index];
                entObj4 = tree3[playbackIndex];
                entObj5 = tree4[videoPollIndex];
                if (item.c && item.c.length > 0) {
                    let {entArr2} = recursionTree(item.c, entObj.children, entObj3.children, entObj4.children, entObj5.children);
                    entObj2.c = entArr2;
                    entArr2 = null;
                }
                if (vehicleTree[item.o1]) {
                    entObj2.vs = [];
                    vehicleTree[item.o1].forEach(vehicle => {
                        // 实时监控车辆树
                        obj1 = {
                            label: vehicle.v2,
                            value: vehicle.v1,
                            type: "vehicle",
                            vehicleStatus: vehicle.v6,
                            offlineTime: vehicle.v7 ? Date.now() - vehicle.v7 : "",
                            pathIds: [...entObj.pathIds, item.o1]
                        };
                        entObj.children.push(obj1);
                        obj1 = null;
                        // 在线车辆树
                        obj2 = {
                            v1: vehicle.v1,
                            v6: vehicle.v6
                        };
                        entObj2.vs.push(obj2);
                        obj2 = null;
                        // 轨迹回放车辆树
                        obj3 = {
                            label: vehicle.v2,
                            value: vehicle.v1,
                            type: "vehicle",
                            pathIds: [...entObj3.pathIds, item.o1]
                        };
                        entObj3.children.push(obj3);
                        // 录像回放车辆树
                        // v8是否有通道号 0无  1有，vt 是否是几G终端
                        if (vehicle.v8 && vehicle.vt == 4) {
                            obj4 = {
                                label: vehicle.v2,
                                value: vehicle.v1,
                                type: "vehicle",
                                vehicleStatus: vehicle.v6,
                                pathIds: [...entObj4.pathIds, item.o1]
                            };
                            entObj4.children.push(obj4);
                            obj4 = null;
                        }
                        // 视频轮询车辆树
                        // v8是否有通道号 0无  1有，vt 是否是几G终端，v6 是否在线
                        if (vehicle.v6 != 0 && vehicle.v8 && vehicle.vt == 4) {
                            obj5 = {
                                label: vehicle.v2,
                                value: vehicle.v1,
                                type: "vehicle",
                                vehicleStatus: vehicle.v6,
                                pathIds: [...entObj5.pathIds, item.o1]
                            };
                            entObj5.children.push(obj5);
                            obj5 = null;
                        }
                    });
                }
                arr2.push(entObj2);
                if (entObj4.children.length == 0) {
                    tree3.splice(playbackIndex, 1);
                    playbackIndex--;
                }
                if (entObj5.children.length == 0) {
                    tree4.splice(videoPollIndex, 1);
                    videoPollIndex--;
                }
                item = entObj = entObj2 = entObj3 = entObj4 = entObj5 = null;
            }
            return {entArr2: arr2};
        }

        let {entArr2} = recursionTree(entTree, monitorTree[0].children, trackPlayTree[0].children, playBackTree[0].children, videoPollTree[0].children);
        onlineNumberTree = entArr2;
        that.$store.commit("setMonitorTree", monitorTree);
        that.$store.commit("setOnlineNumberTree", onlineNumberTree);
        that.$store.commit("setTrackPlayTree", trackPlayTree);
        that.$store.commit("setPlaybackTree", playBackTree);
        that.$store.commit("setVideoPollTree", videoPollTree);
        vehicleTree = entArr2 = null;
    } catch (e) {
    }
}

function getEntTreeV2(entTree, that) {
    that.$store.state.treeLoading2 = true;
    let vehicleTreeList = [{
            label: "监控中心",
            value: "-1",
            extend: true,
            type: 1,
            children: []
        }],
        suVehicleTreeConfig = [{
            label: "监控中心",
            value: "-1",
            expand: true,
            type: 1,
            children: []
        }],
        yueVehicleTreeConfig = [{
            label: "监控中心",
            type: 1,
            value: "-1",
            expand: true,
            children: []
        }];

    function recUserDataEntTree(data, parentItem) {
        let arr = [],
            arr2 = [],
            arr3 = [],
            entObj, entObj2, entObj3;
        data.forEach(item => {
            entObj = {
                value: item.o1,
                label: item.o2,
                type: 1,
                pathIds: parentItem ? [...parentItem.pathIds, parentItem.value] : ["-1"],
                totalCount: item.o7,
                children: []
            };
            entObj2 = {
                value: item.o1,
                label: item.o2,
                type: 1,                
                pathIds: parentItem ? [...parentItem.pathIds, parentItem.value] : ["-1"],
                totalCount: item.o7,
                children: []
            };
            entObj3 = {
                value: item.o1,
                label: item.o2,
                type: 1,                
                pathIds: parentItem ? [...parentItem.pathIds, parentItem.value] : ["-1"],
                totalCount: item.o7,
                children: []
            };
            if (item.c && item.c.length > 0) {
                let {entArr, entArr2, entArr3} = recUserDataEntTree(item.c, entObj);
                entObj.children = entArr;
                entObj2.children = entArr2;
                entObj3.children = entArr3;
                entArr = entArr2 = entArr3 = null;
            }
            arr.push(entObj);
            arr2.push(entObj2);
            arr3.push(entObj3);
            entObj = entObj2 = entObj3 = null;
        });
        return {entArr: arr, entArr2: arr2, entArr3: arr3};
    }

    let {entArr, entArr2, entArr3} = recUserDataEntTree(entTree);
    entArr.sort(function (a, b) {
        return a.label.localeCompare(b.label);
    });  
    entArr2.sort(function (a, b) {
        return a.label.localeCompare(b.label);
    });  
    entArr3.sort(function (a, b) {
        return a.label.localeCompare(b.label);
    }); 
    vehicleTreeList[0].children = entArr;
    suVehicleTreeConfig[0].children = entArr2;
    yueVehicleTreeConfig[0].children = entArr3;
    that.$store.commit("setVehicleTreeList", vehicleTreeList);
    that.$store.commit("setSuVehicleTreeList", suVehicleTreeConfig);
    that.$store.commit("setYueVehicleTreeList", yueVehicleTreeConfig);
    entArr = entArr2 = entArr3 = null;
}

function getVehicleTreeV2(entTree, vehicleTree, that) {
    that.$store.state.treeLoading2 = false;
    try {
        let vehicleTreeList = JSON.parse(JSON.stringify(that.$store.state.vehicleTreeList)),
            suVehicleTreeList = JSON.parse(JSON.stringify(that.$store.state.suVehicleTreeList)),
            yueVehicleTreeList = JSON.parse(JSON.stringify(that.$store.state.yueVehicleTreeList));

        function recursionTree(data, tree1, tree2, tree3) {
            let obj1, obj2, obj3, entObj, entObj2, entObj3, item;
            for (let index = 0; index < data.length; index++) {
                item = data[index];
                entObj = tree1[index];
                entObj2 = tree2[index];
                entObj3 = tree3[index];
                if (item.c && item.c.length > 0) {
                    recursionTree(item.c, entObj.children, entObj2.children, entObj3.children);
                }
                if (vehicleTree[item.o1]) {
                    vehicleTree[item.o1].forEach(vehicle => {
                        // 实时监控车辆树
                        obj1 = {
                            label: vehicle.v2,
                            value: vehicle.v1,
                            type: 2,
                            vehicleStatus: vehicle.v6,
                            pathIds: [...entObj.pathIds, item.o1]
                        };
                        entObj.children.push(obj1);
                        obj1 = null;
                        // 苏标车辆树
                        if (vehicle.pt != 17) {
                            obj2 = {
                                label: vehicle.v2,
                                value: vehicle.v1,
                                type: 2,
                                vehicleStatus: vehicle.v6,
                                pathIds: [...entObj2.pathIds, item.o1]
                            };
                            entObj2.children.push(obj2);
                            obj2 = null;
                        }
                        // 苏标车辆树
                        if (vehicle.pt == 17) {
                            obj3 = {
                                label: vehicle.v2,
                                value: vehicle.v1,
                                type: 2,
                                vehicleStatus: vehicle.v6,
                                pathIds: [...entObj3.pathIds, item.o1]
                            };
                            entObj3.children.push(obj3);
                            obj3 = null;
                        }
                    });
                }
                item = entObj = entObj2 = entObj3 = null;
            }
        }

        recursionTree(entTree, vehicleTreeList[0].children, suVehicleTreeList[0].children, yueVehicleTreeList[0].children);
        vehicleTree = null;
        vehicleTreeList.sort(function (a, b) {
            return a.label.localeCompare(b.label);
        }); 
        suVehicleTreeList.sort(function (a, b) {
            return a.label.localeCompare(b.label);
        }); 
        yueVehicleTreeList.sort(function (a, b) {
            return a.label.localeCompare(b.label);
        });
        that.$store.commit("setVehicleTreeList", vehicleTreeList);
        that.$store.commit("setSuVehicleTreeList", suVehicleTreeList);
        that.$store.commit("setYueVehicleTreeList", yueVehicleTreeList);
    } catch (e) {
    }
}

// 数据平铺
function flatTreeData(arr, that) {
    let newArr = [];
    for (let i = 0; i < arr.length; i++) {
        let templ = arr[i];
        if (Array.isArray(templ)) {
            let newData = flatTreeData(templ, that);
            newArr = newArr.concat(newData);
        } else {
			// templ.checked = false;
			// templ.partchecked = false;
            newArr.push(templ);
			if(!treeDataObject[templ.value]){
				treeDataObject[templ.value] = templ;
			}
        }
        if (Array.isArray(templ[that.treeProps.children])) {
            let newData = flatTreeData(templ[that.treeProps.children], that);
			// newData.checked = false;
			// newData.partchecked = false;
            newArr = newArr.concat(newData);
        }
    }
    return newArr;
}

function treeExtend(item, arr) {
	// 将车辆进行选中或不选中
    item.children.forEach(vehicle => {
        if (vehicle.type == 2) {
            if (vehicle.disabled) {
                vehicle.checked = true;
            } else {
				if(arr && arr.length){
					vehicle.checked = arr.indexOf(vehicle.value) > -1? true: false;
				}				
            }
        }
    });

}

function setTableHeader(col, page, model, that) {
    if (col.length > 0) {
        col.forEach(item => {
            that[model][page].listHeader.forEach(itm => {
                if (item.prop == itm.prop) {
                    item.render = itm.render;
                }
            });
        });
        that.tableConfig.tableHeader = col;
    } else {
        that.tableConfig.tableHeader = that[model][page].listHeader;
        for (let i = 0; i < that.tableConfig.tableHeader.length; i++) {
            let tHeader = that.tableConfig.tableHeader[i];
            tHeader.display = "block";
        }
    }
}

function getDirectlyVel(that, item, checkBox, pageType, callback) {
    let params = {
        entId: item.value,
        isCache: true,
        moreFields: "v7"
    };
    item.expand = true;
    // if (that.pageType == "realtimeMonitor" || this.pageType == "trackPlay" || this.pageType == "videoPolling") {
    //     let index = this.refreshStatusIds.indexOf(item.value);
    //     if (index == -1) {
    //         this.refreshStatusIds.push(item.value);
    //     } else if (!item.expand) {
    //         this.refreshStatusIds.splice(index, 1);
    //     }
    // }
    simpleVehicle(params).then(res => {
        if (res.code === 0 && res.result.length) {
            for (let i = 0; i < res.result.length; i++) {
                let vehicle = res.result[i];
                vehicle.type = 2;
                vehicle.label = vehicle.v2;
                delete vehicle.v2;
                vehicle.value = vehicle.v1;
                delete vehicle.v1;
                vehicle.vehicleStatus = vehicle.v6;
                delete vehicle.v6;
                if (pageType == "monitorManage") {
                    let {icon, className,status} = that.$refs.vehicleTree.setIconColor(vehicle);
                    vehicle.icon = icon;
                    vehicle.className = className;
                    vehicle.status = status;
                }
                vehicle.offlineTime = vehicle.v7 ? Date.now() - vehicle.v7 : "";
                delete vehicle.v7;
                vehicle.checkBox = checkBox;

            }
			item.children = [...res.result];
            //item.children.push(...res.result);
        }
        callback && callback();
    });
}

function vehicleStatusRefresh(that) {
    if (!that.$refs.vehicleTree || (that.$refs.vehicleTree&&that.$refs.vehicleTree.refreshStatusIds.length == 0)||vehicleStatusRefreshLoading) {
        return;
    }   
 
    vehicleStatusRefreshLoading = true;
    let params = {
        entIds: that.$refs.vehicleTree.refreshStatusIds,
        isCache: true,
        selectOrgOwnVeh: false
    };
    
    refreshStatus(params).then(res => {
        if (res.code == 0) {
            let obj = {};
            res.result.forEach(item => {
                obj[item.v1] = {
                    v6: item.v6 + "",
                    vbs: item.vbs
                };
            });

            function recursion(list) {
                list.forEach(item => {
                    if (obj[item.value]) {
                        item.vehicleStatus = obj[item.value].v6 * 1;
                        let {icon, className,status} = that.$refs.vehicleTree.setIconColor(obj[item.value]);
                        item.icon = icon;
                        item.className = className;
                        item.status = status;
                    }
                    if (item.vehicleStatus) {
                        item.offlineTime = 1000;
                    }
                    if (item.children && item.children.length) {
                        recursion(item.children);
                    }
                });
            }

            recursion(that.vehicleTreeData[0].children);
            obj = null;
            vehicleStatusRefreshLoading = false;
        }
    });
}

function pollOfflineTime(that) {
    if(!that.vehicleTreeData.length) return;
    let children = that.vehicleTreeData[0].children;
    if (that.offlineTimer) {
        clearInterval(that.offlineTimer);
    }
    that.offlineTimer = setInterval(() => {
        if (children.length > 0) {
            function recursionTree(data) {
                data.forEach(item => {
                    if (item.children && item.children.length > 0) {
                        item.children = recursionTree(item.children);
                    }
                    if (item.offlineTime && item.offlineTime != 0) {
                        item.offlineTime = item.offlineTime * 1 + 60000;
                    }
                });
                return data;
            }

            children = recursionTree(children);
        }
    }, 60000);
}

function getVehicleStatus(that, callback) {
    if(getVehicleStatusLoading){
        return;
    }
    getVehicleStatusLoading = true;
    let params = {
        isCache: true,
        removeFreeNode: true
    };
    orgVehCountTree(params).then(res => {
        if (res.code === 0) {
            function recursion(list, data) {
                list.forEach((item, index) => {
                    if (data[index].value && data[index].value == item.o1) {
                        data[index].o7 = item.o7;
                        data[index].o9 = item.o9;
                    }
                    if (item.c) {
                        recursion(item.c, data[index].children);
                    }
                });
            }
            getVehicleStatusLoading = false;
            recursion(res.result, that.vehicleTreeData[0].children);

            vehicleStatusRefresh(that);
            callback && callback();
        }
    });
}

function vehicleTreeRefresh(flag = true) {
    if(vehicleTreeRefreshLoading){
        return;
    }
    vehicleTreeRefreshLoading = true;
    let params = {
        isCache: flag,
        removeFreeNode: true
    };
    orgVehCountTree(params).then(res => {
        console.log('刷新车辆缓存');
        vehicleTreeRefreshLoading = false;
    });
}

async function getAllOrgTree (vm,callback){
    let res = await queryAllOrgTree();
    if (res.code == 0) { // && res.result.length
        //组织ID:直属上级组织ID:组织名称:组织编辑权限 1=可查看，2=可编辑
        let orgs1 = [],orgs2 = [];
        let orgs1Obj = {};
        res.result.forEach(item=>{
            let arr = item.split(':');
            let obj = {
                value: arr[0],
                pid: arr[1],
                label: arr[2],
                auth: arr[3],
                entName: arr[2],
                entId: arr[0],
            };
            orgs1.push(obj);
            arr[3]=='2' && orgs2.push(obj);// auth为2的情况下，可以在选择组织的页面进行选择，为1的话不能进行选择
        });
        // orgData1保存全部组织 
        let orgData1 = utils.translateArrToTree(orgs1);
        orgData1.sort(function (a, b) {
            return a.label.localeCompare(b.label);
        }); 
        function recursion(list, parent) {
            list.forEach(item => {
                item.type = 1;
                item.leaf = false;
                item.pathIds = parent ? [...parent.pathIds, parent.value] : [item.pid];
                if (item.children && item.children.length) {
                    recursion(item.children, item);
                }
                orgs1Obj[item.value] = item;
            });                            
        }
        recursion(orgData1);
        let treeList = [];
        // userType:3 普通用户，只有orgData2.length>=2时候才会显示 “监控中心”
        // userType<3 管理员，会一直有“监控中心”这个节点
        if(orgData1.length>1||vm.$store.state.loginInfo.userType<3){
            treeList = [{
                value: "-1",
                label: "监控中心",
                type: 1,
                children: [...orgData1],
                entName: "监控中心", // 为了兼容树，有的树用了entName和entId，有的用了label和value
                entId: "-1"
            }];  
        }else{
            treeList = [...orgData1];
        }                            

        vm.$store.commit("setOrgData1", treeList);
        orgs1Obj['-1'] = treeList[0];
        vm.$store.commit('setOrgData1Object',orgs1Obj);
        let orgData2 = utils.translateArrToTree(orgs2);
        orgData2.sort(function (a, b) {
            return a.label.localeCompare(b.label);
        });  
                   
        // orgData2保存可以进行操作的组织  
        let treeList2 = [];
        // userType:3 普通用户，只有orgData2.length>=2时候才会显示 “监控中心”
        // userType<3 管理员，会一直有“监控中心”这个节点
        if(orgData2.length>1||vm.$store.state.loginInfo.userType<3){
            treeList2 = [{
                label: "监控中心",
                value: "-1",
                children: [...orgData2],
                entName:  "监控中心",
                entId: "-1"  
            }];
        }else{
            treeList2 = [...orgData2];
        }

        vm.$store.commit("setOrgData2", treeList2);  

        console.log('组织转换完成');
        callback && callback();            
    }
}
// 查询所有的车辆和对应终端的信息
async function getVehiclesAndTerminal(vm,callback){
    vm.$store.state.vehicleTreeLoading = true;
    let vehiclesObj = {};
    let resTerminal = await queryAllTerminalTree();

    //vid:simAccount:equipmentType (车辆ID:SIM卡号:设备类型(2:2G部标  4:1078视频))
    // 每辆车的终端信息
    if (resTerminal.code == 0 && resTerminal.result.length) {
        resTerminal.result.forEach(item=>{
            let t = item.split(':');
            vehicleTerminalMap.set(t[0],{
                vid: t[0],
                sim: t[1], 
                eType: t[2],
                channels: t[3]?t[3].split(','):[]
            });
        });
        //vm.$store.commit('setVehicleTerminal',vehicleTerminal);
    }   
    let res = await queryAllVehicleTree();

    if (res.code == 0 && res.result.length) {
        let vehicles = res.result,
            timer = null,
            numbers = [],
            circleNo = 1;

        timer = setInterval(()=>{
            for (var i = (circleNo - 1) * 10000; i <= circleNo * 10000 - 1; i++) {
                numbers.push(i);
                let vehicle = vehicles[i];
                
                if(!vehicle){
                    clearInterval(timer);
                    console.log('车辆转换完成');
                    vm.$store.commit("setVehicleTree", vehiclesObj);  
                    vm.$store.state.vehicleTreeLoading = false;                              
                    break;
                }
                //E041972eb1e0e1011:041a112d620e1000:皖AC0646:2:exp
                let vehicleProps = vehicle.split(':');
                let obj = {
                    pid: vehicleProps[0],
                    value: vehicleProps[1],
                    label: vehicleProps[2],
                    color: vehicleProps[3],
                    exp: vehicleProps[4],
                    leaf: true,
                    type: 2
                };
                // 以组织的id为key,建立一个数组,保存该组织下所有的车辆
                if (!vehiclesObj[vehicleProps[0]]) {
                    vehiclesObj[vehicleProps[0]] = [];
                }
                
                vehiclesObj[vehicleProps[0]].push(obj);
                // 以每辆车的id设置一个对象，存放每辆车的信息
                vm.$Foo.vehicles[vehicleProps[1]] = obj;
                if (i == circleNo * 10000 - 1) {
                    circleNo++;
                }
            }				
        },100);
    }  
    
    callback && callback();
}


export default {
    getGaodeLocation,
    getGaodeLocation2,
    getVehicleSocket,
    getVehicleSocketV2,
    flatTreeData,
    treeExtend,
    setTableHeader,
    getDirectlyVel,
    vehicleStatusRefresh,
    pollOfflineTime,
    getVehicleStatus,
    vehicleTreeRefresh,
	treeDataObject,
    vehicles,
    aMapKey,
    ws,
    getAllOrgTree,
    getVehiclesAndTerminal,
    vehicleTerminalMap,
    
};