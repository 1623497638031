import utils from "../../../utils";
import {areaData, proCity} from "../common/area_data";
import store from "../../../store";
import terminalParam from "./terminalParam";
import {vehicleSearch} from "@/api/index";

const roleLevels = {
	'1': '平台管理员','2': '省级管理员','3': '代理商管理员','4': '企业管理员','5': '企业监控员',
};
var validatePwd = (rule, value, callback) => {
    //需8-16位包含大写字母、小写字母、数字中的两种或以上
    let pattern = /^(?![A-Z]+$)(?![a-z]+$)(?!\d+$)(?![\W_]+$)\S{8,16}$/;
    if(!pattern.test(value)){
        callback(new Error("密码格式错误"));   
    }else{
        callback();
    }
};

export default {
    userMenu: {
        userMenuList: [
            {
                name: "规则管理",
                title: "规则管理",
                path: "ruleManage/",
                menuId: "user_center_rule",
                icon: "icon-menu iconzy_guize iconfont",
                children: [
                    {
                        name: "报警等级设置",
                        title: "报警等级设置",
                        path: "/alarmLevel",
                        routerName: "alarmLevel",
                        parent: "userManage",
                        menuId: "user_center_rule_grade"
                    },
                    {
                        name: "报警提醒设置",
                        title: "报警提醒设置",
                        path: "/alarmWarn",
                        routerName: "alarmWarn",
                        parent: "userManage",
                        menuId: "user_center_rule_warn"
                    }, {
                        name: "报警规则管理",
                        title: "报警规则管理",
                        path: "/alarmrules",
                        routerName: "alarmrules",
                        parent: "userManage",
                        menuId: "user_center_rule_arule"
                    }
                ]
            },
            {
                name: "基础信息",
                title: "运营管理",
                path: "baseInfomanage/",
                menuId: "user_center_base",
                icon: "icon-menu iconjichuxinxi iconfont",
                children: [
                    {
                        name: "组织管理",
                        title: "组织管理",
                        path: "/organization",
                        routerName: "organization",
                        menuId: "user_center_base_org",
                        parent: "userManage"
                    },
                    {
                        name: "车辆管理",
                        title: "车辆管理",
                        path: "/vehicle",
                        routerName: "vehicle",
                        menuId: "user_center_base_vehicle",
                        parent: "userManage"
                    },
                    {
                        name: "字典管理",
                        title: "字典管理",
                        path: "/dictionaries",
                        routerName: "dictionaries",
                        menuId: "user_center_base_dictionaries",
                        parent: "userManage"
                    },
                    {
                        name: "驾驶员管理",
                        title: "驾驶员管理",
                        path: "/driver",
                        routerName: "driver",
                        menuId: "user_center_base_driver",
                        parent: "userManage"
                    },
                    {
                        name: "终端厂商管理",
                        title: "终端厂商管理",
                        path: "/terminal",
                        routerName: "terminal",
                        menuId: "user_center_base_termincompany",
                        parent: "userManage"
                    },
                    {
                        name: "终端型号管理",
                        title: "终端型号管理",
                        path: "/terminalmodel",
                        routerName: "terminalmodel",
                        menuId: "user_center_base_terminmodel",
                        parent: "userManage"
                    }
                ]
            },
            {
                name: "系统管理",
                title: "系统管理",
                path: "systemManage/",
                menuId: "user_center_user",
                icon: "icon-menu iconguanli iconfont",
                children: [
                    {
                        name: "角色管理",
                        title: "角色管理",
                        path: "/role",
                        routerName: "role",
                        menuId: "user_center_user_role",
                        parent: "userManage"
                    },
                    {
                        name: "用户管理",
                        title: "用户管理",
                        path: "/user",
                        routerName: "user",
                        menuId: "user_center_user_account",
                        parent: "userManage"
                    },
                    {
                        name: "配置管理",
                        title: "配置管理",
                        path: "/configManage",
                        routerName: "configManage",
                        menuId: "user_center_config_manage",
                        parent: "userManage"
                    },
                    {
                        name: "报警配置",
                        title: "报警配置",
                        path: "/alarmConfig",
                        routerName: "alarmConfig",
                        menuId: "user_center_alarm_config",
                        parent: "userManage"
                    },
                    {
                        name: "模板管理",
                        title: "模板管理",
                        path: "/noticetemplate",
                        routerName: "noticetemplate",
                        menuId: "user_center_user_template",
                        parent: "userManage"
                    },
                    {
                        name: "短信配置",
                        title: "短信配置",
                        path: "/SMSConfig",
                        routerName: "SMSConfig",
                        menuId: "user_center_user_smsConfig",
                        parent: "userManage"
                    }
                ]
            },
            {
                name: "服务管理",
                title: "服务管理",
                path: "serverManage/",
                menuId: "user_center_serve",
                icon: "icon-menu iconiconfontdianshangbisai-icon- iconfont",
                children: [
                    {
                        name: "接入服务",
                        title: "接入服务",
                        path: "/accessserver",
                        routerName: "accessserver",
                        menuId: "user_center_serve_access",
                        parent: "serverManage"
                    },
                    {
                        name: "转发服务",
                        title: "转发服务",
                        path: "/forwardserver",
                        routerName: "forwardserver",
                        menuId: "user_center_serve_forward",
                        parent: "serverManage"
                    }, {
                        name: "视频服务器配置",
                        title: "视频服务器配置",
                        path: "/videoserver",
                        routerName: "videoserver",
                        menuId: "user_center_serve_video",
                        parent: "serverManage"
                    }, {
                        name: "终端升级",
                        title: "终端升级",
                        path: "/terminalupgrade",
                        routerName: "terminalupgrade",
                        menuId: "user_center_serve_upgrade",
                        parent: "serverManage"
                    }, {
                        name: "批量调度",
                        title: "批量调度",
                        path: "/batchdispatch",
                        routerName: "batchdispatch",
                        menuId: "user_center_serve_batchdispatch",
                        parent: "serverManage"
                    }, {
                        name: "短信服务",
                        title: "短信服务",
                        path: "/SMSServer",
                        routerName: "SMSServer",
                        menuId: "user_center_serve_smsServer",
                        parent: "userManage"
                    }
                ]
            },
            {
                name: "日志管理",
                title: "日志管理",
                path: "logManage/",
                menuId: "user_center_log",
                icon: "icon-menu iconrizhi iconfont",
                children: [
                    {
                        name: "车辆日志",
                        title: "车辆日志",
                        path: "/vehicleLog",
                        routerName: "vehicleLog",
                        parent: "userManage",
                        menuId: "user_center_vehicle_log"
                    },
                    {
                        name: "用户日志",
                        title: "用户日志",
                        path: "/userLog",
                        routerName: "userLog",
                        parent: "userManage",
                        menuId: "user_center_user_log"
                    }, {
                        name: "组织日志",
                        title: "组织日志",
                        path: "/orgLog",
                        routerName: "orgLog",
                        parent: "userManage",
                        menuId: "user_center_org_log"
                    }, {
                        name: "指令日志",
                        title: "指令日志",
                        path: "/orderLog",
                        routerName: "orderLog",
                        parent: "userManage",
                        menuId: "user_center_order_log"
                    }
                ]
            },
            {
                name: "管理员专用",
                title: "管理员专用",
                path: "adminOnly/",
                menuId: "user_center_admin",
                icon: "icon-menu iconguanliyuan_guanliyuanliebiao iconfont",
                children: [
                    {
                        name: "菜单管理",
                        title: "菜单管理",
                        path: "/menuConfig",
                        routerName: "menuConfig",
                        menuId: "user_center_admin_menu",
                        parent: "adminOnly"
                    }, {
                        name: "投诉与建议",
                        title: "投诉与建议",
                        path: "/feedBack",
                        routerName: "feedBack",
                        menuId: "user_center_admin_complain",
                        parent: "adminOnly"
                    },
                    {
                        name: "API账号管理",
                        title: "API账号管理",
                        path: "/accountmanage",
                        routerName: "accountmanage",
                        menuId: "user_center_admin_apiaccount",
                        parent: "adminOnly"
                    }
                ]
            }
        ]
    },
    dictionaries: {
        formItem: [{
            label: "字典类型:",
            type: "select",
            key: "classType",
            popperClass: "monitor-select search-monitor-select",
            style: {
                width: "25%"
            },
            options: [],
            handler: "classType"
        },{
            label: "字典名称:",
            type: "input",
            key: "className",
            style: {
                width: "20%"
            }
        },{
            id: "base_info_org_query",
            label: "",
            text: "查询",
            type: "button",
            key: "search",
            itemType: "primary",
            handler: "search",
        }],
        listHeader: [{
            label: "字典类型",
            prop: "classTypeName",
            render(row) {
                return row.classTypeName || "--";
            }
        },
            {
                label: "字典名称",
                prop: "className",
                render(row) {
                    return row.className || "--";
                }
            },
            {
                label: "字典编码",
                prop: "classValue",
                render(row) {
                    return row.classValue || "--";
                }
            },
            {
                label: "字典描述",
                prop: "classDecr",
                render(row) {
                    return row.classDecr || "--";
                }
            },
            {
                label: "备注",
                prop: "remark",
                render(row) {
                    return row.remark || "--";
                }
            },
            {
                label: "操作",
                option: [{
                    label: "修改",
                    handler: "onEdit",
                    auth: "user_center_base_dictionaries_modify"
                }, {
                    label: "删除",
                    handler: "onDelete",
                    auth: "user_center_base_dictionaries_delete"
                }]
            }
        ],
        addItem: [{
            label: "字典类型:",
            type: "select",
            popperClass: "monitor-select",
            key: "classType",
            style: {
                width: "70%",
                marginLeft: "15%"
            },
            options: [],
            rules: [{
                required: true,
                message: "请选择字典类型",
                trigger: "change"
            }]
        },
            {
                label: "字典名称:",
                type: "input",
                key: "className",
                maxLength: 20,
                style: {
                    width: "70%",
                    marginLeft: "15%"
                },
                rules: [
                    {required: true, message: "请输入字典名称", trigger: "blur"},
                    {validator: utils.validateNoSpecial, message: "字典名称不能包含特殊字符", trigger: "blur"}
                ]
            },
            {
                label: "字典编码:",
                type: "input",
                key: "classValue",
                maxLength: 7,
                style: {
                    width: "70%",
                    marginLeft: "15%"
                },
                rules: [
                    {required: true, message: "请输入字典编码", trigger: "blur"},
                    {validator: utils.checkNumber, trigger: "blur"}
                ]
            },
            {
                label: "字典描述:",
                type: "input",
                key: "classDecr",
                maxLength: 50,
                style: {
                    width: "70%",
                    marginLeft: "15%"
                }
                // rules: [{max: 50, message: "最长输入50个字符", trigger: "blur"}]
            },
            {
                label: "备注:",
                type: "textarea",
                key: "remark",
                maxLength: 100,
                style: {
                    width: "70%",
                    marginLeft: "15%"
                }
                // rules: [{max: 100, message: "最长输入50个字符", trigger: "blur"}]
            }
        ]
    },
    vehicleBasicsInfo: {
		// 车辆管理页中的搜索条件
        formInput: [
            {
                label: "组织名称:",
                type: "orgTree",
                key: "entName",
                itemType: "filterItem",
                style: {
                    width: "calc(25% - 40px)",
                    marginRight: "20px"
                }
            },
            {
                label: "车牌号码:",
                type: "input",
                key: "vehicleNo",
                style: {
                    width: "calc(20% - 40px)",
                    marginRight: "20px"
                }
            },
            {
                label: "SIM卡号:",
                type: "input",
                key: "simAccount",
                style: {
                    width: "calc(20% - 40px)",
                    marginRight: "20px"
                }
            },
            {
                label: "终端ID:",
                type: "input",
                key: "tlMac",
                style: {
                    width: "calc(20% - 40px)",
                    marginRight: "0rem"
                }
            },
            {
                label: "设备类型:",
                type: "select",
                key: "equipmentType",
				options: [],
                style: {
                    width: "calc(20% - 40px)",
                    marginRight: "0rem"
                }
            },			
            {
                label: "北斗运营商:",
                type: "select",
                popperClass: "monitor-select search-monitor-select",
                key: "accessCode",
                options: [],
                style: {
                    width: "calc(20% - 40px)",
                    marginRight: "20px"
                }
            },
            {
                label: "是否绑定运营商:",
                type: "select",
                popperClass: "monitor-select search-monitor-select",
                key: "accessStatus",
                options: [{
                    label: "未绑定",
                    value: "0"
                }, {
                    label: "绑定",
                    value: "1"
                }],
                style: {
                    width: "calc(25% - 40px)",
                    marginRight: "20px"
                }
            }, 
			{
                label: "创建时间:",
                type: "dateRange",
				format:"yyyy-MM-dd",
				noNeedTime: true,
                popperClass: "monitor-date-picker",
                key: "startEndTime",
                style: {
                    width: "calc(30% - 60px)",
                },
                handler: "startEndTime"
            },
            {
                id: "base_info_vehicle_query",
                label: "",
                text: "重置",
                type: "button",
                notFormItem: true,
                key: "reset",
                itemType: "primary",
                handler: "reset",
                style: {
                    marginLeft: "auto",
                    marginRight: "10px",
                    height: "34px"
                }
            },
            {
                id: "base_info_vehicle_query",
                label: "",
                text: "查询",
                type: "button",
                notFormItem: true,
                key: "search",
                itemType: "primary",
                handler: "search",
                style: {
                    height: "34px"
                }
            }
        ],
        formInputV2: [
            {
                label: "组织名称:",
                type: "orgTree",
                key: "entName",
                itemType: "filterItem"
            },
            {
                label: "车牌号码:",
                type: "input",
                key: "vehicleNo",
            },
            {
                label: "SIM卡号:",
                type: "input",
                key: "simAccount",
            },
            {
                label: "终端ID:",
                type: "input",
                key: "tlMac",
            },
            {
                label: "设备类型:",
                type: "select",
                key: "equipmentType",
				options: [],
            },	
            {
                label: "车架号:",
                type: "input",
                key: "vin",
                rules: [
                    {validator: utils.checkCapitalNumber, trigger: "blur"}
                ]
            },	
            {
                label: "真实SIM卡号:",
                type: "input",
                key: "realSimAccount", 
                rules: [
                    {validator: utils.checkNumber, trigger: "blur"}                    
                ]
            },
            {
                label: "北斗运营商:",
                type: "select",
                popperClass: "monitor-select search-monitor-select",
                key: "accessCode",
                options: [],
            },
            {
                label: "绑定运营商:",
                type: "select",
                popperClass: "monitor-select search-monitor-select",
                key: "accessStatus",
                options: [{
                    label: "未绑定",
                    value: "0"
                }, {
                    label: "绑定",
                    value: "1"
                }],
            }, 
			{
                label: "创建时间:",
                type: "dateRange",
				format:"yyyy-MM-dd",
				noNeedTime: true,
                popperClass: "monitor-date-picker",
                key: "startEndTime",
                handler: "startEndTime",
            },
        ], 
        formBtns: [
            {
                text: "查询",
                key: "search",
                type: "primary",
                handler: "search",
            },
        ],      
        tableHeader: [
            {
                prop: "entName",
                label: "所属组织",
                colDisabled: true, // 列配置禁止隐藏
                minWidth: utils.setFontSize(230),
                render: row => {
                    return row.entName;
                }
            },
            {
                prop: "accessName",
                label: "北斗运营商",
                minWidth: utils.setFontSize(120),
                render: function (row) {
                    return row.accessName || "--";
                }
            },
            {
                prop: "receiveCode",
                label: "接入方式",
                minWidth: utils.setFontSize(120),
                render: function (row) {
                    let receiveCode = "--";
                    if (row.receiveCode == 0) {
                        receiveCode = "默认";
                    } else if (row.receiveCode == 1) {
                        receiveCode = "808协议";
                    } else if (row.receiveCode == 2) {
                        receiveCode = "809协议";
                    }
                    return receiveCode;
                }
            },
            {
                prop: "vehicleNo",
                label: "车牌号码",
                colDisabled: true,
                minWidth: utils.setFontSize(120)
            },
            {
                prop: "vehiclePlateColor",
                label: "车牌颜色",
                colDisabled: true,
                minWidth: utils.setFontSize(120),
                render: row => {
                    let vehiclePlateColorArr = JSON.parse(localStorage.getItem("vehiclePlateColorArr")),
                        vehiclePlateColorObj = {};
                    vehiclePlateColorArr.forEach(item => {
                        vehiclePlateColorObj[item.value] = item.label;
                    });
                    return vehiclePlateColorObj[row.vehiclePlateColor] || "";
                }
            },
            {
                prop: "vehicleIndustry",
                label: "运输行业类别",
                width: utils.setFontSize(140),
                render: row => {
                    let vehicleIndustryArr = JSON.parse(localStorage.getItem("vehicleIndustryArr")),
                        vehicleIndustryObj = {};
                    vehicleIndustryArr.forEach(item => {
                        vehicleIndustryObj[item.value] = item.label;
                    });
                    return vehicleIndustryObj[row.vehicleIndustry] || "--";
                }
            },
            {
                prop: "regions",
                label: "所属区域",
                colDisabled: true,
                minWidth: utils.setFontSize(180),
                render: row => {
                    let regions = "";
                    areaData.forEach(province => {
                        if (province.value == row.provinceCode) {
                            regions += province.label;
                            province.children && province.children.forEach(city => {
                                if (city.value == row.cityCode) {
                                    regions += city.label;
                                    city.children && city.children.forEach(county => {
                                        if (county.value == row.countyCode) {
                                            regions += county.label;
                                        }
                                    });
                                }
                            });
                        }
                    });
                    return regions || "--";
                }
            },
            {
                prop: "vehicleType",
                label: "车辆类型",
                width: utils.setFontSize(120),
                render: row => {
                    let vehicleTypeArr = JSON.parse(localStorage.getItem("vehicleTypeArr")),
                        vehicleTypeObj = {};
                    vehicleTypeArr.forEach(item => {
                        vehicleTypeObj[item.value] = item.label;
                    });
                    return vehicleTypeObj[row.vehicleType] || "--";
                }
            },
            {
                prop: "operationType",
                label: "运营状态",
                colDisabled: true,
                minWidth: utils.setFontSize(150),
                render(row) {
                    return row.operationType == 0 ? "非运营" : "运营";
                }
            },
            {
                prop: "explain",
                label: "自编号",
                colDisabled: true,
                minWidth: utils.setFontSize(150),
                render(row) {
                    return row.explain || "--";
                }
            },
            {
                prop: "simAccount",
                label: "SIM卡号",
                colDisabled: true,
                minWidth: utils.setFontSize(160)
            },
            {
                prop: "tlMac",
                label: "终端ID",
                colDisabled: true,
                minWidth: utils.setFontSize(120)
            },
            {
                prop: "realSimAccount",
                label: "真实SIM卡号",
                colDisabled: true,
                minWidth: utils.setFontSize(120),
                render: row => {
                    return row.realSimAccount || "--";
                }
            },
            {
                prop: "deviceNo",
                label: "设备号",
                colDisabled: true,
                minWidth: utils.setFontSize(120),
                render: row => {
                    return row.deviceNo || "--";
                }
            }, 
            {
                prop: "deviceSeq",
                label: "设备序列号",
                colDisabled: true,
                minWidth: utils.setFontSize(120),
                render: row => {
                    return row.deviceSeq || "--";
                }
            },         
            {
                prop: "equipmentType",
                label: "设备类型",
                colDisabled: true,
				tooltip: false,
                width: utils.setFontSize(120),
                render(row) {
                    return row.equipmentType?row.equipmentType==2?'2G部标':'4G视频': "--";
                }				
            },			
            {
                prop: "vin",
                label: "车架号",
                width: utils.setFontSize(120),
                render: function (row) {
                    return row.vin || "--"; 
                }
            },
            {
                prop: "ownerName",
                label: "车主姓名",
                width: utils.setFontSize(120),
                render: function (row) {
                    return row.ownerName || "--";
                }
            },
            {
                prop: "ownerIdCard",
                label: "车主身份证",
                width: utils.setFontSize(200),
                render: function (row) {
                    return row.ownerIdCard || "--";
                }
            },
            {
                prop: "ownerPhone",
                label: "车主联系电话",
                width: utils.setFontSize(140),
                render: function (row) {
                    return row.ownerPhone || "--";
                }
            },
            {

                prop: "createUserName",
                label: "创建人",
                width: utils.setFontSize(120),
                render: function (row) {
                    return row.createUserName || "--";
                }
            },
            {
                prop: "createTime",
                label: "创建时间",
                width: utils.setFontSize(200),
                render: (row) => {
                    return utils.formatDate(row.createTime);
                }
            },
            {
                prop: "updateUserName",
                label: "修改人",
                width: utils.setFontSize(120),
                render: function (row) {
                    return row.updateUserName || "--";
                },
			},
            {
                prop: "updateTime",
                label: "修改时间",
                width: utils.setFontSize(200),
                render: (row) => {
                    return utils.formatDate(row.updateTime);
                }
            },
            {
                name: "",
                label: "操作",
                prop: "operation",
                width: utils.setFontSize(150),
                option: [{
                    label: "修改",
                    handler: "updateVelOpen",
                    auth: "user_center_base_vehicle_modify"
                }, {
                    label: "删除",
                    handler: "delVel",
                    auth: "user_center_base_vehicle_delete"
                }],
                more: [{
                    label: '油耗设置',
                    handler: "oilSetting",
                    auth: 'user_center_base_vehicle_fuelConsumeSet'
                }],
                trigger:'click'
            }
        ],
        // 车辆管理页添加和编辑车辆信息
		addModelItem: [
            {
                text: "车辆信息",
                type: "vertical",
                display: "none",
                style: {
                    width: "100%"
                }
            },
            {
                label: "车牌号码:",
                type: "input",
                key: "vehicleNo",
                handler: "vehicleNo",
				maxLength: 21,
                style: {
                    width: "33.33%"
                },
                rules: [
                    {required: true, message: "请输入车牌号", trigger: "blur"},
                    //{validator: utils.checkVehicle, trigger: "blur"}
                ]
            },
            {
                label: "车牌颜色:",
                type: "select",
                popperClass: "monitor-select",
                key: "vehiclePlateColor",
                style: {
                    width: "33.33%"
                },
                options: [],
                rules: [{required: true, message: "请选择车牌颜色", trigger: "change"}]
            },
            {
                label: "自编号:",
                type: "input",
                key: "explain",
                maxLength: 21,
                style: {
                    width: "33.33%"
                }
                // rules: [{max: 50, message: "最长输入50个字符", trigger: "blur"}]
            },			
            {
                label: "车辆类型:",
                type: "select",
                leftLabel: true,
                popperClass: "monitor-select",
                key: "vehicleType",
                style: {
                    width: "33.33%"
                },
                options: [],
                rules: [{required: true, message: "请选择车辆类型", trigger: "change"}]
            },
            {
                label: "所属组织:",
                type: "orgTree",
                key: "entName",
                pageType: "vehicleManage",
                itemType: "addModelItem",
                style: {
                    width: "33.33%"
                },
                options: [],
                rules: [{required: true, message: "请选择所属组织", trigger: "change"}]
            },
            {
                label: "所属区域:",
                type: "cascader",
                popperClass: "monitor-cascader",
                key: "regions",
                style: {
                    width: "33.33%"
                },
                options: areaData,
                //rules: [{required: true, message: "请选择所属区域", trigger: "change"}]
            },
            {
                label: "运营状态:",
                type: "select",
                key: "operationType",
                popperClass: "monitor-select",
                style: {
                    width: "33.33%"
                },
                options: [{
                    label: "非运营",
                    value: "0"
                }, {
                    label: "运营",
                    value: "1"
                }],
                rules: [{required: true, message: "请选择运营类型", trigger: "change"}]
            },
            {
                label: "运输行业类别:",
                type: "select",
                leftLabel: true,
                key: "vehicleIndustry",
                popperClass: "monitor-select",
                style: {
                    width: "33.33%"
                },
                options: []
            },
            {
                label: "道路运输证号:",
                type: "input",
                key: "roadLicenseNo",
                maxLength: 12,
                style: {
                    width: "33.33%"
                },
                rules: [
                    {validator: utils.checkNumber, trigger: "blur"}
                    // {max: 12, message: "最长输入12个字符", trigger: "blur"}
                ]
            },
            {
                label: "发动机号:",
                type: "input",
                key: "engineNumber",
                maxLength: 8,
                style: {
                    width: "33.33%"
                },
                rules: [
                    {validator: utils.checkCapitalNumber, trigger: "blur"}
                    // {max: 8, message: "最长输入8个字符", trigger: "blur"}
                ]
            },
            {
                label: "座位/吨位:",
                type: "input",
                key: "approvedLoad",
                style: {
                    width: "33.33%"
                }
            },
            {
                label: "车架号:",
                type: "input",
                key: "vin",
                maxLength: 17,
                style: {
                    width: "33.33%"
                },
                rules: [
                    {validator: utils.checkCapitalNumber, trigger: "blur"}
                    // {max: 17, message: "最长输入17个字符", trigger: "blur"}
                ]
            },
            {
                label: "车辆品牌:",
                type: "select",
                key: "vehicleBrand",
                popperClass: "monitor-select",
                style: {
                    width: "33.33%"
                },
                options: []
            },
            {
                label: "车辆颜色:",
                type: "select",
                popperClass: "monitor-select",
                key: "vehicleColor",
                style: {
                    width: "33.33%"
                },
                options: []
            },
            {
                label: "生产日期:",
                type: "datePicker",
                key: "vehicleProductDate",
                popperClass: "monitor-date-picker form-data-picker",
                style: {
                    width: "33.33%"
                },
                rules: [{
                    validator: (rule, value, callback) => {
                        utils.checkDateAfter(rule, value, callback, new Date().getTime());
                    },
                    trigger: "blur",
                    message: "生产日期不能大于当前时间"
                }]
            },
            {
                label: "年审日期:",
                type: "datePicker",
                popperClass: "monitor-date-picker form-data-picker",
                key: "annualReviewDate",
                style: {
                    width: "33.33%"
                }
            },
            {
                label: "安装日期:",
                type: "datePicker",
                popperClass: "monitor-date-picker form-data-picker",
                key: "installTime",
                style: {
                    width: "33.33%"
                }
            },
            {
                label: "备注:",
                type: "textarea",
                key: "remarks",
                maxLength: 50,
                style: {
                    width: "100%"
                }
                // rules: [{max: 50, message: "最长输入50个字符", trigger: "blur"}]
            },
            {
                text: "终端信息",
                type: "vertical",
                display: "none",
                style: {
                    width: "100%"
                }
            },
            {
                label: "SIM卡号:",
                type: "input",
                key: "simAccount",
                style: {
                    width: "33.33%"
                },
                handler: "simAccountInput",
                //placeholder: "请输入数字",
                maxLength: 20,
                rules: [
                    {required: true, message: "请输入SIM卡号", trigger: "blur"},
                    //{validator: utils.checkOnly1113Number, trigger: "blur"}
                    {validator: utils.checkNumber, trigger: "blur"}                    
                ]
            },
            {
                label: "终端ID:",
                type: "input",
                key: "tlMac",
                style: {
                    width: "33.33%"
                },
                placeholder: "请输入终端ID",
                handler: "tlMacInput",
                maxLength: 30,
                rules: [
                    //{required: true, message: "请输入终端ID", trigger: "blur"},
                    {validator: utils.checkNumberLetter, trigger: "blur"}
                ]
            },
            {
                label: "终端厂商:",
                type: "select",
                key: "oemCode",
                popperClass: "monitor-select",
                style: {
                    width: "33.33%"
                },
                options: [],
                rules: [{required: true, message: "请选择终端厂商", trigger: "change"}],
                handler: "oemCode"
            },
            {
                label: "真实SIM卡号:",
                type: "input",	
                //placeholder: "请输入数字",
                key: "realSimAccount",
                handler: "realSimAccountInput",
                style: {
                    width: "33.33%"
                },
                maxLength: 20,
                rules: [
                    {validator: utils.checkNumber, trigger: "blur"}                    
                ]                
            },
            {
                label: "设备号:",
                type: "input",
                key: "deviceNo",
                maxLength: 15,
                style: {
                    width: "33.33%"
                }
            },
            {
                label: "设备序列号:",
                type: "input",
                key: "deviceSeq",
                maxLength: 16,
                style: {
                    width: "33.33%"
                },
            },
            {
                label: "终端型号:",
                type: "select",
                key: "tlModel",
                popperClass: "monitor-select",
                style: {
                    width: "33.33%"
                },
                options: [{
                    value: "JY-ZDX",
                    label: "JY-ZDX"
                }],
                rules: [{required: true, message: "请选择终端型号", trigger: "change"}]
            },
            {
                label: "设备类型:",
                type: "select",
                key: "equipmentType",
                popperClass: "monitor-select",
                style: {
                    width: "33.33%"
                },
                options: [], // 从this.$local.get("equipmentTypeArr") 中获取
                rules: [{required: true, message: "请选择设备类型", trigger: "change"}],
                handler: "equipmentType"
            },
            {
                label: "终端协议:",
                type: "select",
                key: "protocolType",
                popperClass: "monitor-select",
                style: {
                    width: "33.33%"
                },
                options: [],
                rules: [{required: true, message: "请选择终端协议", trigger: "change"}]
            },
            {
                label: "视频通道:",
                type: "select",
                key: "channelNum",
                popperClass: "monitor-select",
                style: {
                    width: "100%"
                },
                multiple: true,
                options: [{
                    label: "通道1",
                    value: "1"
                }, {
                    label: "通道2",
                    value: "2"
                }, {
                    label: "通道3",
                    value: "3"
                }, {
                    label: "通道4",
                    value: "4"
                }, {
                    label: "通道5",
                    value: "5"
                }, {
                    label: "通道6",
                    value: "6"
                }, {
                    label: "通道7",
                    value: "7"
                }, {
                    label: "通道8",
                    value: "8"
                }, {
                    label: "通道9",
                    value: "9"
                }]
            },
            {
                label: "音频编码:",
                type: "select",
                key: "audioCode",
                popperClass: "monitor-select",
                style: {
                    width: "33.33%"
                },
                options: []
            }, {
                label: "对讲通道:",
                type: "select",
                key: "intercomChannel",
                popperClass: "monitor-select",
                style: {
                    width: "33.33%"
                },
                options: [{
                    label: "通道1",
                    value: "1"
                }, {
                    label: "通道2",
                    value: "2"
                }, {
                    label: "通道3",
                    value: "3"
                }, {
                    label: "通道4",
                    value: "4"
                }, {
                    label: "通道5",
                    value: "5"
                }, {
                    label: "通道6",
                    value: "6"
                }, {
                    label: "通道7",
                    value: "7"
                }, {
                    label: "通道8",
                    value: "8"
                }, {
                    label: "通道9",
                    value: "9"
                }, {
                    label: "通道10",
                    value: "10"
                }, {
                    label: "通道11",
                    value: "11"
                }, {
                    label: "通道12",
                    value: "12"
                }, {
                    label: "通道13",
                    value: "13"
                }, {
                    label: "通道33",
                    value: "33"
                }, {
                    label: "通道36",
                    value: "36"
                }, {
                    label: "通道37",
                    value: "37"
                }]
            }, {
                label: "广播通道:",
                type: "select",
                key: "broadcastChannel",
                popperClass: "monitor-select",
                style: {
                    width: "33.33%"
                },
                options: [{
                    label: "通道1",
                    value: "1"
                }, {
                    label: "通道2",
                    value: "2"
                }, {
                    label: "通道3",
                    value: "3"
                }, {
                    label: "通道4",
                    value: "4"
                }, {
                    label: "通道5",
                    value: "5"
                }, {
                    label: "通道6",
                    value: "6"
                }, {
                    label: "通道7",
                    value: "7"
                }, {
                    label: "通道8",
                    value: "8"
                }, {
                    label: "通道9",
                    value: "9"
                }, {
                    label: "通道10",
                    value: "10"
                }, {
                    label: "通道11",
                    value: "11"
                }, {
                    label: "通道12",
                    value: "12"
                }, {
                    label: "通道13",
                    value: "13"
                }, {
                    label: "通道33",
                    value: "33"
                }, {
                    label: "通道36",
                    value: "36"
                }, {
                    label: "通道37",
                    value: "37"
                }]
            },
            {
                text: "车主信息",
                type: "vertical",
                display: "none",
                style: {
                    width: "100%"
                }
            }, {
                label: "车主名称:",
                type: "input",
                key: "ownerName",
                style: {
                    width: "33.33%"
                },
                rules: [{validator: utils.validateNoSpecial, message: "字典名称不能包含特殊字符", trigger: "blur"}]
            }, {
                label: "车主身份证:",
                type: "input",
                key: "ownerIdCard",
                style: {
                    width: "33.33%"
                },
                rules: [
                    // { required: true, message: "请输入车主身份证", trigger: "blur" },
                    {
                        pattern: /(^\d{8}(0\d|10|11|12)([0-2]\d|30|31)\d{3}$)|(^\d{6}(18|19|20)\d{2}(0\d|10|11|12)([0-2]\d|30|31)\d{3}(\d|X|x)$)/,
                        message: "请输入正确的车主身份证",
                        trigger: "blur"
                    }
                ]
            }, {
                label: "车主联系电话:",
                type: "input",
                key: "ownerPhone",
                style: {
                    width: "33.33%"
                },
                rules: [
                    // { required: true, message: "请输入车主联系方式", trigger: "blur" },
                    // {
                    //     pattern: /^((1[3,5,8][0-9])|(14[5,7])|(17[0,5,6,7,8])|(19[7]))\d{8}$/,
                    //     message: "请输入正确的车主联系电话",
                    //     trigger: "blur"
                    // }
                    {validator: utils.checkPhoneNumber, message: "请输入正确的车主联系电话", trigger: "blur"}
                ]
            }, {
                label: "车主信息备注:",
                type: "textarea",
                key: "ownerRemark",
                maxLength: 50,
                style: {
                    width: "100%"
                },
                options: []
                // rules: [{max: 50, message: "最长输入50个字符", trigger: "blur"}]
            }, {
                text: "车辆服务管理信息",
                type: "vertical",
                display: "none",
                style: {
                    width: "100%"
                }
            }, {
                label: "服务开始时间:",
                type: "datePicker",
                popperClass: "monitor-date-picker form-data-picker",
                key: "serviceStartDate",
                style: {
                    width: "33.33%"
                },
                options: []
            }, {
                label: "服务到期时间:",
                type: "datePicker",
                popperClass: "monitor-date-picker form-data-picker",
                key: "serviceExpirationDate",
                style: {
                    width: "33.33%"
                },
                options: []
            }, {
                label: "服务到期提醒天数:",
                type: "number",
                key: "serviceExpirationWarnDay",
                style: {
                    width: "33.33%"
                }
            }, {
                label: "服务是否缴费:",
                type: "select",
                popperClass: "monitor-select",
                key: "servicePay",
                style: {
                    width: "33.33%"
                },
                options: [{
                    label: "是",
                    value: 1
                }, {
                    label: "否",
                    value: 0
                }]
            }, {
                label: "服务费缴纳时间:",
                type: "datePicker",
                popperClass: "monitor-date-picker form-data-picker",
                key: "servicePayDate",
                style: {
                    width: "33.33%"
                },
                options: []
            }, {
                label: "服务费:",
                type: "number",
                key: "serviceCharge",
                style: {
                    width: "33.33%"
                }
            }, {
                label: "设备安装时间:",
                type: "datePicker",
                popperClass: "monitor-date-picker form-data-picker",
                key: "equipmentInstallDate",
                style: {
                    width: "33.33%"
                },
                options: []
            }, {
                label: "安装人员名称:",
                type: "input",
                key: "installerName",
                style: {
                    width: "33.33%"
                },
                rules: [{validator: utils.validateNoSpecial, message: "字典名称不能包含特殊字符", trigger: "blur"}]
            }, {
                label: "联系方式:",
                type: "input",
                key: "installerPhone",
                style: {
                    width: "33.33%"
                },
                rules: [{
                    pattern: /^((1[3,5,8][0-9])|(14[5,7])|(17[0,5,6,7,8])|(19[7]))\d{8}$/,
                    message: "请检查联系方式是否正确",
                    trigger: "blur"
                }]
            }, {
                label: "服务信息备注:",
                type: "textarea",
                key: "serviceRemark",
                maxLength: 50,
                style: {
                    width: "100%"
                }
                // rules: [{max: 50, message: "最长输入50个字符", trigger: "blur"}]
            }
        ],
        
		bindModelItem: [{
            label: "运营商名称:",
            type: "select",
            popperClass: "monitor-select",
            key: "accessCode",
            style: {
                width: "100%"
            },
            options: [],
            rules: [
                {required: true, message: "请选择运营商名称", trigger: "change"}
            ]
        }],
        receiveCode: [{
            label: "接入方式:",
            type: "select",
            popperClass: "monitor-select",
            key: "receiveCode",
            style: {
                width: "100%"
            },
            options: [{
                label: "默认",
                value: 0
            }, {
                label: "808协议",
                value: 1
            }, {
                label: "809协议",
                value: 2
            }],
            rules: [
                {required: true, message: "请选择接入方式", trigger: "change"}
            ]
        }]
    },
    terminalModelManage: {
        formInput: [
            {
                label: "终端厂商:",
                type: "select",
                popperClass: "monitor-select search-monitor-select",
                key: "oemCode",
                style: {
                    width: "25%"
                },
                options: [],
                handler: "doSelect"
            },
            {
                id: "base_info_org_query",
                label: "",
                text: "查询",
                type: "button",
                key: "search",
                itemType: "primary",
                handler: "search",
            }
        ],
        listHeader: [
            {
                label: "终端厂商名称",
                prop: "oemName",
                render(row) {
                    return row.oemName || "--";
                }
            },
            {
                label: "终端厂商编码",
                prop: "oemCode",
                render(row) {
                    return row.oemCode || "--";
                }
            },
            {
                label: "终端型号",
                prop: "tlModel",
                render(row) {
                    return row.tlModel || "--";
                }
            },
            {
                label: "终端型号编码",
                prop: "tlModelCode",
                render(row) {
                    return row.tlModelCode || "--";
                }
            },
            {
                label: "创建时间",
                prop: "createTime",
                render(row) {
                    return utils.formatDate(row.createTime, "YYYY-MM-DD hh:mm:ss") || "--";
                }
            },
            {
                label: "描述",
                prop: "description",
                render(row) {
                    return row.description || "--";
                }
            }, {
                label: "操作",
                option: [{
                    label: "修改",
                    handler: "onEdit",
                    auth: "user_center_base_terminmodel_modify"
                }, {
                    label: "删除",
                    handler: "onDelete",
                    auth: "user_center_base_terminmodel_delete"
                }]
            }
        ],
        addItem: [
            {
                label: "终端厂商:",
                type: "select",
                popperClass: "monitor-select",
                key: "oemCode",
                style: {
                    width: "90%",
                    marginLeft: "5%"
                },
                options: [],
                handler: "doSelect",
                rules: [{
                    required: true,
                    message: "请输入终端厂商编码",
                    trigger: "change"
                }]
            },
            {
                label: "终端型号:",
                type: "input",
                key: "tlModel",
                style: {
                    width: "90%",
                    marginLeft: "5%"
                },
                rules: [{
                    required: true,
                    message: "请输入终端型号",
                    trigger: "blur"
                }]
            },
            {
                label: "终端型号编码:",
                type: "input",
                key: "tlModelCode",
                style: {
                    width: "90%",
                    marginLeft: "5%"
                },
                rules: [{
                    required: true,
                    message: "请输入终端型号编码",
                    trigger: "blur"
                }, {validator: utils.checkCapitalNumber, trigger: "blur"}]
            },
            {
                label: "描述:",
                type: "input",
                key: "description",
                maxLength: 50,
                style: {
                    width: "90%",
                    marginLeft: "5%"
                }
                // rules: [{max: 50, message: "最长输入50个字符", trigger: "blur"}]
            }
        ]
    },
    terminalManage: {
        formInput: [
            {
                label: "厂商名称:",
                type: "input",
                key: "fullName",
                style: {
                    width: "20%"
                }
            }, {
                label: "厂商名称:",
                type: "input",
                key: "fullNameCopy",
                style: {
                    width: "calc(25% - 40px)",
                    marginRight: "40px",
                    display: "none"
                }
            },
            {
                id: "base_info_org_query",
                label: "",
                text: "查询",
                type: "button",
                key: "search",
                itemType: "primary",
                handler: "search",
            }
        ],
        listHeader: [
            {
                label: "厂商名称",
                prop: "fullName",
                width: utils.setFontSize(220),
                render(row) {
                    return row.fullName || "--";
                }
            },
            {
                label: "厂商简称",
                prop: "shortName",
                width: utils.setFontSize(120),
                render(row) {
                    return row.shortName || "--";
                }
            },
            {
                label: "联系人",
                prop: "liaisons",
                width: utils.setFontSize(120),
                render(row) {
                    return row.liaisons || "--";
                }
            },
            {
                label: "联系电话",
                prop: "liaisonMobile",
                width: utils.setFontSize(120),
                render(row) {
                    return row.liaisonMobile || "--";
                }
            },
            {
                label: "所属区域",
                prop: "regions",
                width: utils.setFontSize(180),
                render(row) {
                    let regions = "";
                    areaData.forEach(province => {
                        if (province.value == row.provinceCode) {
                            regions += " " + province.label;
                            province.children && province.children.forEach(city => {
                                if (city.value == row.cityCode) {
                                    regions += city.label;
                                    city.children && city.children.forEach(county => {
                                        if (county.value == row.countyCode) {
                                            regions += county.label;
                                        }
                                    });
                                }
                            });
                        }
                    });
                    return regions ? regions : "--";
                }
            },
            {
                label: "制造商ID",
                prop: "oemCode",
                width: utils.setFontSize(120),
                render(row) {
                    return row.oemCode || "--";
                }
            },
            {
                label: "创建时间",
                prop: "createTime",
                width: utils.setFontSize(180),
                render(row) {
                    return utils.formatDate(row.createTime, "YYYY-MM-DD hh:mm:ss") || "--";
                }
            },
            {
                label: "修改时间",
                prop: "modifyTime",
                width: utils.setFontSize(180),
                render(row) {
                    return utils.formatDate(row.modifyTime, "YYYY-MM-DD hh:mm:ss") || "--";
                }
            },
            {
                label: "厂商地址",
                prop: "address",
                width: utils.setFontSize(250),
                render(row) {
                    return row.address || "--";
                }
            },
            {
                label: "状态",
                prop: "status",
                width: utils.setFontSize(100),
                render(row) {
                    return row.status ? "有效" : "无效";
                }
            }, {
                label: "操作",
                width: utils.setFontSize(150),
                option: [{
                    label: "修改",
                    handler: "onEdit",
                    auth: "user_center_base_termincompany_modify"
                }, {
                    label: "删除",
                    handler: "onDelete",
                    auth: "user_center_base_termincompany_delete"
                }]
            }
        ],
        addItem: [
            {
                label: "厂商名称:",
                type: "input",
                key: "fullName",
                maxLength: 20,
                style: {
                    width: "70%",
                    marginLeft: "15%"
                },
                rules: [
                    {required: true, message: "请输入厂商名称", trigger: "blur"},
                    {validator: utils.validateNoSpecial, message: "厂商名称不能包含特殊字符", trigger: "blur"}
                ]
            },
            {
                label: "制造商ID:",
                type: "input",
                key: "oemCode",
                maxLength: 5,
                style: {
                    width: "70%",
                    marginLeft: "15%"
                },
                rules: [
                    {required: true, message: "请输入制造商ID", trigger: "blur"},
                    {validator: utils.checkCapitalNumber, trigger: "blur"}
                    // {max: 5, message: "最长输入5个字符", trigger: "blur"}
                ]
            },
            {
                label: "所属区域:",
                type: "cascader",
                popperClass: "monitor-cascader",
                key: "regions",
                style: {
                    width: "70%",
                    marginLeft: "15%"
                },
                options: areaData,
                rules: [{
                    required: true,
                    message: "请选择所属区域",
                    trigger: "change"
                }]
            },
            {
                label: "厂商简称:",
                type: "input",
                key: "shortName",
                style: {
                    width: "70%",
                    marginLeft: "15%"
                },
                rules: [{
                    required: false,
                    message: "请输入厂商简称",
                    trigger: "change"
                }]
            },
            {
                label: "联系人:",
                type: "input",
                key: "liaisons",
                maxLength: 8,
                style: {
                    width: "70%",
                    marginLeft: "15%"
                }
                // rules: [{max: 8, message: "最长输入8个字符", trigger: "blur"}]
            },
            {
                label: "联系电话:",
                type: "input",
                key: "liaisonMobile",
                style: {
                    width: "70%",
                    marginLeft: "15%"
                },
                rules: [{
                    pattern: /^((1[3,5,8][0-9])|(14[5,7])|(17[0,5,6,7,8])|(19[7]))\d{8}$/,
                    message: "请检查手机号是否正确",
                    trigger: "blur"
                }]
            },
            {
                label: "厂商地址:",
                type: "input",
                key: "address",
                maxLength: 50,
                style: {
                    width: "70%",
                    marginLeft: "15%"
                }
                // rules: [{max: 50, message: "最长输入50个字符", trigger: "blur"}]
            }
        ]
    },
    driverManage: {
        formInput: [{
            label: "组织名称:",
            type: "saasTree4",
            key: "entName",
            style: {
                width: "25%",
                marginRight: "20px"
            }
        },
            {
                label: "驾驶员姓名:",
                type: "input",
                key: "driverName",
                style: {
                    width: "20%"
                }
            },
            {
                label: "从业资格证:",
                type: "input",
                key: "licenseCode",
                style: {
                    width: "20%"
                }
            },
            {
                id: "base_info_org_query",
                label: "",
                text: "查询",
                type: "button",
                key: "search",
                itemType: "primary",
                handler: "search",
            }
        ],
        listHeader: [
            {
                label: "组织名称",
                prop: "entName",
                width: utils.setFontSize(200),
                render(row) {
                    return row.entName || "--";
                }
            },
            {
                label: "车牌号",
                prop: "bindVehicle",
                width: utils.setFontSize(150),
                render(row) {
                    return row.bindVehicle || "--";
                }
            },
            {
                label: "驾驶员姓名",
                prop: "driverName",
                width: utils.setFontSize(100),
                render(row) {
                    return row.driverName || "--";
                }
            },
            {
                label: "性别",
                prop: "contacts",
                render(row) {
                    return row.sex == 2 ? "女" : "男";
                }
            },
            {
                label: "驾驶证号",
                prop: "idcard",
                width: utils.setFontSize(180),
                render(row) {
                    return row.idcard || "--";
                }
            },
            {
                label: "联系电话",
                prop: "contactPhone",
                width: utils.setFontSize(120),
                render(row) {
                    return row.contactPhone || "--";
                }
            },
            {
                label: "从业资格证号",
                prop: "licenseCode",
                width: utils.setFontSize(180),
                render(row) {
                    return row.licenseCode || "--";
                }
            },
            {
                label: "从业资格证类别",
                prop: "licenceCategory",
                width: utils.setFontSize(150),
                render(row) {
                    return row.licenceCategory || "--";
                }
            },
            {
                label: "从业资格证有效期止",
                prop: "certificateValidityExpiration",
                width: utils.setFontSize(200),
                render(row) {
                    return utils.formatDate(row.certificateValidityExpiration, "YYYY-MM-DD hh:mm:ss") || "--";
                }
            },
            {
                label: "发证机关",
                prop: "licenceIssuingAuthority",
                render(row) {
                    return row.licenceIssuingAuthority || "--";
                }
            }, {
                name: "",
                label: "操作",
                prop: "operation",
                width: utils.setFontSize(150),
                option: [{
                    label: "修改",
                    handler: "onEdit",
                    auth: "user_center_base_driver_modify"
                }, {
                    label: "删除",
                    handler: "onDelete",
                    auth: "user_center_base_driver_delete"
                }]
            }
        ]
    },
    orgManage: {
        formInput: [
            {
                label: "组织名称:",
                type: "input",
                key: "entName",
                style: {
                    width: "20%"
                }
            },
            {
                label: "联系人:",
                type: "input",
                key: "contacts",
                style: {
                    width: "20%"
                }
            },
            {
                id: "base_info_org_query",
                label: "",
                text: "查询",
                type: "button",
                key: "search",
                itemType: "primary",
                handler: "search",
            }
        ],
        listHeader: [
            {
                label: "组织名称",
                prop: "entName",
                width: utils.setFontSize(500),
                render(row) {
                    return row.entName || "--";
                }
            },
            {
                label: "联系人",
                prop: "contacts",
                render(row) {
                    return row.contacts || "--";
                }
            },
            {
                label: "联系电话",
                prop: "contactPhone",
                render(row) {
                    return row.contactPhone || "--";
                }
            },
            {
                label: "说明",
                prop: "explains",
                render(row) {
                    return row.explains || "--";
                }
            },
            {
                label: "创建时间",
                prop: "createTime",
                render(row) {
                    return utils.formatDate(row.createTime, "YYYY-MM-DD hh:mm:ss") || "--";
                }
            },
            {
                label: "创建人",
                prop: "createUserName",
                render(row) {
                    return row.createPeople || "--";
                }
            }, {
                label: "操作",
                option: [{
                    label: "修改",
                    handler: "onEdit",
                    auth: "user_center_base_org_modify"
                }, {
                    label: "删除",
                    handler: "onDelete",
                    auth: "user_center_base_org_delete"
                }]
            }
        ],
        addOrgItem: [
            {
                label: "上级组织:",
                type: "saasTree4",
                key: "parentEntName",
                rules: [{
                    required: true,
                    message: "请选择上级组织",
                    trigger: "change"
                }],
                style: {
                    width: "100%",
                }
            }, 
            {
                label: "组织名称:",
                type: "input",
                key: "entName",
                maxLength: 40,
				minLength: 2,
                rules: [
                    {required: true, message: "请输入组织名称", trigger: "blur"},
                    //{validator: utils.validateOrgName, message: "组织名称为汉字、数字、括号组合，数字只能用做结尾", trigger: "blur"}
					{validator: utils.validateOrgName, message: "长度2-40，只能输入中文、英文、数字、括号", trigger: "blur"}
                ],
                style: {
                    width: "100%",
                }
            },
            {
                label: "联系人:",
                type: "input",
                key: "contacts",
                maxLength: 8,
                style: {
                    width: "100%",
                }
                // rules: [{max: 8, message: "最长输入8个字符", trigger: "blur"}]
            },
            {
                label: "联系电话:",
                type: "input",
                key: "contactPhone",
                rules: [{
                    pattern: /^((1[3,5,8][0-9])|(14[4,5,7,9])|(16[5])|(17[0,1,2,3,5,6,7,8])|(19[1,7,8,9]))\d{8}$/,
                    message: "请检查手机号是否正确",
                    trigger: "blur"
                }],
                style: {
                    width: "100%",
                }
            },
            {
                label: "最大车辆数:",
                type: "number",
                //max: 1000,
                min: 0,
                key: "maxVehicleNum",
                className: "max-vehicle-num",
                style: {
                    width: "100%",
                }
            },
            {
                label: "说明:",
                type: "input",
                key: "explains",
                maxLength: 100,
                style: {
                    width: "100%",
                }
                // rules: [{max: 100, message: "最长输入100个字符", trigger: "blur"}]
            }
        ]
    },
    alarmWarnSetting: {
        formInput: [
            {
                label: "报警类型:",
                type: "alarmTree",
                key: "alarmCode",
                readonly: "readonly",
                style: {
                    width: "25%",
                }
            },
            {
                label: "报警类型:",
                type: "alarmTree1",
                key: "alarmCodeCopy",
                readonly: "readonly",
                style: {
                    width: "calc(25% - 40px)",
                    marginRight: "40px",
                    display: "none"
                }
            },
            {
                id: "rules_management_alarm_remind_setting_query",
                label: "",
                text: "查询",
                type: "button",
                key: "search",
                itemType: "primary",
                handler: "search",
            }
        ],
        listHeader: [
            {
                label: "报警类型",
                prop: "alarmName",
                render(row) {
                    return row.alarmName || "--";
                }
            },
            {
                label: "报警弹窗",
                prop: "alarmDialog",
                tooltip: true,
                render(row) {
                    return row.alarmDialog === 1 ? "<i class=\"iconfont iconicon-test-copy\"></i>" : "<i class=\"iconfont iconicon-test\"></i>";
                }
            },
            {
                label: "报警声音",
                prop: "alarmSound",
                tooltip: true,
                render(row) {
                    return row.alarmSound === 1 ? "<i class=\"iconfont iconicon-test-copy\"></i>" : "<i class=\"iconfont iconicon-test\"></i>";
                }
            },
            {
                label: "创建时间",
                prop: "createTime",
                render(row) {
                    return utils.formatDate(row.createTime, "YYYY-MM-DD hh:mm:ss") || "--";
                }
            },
            {
                label: "设置人",
                prop: "loginAccount",
                render(row) {
                    return row.loginAccount || "--";
                }
            }
        ]
    },
    fatigue: {
        formInput: [{
            label: "规则名称:",
            type: "input",
            key: "ruleName",
            style: {
                width: "20%",
            }
        }, {
            label: "规则名称:",
            type: "input",
            key: "ruleNameCopy",
            style: {
                width: "calc(25% - 40px)",
                marginRight: "40px",
                display: "none"
            }
        },
            {
                id: "rules_management_alarm_level_setting_query",
                label: "",
                text: "查询",
                type: "button",
                key: "search",
                itemType: "primary",
                handler: "search",
            }
        ],
        listHeader: [
            {
                label: "规则名称",
                prop: "ruleName",
                width: utils.setFontSize(180),
                render(row) {
                    return row.ruleName || "--";
                }
            },
            {
                label: "持续时长(分)",
                prop: "ruleContent.dayDuration",
                width: utils.setFontSize(140),
                render(row) {
                    return row.ruleContent.dayDuration || "--";
                }
            },
            {
                label: "休息时长(分)",
                prop: "ruleContent.dayRestTime",
                width: utils.setFontSize(140),
                render(row) {
                    return row.ruleContent.dayRestTime ? row.ruleContent.dayRestTime.toString() : "--";
                }
            },
            {
                label: "语音提示类型",
                prop: "ruleContent.tipType",
                minWidth: utils.setFontSize(180),
                render(row) {
                    let tipTypeObject = {
                        "1": "紧急",
                        "2": "显示屏显示",
                        "3": "TTS语音",
                        "4": "广告屏显示"
                    };
                    let types = row.ruleContent.tipType;
                    let arr = types instanceof Array ? [...types] : types.split(",");
                    let typesArr = [];
                    arr.forEach(v => {
                        tipTypeObject[v] && typesArr.push(tipTypeObject[v]);
                    });
                    return typesArr.join(",");
                }
            },
            {
                label: "语音提示间隔(分)",
                prop: "ruleContent.dayRestTime",
                width: utils.setFontSize(150),
                render(row) {
                    return row.ruleContent.tipInterval + "分" || "--";
                }
            },
            {
                label: "语音提示次数(次)",
                prop: "ruleContent.tipNum",
                width: utils.setFontSize(150),
                render(row) {
                    return row.ruleContent.tipNum ? row.ruleContent.tipNum.toString() : "--";
                }
            },
            {
                label: "创建者",
                prop: "createUserName",
                width: utils.setFontSize(140),
                render(row) {
                    return row.createUserName || "--";
                }
            },
            {
                label: "创建时间",
                prop: "createTime",
                width: utils.setFontSize(200),
                render(row) {
                    return utils.formatDate(row.createTime, "YYYY-MM-DD hh:mm:ss");
                }
            }, {
                label: "操作",
                width: utils.setFontSize(140),
                option: [{
                    label: "修改",
                    handler: "onEdit",
                    auth: "user_center_rule_arule_fatigue_modify"
                }, {
                    label: "删除",
                    handler: "onDelete",
                    auth: "user_center_rule_arule_fatigue_delete"
                }]
            }
        ]
    },
    overspeed: {
        formInput: [
            {
                label: "规则名称:",
                type: "input",
                key: "ruleName",
                style: {
                    width: "20%",
                }
            },
            {
                id: "rules_management_alarm_level_setting_query",
                label: "",
                text: "查询",
                type: "button",
                key: "search",
                itemType: "primary",
                handler: "search",
            }
        ],
        listHeader: [
            {
                label: "规则名称",
                prop: "ruleName",
                width: utils.setFontSize(150),
                render(row) {
                    return row.ruleName || "--";
                }
            },
            {
                label: "最高限速(km/h)",
                prop: "ruleContent.maxSpeed",
                width: utils.setFontSize(130),
                render(row) {
                    return row.ruleContent.maxSpeed || "--";
                }
            },
            {
                label: "持续时长(秒)",
                prop: "ruleContent.duration",
                width: utils.setFontSize(120),
                render(row) {
                    return row.ruleContent.duration ? row.ruleContent.duration.toString() : "--";
                }
            },
            {
                label: "开始时间",
                prop: "ruleContent.startTime",
                width: utils.setFontSize(100),
                render(row) {
                    return row.ruleContent.startTime || "--";
                }
            },
            {
                label: "结束时间",
                prop: "ruleContent.endTime",
                width: utils.setFontSize(100),
                render(row) {
                    return row.ruleContent.endTime || "--";
                }
            },
            {
                label: "语音提示类型",
                prop: "ruleContent.tipType",
                minWidth: utils.setFontSize(180),
                render(row) {
                    let tipTypeObject = {
                        "1": "紧急",
                        "2": "显示屏显示",
                        "3": "TTS语音",
                        "4": "广告屏显示"
                    };
                    let types = row.ruleContent.tipType;
                    let arr = types instanceof Array ? [...types] : types.split(",");
                    let typesArr = [];
                    arr.forEach(v => {
                        tipTypeObject[v] && typesArr.push(tipTypeObject[v]);
                    });
                    return typesArr.join(",");
                }
            },
            {
                label: "语音提示间隔(分)",
                prop: "ruleContent.tipInterval",
                width: utils.setFontSize(150),
                render(row) {
                    return row.ruleContent.tipInterval ? row.ruleContent.tipInterval + "分" : "--";
                }
            },
            {
                label: "语音提示次数(次)",
                prop: "ruleContent.tipNum",
                width: utils.setFontSize(150),
                render(row) {
                    return row.ruleContent.tipNum ? row.ruleContent.tipNum.toString() : "--";
                }
            },
            {
                label: "创建者",
                prop: "createUserName",
                width: utils.setFontSize(100),
                render(row) {
                    return row.createUserName || "--";
                }
            },
            {
                label: "创建时间",
                prop: "createTime",
                width: utils.setFontSize(180),
                render(row) {
                    return utils.formatDate(row.createTime, "YYYY-MM-DD hh:mm:ss");
                }
            },
            {
                label: "操作",
                width: utils.setFontSize(130),
                option: [{
                    label: "修改",
                    handler: "onEdit",
                    auth: "user_center_rule_arule_overspeed_modify"
                }, {
                    label: "删除",
                    handler: "onDelete",
                    auth: "user_center_rule_arule_overspeed_delete"
                }]
            }
        ]
    },
    roadlimitspeed: {
        formInput: [
            {
                label: "规则名称:",
                type: "input",
                key: "ruleName",
                style: {
                    width: "20%",
                }
            },
            {
                id: "rules_management_alarm_level_setting_query",
                label: "",
                text: "查询",
                type: "button",
                key: "search",
                itemType: "primary",
                handler: "search",
            }
        ],
        listHeader: [
            {
                label: "规则名称",
                prop: "ruleName",
                width: utils.setFontSize(150),
                render(row) {
                    return row.ruleName || "--";
                }
            },
            {
                label: "高速(km/h)",
                prop: "ruleContent.highSpeedLimit",
                width: utils.setFontSize(180),
                render(row) {
                    return row.ruleContent.highSpeedLimit || "--";
                }
            },
            {
                label: "国道(km/h)",
                prop: "ruleContent.nationalSpeedLimit",
                width: utils.setFontSize(180),
                render(row) {
                    return row.ruleContent.nationalSpeedLimit || "--";
                }
            },
            {
                label: "省道(km/h)",
                prop: "ruleContent.provincialSpeedLimit",
                width: utils.setFontSize(180),
                render(row) {
                    return row.ruleContent.provincialSpeedLimit || "--";
                }
            },
            {
                label: "城市快速(km/h)",
                prop: "ruleContent.citySpeedLimit",
                width: utils.setFontSize(180),
                render(row) {
                    return row.ruleContent.citySpeedLimit || "--";
                }
            },
            {
                label: "县道(km/h)",
                prop: "ruleContent.countySpeedLimit",
                width: utils.setFontSize(180),
                render(row) {
                    return row.ruleContent.countySpeedLimit || "--";
                }
            },
            {
                label: "乡道(km/h)",
                prop: "ruleContent.townshipSpeedLimit",
                width: utils.setFontSize(180),
                render(row) {
                    return row.ruleContent.townshipSpeedLimit || "--";
                }
            },
            {
                label: "其它道路(km/h)",
                prop: "ruleContent.otherRoadSpeedLimit",
                width: utils.setFontSize(180),
                render(row) {
                    return row.ruleContent.otherRoadSpeedLimit || "--";
                }
            },
            {
                label: "语音提示内容",
                prop: "ruleContent.tipText",
                width: utils.setFontSize(150),
                render(row) {
                    return row.ruleContent.tipText || "--";
                }
            },
            {
                label: "语音提示类型",
                prop: "ruleContent.tipType",
                minWidth: utils.setFontSize(180),
                render(row) {
                    let tipTypeObject = {
                        "1": "紧急",
                        "2": "显示屏显示",
                        "3": "TTS语音",
                        "4": "广告屏显示"
                    };
                    let types = row.ruleContent.tipType;
                    let arr = types instanceof Array ? [...types] : types.split(",");
                    let typesArr = [];
                    arr.forEach(v => {
                        tipTypeObject[v] && typesArr.push(tipTypeObject[v]);
                    });
                    return typesArr.join(",");
                }
            },
            {
                label: "语音提示间隔(分)",
                prop: "ruleContent.tipInterval",
                width: utils.setFontSize(150),
                render(row) {
                    return row.ruleContent.tipInterval ? row.ruleContent.tipInterval + "分" : "--";
                }
            },
            {
                label: "语音提示次数(次)",
                prop: "ruleContent.tipNum",
                width: utils.setFontSize(150),
                render(row) {
                    return row.ruleContent.tipNum ? row.ruleContent.tipNum.toString() : "--";
                }
            },
            {
                label: "创建者",
                prop: "createUserName",
                width: utils.setFontSize(100),
                render(row) {
                    return row.createUserName || "--";
                }
            },
            {
                label: "创建时间",
                prop: "createTime",
                width: utils.setFontSize(180),
                render(row) {
                    return utils.formatDate(row.createTime, "YYYY-MM-DD hh:mm:ss");
                }
            },
            {
                label: "操作",
                width: utils.setFontSize(130),
                option: [{
                    label: "修改",
                    handler: "onEdit",
                    auth: "user_center_rule_arule_limitSpeed_modify"
                }, {
                    label: "删除",
                    handler: "onDelete",
                    auth: "user_center_rule_arule_limitSpeed_delete"
                }]
            }
        ]
    },
    areaRule: {
        formInput: [
            {
                label: "规则名称:",
                type: "input",
                key: "ruleName",
                style: {
                    width: "20%"
                }
            },
            {
                id: "rules_management_alarm_level_setting_query",
                label: "",
                text: "查询",
                type: "button",
                key: "search",
                itemType: "primary",
                handler: "search",
            }
        ],
        listHeader: [
            {
                label: "规则名称",
                prop: "ruleName",
                render(row) {
                    return row.ruleName || "--";
                }
            },
            {
                label: "持续时长(分)",
                prop: "ruleContent.duration",
                render(row) {
                    return row.ruleContent.duration ? row.ruleContent.duration.toString() : "--";
                }
            },
            {
                label: "最大速度(km/h)",
                prop: "ruleContent.maxSpeed",
                render(row) {
                    return row.ruleContent.maxSpeed ? row.ruleContent.maxSpeed.toString() : "--";
                }
            },
            {
                label: "创建者",
                prop: "createUserName",
                render(row) {
                    return row.createUserName || "--";
                }
            },
            {
                label: "创建时间",
                prop: "createTime",
                render(row) {
                    return utils.formatDate(row.createTime, "YYYY-MM-DD hh:mm:ss");
                }
            },
            {
                label: "操作",
                option: [{
                    label: "修改",
                    handler: "onEdit",
                    auth: "user_center_rule_arule_area_modify"
                }, {
                    label: "删除",
                    handler: "onDelete",
                    auth: "user_center_rule_arule_area_delete"
                }]
            }
        ]
    },
    regionRule: {
        formInput: [
            {
                label: "规则名称:",
                type: "input",
                key: "ruleName",
                style: {
                    width: "20%",
                }
            }, {
                label: "规则名称:",
                type: "input",
                key: "ruleNameCopy",
                style: {
                    width: "calc(28% - 40px)",
                    marginRight: "40px",
                    display: "none"
                }
            }, {
                id: "rules_management_alarm_level_setting_query",
                label: "",
                text: "查询",
                type: "button",
                key: "search",
                itemType: "primary",
                handler: "search",
            }],
        listHeader: [
            {
                label: "规则名称",
                prop: "ruleName",
                render(row) {
                    return row.ruleName || "--";
                }
            },
            {
                label: "持续时长(分)",
                prop: "ruleContent.duration",
                render(row) {
                    return row.ruleContent.duration ? row.ruleContent.duration.toString() : "--";
                }
            },
            {
                label: "创建者",
                prop: "createUserName",
                render(row) {
                    return row.createUserName || "--";
                }
            },
            {
                label: "创建时间",
                prop: "createTime",
                render(row) {
                    return utils.formatDate(row.createTime, "YYYY-MM-DD hh:mm:ss");
                }
            },
            {
                label: "操作",
                option: [{
                    label: "修改",
                    handler: "onEdit",
                    auth: "user_center_rule_arule_region_modify"
                }, {
                    label: "删除",
                    handler: "onDelete",
                    auth: "user_center_rule_arule_region_delete"
                }]
            }
        ]
    },
    offlineNotice: {
        formInput: [
            {
                label: "规则名称:",
                type: "input",
                key: "ruleName",
                style: {
                    width: "20%"
                }
            },
            {
                id: "rules_management_alarm_level_setting_query",
                label: "",
                text: "查询",
                type: "button",
                key: "search",
                itemType: "primary",
                handler: "search",
            }
        ],
        listHeader: [
            {
                label: "规则名称",
                prop: "ruleName",
                render(row) {
                    return row.ruleName || "--";
                }
            },
            {
                label: "离线时长(分)",
                prop: "ruleContent.duration",
                render(row) {
                    return row.ruleContent.duration ? row.ruleContent.duration.toString() : "--";
                }
            },
            {
                label: "通知对象",
                prop: "ruleContent.notifyObject",
                render(row) {
                    let tipTypeObject = {
                        "1": "车主",
                        "2": "组织联系人"
                    };
                    let types = row.ruleContent.notifyObject;
                    if (!types) {
                        return "--";
                    }
                    let arr = types instanceof Array ? [...types] : types.split(",");
                    let typesArr = [];
                    arr.forEach(v => {
                        tipTypeObject[v] && typesArr.push(tipTypeObject[v]);
                    });
                    return typesArr.join(",");
                }
            },
            {
                label: "通知方式",
                prop: "ruleContent.notifyType",
                render(row) {
                    let notifyTypeObject = {
                        "1": "短信",
                        "2": "电话"
                    };
                    let types = row.ruleContent.notifyType;
                    if (!types) {
                        return "--";
                    }
                    let arr = types instanceof Array ? [...types] : types.split(",");
                    let typesArr = [];
                    arr.forEach(v => {
                        notifyTypeObject[v] && typesArr.push(notifyTypeObject[v]);
                    });
                    return typesArr.join(",");
                }
            },
            {
                label: "创建者",
                prop: "createUserName",
                render(row) {
                    return row.createUserName || "--";
                }
            },
            {
                label: "创建时间",
                prop: "createTime",
                render(row) {
                    return utils.formatDate(row.createTime, "YYYY-MM-DD hh:mm:ss");
                }
            },
            {
                label: "操作",
                width: utils.setFontSize(130),
                option: [{
                    label: "修改",
                    handler: "onEdit",
                    auth: "user_center_rule_arule_offlinenotice_modify"
                }, {
                    label: "删除",
                    handler: "onDelete",
                    auth: "user_center_rule_arule_offlinenotice_delete"
                }]
            }
        ]
    },
    nightNoTraffic: {
        formInput: [
            {
                label: "规则名称:",
                type: "input",
                key: "ruleName",
                style: {
                    width: "20%"
                }
            },
            {
                id: "rules_management_alarm_level_setting_query",
                label: "",
                text: "查询",
                type: "button",
                key: "search",
                itemType: "primary",
                handler: "search",
            }
        ],
        listHeader: [
            {
                label: "规则名称",
                prop: "ruleName",
                width: utils.setFontSize(150),
                render(row) {
                    return row.ruleName || "--";
                }
            },
            {
                label: "开始时间",
                prop: "ruleContent.startTime",
                width: utils.setFontSize(100),
                render(row) {
                    return row.ruleContent.startTime || "--";
                }
            },
            {
                label: "结束时间",
                prop: "ruleContent.endTime",
                width: utils.setFontSize(100),
                render(row) {
                    return row.ruleContent.endTime || "--";
                }
            },
            {
                label: "ACC",
                prop: "ruleContent.accStatus",
                width: utils.setFontSize(120),
                render(row) {
                    let accStatus = {
                        0: "不限制",
                        1: "限制开"
                    };
                    return accStatus[row.ruleContent.accStatus] || "--";
                }
            },
            {
                label: "速度阈值(km/h)",
                prop: "ruleContent.speedThreshold",
                width: utils.setFontSize(150),
                render(row) {
                    return row.ruleContent.speedThreshold || "--";
                }
            },
            {
                label: "持续时长(分)",
                prop: "ruleContent.duration",
                width: utils.setFontSize(130),
                render(row) {
                    return row.ruleContent.duration ? row.ruleContent.duration.toString() : "--";
                }
            },
            {
                label: "语音提示类型",
                prop: "ruleContent.tipType",
                minWidth: utils.setFontSize(180),
                render(row) {
                    let tipTypeObject = {
                        "1": "紧急",
                        "2": "显示屏显示",
                        "3": "TTS语音",
                        "4": "广告屏显示"
                    };
                    let types = row.ruleContent.tipType;
                    let arr = types instanceof Array ? [...types] : types.split(",");
                    let typesArr = [];
                    arr.forEach(v => {
                        tipTypeObject[v] && typesArr.push(tipTypeObject[v]);
                    });
                    return typesArr.join(",");
                }
            },
            {
                label: "语音提示间隔(分)",
                prop: "ruleContent.tipInterval",
                width: utils.setFontSize(150),
                render(row) {
                    return row.ruleContent.tipInterval ? row.ruleContent.tipInterval + "分" : "--";
                }
            },
            {
                label: "语音提示次数(次)",
                prop: "ruleContent.tipNum",
                width: utils.setFontSize(150),
                render(row) {
                    return row.ruleContent.tipNum ? row.ruleContent.tipNum.toString() : "--";
                }
            },
            {
                label: "创建者",
                prop: "createUserName",
                width: utils.setFontSize(100),
                render(row) {
                    return row.createUserName || "--";
                }
            },
            {
                label: "创建时间",
                prop: "createTime",
                width: utils.setFontSize(180),
                render(row) {
                    return utils.formatDate(row.createTime, "YYYY-MM-DD hh:mm:ss");
                }
            },
            {
                label: "操作",
                width: utils.setFontSize(130),
                option: [{
                    label: "修改",
                    handler: "onEdit",
                    auth: "user_center_rule_arule_nightNoTraffic_modify"
                }, {
                    label: "删除",
                    handler: "onDelete",
                    auth: "user_center_rule_arule_nightNoTraffic_delete"
                }]
            }
        ]
    },
    nightSpeedLimit: {
        formInput: [
            {
                label: "规则名称:",
                type: "input",
                key: "ruleName",
                style: {
                    width: "20%"
                }
            },
            {
                id: "rules_management_alarm_level_setting_query",
                label: "",
                text: "查询",
                type: "button",
                key: "search",
                itemType: "primary",
                handler: "search",
            }
        ],
        listHeader: [
            {
                label: "规则名称",
                prop: "ruleName",
                render(row) {
                    return row.ruleName || "--";
                }
            },
            {
                label: "开始时间",
                prop: "ruleContent.startTime",
                render(row) {
                    return row.ruleContent.startTime || "--";
                }
            },
            {
                label: "结束时间",
                prop: "ruleContent.endTime",
                render(row) {
                    return row.ruleContent.endTime || "--";
                }
            },
            {
                label: "限速百分比(%)",
                prop: "ruleContent.speedPercentage",
                render(row) {
                    return row.ruleContent.speedPercentage || "--";
                }
            },
            {
                label: "创建者",
                prop: "createUserName",
                width: utils.setFontSize(100),
                render(row) {
                    return row.createUserName || "--";
                }
            },
            {
                label: "创建时间",
                prop: "createTime",
                width: utils.setFontSize(180),
                render(row) {
                    return utils.formatDate(row.createTime, "YYYY-MM-DD hh:mm:ss");
                }
            },
            {
                label: "操作",
                width: utils.setFontSize(130),
                option: [{
                    label: "修改",
                    handler: "onEdit",
                    auth: "user_center_rule_arule_nightSpeedLimit_modify"
                }, {
                    label: "删除",
                    handler: "onDelete",
                    auth: "user_center_rule_arule_nightSpeedLimit_delete"
                }]
            }
        ]
    },
    routeSet: {
        formInput: [
            {
                label: "规则名称:",
                type: "input",
                key: "ruleName",
                style: {
                    width: "20%"
                }
            },
            {
                id: "rules_management_alarm_level_setting_query",
                label: "",
                text: "查询",
                type: "button",
                key: "search",
                itemType: "primary",
                handler: "search",
            }
        ],
        listHeader: [
            {
                label: "规则名称",
                prop: "ruleName",
                width: utils.setFontSize(150),
                render(row) {
                    return row.ruleName || "--";
                }
            },
            {
                label: "开始时间",
                prop: "ruleContent.startTime",
                width: utils.setFontSize(100),
                render: function (row) {
                    return row.ruleContent.startTime;
                }
            },
            {
                label: "结束时间",
                prop: "ruleContent.endTime",
                width: utils.setFontSize(100),
                render: function (row) {
                    return row.ruleContent.endTime;
                }
            },
            {
                label: "是否路线偏移报警",
                prop: "ruleContent.isLineDeviateAlarm",
                width: utils.setFontSize(150),
                render(row) {
                    return row.ruleContent.isLineDeviateAlarm ? "是" : "否";
                }
            },
            {
                label: "是否路段限速报警",
                prop: "ruleContent.isSectionSpeedLimitAlarm",
                width: utils.setFontSize(150),
                render(row) {
                    return row.ruleContent.isSectionSpeedLimitAlarm ? "是" : "否";
                }
            },
            {
                label: "是否路段滞留过长报警",
                prop: "ruleContent.isSectionStayLongAlarm",
                minWidth: utils.setFontSize(180),
                render(row) {
                    return row.ruleContent.isSectionStayLongAlarm ? "是" : "否";
                }
            },
            {
                label: "是否路段滞留过短报警",
                prop: "ruleContent.isSectionStayShortAlarm",
                width: utils.setFontSize(180),
                render(row) {
                    return row.ruleContent.isSectionStayShortAlarm ? "是" : "否";
                }
            },
            {
                label: "语音提示类型",
                prop: "ruleContent.tipType",
                minWidth: utils.setFontSize(180),
                render(row) {
                    let tipTypeObject = {
                        "1": "紧急",
                        "2": "显示屏显示",
                        "3": "TTS语音",
                        "4": "广告屏显示"
                    };
                    let types = row.ruleContent.tipType;
                    let arr = types instanceof Array ? [...types] : types.split(",");
                    let typesArr = [];
                    arr.forEach(v => {
                        tipTypeObject[v] && typesArr.push(tipTypeObject[v]);
                    });
                    return typesArr.join(",");
                }
            },
            {
                label: "创建者",
                prop: "createUserName",
                width: utils.setFontSize(100),
                render(row) {
                    return row.createUserName || "--";
                }
            },
            {
                label: "创建时间",
                prop: "createTime",
                width: utils.setFontSize(180),
                render(row) {
                    return utils.formatDate(row.createTime, "YYYY-MM-DD hh:mm:ss");
                }
            },
            {
                label: "操作",
                width: utils.setFontSize(130),
                option: [{
                    label: "修改",
                    handler: "onEdit",
                    auth: "user_center_rule_arule_routeSet_modify"
                }, {
                    label: "删除",
                    handler: "onDelete",
                    auth: "user_center_rule_arule_routeSet_delete"
                }]
            }
        ]
    },
    siteSet: {
        formInput: [
            {
                label: "规则名称:",
                type: "input",
                key: "ruleName",
                style: {
                    width: "20%"
                }
            },
            {
                id: "rules_management_alarm_level_setting_query",
                label: "",
                text: "查询",
                type: "button",
                key: "search",
                itemType: "primary",
                handler: "search",
            }
        ],
        listHeader: [
            {
                label: "规则名称",
                prop: "ruleName",
                width: utils.setFontSize(150),
                render(row) {
                    return row.ruleName || "--";
                }
            },
            {
                label: "是否未准时到达报警",
                prop: "ruleContent.isNotArriveAlarm",
                width: utils.setFontSize(180),
                render(row) {
                    return row.ruleContent.isNotArriveAlarm ? "是" : "否";
                }
            },
            {
                label: "是否未准时离开报警",
                prop: "ruleContent.isNotLeaveAlarm",
                width: utils.setFontSize(180),
                render(row) {
                    return row.ruleContent.isNotLeaveAlarm ? "是" : "否";
                }
            },
            {
                label: "语音提示类型",
                prop: "ruleContent.tipType",
                minWidth: utils.setFontSize(180),
                render(row) {
                    let tipTypeObject = {
                        "1": "紧急",
                        "2": "显示屏显示",
                        "3": "TTS语音",
                        "4": "广告屏显示"
                    };
                    let types = row.ruleContent.tipType;
                    let arr = types instanceof Array ? [...types] : types.split(",");
                    let typesArr = [];
                    arr.forEach(v => {
                        tipTypeObject[v] && typesArr.push(tipTypeObject[v]);
                    });
                    return typesArr.join(",");
                }
            },
            {
                label: "未准时到达语音内容",
                prop: "ruleContent.notArriveTipText",
                minWidth: utils.setFontSize(200),
                render(row) {
                    return row.ruleContent.notArriveTipText;
                }
            },
            {
                label: "未准时离开语音内容",
                prop: "ruleContent.notLeaveText",
                width: utils.setFontSize(200),
                render(row) {
                    return row.ruleContent.notLeaveText;
                }
            },
            {
                label: "创建者",
                prop: "createUserName",
                width: utils.setFontSize(100),
                render(row) {
                    return row.createUserName || "--";
                }
            },
            {
                label: "创建时间",
                prop: "createTime",
                width: utils.setFontSize(180),
                render(row) {
                    return utils.formatDate(row.createTime, "YYYY-MM-DD hh:mm:ss");
                }
            },
            {
                label: "操作",
                width: utils.setFontSize(130),
                option: [{
                    label: "修改",
                    handler: "onEdit",
                    auth: "user_center_rule_arule_siteSet_modify"
                }, {
                    label: "删除",
                    handler: "onDelete",
                    auth: "user_center_rule_arule_siteSet_delete"
                }]
            }
        ]
    },
    driverIdentifice: {
        formInput: [
            {
                label: "规则名称:",
                type: "input",
                key: "ruleName",
                style: {
                    width: "20%"
                }
            }, {
                label: "规则名称:",
                type: "input",
                key: "ruleNameCopy",
                style: {
                    width: "calc(25% - 40px)",
                    marginRight: "40px",
                    display: "none"
                }
            },
            {
                id: "user_center_rule_arule_driver_query",
                label: "",
                text: "查询",
                type: "button",
                key: "search",
                itemType: "primary",
                handler: "search",
            }
        ],
        listHeader: [
            {
                label: "规则名称",
                prop: "ruleName",
                width: utils.setFontSize(180),
                render(row) {
                    return row.ruleName || "--";
                }
            },
            {
                label: "识别通过值",
                prop: "ruleContent.identifyVal",
                render(row) {
                    return row.ruleContent.identifyVal || "--";
                }
            },
            {
                label: "拍摄通道",
                prop: "ruleContent.photoChannel",
                render(row) {
                    return ["通道1", "通道2", "通道3", "通道4", "通道5", "通道6", "通道7", "通道8", "通道9"][row.ruleContent.photoChannel - 1] || "--";
                }
            },
            {
                label: "开始时间",
                prop: "ruleContent.startTime",
                render(row) {
                    return row.ruleContent.startTime || "--";
                }
            },
            {
                label: "结束时间",
                prop: "ruleContent.endTime",
                render(row) {
                    return row.ruleContent.endTime || "--";
                }
            },
            {
                label: "是否报警",
                prop: "ruleContent.isAlarm",
                render(row) {
                    return row.ruleContent.isAlarm ? "是" : "否";
                }
            },
            {
                label: "创建者",
                prop: "createUserName",
                width: utils.setFontSize(100),
                render(row) {
                    return row.createUserName || "--";
                }
            },
            {
                label: "创建时间",
                prop: "createTime",
                width: utils.setFontSize(180),
                render(row) {
                    return utils.formatDate(row.createTime, "YYYY-MM-DD hh:mm:ss");
                }
            },
            {
                label: "操作",
                option: [{
                    label: "修改",
                    handler: "onEdit",
                    auth: "user_center_rule_arule_driver_modify"
                }, {
                    label: "删除",
                    handler: "onDelete",
                    auth: "user_center_rule_arule_driver_delete"
                }]
            }
        ]
    },
    alarmHandle: {
        formInput: [
            {
                label: "规则名称:",
                type: "input",
                key: "ruleName",
                style: {
                    width: "20%"
                }
            }, {
                label: "规则名称:",
                type: "input",
                key: "ruleNameCopy",
                style: {
                    width: "calc(25% - 40px)",
                    marginRight: "40px",
                    display: "none"
                }
            },
            {
                id: "rules_management_alarm_level_setting_query",
                label: "",
                text: "查询",
                type: "button",
                key: "search",
                itemType: "primary",
                handler: "search",
            }
        ],
        listHeader: [
            {
                label: "规则名称",
                prop: "ruleName",
                render(row) {
                    return row.ruleName || "--";
                }
            },
            {
                label: "创建者",
                prop: "createUserName",
                render(row) {
                    return row.createUserName || "--";
                }
            },
            {
                label: "创建时间",
                prop: "createTime",
                render(row) {
                    return utils.formatDate(row.createTime, "YYYY-MM-DD hh:mm:ss");
                }
            },
            {
                label: "操作",
                option: [{
                    label: "修改",
                    handler: "onEdit",
                    auth: "user_center_rule_arule_alarmHandle_modify"
                }, {
                    label: "删除",
                    handler: "onDelete",
                    auth: "user_center_rule_arule_alarmHandle_delete"
                }]
            }
        ]
    },
    harvester: {
        formInput: [
            {
                label: "规则名称:",
                type: "input",
                key: "ruleName",
                style: {
                    width: "20%"
                }
            }, {
                label: "规则名称:",
                type: "input",
                key: "ruleNameCopy",
                style: {
                    width: "calc(25% - 40px)",
                    marginRight: "40px",
                    display: "none"
                }
            },
            {
                id: "user_center_rule_arule_harvester_query",
                label: "",
                text: "查询",
                type: "button",
                key: "search",
                itemType: "primary",
                handler: "search",
            }
        ],
        listHeader: [
            {
                label: "规则名称",
                prop: "ruleName",
                width: utils.setFontSize(180),
                render(row) {
                    return row.ruleName || "--";
                }
            },
            {
                label: "车身宽度(米)",
                prop: "ruleContent.vehicleWidth",
                render(row) {
                    return row.ruleContent.vehicleWidth;
                }
            },
            {
                label: "创建者",
                prop: "createUserName",
                width: utils.setFontSize(100),
                render(row) {
                    return row.createUserName || "--";
                }
            },
            {
                label: "创建时间",
                prop: "createTime",
                width: utils.setFontSize(180),
                render(row) {
                    return utils.formatDate(row.createTime, "YYYY-MM-DD hh:mm:ss");
                }
            },
            {
                label: "操作",
                option: [{
                    label: "修改",
                    handler: "onEdit",
                    auth: "user_center_rule_arule_harvester_modify"
                }, {
                    label: "删除",
                    handler: "onDelete",
                    auth: "user_center_rule_arule_harvester_delete"
                }]
            }
        ]        
    },
    userManagement: {
        formInput: [
            {
                label: "登录账号:",
                type: "input",
                key: "loginAccount",
                style: {
                    width: "20%",
                }
            }, {
                label: "用户名称:",
                type: "input",
                key: "userName",
                style: {
                    width: "20%",
                }
            },
            {
                label: "所属角色:",
                type: "select",
                key: "roleId",
                popperClass: "monitor-select search-monitor-select",
                style: {
                    width: "20%",
                },
                options: []
            },
            {
                id: "system_center_user_query",
                label: "",
                text: "查询",
                type: "button",
                key: "search",
                itemType: "primary",
                handler: "search",
            }
        ],
        listHeader: [
            {
                label: "登录账号",
                prop: "loginAccount",
                width: utils.setFontSize(150),
                render(row) {
                    return row.loginAccount || "--";
                }
            },
            {
                label: "用户名称",
                prop: "userName",
                width: utils.setFontSize(150),
                render(row) {
                    return row.userName || "--";
                }
            },
            {
                label: "用户类型",
                prop: "userType",
                width: utils.setFontSize(180),
                render(row) {
					let str = row.userType === "1" ? "超级管理员" : row.userType === "2" ? "普通管理员" : "普通用户";
					if(row.roleTree==2){
						str+='(角色体系)'
					}
                    return str;
                }
            },
            {
                label: "所属角色",
                prop: "roleName",
                width: utils.setFontSize(120),
                render(row) {
                    return row.roleName || "--";
                }
            },
            {
                label: "上级账号",
                prop: "createUserName",
                width: utils.setFontSize(120),
                render(row) {
                    return row.createUserName || "--";
                }
            },
            {
                label: "创建时间",
                prop: "createTime",
                width: utils.setFontSize(180),
                render(row) {
                    return utils.formatDate(row.createTime, "YYYY-MM-DD hh:mm:ss") || "--";
                }
            }, 			
            {
                label: "到期时间",
                prop: "expireDate",
                width: utils.setFontSize(180),
                render(row) {
                    return utils.formatDate(row.expireDate, "YYYY-MM-DD hh:mm:ss") || "--";
                }
            },
            {
                label: "性别",
                prop: "sex",
                render(row) {
                    let sex = "";
                    if (row.sex == 0) {
                        sex = "女";
                    } else if (row.sex == 1) {
                        sex = "男";
                    }
                    return sex;
                }
            },
            {
                label: "联系电话",
                prop: "mobile",
                width: utils.setFontSize(150),
                render(row) {
                    return row.mobile || "--";
                }
            },
            {
                label: "联系邮箱",
                prop: "email",
                width: utils.setFontSize(160),
                render(row) {
                    return row.email || "--";
                }
            },
            {
                label: "联系地址",
                prop: "address",
                width: utils.setFontSize(200),
                render(row) {
                    return row.address || "--";
                }
            },
            {
                label: "描述",
                prop: "organizationDesc",
                width: utils.setFontSize(200),
                render(row) {
                    return row.organizationDesc || "--";
                }
            },
			{
                label: "操作",
                width: utils.setFontSize(200),
                option: [{
                    label: "修改",
                    handler: "onEdit",
                    auth: "user_center_user_account_modify",
					render: function(row){
						return "修改";
					}
                },{
                    label: "删除",
                    handler: "onDelete",
                    auth: "user_center_user_account_delete",
                    render: function (row) {
                        let loginInfo = store.state.loginInfo;
						// 列表里userId=当前代理人ID，自己不能对自己操作“删除”、“重置密码”功能
						// createUserId !=当前代理人ID, 不能操作“删除”， 不允许跨级删除账号
                        if (row.userId != loginInfo.userId && row.createUserId==loginInfo.userId) {
                            return "删除";
                        }
                    }
                }, {
                    label: "重置密码",
                    handler: "onRetPass",
                    auth: "user_center_user_account_reset",
                    render: function (row) {
                        let loginInfo = store.state.loginInfo;
						// 列表里userId=当前代理人ID，不能操作“删除”、“重置密码”功能
                        if (row.userId != loginInfo.userId) {
                            return "重置密码";
                        }
                    }
                }]
            }
        ],
        retPass: [
            {
                label: "新密码:",
                type: "input",
                key: "newPassword",
                showPassword: true,
                style: {
                    width: "70%",
                    marginLeft: "15%"
                },
                rules: [{
                    required: true,
                    message: "请输入登录密码",
                    trigger: "blur"
                },
                    {
                        pattern: /^[!.@#$%^&*0-9a-zA-Z]{6,11}$/,
                        message: "支持6-11位的数字、字母、特殊字符",
                        trigger: "blur"
                    }
                ]
            },
            {
                label: "旧密码:",
                type: "input",
                key: "oldPassword",
                showPassword: true,
                style: {
                    width: "70%",
                    marginLeft: "15%"
                },
                rules: [{
                    required: true,
                    message: "请输入登录密码",
                    trigger: "blur"
                },
                    {
                        pattern: /^[!.@#$%^&*0-9a-zA-Z]{6,11}$/,
                        message: "支持6-11位的数字、字母、特殊字符",
                        trigger: "blur"
                    }
                ]
            }
        ],
        addUser: [
            {
                label: "登录账号:",
                type: "input",
                key: "loginAccount",
                maxLength: 16,
                style: {
                    width: "100%",
                },
                rules: [
                    {required: true, message: "请输入登录账号", trigger: "blur"},
                    // {max: 16, message: "最长输入16个字符", trigger: "blur"},
                    {pattern: /^[A-Za-z0-9]+$/, message: "登录账号由数字、字母组成", trigger: "blur"}
                ]
            },
            {
                label: "登录密码:",
                type: "input",
                key: "password",
                showPassword: true,
                maxLength: 18,
                placeholder: '8-16位包含大小写字母、数字中的两种或以上',
                style: {
                    width: "100%",
                },
                // rules: [{
                //     required: true,
                //     message: "请输入登录密码",
                //     trigger: "blur"
                // },
                //     {
                //         pattern: /^[!.@#$%^&*0-9a-zA-Z]{6,18}$/,
                //         message: "支持6-11位的数字、字母、特殊字符",
                //         trigger: "blur"
                //     }
                // ],
                rules: [{
                    required: true,
                    message: "请输入登录密码",
                    trigger: "blur"
                    },{validator: validatePwd, trigger: "blur"}]                
            },
            {
                label: "用户名称:",
                type: "input",
                key: "userName",
                maxLength: 16,
                style: {
                    width: "100%",
                   },
                rules: [
                    {required: true, message: "请输入用户名称", trigger: "blur"}
                    // {max: 16, message: "最长输入16个字符", trigger: "blur"}
                ]
            },
            {
                label: "权限模式:",
                type: "radio",
                key: "roleTree",
                handler: "roleTree",
				labelDefine:{  //自定义label,
					label: '权限模式',
					icon: 'el-icon-warning',
					style:{
						fontSize: '14px',
						color: '#999',
						cursor: 'pointer'
					},
					popover: true,
					width: 360,
					render: '<ul class="textTips"><li><span>1、</span><p>权限分为两种模式，一是用户模式，二是角色模式。</p></li>'+
						'<li><span>2、</span><p>用户模式是每个用户的权限单独设置，每个用户权限的调整、不会影响其它用户已有的权限。</p></li>'+
						'<li><span>3、</span><p>角色模式是多个用户归属到一个角色，修改角色的权限、对应的用户权限也会修改。</p></li>'+
						'<li><span>4、</span><p>采用用户权限还是角色权限，由系统管理员创建账号时决定。</p></li>'+
					'</ul>'
				},
                group: [
                    {
                        label: 1,
                        name: "用户模式"
                    },
                    {
                        label: 2,
                        name: "角色模式"
                    }
                ],
                style: {
                    width: "100%",
					display: 'block'
                }
            },
            {
                label: "角色级别:",
                type: "select",
                key: "roleLevel",
                handler: "roleLevel",
                popperClass: "monitor-select",
                style: {
                    width: "100%",
                    display: "none"
                },
                options: []
            },
            {
                label: "所属角色:",
                type: "select",
                key: "roleId",
                popperClass: "monitor-select",
                style: {
                    width: "100%",
                },
                options: [],
                handler: "addSelect",
                rules: [{
                    required: true,
                    message: "请选择所属角色",
                    trigger: "change"
                }]
            },
			{
			    label: "所属角色:",
			    type: "saasTree4",
			    key: "roleId",
			    popperClass: "monitor-select",
			    style: {
			        width: "100%",
					display: 'none'					
			    },
			    options: [],
			    rules: [{required: true, message: "请选择所属角色", trigger: "change"}],
			    handler: "addSelectTree"
			},
            {
                label: "性别:",
                type: "radio",
                key: "sex",
                group: [
                    {
                        label: 1,
                        name: "男"
                    },
                    {
                        label: 0,
                        name: "女"
                    }
                ],
                style: {
                    width: "100%",
                },
                rules: [{
                    required: true,
                    message: "请选择性别",
                    trigger: "blur"
                }]
            },
            {
                label: "到期时间:",
                type: "datePicker",
                popperClass: "monitor-date-picker form-data-picker",
                key: "expireDate",
                dateType: "datetime",
                style: {
                    width: "100%",
                },
                pickerOptions: {
                    disabledDate(v) {
                        return v.getTime() < new Date().getTime();
                    }
                },
                rules: [
                    //{type: "date", required: true, message: "请选择到期时间", trigger: "change"}
                ]
            },
            {
                label: "联系电话:",
                type: "input",
                key: "mobile",
                style: {
                    width: "100%",
                },
                rules: [{
                    pattern: /^\d{11}$/,
                    message: "请检查联系电话是否正确",
                    trigger: "blur"
                }]
            },
            {
                label: "联系邮箱:",
                type: "input",
                key: "email",
                maxLength: 50,
                style: {
                    width: "100%",
                },
                rules: [
                    {type: "email", message: "请检查邮箱地址是否正确", trigger: "blur"}
                    // {max: 50, message: "最长输入50个字符", trigger: "blur"}
                ]
            },
            {
                label: "联系地址:",
                type: "input",
                key: "address",
                maxLength: 80,
                style: {
                    width: "100%",
                }
                // rules: [{max: 80, message: "最长输入80个字符", trigger: "blur"}]
            },
            {
                label: "描述:",
                type: "input",
                key: "organizationDesc",
                maxLength: 50,
                style: {
                    width: "100%",
                }
                // rules: [{max: 50, message: "最长输入50个字符", trigger: "blur"}]
            }
        ],
        smsAddItem: [
            {
                label: "短信配置:",
                type: "select",
                key: "smsConfig",
                popperClass: "monitor-select",
                handler: "smsConfig",
                options: [],
                style: {
                    width: "100%"
                }
            },
            {
                label: "分配数量:",
                type: "number",
                key: "count",
                max: 5000,
                min: 1,
                style: {
                    width: "100%"
                }

            }
        ]
    },
    noticeTempManage: {
        formInput: [
            {
                label: "模板名称:",
                type: "input",
                key: "templateName",
                style: {
                    width: "20%"
                }
            }, {
                label: "模板类型:",
                type: "select",
                popperClass: "monitor-select",
                key: "notifyType",
                options: [],
                style: {
                    width: "20%"
                }
            },
            {
                label: "审核状态:",
                type: "select",
                popperClass: "monitor-select",
                key: "auditStatus",
                style: {
                    width: "15%"
                },
                options: [{
                    label: "未通过",
                    value: 0
                }, {
                    label: "待审核",
                    value: 1
                }, {
                    label: "通过",
                    value: 2
                }]
            },
            {
                id: "system_center_user_query",
                label: "",
                text: "查询",
                type: "button",
                key: "search",
                itemType: "primary",
                handler: "search",
            }
        ],
        listHeader: [
            {
                label: "模板名称",
                prop: "templateName",
                // width: utils.setFontSize(150),
                render(row) {
                    return row.templateName || "--";
                }
            },
            {
                label: "模板类型",
                prop: "notifyType",
                // width: utils.setFontSize(150),
                render(row) {
                    let notifyTypeArr = JSON.parse(localStorage.getItem("templateType")),
                        notifyTypeObj = {};
                    notifyTypeArr.forEach(item => {
                        notifyTypeObj[item.value] = item.label;
                    });
                    return notifyTypeObj[row.notifyType] || "--";
                }
            },
            {
                label: "模板内容",
                prop: "templateContent",
                width: utils.setFontSize(300),
                render(row) {
                    return row.templateContent || "--";
                }
            },
            {
                label: "审核状态",
                prop: "auditStatus",
                tooltip: true,
                // width: utils.setFontSize(120),
                render(row) {
                    let auditStatus = {
                        0: "未通过",
                        1: "待审核",
                        2: "通过"
                    };
                    return `<span class="auditColor${row.auditStatus}">${auditStatus[row.auditStatus]}</span>` || "--";
                }
            },
            {
                label: "创建人",
                prop: "createUserName",
                // width: utils.setFontSize(120),
                render(row) {
                    return row.createUserName || "--";
                }
            },
            {
                label: "创建时间",
                prop: "createTime",
                // width: utils.setFontSize(180),
                render(row) {
                    return utils.formatDate(row.createTime, "YYYY-MM-DD hh:mm:ss") || "--";
                }
            }, {
                label: "操作",
                width: utils.setFontSize(200),
                option: [{
                    label: "",
                    handler: "onDetail",
                    auth: "user_center_user_template_update",
                    render: (row) => {
                        let loginInfo = store.state.loginInfo;
                        return row.createUserId != loginInfo.userId || (row.createUserId == loginInfo.userId && row.auditStatus == 2) ? "查看" : "";
                    }
                }, {
                    label: "",
                    handler: "onEdit",
                    auth: "user_center_user_template_update",
                    render: (row) => {
                        let loginInfo = store.state.loginInfo;
                        return row.createUserId == loginInfo.userId && row.auditStatus != 2 ? "修改" : "";
                    }
                }, {
                    label: "",
                    handler: "onDelete",
                    auth: "user_center_user_template_delete",
                    render: (row) => {
                        let loginInfo = store.state.loginInfo;
                        return row.createUserId == loginInfo.userId ? "删除" : "";
                    }
                }, {
                    label: "",
                    handler: "onNotice",
                    auth: "user_center_user_template_pass",
                    render: (row) => {
                        let notice = "";
                        let loginInfo = store.state.loginInfo;
                        if (+loginInfo.userType < 3) {
                            notice = row.auditStatus == 1 ? "通过" : "";
                        }
                        return notice;
                    }
                }, {
                    label: "",
                    handler: "onNoNotice",
                    auth: "user_center_user_template_no_pass",
                    render: (row) => {
                        let notice = "";
                        let loginInfo = store.state.loginInfo;
                        if (+loginInfo.userType < 3) {
                            notice = row.auditStatus == 1 ? "不通过" : "";
                        }
                        return notice;
                    }
                }, {
                    label: "",
                    handler: "onSmsIssued",
                    auth: "user_center_user_template_sms_issued",
                    render: (row) => {
                        return row.auditStatus == 2 ? "短信下发" : "";
                    }
                }]
            }
        ],
        addItem: [
            {
                label: "模板名称:",
                type: "input",
                key: "templateName",
                style: {
                    width: "100%"
                },
                rules: [{
                    required: true,
                    message: "请输入模板名称",
                    trigger: "blur"
                }]
            },
            {
                label: "模板类型:",
                type: "select",
                popperClass: "monitor-select",
                key: "notifyType",
                style: {
                    width: "100%"
                },
                options: [],
                rules: [{
                    required: true,
                    message: "请选择模板类型",
                    trigger: "change"
                }]
            },
            {
                label: "模板内容:",
                type: "textarea",
                key: "templateContent",
                maxLength: 70,
                style: {
                    width: "100%"
                },
                rules: [
                    {required: true, message: "请选择模板内容", trigger: "change"}
                    // {max: 70, message: "最长输入70个字符", trigger: "blur"}
                ]
            }
        ]
    },
    smsConfig: {
        formInput: [
            {
                label: "平台类型:",
                type: "select",
                popperClass: "monitor-select",
                key: "smsPlatformType",
                style: {
                    width: "20%"
                },
                options: [{
                    label: "华为云IOT",
                    value: "1"
                }]
            },
            {
                label: "配置名称:",
                type: "input",
                key: "name",
                style: {
                    width: "20%",
                }
            },
            {
                id: "system_center_user_query",
                label: "",
                text: "查询",
                type: "button",
                key: "search",
                itemType: "primary",
                handler: "search",
            }
        ],
        listHeader: [
            {
                label: "平台类型",
                prop: "smsPlatformType",
                // width: utils.setFontSize(150),
                render(row) {
                    let smsPlatformType = "--";
                    if (row.smsPlatformType == 1) {
                        smsPlatformType = "华为云IOT";
                    }
                    return smsPlatformType;
                }
            },
            {
                label: "配置名称",
                prop: "name"
                // width: utils.setFontSize(150),
            },
            {
                label: "接入账号",
                prop: "account"
                // width: utils.setFontSize(120),
            },
            {
                label: "接入密码",
                prop: "password"
                // width: utils.setFontSize(120),
            },
            {
                label: "短信签名",
                prop: "signature"
                // width: utils.setFontSize(120),
            },
            {
                label: "短信发送地址",
                prop: "url"
                // width: utils.setFontSize(120),
            },
            {
                label: "创建时间",
                prop: "createTime",
                // width: utils.setFontSize(180),
                render(row) {
                    return utils.formatDate(row.createTime, "YYYY-MM-DD hh:mm:ss") || "--";
                }
            }, {
                label: "操作",
                width: utils.setFontSize(200),
                option: [{
                    label: "模板列表",
                    handler: "onTemplate",
                    auth: "user_center_user_smsConfig_template"
                }, {
                    label: "删除",
                    handler: "onDelete",
                    auth: "user_center_user_smsConfig_delete"
                }]
            }
        ],
        addItem: [
            {
                label: "平台类型:",
                type: "select",
                popperClass: "monitor-select",
                key: "smsPlatformType",
                style: {
                    width: "80%",
                    marginLeft: "8%"
                },
                options: [{
                    label: "华为云IOT",
                    value: "1"
                }],
                rules: [{
                    required: true,
                    message: "请选择平台类型",
                    trigger: "change"
                }]
            },
            {
                label: "配置名称:",
                type: "input",
                key: "name",
                style: {
                    width: "80%",
                    marginLeft: "8%"
                },
                rules: [{
                    required: true,
                    message: "请输入配置名称",
                    trigger: "blur"
                }]
            },
            {
                label: "接入账号:",
                type: "input",
                key: "account",
                style: {
                    width: "80%",
                    marginLeft: "8%"
                },
                rules: [{
                    required: true,
                    message: "请输入接入账号",
                    trigger: "blur"
                }]
            },
            {
                label: "接入密码:",
                type: "input",
                key: "password",
                style: {
                    width: "80%",
                    marginLeft: "8%"
                },
                rules: [{
                    required: true,
                    message: "请输入接入密码",
                    trigger: "blur"
                }]
            },
            {
                label: "短信签名:",
                type: "input",
                key: "signature",
                style: {
                    width: "80%",
                    marginLeft: "8%"
                },
                rules: [{
                    required: true,
                    message: "请输入短信签名",
                    trigger: "blur"
                }]
            },
            {
                label: "短信发送地址:",
                type: "input",
                key: "url",
                style: {
                    width: "80%",
                    marginLeft: "8%"
                },
                rules: [{
                    required: true,
                    message: "请输入短信发送地址",
                    trigger: "blur"
                }]
            }
        ],
        listHeader2: [
            {
                label: "模板类型",
                prop: "templateType",
                // width: utils.setFontSize(150),
                render(row) {
                    let templateType = "--";
                    if (row.templateType == "1001") {
                        templateType = "超速提醒";
                    } else if (row.templateType == "1002") {
                        templateType = "超时提醒";
                    } else if (row.templateType == "10001") {
                        templateType = "终端报警处理";
                    }
                    return templateType;
                }
            },
            {
                label: "模板编号",
                prop: "templateNo"
                // width: utils.setFontSize(150),
            },
            {
                label: "模板内容",
                prop: "templateContent"
                // width: utils.setFontSize(150),
            },
            {
                label: "创建时间",
                prop: "createTime",
                // width: utils.setFontSize(180),
                render(row) {
                    return utils.formatDate(row.createTime, "YYYY-MM-DD hh:mm:ss") || "--";
                }
            }, {
                label: "操作",
                width: utils.setFontSize(120),
                option: [{
                    label: "删除",
                    handler: "onDelete",
                    auth: "user_center_user_smsTemplate_delete"
                }]
            }
        ],
        addItem2: [
            {
                label: "模板类型:",
                type: "select",
                popperClass: "monitor-select",
                key: "templateType",
                style: {
                    width: "80%",
                    marginLeft: "8%"
                },
                options: [{
                    label: "超速提醒",
                    value: "1001"
                }, {
                    label: "超时提醒",
                    value: "1002"
                }, {
                    label: "终端报警处理",
                    value: "10001"
                }],
                rules: [{
                    required: true,
                    message: "请选择模板类型",
                    trigger: "change"
                }]
            },
            {
                label: "模板编号:",
                type: "input",
                key: "templateNo",
                style: {
                    width: "80%",
                    marginLeft: "8%"
                },
                rules: [{
                    required: true,
                    message: "请输入模板编号",
                    trigger: "blur"
                }]
            },
            {
                label: "模板内容:",
                type: "textarea",
                key: "templateContent",
                maxLength: 100,
                style: {
                    width: "80%",
                    marginLeft: "8%"
                },
                rules: [
                    {required: true, message: "请选择模板内容", trigger: "change"}
                ]
            }
        ]
    },
    smsServer: {
        formItem: [
            {
                label: "短信配置:",
                type: "select",
                key: "smsConfigId",
                popperClass: "monitor-select",
                handler: "smsConfig",
                clearable: true,
                options: [],
                style: {
                    width: "20%"
                }
            },
            {
                id: "system_center_user_query",
                label: "",
                text: "查询",
                type: "button",
                key: "search",
                itemType: "primary",
                handler: "search",
            }
        ],
        formItem2: [
            {
                label: "组织名称:",
                type: "orgTree",
                key: "entName",
                itemType: "filterItem",
                style: {
                    width: "30%"
                }
            },			
            {
                label: "车牌号码:",
                type: "searchinputv2",
                placeholder: "输入进行查询",
                key: "vid",
                style: {
                    width: "20%"
                }
            },
            {
                label: "发送状态:",
                type: "select",
                key: "sendStatus",
                popperClass: "monitor-select",
                options: [
                    {
                        label: "等待回执",
                        value: "-1"
                    }, {
                        label: "成功",
                        value: "0"
                    }, {
                        label: "失败",
                        value: "1"
                    }
                ],
                style: {
                    width: "25%"
                }
            },
            {
                label: "模板类型:",
                type: "select",
                key: "templateType",
                popperClass: "monitor-select",
                options: [
                    {
                        label: "超速提醒",
                        value: "1001"
                    }, {
                        label: "超时提醒",
                        value: "1002"
                    }, {
                        label: "终端自动处理",
                        value: "10001"
                    }
                ],
                style: {
                    width: "25%"
                }
            },
            {
                label: "发送时间:",
                type: "dateRange",
                popperClass: "monitor-date-picker",
                key: "startEndTime",
                style: {
                    width: "30%"
                },
                handler: "startEndTime"
            },
            {
                id: "system_center_user_query",
                label: "",
                text: "查询",
                type: "button",
                key: "search",
                itemType: "primary",
                handler: "search",
            }
        ],
        listHeader: [
            {
                label: "平台类型",
                prop: "smsPlatformType",
                // width: utils.setFontSize(150),
                render(row) {
                    let smsPlatformType = "--";
                    if (row.smsPlatformType == 1) {
                        smsPlatformType = "华为云IOT";
                    }
                    return smsPlatformType;
                }
            },
            {
                label: "被分配人",
                prop: "userName"
                // width: utils.setFontSize(150),
            },
            {
                label: "分配短信数量",
                prop: "count",
                render(row) {
                    return row.count + "";
                }
                // width: utils.setFontSize(150),
            },
            {
                label: "创建人",
                prop: "createUserName"
                // width: utils.setFontSize(150),
            },
            {
                label: "创建时间",
                prop: "createTime",
                // width: utils.setFontSize(180),
                render(row) {
                    return utils.formatDate(row.createTime, "YYYY-MM-DD hh:mm:ss") || "--";
                }
            }, {
                label: "操作",
                width: utils.setFontSize(200),
                option: [
				{
                    label: "",
                    handler: "onReclaim",
                    auth: "user_center_serve_smsServer_reclaim",
                    render: (row) => {
                        if (!row.reclaimFlag && !row.isHideReclaim) {
                            return "短信回收";
                        }
                    }
                }, 
				{
                    label: "",
                    name: "noAuth",
                    disabled: true,
                    render(row) {
                        if (row.reclaimFlag) {
                            return "已回收";
                        }
                    }
                }]
            }
        ],
        listHeader2: [
            {
                label: "车牌号码",
                prop: "vehicleNo"
            },
            {
                label: "模板类型",
                prop: "templateType",
                render(row) {
                    let templateType = "--";
                    if (row.templateType == "1001") {
                        templateType = "超速提醒";
                    } else if (row.templateType == "1002") {
                        templateType = "超时提醒";
                    } else if (row.templateType == "10001") {
                        templateType = "终端报警处理";
                    }
                    return templateType;
                }
                // width: utils.setFontSize(150),
            },
            {
                label: "平台类型",
                prop: "smsPlatformType",
                // width: utils.setFontSize(150),
                render(row) {
                    let smsPlatformType = "--";
                    if (row.smsPlatformType == 1) {
                        smsPlatformType = "华为云IOT";
                    }
                    return smsPlatformType;
                }
            },
            {
                label: "短信条数",
                prop: "smsCount",
                // width: utils.setFontSize(150),
                render(row) {
                    return row.smsCount + "";
                }
            },
            {
                label: "短信内容",
                prop: "smsContent"
                // width: utils.setFontSize(150)
            },
            {
                label: "发送时间",
                prop: "sendTime",
                // width: utils.setFontSize(150),
                render(row) {
                    return utils.formatDate(row.sendTime, "YYYY-MM-DD hh:mm:ss") || "--";
                }
            },
            {
                label: "短信发送状态",
                prop: "sendStatus",
                render(row) {
                    let sendStatus = "--";
                    if (row.sendStatus == "-1") {
                        sendStatus = "等待回执";
                    } else if (row.sendStatus == "0") {
                        sendStatus = "成功";
                    } else if (row.sendStatus == "1") {
                        sendStatus = "失败";
                    }
                    return sendStatus;
                }
                // width: utils.setFontSize(150),
            },
            {
                label: "回执时间",
                prop: "receiptTime",
                // width: utils.setFontSize(150),
                render(row) {
                    return utils.formatDate(row.receiptTime, "YYYY-MM-DD hh:mm:ss") || "--";
                }
            }
        ]
    },
    feedBackManage: {
        formInput: [
            {
                label: "标题:",
                type: "input",
                key: "title",
                style: {
                    width: "20%"
                }
            }, {
                label: "起止时间:",
                type: "dateRange",
                popperClass: "monitor-date-picker",
                key: "startEndTime",
                style: {
                    width: "28%"
                },
                handler: "startEndTime"
            },
            {
                label: "审核状态:",
                type: "select",
                popperClass: "monitor-select",
                key: "status",
                style: {
                    width: "16%"
                },
                options: [{
                    label: "待回复",
                    value: 1
                }, {
                    label: "已完成",
                    value: 2
                }, {
                    label: "过期失效",
                    value: 3
                }]
            },
            {
                id: "system_center_user_query",
                label: "",
                text: "查询",
                type: "button",
                key: "search",
                itemType: "primary",
                handler: "search",
            }
        ],
        listHeader: [
            {
                label: "标题",
                prop: "title",
                // width: utils.setFontSize(150),
                render(row) {
                    return row.title || "--";
                }
            },
            {
                label: "投诉建议内容",
                prop: "content",
                // width: utils.setFontSize(150),
                render(row) {
                    return row.content || "--";
                }
            },
            {
                label: "审核状态",
                prop: "status",
                tooltip: true,
                // width: utils.setFontSize(120),
                render(row) {
                    let status = {
                        1: "待回复",
                        2: "已完成",
                        3: "过期失效过期失效"
                    };
                    return `<span class="auditColor${row.status}">${status[row.status]}</span>` || "--";
                }
            },
            {
                label: "投诉人",
                prop: "createUserName",
                // width: utils.setFontSize(120),
                render(row) {
                    return row.createUserName || "--";
                }
            },
            {
                label: "创建时间",
                prop: "createTime",
                // width: utils.setFontSize(180),
                render(row) {
                    return utils.formatDate(row.createTime, "YYYY-MM-DD hh:mm:ss") || "--";
                }
            }, {
                label: "操作",
                width: utils.setFontSize(200),
                option: [{
                    label: "",
                    handler: "onReply",
                    auth: "user_center_admin_complain_reply",
                    isType: "feedBack",
                    render: (row) => {
                        return "回复";
                    }
                }]
            }
        ],
        addItem: [{
            text: "投诉内容",
            type: "vertical",
            display: "none",
            style: {
                width: "100%"
            }
        }, {
            label: "标题:",
            type: "input",
            key: "title",
            disabled: true,
            style: {
                width: "100%"
            }
        },
            {
                label: "内容:",
                type: "textarea",
                key: "content",
                disabled: true,
                style: {
                    width: "100%",
                    height: "80px"
                }
            }, {
                text: "客服回复",
                type: "vertical",
                display: "none",
                style: {
                    width: "100%"
                }
            },
            {
                label: "回复内容:",
                type: "textarea",
                key: "answerContent",
                maxLength: 200,
                style: {
                    width: "100%"
                },
                rules: [
                    {required: true, message: "请选择回复内容", trigger: "change"}
                    // {max: 200, message: "最长输入200个字符", trigger: "blur"}
                ]
            }
        ]
    },
    accountManage: {
        formInput: [{
            label: "appKey:",
            type: "input",
            key: "appKey",
            style: {
                width: "18%"
            }
        }, {
            label: "组织名称:",
            type: "input",
            key: "companyName",
            style: {
                width: "18%"
            }
        },
        {
            label: "状态:",
            type: "select",
            popperClass: "monitor-select",
            key: "status",
            style: {
                width: "15%"
            },
            options: [{
                label: "请选择",
                value: ""
            }, {
                label: "停用",
                value: 0
            }, {
                label: "启用",
                value: 1
            }]
        },
        {
            label: "平台用户:",
            type: "input",
            key: "userAccount",
            style: {
                width: "18%"
            }
        },
        {
            id: "system_center_user_query",
            label: "",
            text: "查询",
            type: "button",
            key: "search",
            itemType: "primary",
            handler: "search",
        }],
        listHeader: [{
            label: "组织名称",
            prop: "companyName",
            // width: utils.setFontSize(150),
            render(row) {
                return row.companyName || "--";
            }
        },
            {
                label: "appKey",
                prop: "appKey",
                // width: utils.setFontSize(150),
                render(row) {
                    return row.appKey || "--";
                }
            },
            {
                label: "appSecret",
                prop: "appSecret",
                // width: utils.setFontSize(120),
                render(row) {
                    return row.appSecret || "--";
                }
            },
            {
                label: "绑定平台用户",
                prop: "userAccount",
                // width: utils.setFontSize(120),
                render(row) {
                    return row.userAccount || "--";
                }
            },
            {
                label: "状态",
                prop: "status",
                tooltip: true,
                // width: utils.setFontSize(120),
                render(row) {
                    let status = {
                        0: "停用",
                        1: "启用"
                    };
                    return status[row.status] || "--";
                }
            },
            {
                label: "创建时间",
                prop: "createTime",
                // width: utils.setFontSize(180),
                render(row) {
                    return utils.formatDate(row.createTime, "YYYY-MM-DD hh:mm:ss") || "--";
                }
            },
            {
                label: "到期时间",
                prop: "expireDate",
                // width: utils.setFontSize(180),
                render(row) {
                    return row.expireDate || "--";
                }
            },
            {
                label: "业务描述",
                prop: "remark",
                // width: utils.setFontSize(180),
                render(row) {
                    return row.remark || "--";
                }
            }, {
                label: "操作",
                width: utils.setFontSize(200),
                option: [{
                    label: "修改",
                    handler: "onEdit",
                    auth: "user_center_admin_apiaccount_update"
                }, {
                    label: "删除",
                    handler: "onDelete",
                    auth: "user_center_admin_apiaccount_delete"
                }, {
                    label: "查看",
                    handler: "onDetail",
                    auth: "user_center_admin_apiaccount_detail"
                }, {
                    label: "",
                    handler: "onStatus",
                    auth: "user_center_admin_apiaccount_status",
                    render: (row) => {
                        return row.status == 0 ? "启用" : "停用";
                    }
                }]
            }
        ],
        addItem: [{
            label: "组织名称",
            type: "input",
            key: "companyName",
            style: {
                width: "100%"
            },
            rules: [{
                required: true,
                message: "请输入组织名称",
                trigger: "blur"
            }]
        }, {
            label: "平台用户:",
            type: "select",
            key: "userAccount",
            options: [],
            style: {
                width: "100%"
            },
            rules: [{
                required: true,
                message: "请选择平台用户",
                trigger: "change"
            }]
        }, {
            label: "状态:",
            type: "radio",
            key: "status",
            group: [{
                label: 1,
                name: "启用"
            }, {
                label: 0,
                name: "停用"
            }],
            style: {
                width: "100%"
            }
        },
            {
                label: "到期时间:",
                type: "datePicker",
                popperClass: "monitor-date-picker form-data-picker",
                key: "expireDate",
                dateType: "datetime",
                style: {
                    width: "100%"
                },
                rules: [{
                    required: true,
                    message: "请选择到期时间",
                    trigger: "change"
                }]
            },
            {
                label: "appKey:",
                type: "input",
                key: "appKey",
                style: {
                    width: "100%"
                },
                rules: [{
                    required: true,
                    message: "请输入appKey",
                    trigger: "blur"
                }]
            }, {
                label: "appSerect",
                type: "input",
                key: "appSecret",
                style: {
                    width: "100%"
                },
                rules: [{
                    required: true,
                    message: "请输入appSerect",
                    trigger: "blur"
                }]
            }, {
                label: "Auth",
                type: "input",
                key: "auth",
                style: {
                    width: "100%"
                },
                rules: [{
                    required: true,
                    message: "请输入Auth",
                    trigger: "blur"
                }]
            },
            {
                label: "业务描述:",
                type: "textarea",
                key: "remark",
                maxLength: 200,
                style: {
                    width: "100%"
                },
                rules: [
                    {required: true, message: "请输入业务描述", trigger: "blur"}
                    // {max: 200, message: "最长输入200个字符", trigger: "blur"}
                ]
            }
        ]
    },
    roleManagement: {
        formInput: [
            {
                label: "角色名称:",
                type: "input",
                key: "roleName",
                style: {
                    width: "20%"
                }
            },
            {
                label: "角色名称:",
                type: "input",
                key: "roleNameCopy",
                style: {
                    width: "calc(25% - 20px)",
                    marginRight: "30px",
                    display: "none"
                }
            },
            {
                id: "system_center_user_query",
                label: "",
                text: "查询",
                type: "button",
                key: "search",
                itemType: "primary",
                handler: "search",
            }
        ],
        listHeader: [
            {
                label: "角色名称",
                prop: "roleName",
                width: utils.setFontSize(300),
                render(row) {
                    return row.roleName || "--";
                }
            },
            {
                label: "角色描述",
                prop: "roleDesc",
                className: "role-desc",
                render(row) {
                    return row.roleDesc || "--";
                }
            },
            {
                label: "创建人",
                prop: "createUserAccount",
                render(row) {
                    return row.createUserAccount || "--";
                }
            },
            {
                label: "创建时间",
                prop: "createTime",
                width: utils.setFontSize(200),
                render(row) {
                    return utils.formatDate(row.createTime, "YYYY-MM-DD hh:mm:ss") || "--";
                }
            },
            {
                label: "修改人",
                prop: "updateUserAccount",
                render(row) {
                    return row.updateUserAccount || "--";
                }
            },			
            {
                label: "修改时间",
                prop: "updateTime",
                width: utils.setFontSize(200),
                render(row) {
                    return utils.formatDate(row.updateTime, "YYYY-MM-DD hh:mm:ss") || "--";
                }
            }, {
                label: "操作",
                prop: "",
                option: [{
                    label: "",
                    handler: "onEdit",
                    auth: "user_center_user_role_modify",
                    render(row) {
						let userId = store.state.loginInfo.userId;
                        if (row.canBeModify && userId == row.createBy) {
							// 登录用户和当前行的createBy相同才允许修改
                            return '修改';
                        }
                    }
                },{
                    label: "",
                    handler: "onView",
                    auth: "user_center_user_role_modify",
                    render(row) {
						let userId = store.state.loginInfo.userId;
                        if (row.canBeModify && userId != row.createBy) {
							// 登录用户和当前行的createBy不相同只允许查看
                            return '查看';
                        }
						return '查看';
                    }
                }, {
                    label: "",
                    handler: "onDelete",
                    auth: "user_center_user_role_delete",
                    render(row) {
                        if (row.canBeModify) {
                            return "删除";
                        }
                    }
                }, 
				{
                    label: "",
                    name: "noAuth",
                    disabled: true,
                    render(row) {
                        if (!row.canBeModify) {
                            return "无权限";
                        }
                    }
                }]
            }
        ],
		listHeader2: [
            {
                label: "角色名称",
                prop: "roleName",
                width: utils.setFontSize(300),
                render(row) {
                    return row.roleName || "--";
                }
            },
			{
				label: "角色级别",
				prop: "roleLevel",
				width: utils.setFontSize(120),
				render(row) {
					return roleLevels[row.roleLevel+''] || "--";
				}
			},
            {
                label: "角色描述",
                prop: "roleDesc",
                className: "role-desc",
                render(row) {
                    return row.roleDesc || "--";
                }
            },
            {
                label: "创建人",
                prop: "createUserAccount",
				width: utils.setFontSize(120),
                render(row) {
                    return row.createUserAccount || "--";
                }
            },
            {
                label: "创建时间",
                prop: "createTime",
                width: utils.setFontSize(200),
                render(row) {
                    return utils.formatDate(row.createTime, "YYYY-MM-DD hh:mm:ss") || "--";
                }
            },
            {
                label: "修改人",
                prop: "updateUserAccount",
				width: utils.setFontSize(120),
                render(row) {
                    return row.updateUserAccount || "--";
                }
            },			
            {
                label: "修改时间",
                prop: "updateTime",
                width: utils.setFontSize(200),
                render(row) {
                    return utils.formatDate(row.updateTime, "YYYY-MM-DD hh:mm:ss") || "--";
                }
            }, {
                label: "操作",
                prop: "",
                option: [{
                    label: "",
                    handler: "onEdit",
                    auth: "user_center_user_role_modify",
                    render(row) {
    					let userId = store.state.loginInfo.userId;
                        if (row.canBeModify && userId == row.createBy) {
    						// 登录用户和当前行的createBy相同才允许修改
                            return '修改';
                        }
                    }
                },{
                    label: "",
                    handler: "onView",
                    auth: "user_center_user_role_modify",
                    render(row) {
    					let userId = store.state.loginInfo.userId;
                        if (row.canBeModify && userId != row.createBy) {
    						// 登录用户和当前行的createBy不相同只允许查看
                            return '查看';
                        }
    					return '查看';
                    }
                }, {
                    label: "",
                    handler: "onDelete",
                    auth: "user_center_user_role_delete",
                    render(row) {
                        if (row.canBeModify) {
                            return "删除";
                        }
                    }
                }]
            }
        ]
    
	
	},
    menuConfigManage: {
        listHeader: [
            {
                label: "菜单名称",
                prop: "menuName",
                render(row) {
                    return row.menuName || "--";
                }
            },
            {
                label: "类型",
                prop: "menuType",
                render(row) {
                    let menuType = "--";
                    if (row.menuType == "1") {
                        menuType = "菜单";
                    } else if (row.menuType == "2") {
                        menuType = "按钮";
                    }
                    return menuType;
                }
            },
            {
                label: "菜单标识",
                prop: "id",
                render(row) {
                    return row.id || "--";
                }
            },
            {
                label: "菜单描述",
                prop: "menuDesc",
                render(row) {
                    return row.menuDesc || "--";
                }
            },
            {
                label: "操作",
                option: [{
                    label: "修改",
                    handler: "onEdit",
                    auth: "user_center_admin_menu_update"
                }, {
                    label: "删除",
                    handler: "onDelete",
                    auth: "user_center_admin_menu_delete"
                }]
            }
        ],
        addOrgItem: [
            {
                label: "类型:",
                type: "radio",
                key: "menuType",
                group: [{
                    label: 1,
                    name: "菜单"
                },
                    {
                        label: 2,
                        name: "按钮"
                    }
                ],
                style: {
                    width: "70%",
                    marginLeft: "15%"
                }
            },
            {
                label: "菜单名称:",
                type: "input",
                key: "menuName",
                style: {
                    width: "70%",
                    marginLeft: "15%"
                },
                rules: [{
                    required: true,
                    message: "请输入菜单名称",
                    trigger: "blur"
                }]
            },
            {
                label: "系统名称:",
                type: "select",
                popperClass: "monitor-select",
                placeholder: "请选择系统名称",
                key: "categoryId",
                style: {
                    width: "70%",
                    marginLeft: "15%"
                },
                options: [{
                    label: "大屏首页",
                    value: 1
                }, {
                    label: "监控中心",
                    value: 3
                }, {
                    label: "用户中心",
                    value: 4
                }, {
                    label: "数据中心",
                    value: 2
                }],
                rules: [{
                    required: true,
                    message: "请选择系统名称",
                    trigger: "change"
                }]
            },
            {
                label: "上级菜单:",
                type: "saasTree4",
                key: "parentMenuName",
                placeholder: "请选择上级菜单",
                style: {
                    width: "70%",
                    marginLeft: "15%"
                },
                options: [],
                rules: [{
                    required: true,
                    message: "请选择上级菜单",
                    trigger: "change"
                }]
            },
            {
                label: "菜单标识:",
                type: "input",
                key: "id",
                style: {
                    width: "70%",
                    marginLeft: "15%"
                },
                rules: [{
                    required: true,
                    message: "请输入菜单标识",
                    trigger: "blur"
                }]
            },
            {
                label: "菜单描述:",
                type: "textarea",
                key: "menuDesc",
                maxLength: 100,
                style: {
                    width: "70%",
                    marginLeft: "15%"
                }
            }
        ]
    },
    videoServerManage: {
        formInput: [
            {
                label: "视频服务器名称:",
                type: "input",
                key: "serverName",
                style: {
                    width: "20%"
                }
            }, {
                label: "所属区域:",
                type: "cascader",
                popperClass: "monitor-cascader",
                key: "regions",
                style: {
                    width: "25%"
                },
                options: areaData
            },
            {
                id: "system_center_user_query",
                label: "",
                text: "查询",
                type: "button",
                key: "search",
                itemType: "primary",
                handler: "search",
            }
        ],
        listHeader: [
            {
                label: "视频服务器名称",
                prop: "serverName",
                width: utils.setFontSize(170),
                render(row) {
                    return row.serverName || "--";
                }
            },
            {
                label: "所属区域",
                prop: "regions",
                width: utils.setFontSize(180),
                render(row) {
                    let regions = "";
                    areaData.forEach(province => {
                        if (province.value == row.provinceCode) {
                            regions += " " + province.label;
                            province.children && province.children.forEach(city => {
                                if (city.value == row.cityCode) {
                                    regions += " " + city.label;
                                    city.children && city.children.forEach(county => {
                                        if (county.value == row.countyCode) {
                                            regions += " " + county.label;
                                        }
                                    });
                                }
                            });
                        }
                    });
                    return regions;
                }
            },
            {
                label: "实时视频接入IP",
                prop: "serverIp",
                width: utils.setFontSize(160),
                render(row) {
                    return row.serverIp || "--";
                }
            },
            {
                label: "实时视频接入端口",
                prop: "serverPort",
                width: utils.setFontSize(180),
                render(row) {
                    return row.serverPort ? row.serverPort.toString() : "--";
                }
            },
            {
                label: "实时视频播放地址",
                prop: "playServerIp",
                width: utils.setFontSize(160),
                render(row) {
                    return row.playServerIp || "--";
                }
            },
            {
                label: "实时视频播放端口",
                prop: "playServerPort",
                width: utils.setFontSize(180),
                render(row) {
                    return row.playServerPort ? row.playServerPort.toString() : "--";
                }
            },
            {
                label: "录像回放接入IP",
                prop: "videotapeServerIp",
                width: utils.setFontSize(160),
                render(row) {
                    return row.videotapeServerIp || "--";
                }
            },
            {
                label: "录像回放接入端口",
                prop: "videotapeServerPort",
                width: utils.setFontSize(180),
                render(row) {
                    return row.videotapeServerPort ? row.videotapeServerPort.toString() : "--";
                }
            },
            {
                label: "录像回放播放地址",
                prop: "videotapePlayServerIp",
                width: utils.setFontSize(160),
                render(row) {
                    return row.videotapePlayServerIp || "--";
                }
            },
            {
                label: "录像回放播放端口",
                prop: "createTime",
                width: utils.setFontSize(180),
                render(row) {
                    return row.videotapePlayServerPort ? row.videotapePlayServerPort.toString() : "--";
                }
            }, {
                label: "ftp登录账号",
                prop: "ftpUserName",
                width: utils.setFontSize(150),
                render(row) {
                    return row.ftpUserName || "--";
                }
            },
            // {
            //     label: "ftp密码",
            //     prop: "ftpUserPassword",
            //     width: utils.setFontSize(150),
            //     render(row) {
            //         return row.ftpUserPassword || "--";
            //     }
            // },
            {
                label: "ftp接入IP",
                prop: "ftpServerIp",
                width: utils.setFontSize(150),
                render(row) {
                    return row.ftpServerIp || "--";
                }
            },
            {
                label: "ftp接入端口",
                prop: "ftpServerPort",
                width: utils.setFontSize(150),
                render(row) {
                    return row.ftpServerPort ? row.ftpServerPort.toString() : "--";
                }
            },
            {
                label: "ftp下载IP",
                prop: "ftpFileDownloadIp",
                width: utils.setFontSize(150),
                render(row) {
                    return row.ftpFileDownloadIp || "--";
                }
            },
            {
                label: "ftp下载端口",
                prop: "ftpFileDownloadPort",
                width: utils.setFontSize(150),
                render(row) {
                    return row.ftpFileDownloadPort ? row.ftpFileDownloadPort.toString() : "--";
                }
            },
            {
                label: "对讲接入IP",
                prop: "intercomServerIp",
                width: utils.setFontSize(150),
                render(row) {
                    return row.intercomServerIp || "--";
                }
            },
            {
                label: "对讲接入端口",
                prop: "intercomServerPort",
                width: utils.setFontSize(150),
                render(row) {
                    return row.intercomServerPort ? row.intercomServerPort.toString() : "--";
                }
            },
            {
                label: "对讲播放地址",
                prop: "intercomPlayServerIp",
                width: utils.setFontSize(150),
                render(row) {
                    return row.intercomPlayServerIp || "--";
                }
            },
            {
                label: "对讲播放端口",
                prop: "intercomPlayServerPort",
                width: utils.setFontSize(150),
                render(row) {
                    return row.intercomPlayServerPort ? row.intercomPlayServerPort.toString() : "--";
                }
            },
            {
                label: "广播接入IP",
                prop: "broadcastServerIp",
                width: utils.setFontSize(150),
                render(row) {
                    return row.broadcastServerIp || "--";
                }
            },
            {
                label: "广播接入端口",
                prop: "broadcastServerPort",
                width: utils.setFontSize(150),
                render(row) {
                    return row.broadcastServerPort || "--";
                }
            }, {
                label: "操作",
                prop: "",
                width: utils.setFontSize(150),
                option: [{
                    label: "",
                    handler: "onEdit",
                    auth: "user_center_serve_video_edit",
                    render(row) {
                        if (row.vsId !== "platform_default_video_server") {
                            return "修改";
                        }
                    }
                }, {
                    label: "",
                    handler: "onDelete",
                    auth: "user_center_serve_video_delete",
                    render(row) {
                        if (row.vsId !== "platform_default_video_server" && row.createUserId == store.state.loginInfo.userId) {
                            return "删除";
                        }
                    }
                }, {
                    label: "",
                    handler: "onDetail",
                    auth: "user_center_serve_video_delete",
                    render(row) {
                        if (row.vsId === "platform_default_video_server") {
                            return "查看";
                        }
                    }
                }]
            }
        ],
        addItem: [
            {
                text: "基本信息",
                type: "vertical",
                display: "none",
                style: {
                    width: "100%"
                }
            }, {
                label: "视频服务器名称:",
                type: "input",
                key: "serverName",
                maxLength: 30,
                style: {
                    width: "50%"
                },
                rules: [
                    {required: true, message: "请输入视频服务器名称", trigger: "blur"}
                    // {max: 30, message: "最长输入30个字符", trigger: "blur"}
                ]
            },
            {
                label: "所属区域:",
                type: "cascader",
                popperClass: "monitor-cascader",
                key: "regions",
                style: {
                    width: "50%"
                },
                options: areaData,
                rules: [{
                    required: true,
                    message: "请选择所属区域",
                    trigger: "change"
                }]
            },
            {
                label: "播放协议:",
                type: "select",
                key: "playServerProtocolType",
                style: {
                    width: "50%"
                },
                options: [{
                    value: "https",
                    label: "https"
                }, {
                    value: "http",
                    label: "http"
                }],
                rules: [{
                    required: true,
                    message: "请选择实时视频播放协议",
                    trigger: "blur"
                }]
            },
            {
                text: "实时视频信息",
                type: "vertical",
                display: "none",
                style: {
                    width: "100%"
                }
            },
            {
                label: "实时视频接入IP:",
                type: "input",
                key: "serverIp",
                style: {
                    width: "50%"
                },
                rules: [{
                    required: true,
                    message: "请输入实时视频接入IP",
                    trigger: "blur"
                }, {validator: utils.validateIP, trigger: "blur"}]
            },
            {
                label: "实时视频接入端口:",
                type: "input",
                key: "serverPort",
                maxLength: 5,
                style: {
                    width: "50%"
                },
                rules: [
                    {required: true, message: "请输入实时视频接入端口", trigger: "blur"},
                    {validator: utils.checkNumber, trigger: "blur"}
                ]
            },
            {
                label: "实时视频播放地址:",
                type: "input",
                key: "playServerIp",
                style: {
                    width: "50%"
                },
                rules: [{
                    required: true,
                    message: "请输入实时视频播放地址",
                    trigger: "blur"
                }, {validator: utils.validateUrl, message: "请输入正确的实时视频播放地址", trigger: "blur"}]
            },
            {
                label: "实时视频播放端口:",
                type: "input",
                key: "playServerPort",
                maxLength: 5,
                style: {
                    width: "50%"
                },
                rules: [
                    {required: true, message: "请输入实时视频播放端口", trigger: "blur"},
                    {validator: utils.checkNumber, trigger: "blur"}
                    // {max: 5, message: "最长输入5个字符", trigger: "blur"}
                ]
            },
            // {
            //     label: "对讲接入IP:",
            //     type: "input",
            //     key: "intercomServerIp",
            //     style: {
            //         width: "50%"
            //     },
            // },
            {
                label: "对讲接入端口:",
                type: "input",
                key: "intercomServerPort",
                maxLength: 5,
                style: {
                    width: "50%"
                }
                // rules: [{max: 5, message: "最长输入5个字符", trigger: "blur"}]
            },
            // {
            //     label: "对讲播放IP:",
            //     type: "input",
            //     key: "intercomPlayServerIp",
            //     style: {
            //         width: "50%"
            //     },
            // },
            {
                label: "对讲播放端口:",
                type: "input",
                key: "intercomPlayServerPort",
                maxLength: 5,
                style: {
                    width: "50%"
                }
                // rules: [{max: 5, message: "最长输入5个字符", trigger: "blur"}]
            },
            // {
            //     label: "广播接入IP:",
            //     type: "input",
            //     key: "broadcastServerIp",
            //     style: {
            //         width: "50%"
            //     },
            // },
            {
                label: "广播接入端口:",
                type: "input",
                key: "broadcastServerPort",
                maxLength: 5,
                style: {
                    width: "50%"
                }
                // rules: [{max: 5, message: "最长输入5个字符", trigger: "blur"}]
            },
            {
                text: "录像回放信息",
                type: "vertical",
                display: "none",
                style: {
                    width: "100%"
                }
            },
            {
                label: "录像回放接入IP:",
                type: "input",
                key: "videotapeServerIp",
                style: {
                    width: "50%"
                },
                rules: [{
                    required: true,
                    message: "请输入录像回放接入IP",
                    trigger: "blur"
                }, {validator: utils.validateIP, trigger: "blur"}]
            },
            {
                label: "录像回放接入端口:",
                type: "input",
                key: "videotapeServerPort",
                maxLength: 5,
                style: {
                    width: "50%"
                },
                rules: [
                    {required: true, message: "请输入录像回放接入端口", trigger: "blur"},
                    {validator: utils.checkNumber, trigger: "blur"}
                    // {max: 5, message: "最长输入5个字符", trigger: "blur"}
                ]
            },
            {
                label: "录像回放播放地址:",
                type: "input",
                key: "videotapePlayServerIp",
                style: {
                    width: "50%"
                },
                rules: [{
                    required: true,
                    message: "请输入录像回放播放地址",
                    trigger: "blur"
                }, {validator: utils.validateUrl, message: "请输入正确的录像回放播放地址", trigger: "blur"}]
            },
            {
                label: "录像回放播放端口:",
                type: "input",
                key: "videotapePlayServerPort",
                maxLength: 5,
                style: {
                    width: "50%"
                },
                rules: [
                    {required: true, message: "请输入录像回放播放端口", trigger: "blur"},
                    {validator: utils.checkNumber, trigger: "blur"}
                    // {max: 5, message: "最长输入5个字符", trigger: "blur"}
                ]
            },
            {
                text: "ftp信息",
                type: "vertical",
                display: "none",
                style: {
                    width: "100%"
                }
            },
            {
                label: "ftp登录账号:",
                type: "input",
                key: "ftpUserName",
                style: {
                    width: "50%"
                },
                rules: [{
                    required: true,
                    message: "请输入ftp登录账号",
                    trigger: "blur"
                }]
            },
            {
                label: "ftp密码:",
                type: "input",
                key: "ftpUserPassword",
                showPassword: true,
                style: {
                    width: "50%"
                },
                rules: [{
                    required: true,
                    message: "请输入ftp密码",
                    trigger: "blur"
                }]
            },
            {
                label: "ftp接入IP:",
                type: "input",
                key: "ftpServerIp",
                style: {
                    width: "50%"
                },
                rules: [{
                    required: true,
                    message: "请输入ftp接入IP",
                    trigger: "blur"
                }, {validator: utils.validateIP, trigger: "blur"}]
            },
            {
                label: "ftp接入端口:",
                type: "input",
                key: "ftpServerPort",
                maxLength: 5,
                style: {
                    width: "50%"
                },
                rules: [
                    {required: true, message: "请输入ftp接入端口", trigger: "blur"},
                    {validator: utils.checkNumber, trigger: "blur"}
                    // {max: 5, message: "最长输入5个字符", trigger: "blur"}
                ]
            },
            {
                label: "ftp下载IP:",
                type: "input",
                key: "ftpFileDownloadIp",
                style: {
                    width: "50%"
                },
                rules: [{
                    required: true,
                    message: "请输入ftp下载IP",
                    trigger: "blur"
                }, {validator: utils.validateIP, trigger: "blur"}]
            },
            {
                label: "ftp下载端口:",
                type: "input",
                key: "ftpFileDownloadPort",
                maxLength: 5,
                style: {
                    width: "50%"
                },
                rules: [
                    {required: true, message: "请输入ftp下载端口", trigger: "blur"},
                    {validator: utils.checkNumber, trigger: "blur"}
                    // {max: 5, message: "最长输入5个字符", trigger: "blur"}
                ]
            }
        ]
    },
    accessServerManage: {
        formInput: [
            {
                label: "运营商名称:",
                type: "input",
                key: "name",
                style: {
                    width: "16%"
                }
            }, {
                label: "接入IP:",
                type: "input",
                key: "accessIp",
                style: {
                    width: "16%",
                }
            }, {
                id: "system_center_user_query",
                label: "",
                text: "查询",
                type: "button",
                key: "search",
                itemType: "primary",
                handler: "search",
            }
        ],
        listHeader: [
            {
                label: "运营商名称",
                prop: "name",
                width: utils.setFontSize(150),
                render(row) {
                    return row.name || "--";
                }
            },
            {
                label: "接入IP",
                prop: "accessIp",
                width: utils.setFontSize(150),
                render(row) {
                    return row.accessIp || "--";
                }
            },
            {
                label: "接入码",
                prop: "accessCode",
                width: utils.setFontSize(150),
                render(row) {
                    return row.accessCode ? row.accessCode.toString() : "--";
                }
            },
            {
                label: "接入用户名",
                prop: "accessUser",
                width: utils.setFontSize(150),
                render(row) {
                    return row.accessUser || "--";
                }
            },
            {
                label: "接入密码",
                prop: "accessPwd",
                width: utils.setFontSize(150),
                render(row) {
                    return row.accessPwd || "--";
                }
            },
            {
                prop: "regions",
                label: "所属区域",
                width: utils.setFontSize(150),
                render: row => {
                    let regions = "";
                    areaData.forEach(province => {
                        if (province.value == row.provinceCode) {
                            regions += province.label;
                            province.children && province.children.forEach(city => {
                                if (city.value == row.cityCode) {
                                    regions += city.label;
                                    city.children && city.children.forEach(county => {
                                        if (county.value == row.countyCode) {
                                            regions += county.label;
                                        }
                                    });
                                }
                            });
                        }
                    });
                    return regions;
                }
            }, {
                label: "服务启用状态",
                prop: "status",
                width: utils.setFontSize(120),
                render(row) {
                    return ["停用", "启用"][row.status] || "--";
                }
            }, {
                label: "平台编码",
                prop: "platformCode",
                width: utils.setFontSize(150),
                render(row) {
                    if (row.platformCode && row.platformCode !== 0) {
                        row.platformCode += "";
                    }
                    return row.countyCode ? row.countyCode + row.platformCode : row.cityCode + row.platformCode || "--";
                }
            },
            {
                label: "加密参数M1",
                prop: "encryptionM1",
                width: utils.setFontSize(150),
                render(row) {
                    if (row.encryptionM1 || +row.encryptionM1 === 0) {
                        return row.encryptionM1 + "";
                    } else {
                        return "--";
                    }
                }
            },
            {
                label: "加密参数A1",
                prop: "encryptionA1",
                width: utils.setFontSize(150),
                render(row) {
                    if (row.encryptionA1 || +row.encryptionA1 === 0) {
                        return row.encryptionA1 + "";
                    } else {
                        return "--";
                    }
                }
            },
            {
                label: "加密参数C1",
                prop: "encryptionC1",
                width: utils.setFontSize(150),
                render(row) {
                    if (row.encryptionC1 || +row.encryptionC1 === 0) {
                        return row.encryptionC1 + "";
                    } else {
                        return "--";
                    }
                }
            },
            {
                label: "创建者",
                prop: "createUserName",
                width: utils.setFontSize(150),
                render(row) {
                    return row.createUserName || "--";
                }
            },
            {
                label: "创建时间",
                prop: "createTime",
                width: utils.setFontSize(180),
                render(row) {
                    return utils.formatDate(row.createTime, "YYYY-MM-DD hh:mm");
                }
            },
            {
                label: "备注",
                prop: "note",
                width: utils.setFontSize(150),
                render(row) {
                    return row.note || "--";
                }
            }, {
                label: "操作",
                prop: "",
                width: utils.setFontSize(180),
                option: [{
                    label: "修改",
                    handler: "onEdit",
                    auth: "user_center_serve_access_809_update"
                }, {
                    label: "删除",
                    handler: "onDelete",
                    auth: "user_center_serve_access_809_delete"
                }, {
                    label: "离线通知",
                    handler: "onLine",
                    auth: "user_center_serve_access_809_notice"
                }]
            }
        ],
        userListHeader: [
            {
                label: "登录名",
                prop: "loginAccount",
                render(row) {
                    return row.loginAccount || "--";
                }
            }, {
                label: "用户名",
                prop: "userName",
                render(row) {
                    return row.userName || "--";
                }
            }
        ],
        addItem: [
            {
                label: "运营商名称:",
                type: "input",
                key: "name",
                maxLength: 64,
                style: {
                    width: "50%"
                },
                rules: [
                    {required: true, message: "请输入名称", trigger: "blur"}
                ]
            }, {
                label: "接入IP:",
                type: "input",
                key: "accessIp",
                style: {
                    width: "50%"
                },
                rules: [{
                    required: true,
                    message: "请输入接入IP",
                    trigger: "blur"
                }, {validator: utils.validateIP, trigger: "blur"}]
            }, {
                label: "接入码:",
                type: "input",
                key: "accessCode",
                maxLength: 10,
                style: {
                    width: "50%"
                },
                rules: [
                    {required: true, message: "请输入接入码", trigger: "blur"},
                    {validator: utils.checkNumber, trigger: "blur", total: 10},
                    {validator: utils.numberScope, trigger: "blur"}
                    // {max: 10, message: "最长输入10个字符", trigger: "blur"}
                ]
            }, {
                label: "接入用户名:",
                type: "input",
                key: "accessUser",
                style: {
                    width: "50%"
                },
                rules: [
                    {required: true, message: "请输入接入用户名", trigger: "blur"},
                    {validator: utils.checkNumber, trigger: "blur"},
                    {validator: utils.numberScope, trigger: "blur"}
                ]
            }, {
                label: "接入密码:",
                type: "input",
                key: "accessPwd",
                maxLength: 8,
                style: {
                    width: "50%"
                },
                rules: [
                    {required: true, message: "请输入接入密码", trigger: "blur"},
                    {validator: utils.checkPwd, trigger: "blur"}
                    // {max: 8, message: "最长输入8个字符", trigger: "blur"}
                ]
            }, {
                label: "所属区域:",
                type: "cascader",
                popperClass: "monitor-cascader",
                key: "regions",
                style: {
                    width: "50%"
                },
                options: areaData,
                rules: [{
                    required: true,
                    message: "请选择所属区域",
                    trigger: "change"
                }]
            }, {
                label: "所属组织:",
                type: "saasTree4",
                key: "entId",
                itemType: "addModelItem",
                disabled: true,
                style: {
                    width: "50%"
                },
                options: [],
                rules: [{required: false, message: "请选择所属组织", trigger: "change"}]
            }, {
                label: "组织启用状态:",
                type: "radio",
                key: "entEnableState",
                handler: "entEnableState",
                group: [{
                    label: 1,
                    name: "启用"
                }, {
                    label: 0,
                    name: "停用"
                }],
                style: {
                    width: "50%"
                }
            }, {
                label: "平台编码:",
                type: "input",
                key: "platformCode",
                maxLength: 5,
                style: {
                    width: "50%"
                },
                rules: [
                    {required: true, message: "请输入平台编码", trigger: "blur"},
                    {validator: utils.checkNumber, trigger: "blur"}
                    // {max: 5, message: "最长输入5个字符", trigger: "blur"}
                ]
            }, {
                label: "加密参数M1:",
                type: "input",
                key: "encryptionM1",
                style: {
                    width: "50%"
                },
                rules: [
                    {validator: utils.checkNumber, trigger: "blur"},
                    {validator: utils.numberScope, trigger: "blur"}
                ]
            }, {
                label: "加密参数A1:",
                type: "input",
                key: "encryptionA1",
                style: {
                    width: "50%"
                },
                rules: [
                    {validator: utils.checkNumber, trigger: "blur"},
                    {validator: utils.numberScope, trigger: "blur"}
                ]
            }, {
                label: "加密参数C1:",
                type: "input",
                key: "encryptionC1",
                style: {
                    width: "50%"
                },
                rules: [
                    {validator: utils.checkNumber, trigger: "blur"},
                    {validator: utils.numberScope, trigger: "blur"}
                ]
            }, {
                label: "809接入协议:",
                type: "radio",
                key: "version",
                group: [{
                    label: 2011,
                    name: "2011版"
                },
                    {
                        label: 2019,
                        name: "2019版"
                    }
                ],
                style: {
                    width: "50%"
                }
            }, {
                label: "服务启用状态:",
                type: "radio",
                key: "status",
                group: [{
                    label: 1,
                    name: "启用"
                }, {
                    label: 0,
                    name: "停用"
                }],
                style: {
                    width: "50%"
                }
            }, {
                label: "备注:",
                type: "textarea",
                key: "note",
                maxLength: 100,
                style: {
                    width: "100%"
                }
            }]
    },
    forwardServerManage: {
        formInput: [
            {
                label: "名称:",
                type: "input",
                key: "name",
                style: {
                    width: "25%"
                }
            }, {
                label: "主链路用户名:",
                type: "input",
                key: "mainLinkUser",
                style: {
                    width: "25%"
                }
            }, {
                label: "接入码:",
                type: "input",
                key: "accessCode",
                style: {
                    width: "25%"
                }
            }, {
                label: "启用状态:",
                type: "select",
                key: "enableState",
                options: [{
                    label: "停用",
                    value: 0
                }, {
                    label: "启用",
                    value: 1
                }],
                style: {
                    width: "25%"
                }
            }, {
                label: "主链路IP:",
                type: "input",
                key: "mainLinkIp",
                style: {
                    width: "25%"
                }
            }, {
                id: "system_center_user_query",
                label: "",
                text: "查询",
                type: "button",
                key: "search",
                itemType: "primary",
                handler: "search",
            }
        ],
        listHeader: [
            {
                label: "名称",
                prop: "name",
                width: utils.setFontSize(150),
                render(row) {
                    return row.name || "--";
                }
            }, {
                label: "转发服务ID",
                prop: "serviceId",
                width: utils.setFontSize(150),
                render(row) {
                    let service809Arr = JSON.parse(localStorage.getItem("service809Arr")),
                        service809Obj = {};
                    service809Arr.forEach(item => {
                        service809Obj[item.value] = item.label;
                    });
                    return service809Obj[row.serviceId] || "--";
                }
            },
            {
                label: "接入码",
                prop: "accessCode",
                width: utils.setFontSize(150),
                render(row) {
                    return row.accessCode || "--";
                }
            },
            {
                label: "主链路用户名",
                prop: "mainLinkUser",
                width: utils.setFontSize(150),
                render(row) {
                    return row.mainLinkUser || "--";
                }
            },
            {
                label: "主链路密码",
                prop: "mainLinkPwd",
                width: utils.setFontSize(150),
                render(row) {
                    return row.mainLinkPwd || "--";
                }
            },
            {
                label: "主链路IP",
                prop: "mainLinkIp",
                width: utils.setFontSize(150),
                render(row) {
                    return row.mainLinkIp || "--";
                }
            },
            {
                label: "主链路端口",
                prop: "mainLinkPort",
                width: utils.setFontSize(150),
                render(row) {
                    return row.mainLinkPort ? row.mainLinkPort.toString() : "--";
                }
            },
            {
                label: "从链路IP",
                prop: "slaveLinkIp",
                width: utils.setFontSize(150),
                render(row) {
                    return row.slaveLinkIp || "--";
                }
            },
            {
                label: "从链路端口",
                prop: "slaveLinkPort",
                width: utils.setFontSize(150),
                render(row) {
                    return row.slaveLinkPort ? row.slaveLinkPort.toString() : "--";
                }
            }, {
                label: "加密参数M1",
                prop: "encryptionM1",
                width: utils.setFontSize(150),
                render(row) {
                    return row.encryptionM1 || "--";
                }
            },
            {
                label: "加密参数A1",
                prop: "encryptionA1",
                width: utils.setFontSize(150),
                render(row) {
                    return row.encryptionA1 || "--";
                }
            },
            {
                label: "加密参数C1",
                prop: "encryptionC1",
                width: utils.setFontSize(150),
                render(row) {
                    return row.encryptionC1 || "--";
                }
            },
            {
                label: "平台编码",
                prop: "platformCode",
                width: utils.setFontSize(150),
                render(row) {
                    return row.platformCode || "--";
                }
            },
            {
                label: "状态",
                prop: "enableState",
                width: utils.setFontSize(150),
                render(row) {
                    return row.enableState == 0 ? "停用" : "启用";
                }
            },
            {
                label: "备注",
                prop: "remark",
                width: utils.setFontSize(150),
                maxLength: 100,
                render(row) {
                    return row.remark || "--";
                }
            }, {
                label: "操作",
                prop: "",
                width: utils.setFontSize(180),
                option: [{
                    label: "修改",
                    handler: "onEdit",
                    auth: "user_center_serve_forward_809update"
                }, {
                    label: "删除",
                    handler: "onDelete",
                    auth: "user_center_serve_forward_809delete"
                }, {
                    label: "查岗通知",
                    handler: "onLine",
                    auth: "user_center_serve_forward_809notice"
                }]
            }
        ],
        addItem: [
            {
                label: "名称:",
                type: "input",
                key: "name",
                maxLength: 64,
                style: {
                    width: "50%"
                },
                rules: [{
                    required: true,
                    message: "请输入名称",
                    trigger: "blur"
                }]
            },
            {
                label: "转发服务ID",
                type: "select",
                popperClass: "monitor-select",
                key: "serviceId",
                style: {
                    width: "50%"
                },
                options: [],
                handler: "addSelect",
                rules: [{
                    required: true,
                    message: "请选择转发服务ID",
                    trigger: "change"
                }]
            },
            {
                label: "主链路IP:",
                type: "input",
                key: "mainLinkIp",
                style: {
                    width: "50%"
                },
                rules: [{
                    required: true,
                    message: "请输入主链路IP",
                    trigger: "blur"
                }, {validator: utils.validateIP, trigger: "blur"}]
            },
            {
                label: "主链路端口:",
                type: "input",
                key: "mainLinkPort",
                maxLength: 5,
                style: {
                    width: "50%"
                },
                rules: [
                    {required: true, message: "请输入主链路端口", trigger: "blur"},
                    {validator: utils.checkNumber, trigger: "blur"}
                    // {max: 5, message: "最长输入5个字符", trigger: "blur"}
                ]
            },
            {
                label: "主链路用户名:",
                type: "input",
                key: "mainLinkUser",
                maxLength: 10,
                style: {
                    width: "50%"
                },
                rules: [
                    {required: true, message: "请输入主链路用户名", trigger: "blur"},
                    {validator: utils.checkNumber, trigger: "blur"},
                    {validator: utils.numberScope, trigger: "blur"}
                    // {max: 10, message: "最长输入10个字符", trigger: "blur"}
                ]
            },
            {
                label: "主链路密码:",
                type: "input",
                key: "mainLinkPwd",
                maxLength: 8,
                style: {
                    width: "50%"
                },
                rules: [
                    {required: true, message: "请输入主链路密码", trigger: "blur"},
                    {validator: utils.checkPwd, trigger: "blur"}
                    // {max: 8, message: "最长输入8个字符", trigger: "blur"}
                ]
            },
            {
                label: "从链路IP:",
                type: "input",
                key: "slaveLinkIp",
                style: {
                    width: "50%"
                },
                rules: [{
                    required: true,
                    message: "请输入从链路IP",
                    trigger: "blur"
                }, {validator: utils.validateIP, trigger: "blur"}]
            },
            {
                label: "从链路端口:",
                type: "input",
                key: "slaveLinkPort",
                maxLength: 5,
                style: {
                    width: "50%"
                },
                rules: [
                    {required: true, message: "请输入从链路端口", trigger: "blur"},
                    {validator: utils.checkNumber, trigger: "blur"}
                    // {max: 5, message: "最长输入5个字符", trigger: "blur"}
                ]
            },
            {
                label: "接入码:",
                type: "input",
                key: "accessCode",
                maxLength: 10,
                style: {
                    width: "50%"
                },
                rules: [
                    {required: true, message: "请输入接入码", trigger: "blur"},
                    {validator: utils.checkNumber, trigger: "blur"}
                    // {max: 10, message: "最长输入10个字符", trigger: "blur"}
                ]
            },
            {
                label: "平台唯一编码:",
                type: "input",
                key: "platformCode",
                maxLength: 11,
                style: {
                    width: "50%"
                },
                rules: [
                    {validator: utils.checkNumber, trigger: "blur"}
                    // {max: 11, message: "最长输入11个字符", trigger: "blur"}
                ]
            },
            {
                label: "状态:",
                type: "radio",
                key: "enableState",
                group: [{
                    label: 1,
                    name: "启用"
                },
                    {
                        label: 0,
                        name: "停用"
                    }
                ],
                style: {
                    width: "50%"
                }
            },
            {
                label: "加密参数M1:",
                type: "input",
                key: "encryptionM1",
                maxLength: 10,
                style: {
                    width: "50%"
                },
                rules: [
                    {validator: utils.checkNumber, trigger: "blur"},
                    {validator: utils.numberScope, trigger: "blur"}
                    // {max: 10, message: "最长输入10个字符", trigger: "blur"}
                ]
            },
            {
                label: "加密参数A1:",
                type: "input",
                key: "encryptionA1",
                maxLength: 10,
                style: {
                    width: "50%"
                },
                rules: [
                    {validator: utils.checkNumber, trigger: "blur"},
                    {validator: utils.numberScope, trigger: "blur"}
                    // {max: 10, message: "最长输入10个字符", trigger: "blur"}
                ]
            },
            {
                label: "加密参数C1:",
                type: "input",
                key: "encryptionC1",
                maxLength: 10,
                style: {
                    width: "50%"
                },
                rules: [
                    {validator: utils.checkNumber, trigger: "blur"},
                    {validator: utils.numberScope, trigger: "blur"}
                    // {max: 10, message: "最长输入10个字符", trigger: "blur"}
                ]
            },
            {
                label: "备注:",
                type: "textarea",
                key: "remark",
                maxLength: 100,
                style: {
                    width: "100%"
                }
                // rules: [{max: 100, message: "最长输入100个字符", trigger: "blur"}]
            }
        ]
    },
    forward808ServerManage: {
        formInput: [
            {
                label: "企业名称:",
                type: "input",
                key: "thirdEntName",
                style: {
                    width: "20%"
                }
            }, {
                label: "企业名称:",
                type: "input",
                key: "thirdEntNameCopy",
                style: {
                    width: "calc(30% - 40px)",
                    marginRight: "40px",
                    display: "none"
                }
            },
            {
                id: "system_center_user_query",
                label: "",
                text: "查询",
                type: "button",
                key: "search",
                itemType: "primary",
                handler: "search",
            }
        ],
        listHeader: [
            {
                label: "企业名称",
                prop: "thirdEntName",
                width: utils.setFontSize(150),
                render(row) {
                    return row.thirdEntName || "--";
                }
            }, {
                label: "主机IP",
                prop: "masterIp",
                width: utils.setFontSize(130),
                render(row) {
                    return row.masterIp || "--";
                }
            },
            {
                label: "主机TCP端口",
                prop: "masterTcpPort",
                width: utils.setFontSize(120),
                render(row) {
                    return row.masterTcpPort || "--";
                }
            },
            {
                label: "主机UDP端口",
                prop: "masterUdpPort",
                width: utils.setFontSize(120),
                render(row) {
                    return row.masterUdpPort || "--";
                }
            },
            {
                label: "备机IP",
                prop: "slaveIp",
                width: utils.setFontSize(130),
                render(row) {
                    return row.slaveIp || "--";
                }
            },
            {
                label: "备机TCP端口",
                prop: "slaveTcpPort",
                width: utils.setFontSize(120),
                render(row) {
                    return row.slaveTcpPort || "--";
                }
            },
            {
                label: "备机UDP端口",
                prop: "slaveUdpPort",
                width: utils.setFontSize(120),
                render(row) {
                    return row.slaveUdpPort || "--";
                }
            },
            {
                label: "创建人",
                prop: "createUserName",
                width: utils.setFontSize(120),
                render(row) {
                    return row.createUserName || "--";
                }
            },
            {
                label: "创建时间",
                prop: "createTime",
                width: utils.setFontSize(180),
                render(row) {
                    return utils.formatDate(row.createTime, "YYYY-MM-DD hh:mm:ss") || "--";
                }
            },
            {
                label: "修改人",
                prop: "updateUserName",
                width: utils.setFontSize(120),
                render(row) {
                    return row.updateUserName || "--";
                }
            },
            {
                label: "修改时间",
                prop: "updateTime",
                width: utils.setFontSize(180),
                render(row) {
                    return utils.formatDate(row.updateTime, "YYYY-MM-DD hh:mm:ss") || "--";
                }
            }, {
                label: "启用状态",
                prop: "enableState",
                width: utils.setFontSize(100),
                render(row) {
                    return row.enableState == 0 ? "停用" : "启用";
                }
            }, {
                label: "操作",
                prop: "",
                width: utils.setFontSize(150),
                option: [{
                    label: "修改",
                    handler: "onEdit",
                    auth: "user_center_serve_forward_808update"
                }, {
                    label: "删除",
                    handler: "onDelete",
                    auth: "user_center_serve_forward_808delete"
                }]
            }
        ],
        addItem: [{
            label: "企业名称:",
            type: "input",
            key: "thirdEntName",
            maxLength: 32,
            style: {
                width: "50%"
            },
            rules: [
                {required: true, message: "请输入企业名称", trigger: "blur"}
            ]
        },
            {
                label: "主机IP:",
                type: "input",
                key: "masterIp",
                style: {
                    width: "50%"
                },
                rules: [{
                    required: true,
                    message: "请选择主机IP",
                    trigger: "blur"
                }, {validator: utils.validateIP, trigger: "blur"}]
            },
            {
                label: "主机TCP端口:",
                type: "input",
                key: "masterTcpPort",
                maxLength: 5,
                style: {
                    width: "50%"
                },
                rules: [{
                    required: true,
                    message: "请输入主机TCP端口",
                    trigger: "blur"
                }, {validator: utils.checkNumber, trigger: "blur"}]
            },
            {
                label: "主机UDP端口:",
                type: "input",
                key: "masterUdpPort",
                maxLength: 5,
                style: {
                    width: "50%"
                },
                rules: [{
                    required: true,
                    message: "请输入主机UDP端口",
                    trigger: "blur"
                }, {validator: utils.checkNumber, trigger: "blur"}]
            },
            {
                label: "备机IP:",
                type: "input",
                key: "slaveIp",
                style: {
                    width: "50%"
                },
                handler: "slaveIp",
                rules: [{validator: utils.validateIP, trigger: "blur"}]
            },
            {
                label: "备机TCP端口:",
                type: "input",
                key: "slaveTcpPort",
                maxLength: 5,
                style: {
                    width: "50%"
                },
                handler: "slaveTcpPort",
                rules: [{validator: utils.checkNumber, trigger: "blur"}]
            },
            {
                label: "备机UDP端口:",
                type: "input",
                key: "slaveUdpPort",
                maxLength: 5,
                style: {
                    width: "50%"
                },
                handler: "slaveUdpPort",
                rules: [{validator: utils.checkNumber, trigger: "blur"}]
            },
            {
                label: "状态:",
                type: "radio",
                key: "enableState",
                group: [
                    {
                        label: 1,
                        name: "启用"
                    },
                    {
                        label: 0,
                        name: "停用"
                    }
                ],
                style: {
                    width: "50%"
                }
            }
        ]
    },
    forward808New: {
        formInput: [
            {
                label: "企业名称:",
                type: "input",
                key: "name",
                className: "third-ent-name",
                style: {
                    width: "20%"
                }
            }, {
                label: "注册鉴权状态:",
                type: "select",
                popperClass: "monitor-select",
                key: "authed",
                style: {
                    width: "18%"
                },
                options: [{
                    label: "是",
                    value: 1
                }, {
                    label: "否",
                    value: 0
                }]
            }, {
                label: "启用状态:",
                type: "select",
                popperClass: "monitor-select",
                key: "enableState",
                style: {
                    width: "18%"
                },
                options: [{
                    value: 0,
                    label: "停用"
                },
                    {
                        value: 1,
                        label: "启用"
                    }
                ]
            }, {
                id: "system_center_user_query",
                label: "",
                text: "查询",
                type: "button",
                key: "search",
                itemType: "primary",
                handler: "search",
            }
        ],
        listHeader: [
            {
                label: "企业名称",
                prop: "name",
                width: utils.setFontSize(150),
                render(row) {
                    return row.name || "--";
                }
            }, {
                label: "企业IP地址",
                prop: "ip",
                width: utils.setFontSize(180),
                render(row) {
                    return row.ip || "--";
                }
            }, {
                label: "企业TCP端口",
                prop: "port",
                width: utils.setFontSize(180),
                render(row) {
                    return row.port || "--";
                }
            },
            {
                label: "注册鉴权状态",
                prop: "authed",
                width: utils.setFontSize(130),
                render(row) {
                    let authed = "--";
                    if (row.authed == 1) {
                        authed = "是";
                    } else if (row.authed == 0) {
                        authed = "否";
                    }
                    return authed;
                }
            },
            {
                label: "启用状态",
                prop: "enableState",
                width: utils.setFontSize(120),
                render(row) {
                    let enableState = "--";
                    if (row.enableState == 1) {
                        enableState = "启用";
                    } else if (row.enableState == 0) {
                        enableState = "停用";
                    }
                    return enableState;
                }
            },
            {
                label: "备注",
                prop: "remark",
                width: utils.setFontSize(120),
                maxLength: 100,
                render(row) {
                    return row.remark || "--";
                }
            },
            {
                label: "创建人",
                prop: "createUserName",
                width: utils.setFontSize(120),
                render(row) {
                    return row.createUserName || "--";
                }
            },
            {
                label: "创建时间",
                prop: "createTime",
                width: utils.setFontSize(180),
                render(row) {
                    return utils.formatDate(row.createTime, "YYYY-MM-DD hh:mm:ss") || "--";
                }
            },
            {
                label: "修改人",
                prop: "updateUserName",
                width: utils.setFontSize(120),
                render(row) {
                    return row.updateUserName || "--";
                }
            }, 
            {
                label: "修改时间",
                prop: "updateTime",
                width: utils.setFontSize(180),
                render(row) {
                    return utils.formatDate(row.updateTime, "YYYY-MM-DD hh:mm:ss") || "--";
                }
            }, {
                label: "操作",
                prop: "",
                width: utils.setFontSize(150),
                option: [{
                    label: "修改",
                    handler: "onEdit",
                    auth: "user_center_serve_forward_zf808-update"
                }, {
                    label: "删除",
                    handler: "onDelete",
                    auth: "user_center_serve_forward_zf808-delete"
                }]
            }
        ],
        addItem: [{
            label: "企业名称:",
            type: "input",
            key: "name",
            maxLength: 32,
            style: {
                width: "50%"
            },
            ruls: [
                {required: true, message: "请输入企业名称", trigger: "change"}
            ]
        },
            {
                label: "服务标识:",
                type: "select",
                popperClass: "monitor-select",
                key: "serviceId",
                style: {
                    width: "50%"
                },
                options: [],
                rules: [{
                    required: true,
                    message: "请选择服务标识",
                    trigger: "change"
                }]
            },
            {
                label: "企业IP地址:",
                type: "input",
                key: "ip",
                style: {
                    width: "50%"
                },
                rules: [{
                    required: true,
                    message: "请输入企业IP地址",
                    trigger: "change"
                }, {validator: utils.validateUrl, trigger: "blur"}]
            },
            {
                label: "企业TCP端口:",
                type: "input",
                key: "port",
                maxLength: 5,
                style: {
                    width: "50%"
                },
                rules: [
                    {required: true, message: "请选择企业TCP端口", trigger: "change"},
                    {validator: utils.checkNumber, trigger: "blur"}
                    // {max: 5, message: "最长输入5个字符", trigger: "blur"}
                ]
            },
            {
                label: "注册鉴权状态:",
                type: "select",
                popperClass: "monitor-select",
                key: "authed",
                style: {
                    width: "50%"
                },
                options: [{
                    label: "是",
                    value: 1
                }, {
                    label: "否",
                    value: 0
                }],
                rules: [{
                    required: true,
                    message: "请选择注册鉴权状态",
                    trigger: "change"
                }]
            },
            {
                label: "启用状态:",
                type: "radio",
                key: "enableState",
                group: [{
                    label: 0,
                    name: "停用"
                },
                    {
                        label: 1,
                        name: "启用"
                    }
                ],
                style: {
                    width: "50%"
                }
            },
            {
                label: "备注:",
                type: "textarea",
                key: "remark",
                maxLength: 100,
                style: {
                    width: "100%"
                }
                // rules: [{max: 100, message: "最长输入100个字符", trigger: "blur"}]
            }
        ]
    },
    // 自定义转发服务
    forwardZDYServerManage: {
        formInput: [
            {
                label: "名称:",
                type: "input",
                key: "name",
                style: {
                    width: "20%"
                }
            }, {
                label: "接入码:",
                type: "input",
                key: "accessCode",
                style: {
                    width: "18%"
                }
            },
            {
                label: "启用状态:",
                type: "select",
                popperClass: "monitor-select",
                key: "enableStatus",
                style: {
                    width: "18%"
                },
                options: [
                    {
                        value: 0,
                        label: "停用"
                    },
                    {
                        value: 1,
                        label: "启用"
                    }
                ]
            },
            {
                id: "system_center_user_query",
                label: "",
                text: "查询",
                type: "button",
                key: "search",
                itemType: "primary",
                handler: "search",
            }
        ],
        listHeader: [
            {
                label: "名称",
                prop: "name",
                width: utils.setFontSize(180),
                render(row) {
                    return row.name || "--";
                }
            }, {
                label: "转发服务ID",
                prop: "serviceId",
                width: utils.setFontSize(180),
                render(row) {
                    let serviceComtorArr = JSON.parse(localStorage.getItem("serviceComtorArr")),
                        serviceComtorObj = {};
                    serviceComtorArr.forEach(item => {
                        serviceComtorObj[item.value] = item.label;
                    });
                    return serviceComtorObj[row.serviceId] || "--";
                }
            },
            {
                label: "接入码",
                prop: "accessCode",
                width: utils.setFontSize(180),
                render(row) {
                    return row.accessCode || "--";
                }
            },
            {
                label: "http地址",
                prop: "address",
                width: utils.setFontSize(180),
                render(row) {
                    return row.address || "--";
                }
            },
            {
                label: "平台编码",
                prop: "platformId",
                width: utils.setFontSize(180),
                render(row) {
                    return row.platformId || "--";
                }
            },
            {
                label: "状态",
                prop: "enableStatus",
                width: utils.setFontSize(180),
                render(row) {
                    return row.enableStatus == 0 ? "停用" : "启用";
                }
            },
            {
                label: "备注",
                prop: "remark",
                width: utils.setFontSize(180),
                render(row) {
                    return row.remark || "--";
                }
            }, {
                label: "操作",
                prop: "",
                width: utils.setFontSize(180),
                option: [{
                    label: "修改",
                    handler: "onEdit",
                    auth: "user_center_serve_forward_ZDY_update"
                }, {
                    label: "删除",
                    handler: "onDelete",
                    auth: "user_center_serve_forward_ZDY_delete"
                }]
            }
        ],
        addItem: [
            {
                label: "名称:",
                type: "input",
                key: "name",
                maxLength: 64,
                style: {
                    width: "50%"
                },
                rules: [{
                    required: true,
                    message: "请输入名称",
                    trigger: "blur"
                }]
            },
            {
                label: "转发服务ID",
                type: "select",
                popperClass: "monitor-select",
                key: "serviceId",
                style: {
                    width: "50%"
                },
                options: [],
                handler: "serviceIdChange",
                rules: [{
                    required: true,
                    message: "请选择转发服务ID",
                    trigger: "change"
                }]
            },
            {
                label: "http地址:",
                type: "input",
                key: "address",
                style: {
                    width: "50%"
                },
                rules: [{
                    required: true,
                    message: "请输入http地址",
                    trigger: "blur"
                }]
            },
            {
                label: "接入码:",
                type: "input",
                key: "accessCode",
                maxLength: 50,
                style: {
                    width: "50%"
                },
                rules: [
                    {required: true, message: "请输入接入码", trigger: "blur"}
                    // {required: true, message: "请输入接入码", trigger: "blur"},
                    // {validator: utils.checkNumber, trigger: "blur"}
                ]
            },
            {
                label: "平台编码:",
                type: "input",
                key: "platformId",
                maxLength: 12,
                style: {
                    width: "50%"
                },
                rules: [{
                    required: true,
                    message: "请输入平台编码",
                    trigger: "blur"
                }, {validator: utils.validateUser, trigger: "blur"}]
            },
            {
                label: "启用状态:",
                type: "radio",
                key: "enableStatus",
                style: {
                    width: "50%"
                },
                group: [{
                    label: 0,
                    name: "停用"
                },
                    {
                        label: 1,
                        name: "启用"
                    }
                ]
            },
            {
                label: "备注:",
                type: "textarea",
                key: "remark",
                maxLength: 100,
                style: {
                    width: "100%"
                }
                // rules: [{max: 100, message: "最长输入100个字符", trigger: "blur"}]
            }
        ]
    },
    ...terminalParam,
    offlineGenereManage: {
        formInput: [{
            label: "起止时间:",
            type: "dateRange",
            popperClass: "monitor-date-picker",
            key: "startEndTime",
            style: {
                width: "30%",
            }
        }, 
        {
            label: "下发指令:",
            type: "cascader",
            key: "commandType",
            placeholder:'请选择指令',
            //checkStrictly: true,
            filterable: true,
            options: [],
            style: {
                width: "20%",                
            }
        },         
        // {
        //     label: "指令:",
        //     type: "select",
        //     popperClass: "monitor-select",
        //     key: "commandType",
        //     options: [{
        //         label: "通讯连接参数",
        //         value: "1"
        //     }, {
        //         label: "服务器参数",
        //         value: "2"
        //     }, {
        //         label: "位置汇报参数",
        //         value: "3"
        //     }, {
        //         label: "报警参数",
        //         value: "4"
        //     }, {
        //         label: "拍照照片参数",
        //         value: "5"
        //     }, {
        //         label: "车辆参数",
        //         value: "6"
        //     }, {
        //         label: "GNSS参数",
        //         value: "7"
        //     }, {
        //         label: "高级驾驶辅助系统参数",
        //         value: "8"
        //     }, {
        //         label: "驾驶员状态检测系统参数",
        //         value: "9"
        //     }, {
        //         label: "标准版本",
        //         value: "10"
        //     }, {
        //         label: "驾驶员信息",
        //         value: "11"
        //     }, {
        //         label: "记录仪时间",
        //         value: "12"
        //     }, {
        //         label: "累计里程",
        //         value: "13"
        //     }, {
        //         label: "脉冲系数",
        //         value: "14"
        //     }, {
        //         label: "车辆信息",
        //         value: "15"
        //     }, {
        //         label: "配置信息",
        //         value: "16"
        //     }, {
        //         label: "唯一编号",
        //         value: "17"
        //     }],
        //     style: {
        //         width: "calc(25% - 40px)",
        //         marginRight: "40px"
        //     }
        // }, 
        {
            id: "system_center_user_query",
            label: "",
            text: "查询",
            type: "button",
            key: "search",
            itemType: "primary",
            handler: "search",
        }],
        listHeader: [{
            label: "车牌号",
            prop: "vehicleNo",
        }, {
            label: "指令名称",
            prop: "commandName",
        },
            {
                label: "发送者",
                prop: "userName",
                render(row) {
                    return row.userName || "--";
                }
            }, {
                label: "指令下发时间",
                prop: "downTime",
                width: utils.setFontSize(180),
                render(row) {
                    return utils.formatDate(row.downTime, "YYYY-MM-DD hh:mm");
                }
            }, {
                label: "下发指令内容",
                prop: "downContent"
            },
            {
                label: "下发状态",
                prop: "downStatusText",
                width: utils.setFontSize(180),
            },
            // {
            //     label: "响应时间",
            //     prop: "upTime",
            //     width: utils.setFontSize(180),
            //     render(row) {
            //         return utils.formatDate(row.upTime, "YYYY-MM-DD hh:mm");
            //     }
            // },
            // {
            //     label: "响应状态",
            //     prop: "upStatusText",
            //     width: utils.setFontSize(150),
            // },
            // {
            //     label: "响应指令内容",
            //     prop: "upContent",
            //     width: utils.setFontSize(150),
            //     render(row) {
            //         return row.upContent || "--";
            //     }
            // }
        ]
    },
    sendHistoryManage: {
        formInput: [{
            label: "起止时间:",
            type: "dateRange",
            popperClass: "monitor-date-picker",
            key: "startEndTime",
            style: {
                width: "30%",
            }
        }, 
        {
            label: "下发指令:",
            type: "cascader",
            key: "commandType",
            placeholder:'请选择指令',
            //checkStrictly: true,
            filterable: true,
            options: [],
            style: {
                width: "20%",                
            }
        }, 
        {
            id: "system_center_user_query",
            label: "",
            text: "查询",
            type: "button",
            key: "search",
            itemType: "primary",
            handler: "search",
        },{
            id: "system_center_user_query",
			label: "",
			text: "导出",
			type: "button",
			key: "export",
			itemType: "primary",
			handler: "export",
        }],
        listHeader: [{
            label: "车牌号",
            prop: "vehicleNo",
            render(row) {
                return row.vehicleNo || "--";
            }
        }, {
            label: "指令名称",
            prop: "commandName",
        },
            {
                label: "发送者",
                prop: "userName",
                render(row) {
                    return row.userName || "--";
                }
            }, {
                label: "指令下发时间",
                prop: "downTime",
                width: utils.setFontSize(180),
                render(row) {
                    return utils.formatDate(row.downTime, "YYYY-MM-DD hh:mm");
                }
            }, {
                label: "下发指令内容",
                prop: "downContent",
            },
            {
                label: "下发状态",
                prop: "downStatusText",
                width: utils.setFontSize(180),
            },
        ]
    },
    iconConfig: {
        formItem: [{
            label: "车辆类型:",
            type: "select",
            popperClass: "monitor-select",
            key: "classValue",
            style: {
                width: "25%"
            },
            options: []
        },
            {
                id: "system_center_user_query",
                label: "",
                text: "查询",
                type: "button",
                key: "search",
                itemType: "primary",
                handler: "search",
                style: {
                    position: "absolute",
                    right: "20px",
                    top: "0"
                }
            }
        ],
        tableHeader: [{
            name: "ipAddress",
            label: "IP地址",
            render: row => {
                return row.ipAddress || "--";
            }
        }, {
            name: "opraterAccount",
            label: "操作用户",
            render: row => {
                return row.opraterAccount || "--";
            }
        },
            {
                name: "opraterTime",
                label: "操作时间",
                // width: utils.setFontSize(150),
                render(row) {
                    return utils.formatDate(row.opraterTime, "YYYY-MM-DD hh:mm:ss") || "--";
                }
            },
            {
                name: "opraterName",
                label: "日志类型",
                render: row => {
                    return row.opraterName || "--";
                }
            },
            {
                name: "jsonContent",
                label: "日志内容",
                render: row => {
                    return row.jsonContent || "--";
                }
            }
        ]
    },
    batchDispatch: {
        listHeader: [
            {
                label: "车牌号码",
                prop: "name",
                render(row) {
                    return row.name || "--";
                }
            },
            {
                label: "终端标识",
                prop: "vid",
                render(row) {
                    return row.vid || "--";
                }
            },
            {
                label: "文本内容",
                prop: "content",
                render(row) {
                    return row.content || "--";
                }
            },
            {
                label: "发送状态",
                prop: "sendStatus",
                width: utils.setFontSize(180),
                render(row) {
                    return row.sendStatus || "--";
                }
            }
        ]
    },
    upgradePackage: {
        formInput: [{
            label: "名称:",
            type: "input",
            key: "upgradeName",
            style: {
                width: "18%"
            }
        }, {
            label: "固定版本号:",
            type: "input",
            key: "upgradeVersion",
            style: {
                width: "18%",
            }
        }, {
            label: "升级类型:",
            type: "select",
            key: "upgradeType",
            style: {
                width: "18%",
            },
            options: [{
                label: "全部",
                value: ""
            }, {
                label: "终端",
                value: "0"
            }, {
                label: "道路运输证IC卡读卡器",
                value: "12"
            }, {
                label: "北斗定位模块",
                value: "52"
            }, {
                label: "驾驶辅助功能模块",
                value: "100"
            }, {
                label: "驾驶员行为监测模块",
                value: "101"
            }, {
                label: "轮胎状态监测模块",
                value: "102"
            }, {
                label: "盲区监测模块",
                value: "103"
            }]
        }, {
            label: "起止时间:",
            type: "dateRange",
            popperClass: "monitor-date-picker",
            key: "startEndTime",
            style: {
                width: "28%"
            },
            handler: "startEndTime"
        },
            {
                id: "system_center_user_query",
                label: "",
                text: "查询",
                type: "button",
                key: "search",
                itemType: "primary",
                handler: "search",
            }
        ],
        listHeader: [{
            label: "名称",
            prop: "upgradeName",
            width: utils.setFontSize(150),
            render(row) {
                return row.upgradeName || "--";
            }
        },
            {
                label: "升级包地址",
                prop: "upgradeUrl",
                width: utils.setFontSize(200),
                render(row) {
                    return row.upgradeUrl || "--";
                }
            },
            {
                label: "制造商ID",
                prop: "manufacturerId",
                width: utils.setFontSize(120),
                render(row) {
                    return row.manufacturerId || "--";
                }
            },
            {
                label: "升级包ID",
                prop: "upgradeId",
                width: utils.setFontSize(150),
                render(row) {
                    return row.upgradeId || "--";
                }
            },
            {
                label: "升级类型",
                prop: "upgradeType",
                width: utils.setFontSize(150),
                render(row) {
                    let upgradeType = {
                        0: "终端",
                        12: "道路运输证IC卡读卡器",
                        52: "北斗定位模块",
                        100: "驾驶辅助功能模块",
                        101: "驾驶员行为监测模块",
                        102: "轮胎状态监测模块",
                        103: "盲区监测模块"
                    };
                    return upgradeType[row.upgradeType] || "--";
                }
            },
            {
                label: "固定版本号",
                prop: "upgradeVersion",
                width: utils.setFontSize(160),
                render(row) {
                    return row.upgradeVersion || "--";
                }
            },
            {
                label: "创建用户名",
                prop: "createName",
                width: utils.setFontSize(200),
                render(row) {
                    return row.createName || "--";
                }
            },
            {
                label: "创建时间",
                prop: "createTime",
                width: utils.setFontSize(180),
                render(row) {
                    return utils.formatDate(row.createTime, "YYYY-MM-DD hh:mm:ss") || "--";
                }
            }, {
                label: "操作",
                width: utils.setFontSize(200),
                option: [{
                    label: "修改",
                    handler: "onEdit",
                    auth: "user_center_serve_upgrade_package_update"
                }, {
                    label: "删除",
                    handler: "onDelete",
                    auth: "user_center_serve_upgrade_package_delete"
                }]
            }
        ],
        addItem: [{
            label: "名称:",
            type: "input",
            key: "upgradeName",
            maxLength: 16,
            style: {
                width: "70%",
                marginLeft: "15%"
            },
            rules: [
                {required: true, message: "请输入名称", trigger: "blur"}
                // {max: 16, message: "最长输入16个字符", trigger: "blur"}
            ]
        },
            {
                label: "升级包地址:",
                type: "uploadFile",
                key: "upgradeUrl",
                placeholder: "请选择升级包地址",
                style: {
                    width: "70%",
                    marginLeft: "15%"
                },
                rules: [{
                    required: true,
                    message: "请输入升级包地址",
                    trigger: "blur"
                }]
            },
            {
                label: "升级类型:",
                type: "select",
                key: "upgradeType",
                style: {
                    width: "70%",
                    marginLeft: "15%"
                },
                options: [{
                    label: "终端",
                    value: 0
                }, {
                    label: "道路运输证IC卡读卡器",
                    value: 12
                }, {
                    label: "北斗定位模块",
                    value: 52
                }, {
                    label: "驾驶辅助功能模块",
                    value: 100
                }, {
                    label: "驾驶员行为监测模块",
                    value: 101
                }, {
                    label: "轮胎状态监测模块",
                    value: 102
                }, {
                    label: "盲区监测模块",
                    value: 103
                }],
                rules: [{
                    required: true,
                    message: "请选择升级类型",
                    trigger: "change"
                }]
            },
            {
                label: "固定版本号:",
                type: "input",
                key: "upgradeVersion",
                maxLength: 80,
                style: {
                    width: "70%",
                    marginLeft: "15%"
                },
                rules: [
                    {required: true, message: "请输入固定版本号", trigger: "blur"}
                    // {max: 80, message: "最长输入80个字符", trigger: "blur"}
                ]
            },
            {
                label: "制造商ID:",
                type: "input",
                inputType: "number",
                key: "manufacturerId",
                style: {
                    width: "70%",
                    marginLeft: "15%"
                }
            }
        ]
    },
    upgradeTask: {
        formInput: [
            {
                label: "目标版本号:",
                type: "input",
                key: "upgradeTargetVersion",
                style: {
                    width: "18%"
                }
            }, {
                label: "任务状态:",
                type: "select",
                key: "taskStatus",
                options: [{
                    label: "待升级",
                    value: "-1"
                }, {
                    label: "升级中",
                    value: "0"
                }, {
                    label: "升级结束",
                    value: "1"
                }],
                style: {
                    width: "15%"
                }
            }, {
                label: "起止时间:",
                type: "dateRange",
                popperClass: "monitor-date-picker",
                key: "startEndTime",
                style: {
                    width: "28%"
                },
                handler: "startEndTime"
            },
            {
                id: "system_center_user_query",
                label: "",
                text: "查询",
                type: "button",
                key: "search",
                itemType: "primary",
                handler: "search",
            }
        ],
        listHeader: [
            {
                label: "任务状态",
                prop: "taskStatus",
                width: utils.setFontSize(150),
                render(row) {
                    let taskStatus = {
                        "-1": "待升级",
                        0: "升级中",
                        1: "升级结束"
                    };
                    return taskStatus[row.taskStatus] || "--";
                }
            },
            {
                label: "升级目标版本号",
                prop: "upgradeTargetVersion",
                width: utils.setFontSize(150),
                render(row) {
                    return row.upgradeTargetVersion || "--";
                }
            },
            {
                label: "升级总数",
                prop: "upgradeTotalCount",
                width: utils.setFontSize(150),
                render(row) {
                    return row.upgradeTotalCount || "--";
                }
            },
            {
                label: "未升级数",
                prop: "notUpgradeCount",
                width: utils.setFontSize(150),
                render(row) {
                    return row.notUpgradeCount || "--";
                }
            },
            {
                label: "创建用户名",
                prop: "createUserName",
                width: utils.setFontSize(200),
                render(row) {
                    return row.createUserName || "--";
                }
            },
            {
                label: "升级开始时间",
                prop: "startTime",
                width: utils.setFontSize(180),
                render(row) {
                    return utils.formatDate(row.startTime, "YYYY-MM-DD hh:mm:ss") || "--";
                }
            },
            {
                label: "升级结束时间",
                prop: "endTime",
                width: utils.setFontSize(180),
                render(row) {
                    return utils.formatDate(row.endTime, "YYYY-MM-DD hh:mm:ss") || "--";
                }
            },
            {
                label: "创建时间",
                prop: "createTime",
                width: utils.setFontSize(180),
                render(row) {
                    return utils.formatDate(row.createTime, "YYYY-MM-DD hh:mm:ss") || "--";
                }
            }, {
                label: "操作",
                width: utils.setFontSize(200),
                option: [{
                    label: "",
                    handler: "onEdit",
                    isBtnHtml: true,
                    auth: "user_center_serve_upgrade_task_update",
                    render: (row) => {
                        return "修改";
                    }
                }, {
                    label: "",
                    handler: "onDelete",
                    auth: "user_center_serve_upgrade_task_delete",
                    isBtnHtml: true,
                    render: (row) => {
                        return "删除";
                    }
                }, {
                    label: "查看",
                    handler: "onDetail",
                    auth: "user_center_serve_upgrade_task_detail"
                }]
            }
        ],
        addItem: [
            {
                label: "车牌号码:",
                type: "vehicleTree",
                placeholder: "输入进行查询",
                key: "entName",
                style: {
                    width: "80%",
                    marginLeft: "10%"
                },
                rules: [{required: true, message: "请选择车牌号码", trigger: "change"}]
            }, {
                label: "升级包名称:",
                type: "input",
                readonly: true,
                key: "upgradeName",
                style: {
                    width: "80%",
                    marginLeft: "10%"
                },
                handler: "clickUpgradeId",
                rules: [{required: true, message: "请选择升级包名称", trigger: "change"}]
            },
            {
                label: "目标版本号:",
                type: "input",
                key: "upgradeTargetVersion",
                style: {
                    width: "80%",
                    marginLeft: "10%"
                }
            }
        ]
    },
    upgradeHistory: {
        formInput: [{
            label: "车牌号码:",
            type: "vehicleTree",
            placeholder: "输入进行查询",
            key: "entName",
            style: {
                width: "20%"
            }
        }, {
            label: "升级状态:",
            type: "select",
            popperClass: "monitor-select",
            key: "upgradeStatus",
            options: [{
                label: "待升级",
                value: "-1"
            }, {
                label: "升级中",
                value: 0
            }, {
                label: "升级成功",
                value: 1
            }, {
                label: "升级失败",
                value: 2
            }],
            style: {
                width: "15%"
            }
        }, {
            label: "起止时间:",
            type: "dateRange",
            popperClass: "monitor-date-picker",
            key: "startEndTime",
            style: {
                width: "28%"
            },
            handler: "startEndTime"
        },
            {
                id: "system_center_user_query",
                label: "",
                text: "查询",
                type: "button",
                key: "search",
                itemType: "primary",
                handler: "search",
            }
        ],
        listHeader: [{
            label: "车牌号",
            prop: "vehicleNo",
            // width: utils.setFontSize(150),
            render(row) {
                return row.vehicleNo || "--";
            }
        },
            {
                label: "升级指令状态",
                prop: "upgradeCommandStatus",
                // width: utils.setFontSize(150),
                render(row) {
                    let upgradeCommandStatus = {
                        0: "待下发",
                        1: "下发结束"
                    };
                    return upgradeCommandStatus[row.upgradeCommandStatus] || "--";
                }
            },
            {
                label: "升级状态",
                prop: "upgradeStatus",
                // width: utils.setFontSize(120),
                render(row) {
                    let upgradeStatus = {
                        "-1": "待升级",
                        0: "升级中",
                        1: "升级成功",
                        2: "升级失败"
                    };
                    return upgradeStatus[row.upgradeStatus] || "--";
                }
            },
            {
                label: "升级开始时间",
                prop: "startTime",
                // width: utils.setFontSize(180),
                render(row) {
                    return utils.formatDate(row.startTime, "YYYY-MM-DD hh:mm:ss") || "--";
                }
            },
            {
                label: "升级结束时间",
                prop: "endTime",
                // width: utils.setFontSize(180),
                render(row) {
                    return utils.formatDate(row.endTime, "YYYY-MM-DD hh:mm:ss") || "--";
                }
            },
            {
                label: "创建用户名",
                prop: "createUserName",
                // width: utils.setFontSize(120),
                render(row) {
                    return row.createUserName || "--";
                }
            },
            {
                label: "创建时间",
                prop: "createTime",
                // width: utils.setFontSize(180),
                render(row) {
                    return utils.formatDate(row.createTime, "YYYY-MM-DD hh:mm:ss") || "--";
                }
            }
        ]
    },
    vehicleLog: {
        formInput: [
            // {
            //     label: "车牌号码:",
            //     type: "vehicleTree",
            //     placeholder: "输入进行查询",
            //     key: "vehicleNo",
            //     style: {
            //         width: "calc(25% - 60px)",
            //         marginRight: "20px"
            //     }
            // },
			{
			    label: "车牌号码:",
			    type: "searchinput",
			    placeholder: "输入进行查询",
			    key: "vid",
			    style: {
			        width: "20%",
			    },
				params:{
					limit: 50,
					dataType: 1
				},
				options: []
				
			},
            {
                label: "起止时间:",
                type: "dateRange",
                popperClass: "monitor-date-picker",
                key: "startEndTime",
                style: {
                    width: "30%"
                },
                handler: "startEndTime"
            },
            {
                label: "操作类型:",
                type: "select",
                key: "opraterCode",
                options: [{
                    label: "修改",
                    value: "3"
                }, {
                    label: "删除",
                    value: "2"
                }],
                style: {
                    width: "25%"
                }
            },
            {
                label: "操作用户:",
                type: "input",
                key: "opraterUser",
                style: {
                    width: "25%"
                }
            },
            {
                label: "SIM卡号:",
                type: "input",
                key: "simAccount",
                style: {
                    width: "20%"
                }
            },
            {
                id: "system_center_user_query",
                label: "",
                text: "查询",
                type: "button",
                key: "search",
                itemType: "primary",
                handler: "search",
                notFormItem: true,
            }
        ],
        tableHeader: [
            {
                name: "entName",
                label: "组织名称",
                render: row => {
                    return row.entName || "--";
                }
            },
            {
                name: "vehicleNo",
                label: "车牌号码",
                render: row => {
                    return row.vehicleNo || "--";
                }
            },
            {
                name: "simAccount",
                label: "SIM卡号",
                render: row => {
                    return row.simAccount || "--";
                }
            },
            {
                name: "tlMac",
                label: "终端ID",
                render: row => {
                    return row.tlMac || "--";
                }
            },
            {
                name: "ipAddress",
                label: "IP地址",
                render: row => {
                    return row.ipAddress || "--";
                }
            },
            {
                name: "opraterName",
                label: "操作类型",
                render: row => {
                    return row.opraterName || "--";
                }
            },
            {
                name: "opraterAccount",
                label: "操作用户",
                render: row => {
                    return row.opraterAccount || "--";
                }
            },
            {
                name: "opraterTime",
                label: "操作时间",
                width: utils.setFontSize(180),
                render(row) {
                    return utils.formatDate(row.opraterTime, "YYYY-MM-DD hh:mm:ss") || "--";
                }
            }
        ]
    },
    userLog: {
        formInput: [
            {
                label: "登录账户:",
                type: "input",
                key: "loginAccount",
                style: {
                    width: "20%"
                }
            },
            {
                label: "起止时间:",
                type: "dateRange",
                popperClass: "monitor-date-picker",
                key: "startEndTime",
                style: {
                    width: "30%"
                },
                handler: "startEndTime"
            },
            {
                label: "用户名称:",
                type: "input",
                key: "userName",
                style: {
                    width: "25%"
                }
            },
            {
                label: "操作用户:",
                type: "input",
                key: "opraterUser",
                style: {
                    width: "25%"
                }
            },
            {
                label: "操作类型:",
                type: "select",
                key: "opraterCode",
                options: [{
                    label: "修改",
                    value: "3"
                }, {
                    label: "删除",
                    value: "2"
                }, {
                    label: "pc端登录",
                    value: "5"
                }, {
                    label: "小程序端登录",
                    value: "6"
                }],
                style: {
                    width: "20%"
                }
            },
            {
                id: "system_center_user_query",
                label: "",
                text: "查询",
                type: "button",
                key: "search",
                itemType: "primary",
                handler: "search",
                notFormItem: true,
            }
        ],
        tableHeader: [
            {
                name: "loginAccount",
                label: "登录账户",
                render: row => {
                    return row.loginAccount || "--";
                }
            },
            {
                name: "userName",
                label: "用户名称",
                render: row => {
                    return row.userName || "--";
                }
            },
            {
                name: "roleName",
                label: "角色名称",
                render: row => {
                    return row.roleName || "--";
                }
            },
            {
                name: "expireDate",
                label: "到期时间",
                width: utils.setFontSize(180),
                render: row => {
                    return utils.formatDate(row.expireDate, "YYYY-MM-DD hh:mm:ss") || "--";
                }
            },
            {
                name: "ipAddress",
                label: "IP地址",
                render: row => {
                    return row.ipAddress || "--";
                }
            },
            {
                name: "opraterName",
                label: "操作类型",
                render: row => {
                    return row.opraterName || "--";
                }
            },
            {
                name: "opraterAccount",
                label: "操作用户",
                render: row => {
                    return row.opraterAccount || "--";
                }
            },
            {
                name: "opraterTime",
                label: "操作时间",
                width: utils.setFontSize(180),
                render(row) {
                    return utils.formatDate(row.opraterTime, "YYYY-MM-DD hh:mm:ss") || "--";
                }
            }
        ]
    }
};