<template>
    <div style="width: 100%;height: 100%;">
        <div id="mapBaidu" style="width: 100%;height: 100%;" v-loading="!mapLoad"></div>
        <ul class="map-operation">
            <li @click="satelliteMap">{{isSatelliteMap?"普通地图": "卫星地图"}}</li>
            <!--            <li :class="isTrafficState?'traffic-show':'traffic-hide'" @click="trafficClick">路况事件</li>-->
            <li :title="weatherText" :class="weather">{{address}}</li>
            <!--            <li class="map-instrument-btn" @click="mapInstrumentShow = !mapInstrumentShow">-->
            <!--                地图工具-->
            <!--                <div v-if="mapInstrumentShow" class="map-instrument-container">-->
            <!--                    <div class="iconfont iconshitucemianji" @click="mapInstrumentClick('1')"><span>测面</span></div>-->
            <!--                    <div class="iconfont iconfangda" @click="mapInstrumentClick('2')"><span>放大</span></div>-->
            <!--                    <div class="iconfont iconsuoxiao" @click="mapInstrumentClick('3')"><span>缩小</span></div>-->
            <!--                    <div class="iconfont iconranging_line" @click="mapInstrumentClick('4')"><span>测距</span></div>-->
            <!--                    <div class="iconfont iconclose" @click="mapInstrumentClick('5')"><span>清除工具</span></div>-->
            <!--                </div>-->
            <!--            </li>-->
            <li v-if="page == 'realtimeMonitor'" class="map-switch-btn">
                地图切换
                <div class="map-switch-container">
                    <div @click="mapSwitchClick('1')">高德地图</div>
                    <div @click="mapSwitchClick('2')" class="active">百度地图</div>
                    <div class="iconfont iconshangjiantou shangjiantou"></div>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
    import {gaodeLocation} from "@/api/monitorManage";

    export default {
        name: "SAASBMap",
        props: ["page"],
        data() {
            return {
                num: 1,
                isTrafficState: false,
                weather: "",
                isSatelliteMap: false,
                satelliteLayer: "",
                roadNetLayer: "",
                trafficLayer: "",
                markers: [],
                address: "",
                zoom: "",
                weatherText: "",
                weatherShow: true,
                map: "",
                mouseTool: "",
                mapInstrumentShow: false,
                loading: false,
                mapLoad: false
            };
        },
        computed: {
            monitorSkin() {
                return this.$store.state.userConfig.monitorSkin;
            }
        },
        watch: {
            monitorSkin: {
                handler(newVal) {
                    if (newVal == "heiseshangwu") {
                        this.map.setMapStyleV2({
                            styleJson: this.$commonConfig.mapJson
                        });
                    } else if (newVal == "shenhaiweilan") {
                        this.map.setMapStyleV2({
                            styleJson: this.$commonConfig.mapJson2
                        });
                    } else if (newVal == "baisejianyue" || newVal == "tiankongzhilan") {
                        this.map.setMapStyleV2({
                            styleId: "df47a15c6a7d1a8a2babfef5dc0629e9"
                        });
                    }
                }
            }
        },
        mounted() {
            this.setMap();
        },
        methods: {
            show() {
                if (this.num == 1) {
                    setTimeout(() => {
                        this.map.centerAndZoom(new BMap.Point(113.397428, 36.90923),5);
                        // this.map.setCenter(new BMapGL.Point(113.397428, 36.90923))
                    }, 200);
                    this.num++;
                }
            },
            mapInstrumentClick(type) {
                this.mouseTool ? this.mouseTool.close() : "";
                var styleOptions = {
                    strokeColor: "#80d8ff",   // 边线颜色
                    fillColor: "#80d8ff",     // 填充颜色。当参数为空时，圆形没有填充颜色
                    strokeWeight: 2,          // 边线宽度，以像素为单位
                    strokeOpacity: 1,         // 边线透明度，取值范围0-1
                    fillOpacity: 0.3         // 填充透明度，取值范围0-1
                };
                var labelOptions = {
                    borderRadius: "2px",
                    background: "#FFFBCC",
                    border: "1px solid #E1E1E1",
                    color: "#703A04",
                    fontSize: "12px",
                    letterSpacing: "0",
                    padding: "5px"
                };
                switch (type) {
                    case "1": {
                        this.mouseTool = new BMapGLLib.DrawingManager(this.map, {
                            enableCalculate: true,  // 绘制是否进行测距测面
                            enableSorption: true,   // 是否开启边界吸附功能
                            sorptiondistance: 20,   // 边界吸附距离
                            enableGpc: true,        // 是否开启延边裁剪功能
                            enableLimit: true,      // 是否开启超限提示
                            limitOptions: {
                                area: 50000000,     // 面积超限值
                                distance: 30000     // 距离超限值
                            },
                            polygonOptions: styleOptions  // 矩形的样式
                        });
                        this.mouseTool.setDrawingMode(BMAP_DRAWING_POLYGON);
                        this.mouseTool.open();
                        break;
                    }
                    case "2": {
                        this.mouseTool = new BMapLib.RectangleZoom(this.map, {
                            followText: "拖拽鼠标进行操作",
                            zoomType: 0
                        });
                        this.mouseTool.open();  //开启拉框放大
                        break;
                    }
                    case "3": {
                        this.mouseTool = new BMapLib.RectangleZoom(this.map, {
                            followText: "拖拽鼠标进行操作",
                            zoomType: 1
                        });
                        this.mouseTool.open();  //开启拉框放大
                        break;
                    }
                    case "4": {
                        this.mouseTool.rule({
                            startMarkerOptions: {//可缺省
                                icon: new AMap.Icon({
                                    size: new AMap.Size(19, 31),//图标大小
                                    imageSize: new AMap.Size(19, 31),
                                    image: "https://webapi.amap.com/theme/v1.3/markers/b/start.png"
                                })
                            },
                            endMarkerOptions: {//可缺省
                                icon: new AMap.Icon({
                                    size: new AMap.Size(19, 31),//图标大小
                                    imageSize: new AMap.Size(19, 31),
                                    image: "https://webapi.amap.com/theme/v1.3/markers/b/end.png"
                                }),
                                offset: new AMap.Pixel(-9, -31)
                            },
                            midMarkerOptions: {//可缺省
                                icon: new AMap.Icon({
                                    size: new AMap.Size(19, 31),//图标大小
                                    imageSize: new AMap.Size(19, 31),
                                    image: "https://webapi.amap.com/theme/v1.3/markers/b/mid.png"
                                }),
                                offset: new AMap.Pixel(-9, -31)
                            },
                            lineOptions: {//可缺省
                                strokeStyle: "solid",
                                strokeColor: "#80d8ff",
                                strokeOpacity: 1,
                                strokeWeight: 2
                            }
                            //同 RangingTool 的 自定义 设置，缺省为默认样式
                        });
                        break;
                    }
                    case "5":
                        this.mouseTool.close(true);
                    default:
                        break;
                }
            },
            mapSwitchClick(type) {
                this.$emit("mapSwitchClick", type);
            },
            trafficClick() {
                this.isTrafficState = !this.isTrafficState;
                if (this.isTrafficState) {
                    this.map.setTrafficOn();
                } else {
                    this.map.setTrafficOff();
                }
            },
            satelliteMap() {
                this.isSatelliteMap = !this.isSatelliteMap;
                if (this.isSatelliteMap) {
                    // 卫星地图
                    this.map.setMapType(BMAP_SATELLITE_MAP);
                } else {
                    // 普通地图
                    this.map.setMapType(BMAP_NORMAL_MAP);
                }
                // MAP_TYPE_SATELLITE
            },
            // 初始化地图
            setMap() {
                var _this = this;
                this.map = new BMap.Map("mapBaidu", {
                    // this.map = new BMapGL.Map('mapBaidu', {
                    minZoom: 3,
                    maxZoom: 20,
                    enableHighResolution: true,
                    enableMapClick: true
                });
                var mapContainer = document.getElementById('mapBaidu');
                mapContainer.style.width = '100%';
                mapContainer.style.height = '100%';
                this.map.centerAndZoom(new BMap.Point(113.397428, 36.90923), 5);
                // this.map.centerAndZoom(new BMapGL.Point(113.397428, 36.90923), 5)
                this.map.enableScrollWheelZoom(true); //启用滚轮放大缩小，默认禁用
                this.map.enableDragging(true); //启用地图拖拽，默认启用。
                setTimeout(()=>{
                    if (this.monitorSkin == "heiseshangwu") {
                        this.map.setMapStyleV2({
                            styleJson: this.$commonConfig.mapJson
                        });
                    } else if (this.monitorSkin == "shenhaiweilan") {
                        this.map.setMapStyleV2({
                            styleJson: this.$commonConfig.mapJson2
                        });
                    } else if (this.monitorSkin == "baisejianyue" || this.monitorSkin == "tiankongzhilan") {
                        this.map.setMapStyleV2({
                            styleId: "df47a15c6a7d1a8a2babfef5dc0629e9"
                        });
                    }
                },500);

                this.logMapinfo();
                //绑定地图移动事件
                this.map.addEventListener("moveend", this.logMapinfo);
                this.map.addEventListener("zoomend", this.logMapinfo);
                this.map.addEventListener('tilesloaded', () => {
                    if (!_this.mapLoad) {
                        _this.mapLoad = true;
                    }
                })
            },
            //获取并展示当前城市信息
            logMapinfo() {
                if(this.loading) return;
                this.loading = true;
                let that = this;
                that.zoom = that.map.getZoom();
                let bdCenter = this.map.getCenter();
                let gdCenter = this.$utils.bd_decrypt(bdCenter.lng, bdCenter.lat);
                let param = {
                    output: "JSON",
                    location: `${gdCenter.lng},${gdCenter.lat}`,
                    key: that.$Foo.aMapKey,
                    radius: "1000",
                    roadlevel: 0,
                    batch: false,
                    extensions: "all",
                    "time": +new Date()
                };
                gaodeLocation(param).then(res => {
                    let result = res.data;
                    if (result.infocode == "10000") {
                        let info = result.regeocode.addressComponent || {};
                        if (info.citycode.length <= 0) {
                            that.address = "未知位置";
                            that.weatherText = "";
                            that.weather = "";
                            return;
                        }
                        let address = info.city && info.city.length != 0 ? info.city : info.province;
                        if (that.zoom >= 10) {
                            AMap.plugin("AMap.Weather", function () {
                                var weather = new AMap.Weather();
                                //查询实时天气信息, 查询的城市到行政级别的城市，如朝阳区、杭州市
                                weather.getLive(address, function (err, data) {
                                    that.weatherShow = true;
                                    that.address = address;
                                    if (!err) {
                                        that.weatherText = address + " " + data.weather + " " + data.temperature + "℃";
                                        if (data.weather.indexOf("晴") != -1) {
                                            that.weather = "weather-qing";
                                        } else if (data.weather.indexOf("霾") != -1) {
                                            that.weather = "weather-mai";
                                        } else if (data.weather.indexOf("阴") != -1) {
                                            that.weather = "weather-yin";
                                        } else if (data.weather.indexOf("雨夹雪") != -1) {
                                            that.weather = "weather-yujiaxue";
                                        } else if (data.weather.indexOf("多云") != -1) {
                                            that.weather = "weather-duoyun";
                                        } else if (data.weather.indexOf("雨") != -1) {
                                            that.weather = "weather-yu";
                                        } else if (data.weather.indexOf("雪") != -1) {
                                            that.weather = "weather-xue";
                                        } else if (data.weather.indexOf("尘") != -1) {
                                            that.weather = "weather-fuchen";
                                        } else if (data.weather.indexOf("扬沙") != -1) {
                                            that.weather = "weather-yangsha";
                                        } else if (data.weather.indexOf("冰雹") != -1) {
                                            that.weather = "weather-bingbao";
                                        } else {
                                            that.weather = "";
                                        }
                                    }
                                });
                            });
                        } else if (that.zoom < 10 && that.zoom > 7) {
                            that.weatherShow = false;
                            that.weather = "";
                            that.weatherText = info.province;
                            that.address = info.province;
                        } else {
                            that.weatherShow = false;
                            that.weather = "";
                            that.weatherText = "";
                            that.address = "全国";
                        }
                    }
                    this.loading = false;
                });
            }
        }
    };
</script>

<style lang="less">
    .amap-logo {
        display: none !important;
    }

    #mapGaode {
        background: transparent !important;

        .amap-ranging-label, .amap-marker-content {
            color: #000;
            font-size: 10px;
        }
    }

    .map-operation {
        position: absolute;
        right: 20px;
        top: 50px;
        width: 7rem;
        border-radius: 0.5rem;
        padding: 0 0.4rem;
        cursor: pointer;

        .map-instrument-container {
            height: 36px;
            position: absolute;
            left: -404px;
            bottom: 0;
            display: flex;
            background: rgba(0, 0, 0, 0.6);
            align-items: center;
            padding: 4px 0;

            div {
                padding: 0 4px;
                width: 80px;
                text-align: center;
                height: 100%;
                line-height: 30px;
                font-size: 12px;

                span {
                    padding-left: 4px;
                }

                &:not(:last-child) {
                    border-right: 1px solid #eee;
                }
            }
        }

        .map-switch-btn:hover > .map-switch-container {
            display: block;
        }

        .map-switch-container:hover {
            display: block;
        }

        .map-switch-container {
            display: none;
            position: absolute;
            top: 100%;
            right: 1rem;
            width: calc(100% - 2rem);
            text-align: center;
            border-radius: 5px;
            padding: 0 0.5rem;

            div:first-child {
                border-bottom: 1px solid rgba(231, 231, 231, 0.3);
            }

            div:not(:last-child):hover {
                background: rgba(255, 255, 255, 0.2);
            }

            .shangjiantou {
                position: absolute;
                top: -1.45rem;
                color: rgba(0, 0, 0, 0.7);
                font-size: 1.2rem;
                width: calc(100% - 1rem)
            }
        }

        li {
            height: 2.4rem;
            line-height: 2.4rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding: 0 0.5rem 0 2rem;
        }

        li:not(:last-child) {
            border-bottom: 1px solid rgba(231, 231, 231, 0.3);
        }

        .traffic-show {
            background: url("../../assets/images/common/lukuang-open.png") no-repeat 10% 52%;
            background-size: 10%;
        }

        .traffic-hide {
            background: url("../../assets/images/common/lukuang-close.png") no-repeat 10% 52%;
            background-size: 10%;
        }

        .weather-qing {
            background: url("../../assets/images/common/weather/tianqi-qing.png") no-repeat 6% 52%;
            background-size: 20%;
        }

        .weather-mai {
            background: url("../../assets/images/common/weather/tianqi-mai.png") no-repeat 6% 52%;
            background-size: 20%;
        }

        .weather-yin {
            background: url("../../assets/images/common/weather/tianqi-yin.png") no-repeat 6% 52%;
            background-size: 20%;
        }

        .weather-yujiaxue {
            background: url("../../assets/images/common/weather/tianqi-yujiaxue.png") no-repeat 6% 52%;
            background-size: 20%;
        }

        .weather-duoyun {
            background: url("../../assets/images/common/weather/tianqi-duoyun.png") no-repeat 6% 52%;
            background-size: 20%;
        }

        .weather-yu {
            background: url("../../assets/images/common/weather/tianqi-yu.png") no-repeat 6% 52%;
            background-size: 20%;
        }

        .weather-xue {
            background: url("../../assets/images/common/weather/tianqi-xue.png") no-repeat 6% 52%;
            background-size: 20%;
        }

        .weather-fuchen {
            background: url("../../assets/images/common/weather/tianqi-fuchen.png") no-repeat 6% 52%;
            background-size: 20%;
        }

        .weather-zhenxue {
            background: url("../../assets/images/common/weather/tianqi-zhenxue.png") no-repeat 6% 52%;
            background-size: 20%;
        }

        .weather-leizhenyu {
            background: url("../../assets/images/common/weather/tianqi-leizhenyu.png") no-repeat 6% 52%;
            background-size: 20%;
        }

        .weather-bingbao {
            background: url("../../assets/images/common/weather/tianqi-bingbao.png") no-repeat 6% 52%;
            background-size: 20%;
        }

        .weather-baofengxue {
            background: url("../../assets/images/common/weather/tianqi-baofengxue.png") no-repeat 6% 52%;
            background-size: 20%;
        }

        .weather-yangsha {
            background: url("../../assets/images/common/weather/tianqi-yangsha.png") no-repeat 6% 52%;
            background-size: 20%;
        }
    }
</style>
