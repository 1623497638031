<template>
    <el-dialog
            class="vehicle-assignment"
            title="分配车辆"
            :visible.sync="formVisible"
            width="20rem"
            :close-on-click-modal="false"
    >
        <VehicleTree2
                ref="vehicleTree"
                :treeData="treeData"
                :checkBox="true"
                :treeShowFlag="true"
                iconName='iconsousuo'
                pageType="velAssign"
                @treeChoose="treeChoose"
                @treeExtend="(item) => {this.$Foo.treeExtend(item, vehicleNodeChecked)}"
        />
        <div class="hint" v-if='parentId'>注：当前用户只能给下级分配车辆，否则分配无效</div>
        <div
                slot="footer"
                class="dialog-footer"
        >
            <el-button @click="formVisible = false">取 消</el-button>
            <el-button
                    type="primary"
                    @click="submitForm"
                    v-show="!(parentId && parentId !== userId)"
            >保 存
            </el-button>
        </div>
    </el-dialog>
</template>
<script>
    import {refreshStatus} from "@/api/index";

    export default {
        name: "VehicleAssignment",
        props: {
            parentId: {
                required: false
            },
            treeData: {
                required: true
            },
            treeProps: {
                type: Object,
                default: function () {
                    return {
                        label: "label",
                        value: "value",
                        children: "children"
                    };
                }
            }
        },
        watch: {
            treeData(val) {
                this.treeDataTile = this.$Foo.flatTreeData(val, this);
            }
        },
        data() {
            return {
                treeDataTile: this.$Foo.flatTreeData(this.treeData, this), // 组织树平铺
                userId: this.$store.state.loginInfo.userId,
                treeSearchValue: "",
                timer: "",
                searchTreeData: {
                    list: []
                },
                searchListShow: false,
                noSearchTreeData: false, // 搜索无数据
                formVisible: false,
                vsId: "",
                vehicleNodeChecked: [], // 已选中车辆节点
                delVehicleNodeChecked: [], // 删除车辆节点
            };
        },
        methods: {
            initVehicleAssignment(result, pageType) {
                this.formVisible = true;
                this.vehicleNodeChecked = [];
                this.delVehicleNodeChecked = [];
                this.setCheckedNodes(result, pageType);
            },
			setCheckedNodes(arr, pageType) {
				const _this = this;
				let orgPartialSelect = new Set();
				let newArr = new Map();
				if (arr && arr.length > 0) {
					arr.forEach((item, idx) => {
						newArr.set(item.vid, idx);
						this.vehicleNodeChecked.push(item.vid);
						this.delVehicleNodeChecked.push(item.vid);
						orgPartialSelect.add(item.entId);
						let pathIds = this.$Foo.treeDataObject[item.entId].pathIds;
						for(let i=0;i<pathIds.length;i++){
							if(pathIds[i]!='-1'){
								orgPartialSelect.add(pathIds[i]);
							}
						}
					});
				}
				this.treeDataTile.forEach(item => {
					// this.$set(item, 'checked', false);
					// if (newArr.has(item.value)) {
					// 	this.$set(item, 'checked', true);
					// }
					if(item.value!='-1'){
						delete item.expand;
						delete item.isExpand;	
					}
					this.$set(item, 'checked', false);
					this.$set(item, "partchecked", orgPartialSelect.has(item.value)?true:false);
					if (pageType == 'user') {
						let index = newArr.get(item.value);
						if (index !== undefined && this.userId != arr[index].createUserId) {
							this.$set(item, 'disabled', true);
						}
					}
				});
				//this.toggleCheckBox();
			},			
            submitForm() {
                let params = {
                    vehicleNodeChecked: this.vehicleNodeChecked,
                    delVehicleNodeChecked: this.delVehicleNodeChecked
                };
                this.$emit("handleClick", "assignmentVehicle", params);
            },
            getAllVehicle(item, callback) {
                let params = {
                    entIds: [item.value],
                    isCache: true,
                    selectOrgOwnVeh: false,
                    returnMoreFields: ""
                };
                refreshStatus(params).then(res => {
                    if (res.code === 0 && res.result.length) {
                        callback(res.result);
                    }
                });
            },
            treeChoose(item) {
                let arr = [];
                if (item.type == 2) {
                    if (item.checked) {
                        arr = [item.value, ...this.vehicleNodeChecked];
                        arr = [...new Set(arr)];
                        this.vehicleNodeChecked = arr;
                    } else {
                        this.vehicleNodeChecked.splice(this.vehicleNodeChecked.indexOf(item.value), 1);
                    }
                }
                if (item.type == 1) {
                    this.getAllVehicle(item, (data) => {
                        let arr2 = [];
                        data.forEach(vehicle => {
                            arr2.push(vehicle.v1);
                        });
                        if (item.checked || item.partchecked) {
                            arr = [...arr2, ...this.vehicleNodeChecked];
                            arr = [...new Set(arr)];
                            this.vehicleNodeChecked = arr;
                        } else {
                            this.vehicleNodeChecked = this.vehicleNodeChecked.filter(function (vid) {
                                return arr2.indexOf(vid) == -1;
                            });
                        }
                    });
                }
            },
            treeExtend(item) {
                this.$Foo.treeExtend(item, this.vehicleNodeChecked);
            },
			toggleCheckBox() {
			  // 勾选某一行时，修改父节点及子节点选中状态
			  var that = this;

			  function recursionList(item) {
				var checkedNum = 0;
				var partCheckedNum = 0;
				item.children.forEach(li => {
				  if (li.children && li.children.length > 0) {
					recursionList(li);
				  }
				  if (li.checked === true) {
					checkedNum++;
				  } else if (li.partchecked === true) {
					partCheckedNum++;
				  }
				});
				if (checkedNum === item.children.length) {
				  //全选
				  that.$set(item, 'checked', true);
				  that.$set(item, 'partchecked', false);
				} else if (checkedNum > 0 || partCheckedNum > 0) {
				  //部分勾选
				  that.$set(item, 'checked', false);
				  that.$set(item, 'partchecked', true);
				} else {
				  //没有勾选
				  that.$set(item, 'checked', false);
				  that.$set(item, 'partchecked', false);
				}
			  }

			  recursionList(this.treeData[0]);
			},			
        }
    };
</script>
<style lang="less">
    .vehicle-assignment {
        .tree-search-container {
            height: 100%;
            position: relative;
            z-index: 1;

            .input-icon {
                font-size: 12px;
                padding-right: 5px;
            }

            .el-input__inner {
                height: 34px;
                line-height: 34px;
                padding-right: 24px;
                border: none;
            }

            .tree-item-checkbox {
                color: #ccc;
            }

            .iconCheckboxChecked-1-copy, .iconindeterminatecheckbox-copy {
                color: #409eff;
            }
        }

        .vel-tree-container {
            position: relative;
            width: 100%;
            height: 360px;
            overflow: auto;
            box-shadow: none;
        }

        .hint {
            color: red;
            font-size: 12px;
            padding-top: 4px;
        }

        .el-dialog__wrapper .el-input {
            width: 100%;
        }

        .el-input__suffix {
            line-height: 34px;
        }
    }
</style>
