import request from "@/utils/request";
import request4 from "@/utils/request4";

let userService = "user-service/";
let orgService = "organization-service/";
let velService = "vehicle-service/";
let fileService = "file-service/";
let alarmService = "alarm-service/";
let dispateService = "dispatcher-service/";

// 获取用户
export function getUser(data) {
    return request({
        url: userService + "user/query/current/userPage",
        method: "post",
        data
    });
}

// 删除用户
export function deleteUser(id) {
    return request({
        url: userService + `user/delete/user/${id}`,
        method: "delete"
    });
}

// 保存用户
export function addUser(data) {
    return request({
        url: userService + "user/addUser",
        method: "post",
        data
    });
}

// 修改用户
export function updateUser(data) {
    return request({
        url: userService + "user/updateUser",
        method: "post",
        data
    });
}

// 根据userId查询当前及下级用户id列表
export function userDetailList(id) {
    return request({
        url: userService + `user/query/userTreeOpenList/${id}`,
        method: "get"
    });
}

// 重置密码
export function resetPassword(params) {
    return request({
        url: userService + "user/resetPassword",
        method: "put",
        params
    });
}

// 根据用户查询分配车辆
// /saas/api/vehicle-service/vehicle/user/addBatchUserVehicleRelation
export function addBatchUserVehicleRelation(data) {
    return request({
        url: velService + "vehicle/user/addBatchUserVehicleRelation",
        method: "post",
        data
    });
}
export function addNewBatchUserVehicleRelation(data) {
    return request({
        url: velService + "vehicle/user/addNewBatchUserVehicleRelation",
        method: "post",
        data
    });
}

// 车辆用户关系查询
export function queryOrgVehChoiceRelation(userId) {
    return request({
        url: velService + `vehicle/user/${userId}/queryOrgVehChoiceRelation`,
        method: "get"
    });
}

// 查询用户树-有层级关系
// /saas/api/user-service/user/query/userTree/{userId}
export function userTree(data) {
    return request({
        url: userService + "user/query/userTree/" + data.userId,
        method: "get"
    });
}

// 获取角色下拉框
export function getSelectRole(params) {
    return request({
        url: userService + "sysRoles/query/currentUser/roles",
        method: "get",
        params
    });
}

// 根据角色级别获取角色下拉框
export function queryRoleList(params) {
    return request({
        url: userService + "sysRoles/roleSystem/roleList",
        method: "get",
        params
    });
}

// 获取角色
export function getRole(params) {
    return request({
        url: userService + "sysRoles/query/currentUser/rolePage",
        method: "get",
        params
    });
}

// 删除角色
export function deleteRole(id) {
    return request({
        url: userService + `sysRoles/deleteRole/${id}`,
        method: "delete"
    });
}

// 保存角色
export function saveRole(data) {
    return request({
        url: userService + "sysRolesMenu/addRolesMenus",
        method: "post",
        data
    });
}

// 查询系统默认的角色体系树结构
// /saas/api/user-service/sysRoles/roleSystem/roleLevels
export function queryRoleLevels(params) {
    return request({
        url: userService + "sysRoles/roleSystem/roleLevels",
        method: "get",
        params
    });
}

// 角色树
export function roleTree() {
    return request({
        url: userService + "sysMenu/query/menus/tree",
        method: "get"
    });
}

// 根据roleId查询菜单列表
export function roleMenus(id) {
    return request({
        url: userService + `sysMenu/query/${id}/menus`,
        method: "get"
    });
}

// 添加组织
export function addOrg(data) {
    return request({
        url: velService + `organization/add`,
        method: "post",
        data
    });
}

// 修改组织
export function editOrg(data) {
    return request({
        url: velService + `organization/edit`,
        method: "post",
        data
    });
}

// 获取组织
export function getOrg(data) {
    return request({
        url: velService + `organization/findOrgTree`,
        method: "post",
        data
    });
}

// 修改同级组织的显示顺序
// /saas/api/vehicle-service/userorg/modify/sort
export function orgSort(data) {
    return request({
        url: velService + `userorg/modify/sort`,
        method: "post",
        data
    });
}

// 根据组织id查询组织详情
export function detailOrg(id) {
    return request({
        url: velService + `organization/detail/${id}`,
        method: "get"
    });
}

// 删除组织
export function deleteOrg(id) {
    return request({
        url: velService + `organization/remove/${id}`,
        method: "delete"
    });
}

// 查询车辆列表
export function vehicleList(params, showMask) {
    return request({
        url: velService + "vehicle/list",
        method: "get",
        params,
        showMask
    });
}

// 车辆添加
// /saas/api/vehicle-service/vehicle/add
export function vehicleAdd(data) {
    return request({
        url: velService + "vehicle/add",
        method: "post",
        data
    });
}

// 车辆更新
// /saas/api/vehicle-service/vehicle/modify
export function vehicleModify(data) {
    return request({
        url: velService + "vehicle/modify",
        method: "put",
        data
    });
}

// 车辆删除
// /saas/api/vehicle-service/vehicle/clear
export function vehicleDel(data) {
    return request({
        url: velService + "vehicle/clear",
        method: "delete",
        data
    });
}

// 车辆详情查询
// /saas/api/vehicle-service/vehicle/{id}/details
export function vehicleDeatil(option) {
    return request({
        url: velService + "vehicle/" + option.vid + "/details",
        method: "get"
    });
}

// 车辆导出
export function vehicleExport(params) {
    return request({
        url: velService + "vehicle/exportVehicle",
        method: "get",
        params,
        responseType: "blob",
        contentType: "application/vnd.ms-excel",
        showMask: true
    });
}

// 车辆导入
// 根据在界面上选择的tabIndex调用不同的接口
export function vehicleImport(formData,tabIndex) {
	let arrUrl = ['importFreightVehicle','importSimplifyVehicle'];
    return request4({
        //url: velService + "vehicle/importVehicle",
		url: velService + `vehicle/${arrUrl[tabIndex]}`,
        method: "post",
        data: formData,
        responseType: "blob",
        contentType: "multipart/form-data"
    });
}

// 车主信息导入
// /saas/api/vehicle-service/vehicle/importVehicleOwner
export function vehicleOwnerImport(formData) {
    return request4({
        url: velService + "vehicle/importVehicleOwner",
        method: "post",
        data: formData,
        responseType: "blob",
        contentType: "multipart/form-data"
    });
}

// 绑定北斗运营商
export function modifyVehicleBindAccessUrl(data) {
    return request({
        url: velService + "vehicle/modifyVehicleBindAccess",
        method: "put",
        data
    });
}

// 车辆接入方式
export function modifyVehicleReceiveCodeUrl(data) {
    return request({
        url: velService + "vehicle/modifyVehicleReceiveCode",
        method: "put",
        data
    });
}

// 组织导入
export function orgImport(formData) {
    return request({
        url: velService + "organization/importOrganization",
        method: "post",
        data: formData,
        responseType: "blob",
        contentType: "multipart/form-data"
    });
}

// 码表信息批量查询
// /saas/api/vehicle-service/general/list
export function generalList(data) {
    return request({
        url: userService + "dictionary/list",
        method: "post",
        data: data
    });
}

// 终端厂商查询
// /saas/api/vehicle-service/terminal/oem/dictionary
export function dictionaryOem(params) {
    return request({
        url: velService + "terminal/oem/dictionary",
        method: "get",
        params
    });
}

// 终端类型查询
// /saas/api/vehicle-service/terminal/model/{oemCode}/dictionary
export function dictionaryModel(params) {
    return request({
        url: velService + "terminal/model/" + params.oemCode + "/dictionary",
        method: "get"
    });
}

// 添加驾驶员
export function addDriver(data) {
    return request({
        url: velService + `driver/add`,
        method: "post",
        data
    });
}

// 修改驾驶员
export function editDriver(data) {
    return request({
        url: velService + `driver/edit`,
        method: "post",
        data
    });
}

// 获取驾驶员列表
export function getDriver(data) {
    return request({
        url: velService + `driver/pageList`,
        method: "post",
        data
    });
}

// 驾驶员字典表查询
// /saas/api/vehicle-service/driver/dictionary
// export function getDriverTree() {
//     return request({
//         url: velService + `driver/dictionary`,
//         method: "get"
//     });
// }

// 查询当前用户能查询到的驾驶员字典信息
// /saas/api/vehicle-service/driver/list
export function getDriverTree() {
    return request({
        url: velService + `driver/list`,
        method: "get"
    });
}

// 通过车辆查询驾驶员
// /saas/api/vehicle-service/relation/driver/vehicle/queryDriverVehicleRelationByVid
export function getDriverVehicleRelationByVid(params) {
    return request({
        url: velService + `relation/driver/vehicle/queryDriverVehicleRelationByVid`,
        method: "get",
        params
    });
}

// 车辆添加驾驶员
// /saas/api/vehicle-service/relation/driver/vehicle/addBatchVehicleDriverRelation
export function addBatchVehicleDriverRelation(data) {
    return request({
        url: velService + `relation/driver/vehicle/addBatchVehicleDriverRelation`,
        method: "post",
        data
    });
}

// 根据组织id查询驾驶员详情
export function detailDriver(id) {
    return request({
        url: velService + `driver/detail/${id}`,
        method: "get"
    });
}

// 删除驾驶员
export function deleteDriver(data) {
    return request({
        url: velService + `driver/remove`,
        method: "post",
        data
    });
}

// 导出驾驶员
export function driverExport(data) {
    return request({
        url: velService + `driver/export`,
        method: "post",
        responseType: "blob",
        contentType: "application/vnd.ms-excel",
        data
    });
}

// 上传文件
export function fileUpload(data) {
    return request({
        url: fileService + `file/upload`,
        method: "post",
        data
    });
}

// 文件下载
export function fileDownload(data) {
    return request({
        url: fileService + `file/download`,
        method: "post",
        responseType: "blob",
        "Content-Type": "image/jpeg",
        data
    });
}

// 车辆管理模板下载
export function fileDownloadGet(params) {
    return request({
        url: fileService + `file/downloadGet`,
        method: "get",
        responseType: "blob",
        contentType: "application/vnd.ms-excel",
        params
    });
}

// 获取报警等级列表
export function getAlarmLevel(params) {
    return request({
        url: alarmService + `alarmLevel/queryList`,
        method: "get",
        params
    });
}

// 设置报警等级
export function setAlarmLevel(data) {
    return request({
        url: alarmService + `alarmLevel/set`,
        method: "post",
        data
    });
}

// 添加终端厂商
export function addTerminal(data) {
    return request({
        url: velService + `terminal/oem/addTerminalOem`,
        method: "post",
        data
    });
}

// 修改终端厂商
export function editTerminal(data) {
    return request({
        url: velService + `terminal/oem/modify`,
        method: "put",
        data
    });
}

// 获取终端厂商
export function getTerminal(params) {
    return request({
        url: velService + `terminal/oem/queryTerminalOemPageList`,
        method: "get",
        params
    });
}

// 获取终端厂商详情
export function detailTerminal(id) {
    return request({
        url: velService + `terminal/oem/${id}/details`,
        method: "get"
    });
}

// 删除终端厂商
export function deleteTerminal(id) {
    return request({
        url: velService + `terminal/oem/${id}/clear`,
        method: "delete"
    });
}

// 添加终端型号
export function addTerminalModel(data) {
    return request({
        url: velService + `terminal/model/addTerminalModel`,
        method: "post",
        data
    });
}

// 修改终端型号
export function editTerminalModel(data) {
    return request({
        url: velService + `terminal/model/modify`,
        method: "put",
        data
    });
}

// 获取终端型号
export function getTerminalModel(params) {
    return request({
        url: velService + `terminal/model/queryTerminalModelPageList`,
        method: "get",
        params
    });
}

// 获取终端型号详情
export function detailTerminalModel(id) {
    return request({
        url: velService + `terminal/model/${id}/details`,
        method: "get"
    });
}

// 删除终端型号
export function deleteTerminalModel(id) {
    return request({
        url: velService + `terminal/model/${id}/clear`,
        method: "delete"
    });
}


// 添加报警规则
export function addAlarmRule(data) {
    return request({
        url: alarmService + `alarmRule/saveRule`,
        method: "post",
        data
    });
}

// 修改报警规则
export function editAlarmRule(data) {
    return request({
        url: alarmService + `alarmRule/updateRule`,
        method: "post",
        data
    });
}

// 获取报警规则
export function getAlarmRule(data) {
    return request({
        url: alarmService + `alarmRule/query/user/list`,
        method: "post",
        data
    });
}

// 获取报警规则详情
export function detailAlarmRule(id) {
    return request({
        url: alarmService + `alarmRule/query/rule/${id}`,
        method: "get"
    });
}

// 删除报警规则
export function deleteAlarmRule(data) {
    return request({
        url: alarmService + `alarmRule/deleteeRule`,
        method: "delete",
        data
    });
}

// 报警规则树
export function queryRuleTree(params) {
    return request({
        url: alarmService + `alarmRule/queryRuleTree`,
        method: "get",
        params
    });
}

// 车辆绑定报警规则
export function saveVidBindRule(data) {
    return request({
        url: alarmService + `alarmRule/saveVidBindRule`,
        method: "post",
        data
    });
}

// 规则绑定报警车辆
export function saveRuleBindVid(data) {
    return request({
        url: alarmService + `alarmRule/saveRuleBindVid`,
        method: "post",
        data
    });
}

// 获取规则绑定的车辆
export function queryBindByRule(params) {
    return request({
        url: alarmService + `alarmRule/queryBindByRule`,
        method: "get",
        params
    });
}

// 获取车辆绑定的规则
export function queryBindByVid(params) {
    return request({
        url: alarmService + `alarmRule/queryBindByVid`,
        method: "get",
        params
    });
}

// 获取报警提醒
export function getWarnList(data) {
    return request({
        url: alarmService + `alarmWarn/list`,
        method: "post",
        data
    });
}

// 报警提醒设置
export function modifyAlarmWarn(data) {
    return request({
        url: alarmService + `alarmWarn/set`,
        method: "post",
        data
    });
}

// 获取报警提醒全查询
export function queryAlarmWarn(params) {
    return request({
        url: alarmService + `alarmWarn/queryAlarmWarn`,
        method: "get",
        params
    });
}

// 查询当前用户报警类型树
export function queryTreeOwn(params) {
    return request({
        url: alarmService + `alarmType/queryTree/own`,
        method: "get",
        params
    });
}

// 添加视频服务器
export function addVideoServer(data) {
    return request({
        url: velService + `videoServer/save`,
        method: "post",
        data
    });
}

// 修改视频服务器
export function editVideoServer(data) {
    return request({
        url: velService + `videoServer/update`,
        method: "post",
        data
    });
}

// 获取视频服务器详情
export function detailVideoServer(params) {
    return request({
        url: velService + `videoServer/detail`,
        method: "get",
        params
    });
}

// 删除视频服务器
export function deleteVideoServer(params) {
    return request({
        url: velService + `videoServer/delete`,
        method: "delete",
        params
    });
}

// 获取视频服务器列表
export function getVideoServer(data) {
    return request({
        url: velService + `videoServer/currentUser/pageList`,
        method: "post",
        data
    });
}

// 车辆绑定视频服务器
export function saveBindVid(data) {
    return request({
        url: velService + `vehicleVideo/saveBindVid`,
        method: "post",
        data
    });
}

// 通过vid查询车辆绑定的视频服务器
export function queryVideoServerByVid(params) {
    return request({
        url: velService + `vehicleVideo/queryVideoServerByVid`,
        method: "get",
        params
    });
}

// 通过视频服务器vsId，查询绑定的车辆
export function queryBindByServerId(params) {
    return request({
        url: velService + `vehicleVideo/queryBindByServerId`,
        method: "get",
        params
    });
}

// 添加转发服务
export function addForwardServer(data) {
    return request({
        url: velService + `report/809/add`,
        method: "post",
        data
    });
}

// 修改转发服务
export function editForwardServer(data) {
    return request({
        url: velService + `report/809/modify`,
        method: "post",
        data
    });
}

// 获取转发服务详情
export function detailForwardServer(params) {
    return request({
        url: velService + `report/809/details`,
        method: "get",
        params
    });
}

// 删除转发服务
export function deleteForwardServer(params) {
    return request({
        url: velService + `report/809/clear`,
        method: "get",
        params
    });
}

// 获取转发服务列表
export function getForwardServer(data) {
    return request({
        url: velService + `report/809/list`,
        method: "post",
        data
    });
}

// 809上报-标准绑定
export function addOrRemoveReportRelation(data) {
    return request({
        url: velService + `relation/809/report/addOrRemoveReportRelation`,
        method: "post",
        data
    });
}

// 809上报-标准绑定查询
export function queryReportRelationById(params) {
    return request({
        url: velService + `relation/809/report/queryReportRelationById`,
        method: "get",
        params
    });
}

// 809车辆注册
// /saas/api/dispatcher-service/jgPlatformQuery/vehicle/registry/send
export function registrySend(data) {
    return request({
        url: dispateService + `jgPlatformQuery/vehicle/registry/send`,
        method: "post",
        data
    });
}

// 808-添加转发服务
export function add808ForServer(data) {
    return request({
        url: velService + `report/808/add`,
        method: "post",
        data
    });
}

// 808-修改转发服务
export function edit808ForServer(data) {
    return request({
        url: velService + `report/808/modify`,
        method: "post",
        data
    });
}

// 808-获取转发服务详情
export function detail808ForServer(params) {
    return request({
        url: velService + `report/808/${params}/details`,
        method: "get"
    });
}

// 808-删除转发服务
export function delete808ForServer(params) {
    return request({
        url: velService + `report/808/${params}/clear`,
        method: "delete"
    });
}

// 808-获取转发服务列表
export function get808ForServer(data) {
    return request({
        url: velService + `report/808/list`,
        method: "post",
        data
    });
}

// 808上报-标准绑定
export function add808OrRemoveReportRelation(data) {
    return request({
        url: velService + `relation/808/report/addOrRemoveReportRelation`,
        method: "post",
        data
    });
}

// 808上报-标准绑定查询
export function query808ReportRelationById(params) {
    return request({
        url: velService + `relation/808/report/queryReportRelationById`,
        method: "get",
        params
    });
}

// 808转发服务列表
// /saas/api/vehicle-service/forward/808/list
export function forward808List(data) {
    return request({
        url: velService + `forward/808/list`,
        method: "post",
        data
    });
}

// 808转发服务新增
// /saas/api/vehicle-service/forward/808/add
export function forward808Add(data) {
    return request({
        url: velService + `forward/808/add`,
        method: "post",
        data
    });
}

// 808转发服务修改
// /saas/api/vehicle-service/forward/808/modify
export function forward808Modify(data) {
    return request({
        url: velService + `forward/808/modify`,
        method: "post",
        data
    });
}

// 808转发服务删除
// /saas/api/vehicle-service/forward/808/{forwardId}/clear
export function forward808Clear(forwardId) {
    return request({
        url: velService + `forward/808/${forwardId}/clear`,
        method: "delete"
    });
}

// 808转发服务详情
// /saas/api/vehicle-service/forward/808/{forwardId}/details
export function forward808Detail(forwardId) {
    return request({
        url: velService + `forward/808/${forwardId}/details`,
        method: "get"
    });
}

// 808转发绑定车辆
// /saas/api/vehicle-service/relation/808/forward/addOrRemoveForwardRelation
export function editForward808Relation(data) {
    return request({
        url: velService + `relation/808/forward/addOrRemoveForwardRelation`,
        method: "post",
        data
    });
}

// 808转发查询绑定车辆
// /saas/api/vehicle-service/relation/808/forward/queryForwardRelationById
export function queryForward808Relation(params) {
    return request({
        url: velService + `relation/808/forward/queryForwardRelationById`,
        method: "get",
        params
    });
}


export function bindRelation808(data) {
    return request({
        url: velService + `forward/808/bind`,
        method: "post",
        data
    });
}

//808转发查询绑定车辆
export function queryBindRelation808(params) {
    return request({
        url: velService + `forward/808/queryBindRelation`,
        method: "get",
        params
    });
}
//809转发查询绑定车辆 
export function queryBindRelation809(params) {
    return request({
        url: velService + `report/809/queryBindRelation`,
        method: "get",
        params
    });
}
export function bindRelation809(data) {
    return request({
        url: velService + `report/809/bind`,
        method: "post",
        data
    });
}


// 添加接入服务
export function addAccessServer(data) {
    return request({
        url: userService + `access/add`,
        method: "post",
        data
    });
}

// 修改接入服务
export function editAccessServer(data) {
    return request({
        url: userService + `access/modify`,
        method: "post",
        data
    });
}

// 获取接入服务详情
export function detailAccessServer(params) {
    return request({
        url: userService + `access/detail`,
        method: "get",
        params
    });
}

// 删除接入服务
export function deleteAccessServer(params) {
    return request({
        url: userService + `access/clear`,
        method: "delete",
        params
    });
}

// 获取接入服务列表
export function getAccessServer(data) {
    return request({
        url: userService + `access/list`,
        method: "post",
        data
    });
}

// 获取接入服务配置下拉框
export function queryAccessDictionaryUrl() {
    return request({
        url: userService + `access/queryAccessDictionary`,
        method: "get"
    });
}

// 查询接入状态通知列表
export function addAccessNotify(data) {
    return request({
        url: userService + `access/addAccessNotify`,
        method: "post",
        data
    });
}

// 增加接入状态通知
export function queryAccessNotifyList(data) {
    return request({
        url: userService + `access/queryAccessNotifyList`,
        method: "post",
        data
    });
}

// 多车终端参数设置
export function setTerminalParamUrl(data) {
    return request({
        url: dispateService + `command/terminal/params/setting`,
        method: "post",
        data
    });
}

// 多车终端控制指令设置
export function setTerminalControlUrl(data) {
    return request({
        url: dispateService + `command/terminal/command/send`,
        method: "post",
        data
    });
}

// 多车终端参数获取
export function gettingTerminalParamUrl(data) {
    return request({
        url: dispateService + `command/terminal/params/getting`,
        method: "post",
        data
    });
}

// /terminal/driving/setting
export function settingDrivingParamUrl(data) {
    return request({
        url: dispateService + `command/terminal/driving/setting`,
        method: "post",
        data
    });
}

// /terminal/driving/getting
export function gettingDrivingParamUrl(data) {
    return request({
        url: dispateService + `command/terminal/driving/getting`,
        method: "post",
        data
    });
}

// 用户终端参数回显查询
export function getTerminalParamUrl(params) {
    return request({
        url: dispateService + `command/terminal/params/query`,
        method: "get",
        params
    });
}

// 轮询终端参数设置响应接口
export function getTerminalParamAnswerUrl(data) {
    return request({
        url: dispateService + `command/terminal/params/answer/list`,
        method: "post",
        data
    });
}

// 查询终端参数设置响应分页接口
export function getTerminalPageListUrl(data) {
    return request({
        url: dispateService + `command/terminal/params/answer/pageList`,
        method: "post",
        data
    });
}
// 查询终端参数离线待发分页接口
export function getOfflineSentListUrl(data) {
    return request({
        url: dispateService + `command/terminal/params/queryOfflineSentList`,
        method: "post",
        data
    });
}

// 菜单列表树
// /saas/api/user-service/sysMenu/queryMenuTreeList
export function menuTreeList(params) {
    return request({
        url: userService + `sysMenu/queryMenuTreeList`,
        method: "get",
        params
    });
}

// 管理列菜单树
// /saas/api/user-service/sysMenu/queryManageMenuTree
export function manageMenuTree(params) {
    return request({
        url: userService + `sysMenu/queryManageMenuTree`,
        method: "get",
        params
    });
}

// 菜单新增
// /saas/api/user-service/sysMenu/addMenu
export function addMenu(data) {
    return request({
        url: userService + `sysMenu/addMenu`,
        method: "post",
        data
    });
}

// 菜单更新
// /saas/api/user-service/sysMenu/addMenu
export function modifyMenu(data) {
    return request({
        url: userService + `sysMenu/modifyMenu`,
        method: "put",
        data
    });
}

// 菜单详情查询
// /saas/api/user-service/sysMenu/{id}/details
export function detailMenu(id) {
    return request({
        url: userService + `sysMenu/${id}/details`,
        method: "get"
    });
}

// 菜单删除
// /saas/api/user-service/sysMenu/{id}/clear
export function deleteMenu(id) {
    return request({
        url: userService + `sysMenu/${id}/clear`,
        method: "delete"
    });
}

// 字典码表列表查询
// /saas/api/user-service/dictionary/pageList
export function dictionaryPageList(data) {
    return request({
        url: userService + `dictionary/pageList`,
        method: "post",
        data
    });
}

// 字典码表新增
// /saas/api/user-service/dictionary/add
export function dictionaryAdd(data) {
    return request({
        url: userService + `dictionary/add`,
        method: "post",
        data
    });
}

// 字典码表修改
// /saas/api/user-service/dictionary/modify
export function dictionaryModify(data) {
    return request({
        url: userService + `dictionary/modify`,
        method: "put",
        data
    });
}

// 字典码表删除
// /saas/api/user-service/dictionary/pageList
export function dictionaryDelete(id) {
    return request({
        url: userService + `dictionary/${id}/clear`,
        method: "delete"
    });
}

// 字典码表详情查询
// /saas/api/user-service/dictionary/pageList
export function dictionaryDetails(id) {
    return request({
        url: userService + `dictionary/${id}/details`,
        method: "get"
    });
}

// 809上报-标准绑定车辆树
export function queryRelationTreeUrl(params) {
    return request({
        url: velService + `relation/809/report/queryReportRelationTree`,
        method: "get",
        params
    });
}

// 用户终端参数回显查询
export function addReissuePositionUrl(data) {
    return request({
        url: dispateService + `exchange/addReissuePosition`,
        method: "post",
        data
    });
}

// 模板列表查询
export function getTemplateListUrl(params) {
    return request({
        url: userService + `notify/template/queryTemplatePageList`,
        method: "get",
        params
    });
}

// 模板新增
export function addTemplateUrl(data) {
    return request({
        url: userService + `notify/template/add`,
        method: "post",
        data
    });
}

// 模板修改
export function updateTemplateUrl(data) {
    return request({
        url: userService + `notify/template/modify`,
        method: "put",
        data
    });
}

// 模板删除
export function deleteTemplateUrl(id) {
    return request({
        url: userService + `notify/template/${id}/clear`,
        method: "delete"
    });
}

// 模板详情查询
export function queryTemplateByIdUrl(id) {
    return request({
        url: userService + `notify/template/${id}/queryTemplateById`,
        method: "get"
    });
}

// 模板信息审核
export function reviewTemplateUrl(data, id) {
    return request({
        url: userService + `notify/template/${id}/modifyAuditTemplate`,
        method: "put",
        data
    });
}

// 模板信息查询
export function queryTemplateByTypeUrl(params) {
    return request({
        url: userService + `notify/template/queryTemplateByType`,
        method: "get",
        params
    });
}

// 短信平台配置查询
// /saas/api/user-service/sms/config/querySmsConfigPageList
export function querySmsConfigPageList(params) {
    return request({
        url: userService + `sms/config/querySmsConfigPageList`,
        method: "get",
        params
    });
}

// 短信平台配置添加
// /saas/api/user-service/sms/config/addSmsConfig
export function addSmsConfig(data) {
    return request({
        url: userService + `sms/config/addSmsConfig`,
        method: "post",
        data
    });
}

// 短信平台配置删除
// /saas/api/user-service/sms/config/clearSmsConfig/{smsConfigId}
export function clearSmsConfig(smsConfigId) {
    return request({
        url: userService + `sms/config/clearSmsConfig/${smsConfigId}`,
        method: "delete"
    });
}

// 用户短信配置查询
// /saas/api/user-service/sms/summary/queryUserSmsConfig
export function queryUserSmsConfig(params) {
    return request({
        url: userService + `sms/summary/queryUserSmsConfig`,
        method: "get",
        params
    });
}

// 车辆发送短信与用户关系查询
// /saas/api/user-service/sms/relation/querySmsRelation
export function querySmsRelation() {
    return request({
        url: userService + `sms/relation/querySmsRelation`,
        method: "post"
    });
}

// 车辆发送短信与用户关系添加或删除
// /saas/api/user-service/sms/relation/addOrModifySmsRelation
export function addOrModifySmsRelation(data) {
    return request({
        url: userService + `sms/relation/addOrModifySmsRelation`,
        method: "post",
        data
    });
}

// 短信模板配置查询
// /saas/api/user-service/sms/template/{smsConfigId}/querySmsTemplate
export function querySmsTemplate(params) {
    return request({
        url: userService + `sms/template/${params.smsConfigId}/querySmsTemplate`,
        method: "get",
        params
    });
}

// 短信模板配置添加
// /saas/api/user-service/sms/template/addSmsTemplate
export function addSmsTemplate(data) {
    return request({
        url: userService + `sms/template/addSmsTemplate`,
        method: "post",
        data
    });
}

// 短信模板配置删除
// /saas/api/user-service/sms/template/clearSmsTemplate/{smsTemplateId}
export function clearSmsTemplate(smsTemplateId) {
    return request({
        url: userService + `sms/template/clearSmsTemplate/${smsTemplateId}`,
        method: "delete"
    });
}

// 短信分配新增
// /saas/api/user-service/sms/allocate/detail/addSmsAllocateDetail
export function addSmsAllocateDetail(data) {
    return request({
        url: userService + `sms/allocate/detail/addSmsAllocateDetail`,
        method: "post",
        data
    });
}

// 短信分配明细查询
// /saas/api/user-service/sms/allocate/detail/querySmsAllocateDetail
export function querySmsAllocateDetail(params) {
    return request({
        url: userService + `sms/allocate/detail/querySmsAllocateDetail`,
        method: "get",
        params
    });
}

// 短信充值明细查询
// /saas/api/user-service/sms/allocate/detail/querySmsRechargeDetail
export function querySmsRechargeDetail(params) {
    return request({
        url: userService + `sms/allocate/detail/querySmsRechargeDetail`,
        method: "get",
        params
    });
}

// 短信个人汇总信息查询
// /saas/api/user-service/sms/summary/querySmsSummary/{smsConfigId}
export function querySmsSummary(smsConfigId) {
    return request({
        url: userService + `sms/summary/querySmsSummary/${smsConfigId}`,
        method: "get"
    });
}

// 短信分配回收
// /saas/api/user-service/sms/allocate/detail/reclaimSmsCount/{smsSummaryId}/{id}
export function reclaimSmsCount({smsSummaryId, id}) {
    return request({
        url: userService + `sms/allocate/detail/reclaimSmsCount/${smsSummaryId}/${id}`,
        method: "put"
    });
}

// 设置短信下发使用平台配置
// /saas/api/user-service/sms/summary/modifySmsSendWay/{smsSummaryId}
export function modifySmsSendWay(smsSummaryId) {
    return request({
        url: userService + `sms/summary/modifySmsSendWay/${smsSummaryId}`,
        method: "put"
    });
}

// 短信发送明细查询
// /saas/api/user-service/sms/send/querySmsSendDetails
export function querySmsSendDetails(params) {
    return request({
        url: userService + `sms/send/querySmsSendDetails`,
        method: "get",
        params
    });
}

// 短信发送明细查询
// /saas/api/user-service/sms/send/exportSmsSendDetails
export function exportSmsSendDetails(data) {
    return request({
        url: userService + `sms/send/exportSmsSendDetails`,
        method: "post",
        data,
        responseType: "blob",
        contentType: "application/vnd.ms-excel"
    });
}

// 获取视频服务器列表
export function accessVerifyUrl(params) {
    return request({
        url: velService + `vehicle/realTimeVideo/accessVerify`,
        method: "get",
        params
    });
}

// 短信下发
export function sendSmsUrl(data) {
    return request({
        url: dispateService + `notify/record/sendSms`,
        method: "post",
        data
    });
}


// 自定义转发列表查询
export function getCustomListUrl(params) {
    return request({
        url: velService + `custom/forward/queryCustomForwardPageList`,
        method: "get",
        params
    });
}

// 自定义转发新增
export function addCustomUrl(data) {
    return request({
        url: velService + `custom/forward/add`,
        method: "post",
        data
    });
}

// 自定义转发修改
export function updateCustomUrl(data) {
    return request({
        url: velService + `custom/forward/modify`,
        method: "put",
        data
    });
}

// 自定义转发删除
export function deleteCustomUrl(id) {
    return request({
        url: velService + `custom/forward/${id}/clear`,
        method: "delete"
    });
}

// 自定义转发详情查询
export function detailsCustomUrl(id) {
    return request({
        url: velService + `custom/forward/${id}/details`,
        method: "get"
    });
}

// 自定义转发绑定查询
export function getCustomBindListUrl(params) {
    return request({
        url: velService + `relation/custom/forward/queryCustomForwardRelationById`,
        method: "get",
        params
    });
}

// 自定义转发绑定
export function addCustombindUrl(data) {
    return request({
        url: velService + `relation/custom/forward/addOrRemoveCustomForwardRelation`,
        method: "post",
        data
    });
}

// 保存反馈
export function feedbackAnswerUrl(data) {
    return request({
        url: userService + `userFeedback/answer`,
        method: "post",
        data
    });
}

// 投诉建议列表
export function feedBackListUrl(data) {
    return request({
        url: userService + `userFeedback/query/pageList`,
        method: "post",
        data
    });
}

// 日志管理
export function operatelogListUrl(data, business) {
    return request({
        url: userService + `operatelog/query/${business}/pageList`,
        method: "post",
        data
    });
}

// 车辆日志列表
// /saas/api/user-service/operatelog/query/vehicle/list
export function vehicleLogUrl(data) {
    return request({
        url: userService + `operatelog/query/vehicle/list`,
        method: "post",
        data
    });
}

// 用户日志列表
// /saas/api/user-service/operatelog/query/user/list
export function userLogUrl(data) {
    return request({
        url: userService + `operatelog/query/user/list`,
        method: "post",
        data
    });
}

// 投诉建议列表
export function logTreeUrl(params) {
    return request({
        url: userService + `operatelog/query/opraterType`,
        method: "get",
        params
    });
}

// 车辆类型图标回显查询
// /saas/api/user-service/vehicleType/icon/list
export function iconList() {
    return request({
        url: userService + `vehicleType/icon/list`,
        method: "get"
    });
}

// 批量保存图标
// /saas/api/user-service/vehicleType/icon/save
export function iconSave(data) {
    return request({
        url: userService + `vehicleType/icon/save`,
        method: "post",
        data
    });
}

// 删除车辆类型图标
// /saas/api/user-service/vehicleType/icon/delete/{id}
export function iconDelete(id) {
    return request({
        url: userService + `vehicleType/icon/delete/${id}`,
        method: "delete"
    });
}


// 升级包列表查询
export function getUpgradeListUrl(params) {
    return request({
        url: velService + `upgrade/package/manage/queryUpgradePackagePageList`,
        method: "get",
        params
    });
}

// 升级包新增
export function addUpgradeUrl(data) {
    return request({
        url: velService + `upgrade/package/manage/addUpgradePackage`,
        method: "post",
        data
    });
}

// 升级包修改
export function updateUpgradeUrl(data) {
    return request({
        url: velService + `upgrade/package/manage/modifyUpgradePackage`,
        method: "put",
        data
    });
}

// 升级包删除
export function deleteUpgradeUrl(id) {
    return request({
        url: velService + `upgrade/package/manage/${id}/clear`,
        method: "delete"
    });
}

// 升级包详情查询
export function detailsUpgradeUrl(id) {
    return request({
        url: velService + `upgrade/package/manage/${id}/details`,
        method: "get"
    });
}


// 升级历史列表查询
export function getUpgradeTaskDetailListUrl(params) {
    return request({
        url: velService + `upgrade/taskDetail/queryUpgradeTaskDetailPageList`,
        method: "get",
        params
    });
}


// 升级任务列表查询
export function queryUpgradeTaskListUrl(params) {
    return request({
        url: velService + `upgrade/task/queryUpgradeTaskPageList`,
        method: "get",
        params
    });
}

// 升级任务包新增
export function addUpgradeTaskUrl(data) {
    return request({
        url: velService + `upgrade/task/addUpgradeTask`,
        method: "post",
        data
    });
}

// 升级任务包修改
export function updateUpgradeTaskUrl(data) {
    return request({
        url: velService + `upgrade/task/modifyUpgradeTask`,
        method: "put",
        data
    });
}

// 升级任务包删除
export function deleteUpgradeTaskUrl(id) {
    return request({
        url: velService + `upgrade/task/${id}/clear`,
        method: "delete"
    });
}

// 升级任务包详情查询
export function detailsUpgradeTaskUrl(id) {
    return request({
        url: velService + `upgrade/task/${id}/details`,
        method: "get"
    });
}

// 升级包上传文件
export function uploadMultipartFile(data) {
    return request({
        url: fileService + `file/uploadMultipartFile`,
        method: "post",
        data
    });
}

// 失败任务再执行
export function failedUpgradeTaskUrl(id) {
    return request({
        url: dispateService + `command/${id}/executeFailedUpgradeTask`,
        method: "get"
    });
}

// 升级任务执行
export function executeUpgradeTaskUrl(id) {
    return request({
        url: dispateService + `command/${id}/executeUpgradeTask`,
        method: "get"
    });
}

// 升级任务明细
export function queryUpgradeTaskDetailListUrl(id) {
    return request({
        url: velService + `upgrade/taskDetail/${id}/queryUpgradeTaskDetailList`,
        method: "get"
    });
}

// api账号管理列表查询
export function queryApiAccountListUrl(data) {
    return request({
        url: userService + `apiAccount/page/list`,
        method: "post",
        data
    });
}

// api账号管理新增
export function addApiAccountUrl(data) {
    return request({
        url: userService + `apiAccount/add`,
        method: "post",
        data
    });
}

// api账号管理修改
export function updateApiAccountUrl(data) {
    return request({
        url: userService + `apiAccount/edit`,
        method: "post",
        data
    });
}

// api账号管理删除
export function deleteApiAccountUrl(id) {
    return request({
        url: userService + `apiAccount/delete/${id}`,
        method: "delete"
    });
}

// api账号管理详情查询
export function detailsApiAccountUrl(id) {
    return request({
        url: userService + `apiAccount/details/${id}`,
        method: "get"
    });
}

// 随机生成API的账号密码
export function authApiAccountUrl(params) {
    return request({
        url: userService + `apiAccount/generate/auth`,
        method: "get",
        params
    });
}

// 查询可配置API账号的用户列表
export function apiAccountUserUrl(params) {
    return request({
        url: userService + `apiAccount/userList`,
        method: "get",
        params
    });
}


// 终端参数事件列表查询
export function queryEventUrl(data) {
    return request({
        url: userService + `event/page/list`,
        method: "post",
        data
    });
}

// 终端参数事件新增
export function addEventUrl(data) {
    return request({
        url: userService + `event/add`,
        method: "post",
        data
    });
}

// 终端参数事件修改
export function updateEventUrl(data) {
    return request({
        url: userService + `event/edit`,
        method: "post",
        data
    });
}

// 终端参数事件删除
export function deleteEventUrl(id) {
    return request({
        url: userService + `event/delete/${id}`,
        method: "delete"
    });
}

// 终端参数菜单列表查询
export function queryMenuListUrl(data) {
    return request({
        url: userService + `menu/page/list`,
        method: "post",
        data
    });
}

// 终端参数菜单新增
export function addMenuUrl(data) {
    return request({
        url: userService + `menu/add`,
        method: "post",
        data
    });
}

// 终端参数菜单修改
export function updateMenuUrl(data) {
    return request({
        url: userService + `menu/edit`,
        method: "post",
        data
    });
}

// 终端参数菜单删除
export function deleteMenuUrl(id) {
    return request({
        url: userService + `menu/delete/${id}`,
        method: "delete"
    });
}


// 终端参数菜单短语查询
export function queryPhraseListUrl(data) {
    return request({
        url: userService + `phrase/page/list`,
        method: "post",
        data
    });
}

// 终端参数短语新增
export function addPhraseUrl(data) {
    return request({
        url: userService + `phrase/add`,
        method: "post",
        data
    });
}

// 终端参数短语修改
export function updatePhraseUrl(data) {
    return request({
        url: userService + `phrase/edit`,
        method: "post",
        data
    });
}

// 终端参数短语删除
export function deletePhraseUrl(id) {
    return request({
        url: userService + `phrase/delete/${id}`,
        method: "delete"
    });
}

// 查询默认的报警查询类型
// /saas/api/alarm-service/alarm/viewPermission/defaultList
export function alarmAuthDefaultList() {
    return request({
        url: alarmService + `alarm/viewPermission/defaultList`,
        method: "get"
    });
}

// 保存查看报警权限
// /saas/api/alarm-service/alarm/viewPermission/save
export function alarmAuthSave(data) {
    return request({
        url: alarmService + `alarm/viewPermission/save`,
        method: "post",
        data
    });
}

// 查询子用户勾选的报警类型(用于回显)
// /saas/api/alarm-service/alarm/viewPermission/child/list
export function alarmAuthChildList(params) {
    return request({
        url: alarmService + `alarm/viewPermission/child/list`,
        method: "get",
        params
    });
}

// 查询当前用户可查看的报警类型
// /saas/api/alarm-service/alarm/viewPermission/list
export function alarmAuthList(params) {
    return request({
        url: alarmService + `alarm/viewPermission/list`,
        method: "get"
    });
}
// 查询角色模式下当前登录用户角色树
export function getCurrentUserRoleTree(data) {
    return request({
        url: userService + "sysRoles/query/currentUser/roleTree",
        method: "get",
        data
    });
}

// 导出车辆日报表明细
export function exportParamsAnswer(data) {
    return request({
        url: dispateService + `command/terminal/params/answer/export`,
        method: "post",
        data,
        responseType: "blob",
        contentType: "application/vnd.ms-excel",
        showMask: true
    });
}

// 查询油箱信息详情
export function getOilBoxDetail(data) {
    return request({
        url: velService + "oilbox/detail?vid=" + data.vid,
        method: "get",
    });
}
// 查询油耗标定参考车辆列表
export function queryReferVehicleList(data) {
    return request({
        url: velService + "oilbox/QueryReferVehicleList?vehicleNo=" + data.vehicleNo,
        method: "get",
    });
}
// 增加油箱信息
export function saveOilBox(data) {
    return request({
        url: velService + "oilbox/save",
        method: "post",
        data
    });
}

// 查询加油标定信息
export function getRefuelCalibrationDetail(data) {
    return request({
        url: velService + "refuelCalibration/detail?vid=" + data.vid + '&tank=' + data.tank,
        method: "get",
    });
}
// 保存油耗标定
export function saveRefuelCalibrationCalibration(data) {
    return request({
        url: velService + "refuelCalibration/saveCalibration",
        method: "post",
        data
    });
}
// 增加加油标定
export function saveRefuelCalibration(data) {
    return request({
        url: velService + "refuelCalibration/save",
        method: "post",
        data
    });
}
// 删除加油小票标定信息
export function deleteRefuelCalibration(data) {
    return request({
        url: velService + "refuelCalibration/delete?id=" + data.id,
        method: "get",
    });
}
