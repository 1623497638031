<template>
	<div style="width: 100%;height: 100%;">
		<div id="mapGaode" style="height: 100%;"></div>
		<ul class="map-operation">
			<li @click="satelliteMap">{{ isSatelliteMap ? "普通地图" : "卫星地图" }}</li>
			<li :class="isTrafficState ? 'traffic-show' : 'traffic-hide'" @click="trafficClick">路况事件</li>
			<li :title="weatherText" :class="weather">{{ address }}</li>
			<li class="map-instrument-btn" @click="mapInstrumentShow = !mapInstrumentShow">
				地图工具
				<div v-if="mapInstrumentShow" class="map-instrument-container">
					<div class="iconfont iconshitucemianji" @click="mapInstrumentClick('1')"><span>测面</span></div>
					<div class="iconfont iconfangda" @click="mapInstrumentClick('2')"><span>放大</span></div>
					<div class="iconfont iconsuoxiao" @click="mapInstrumentClick('3')"><span>缩小</span></div>
					<div class="iconfont iconranging_line" @click="mapInstrumentClick('4')"><span>测距</span></div>
					<div class="iconfont iconclose" @click="mapInstrumentClick('5')"><span>清除工具</span></div>
				</div>
			</li>
			<li v-if="page == 'realtimeMonitor'" class="map-switch-btn">
				地图切换
				<div class="map-switch-container">
					<div @click="mapSwitchClick('1')">高德地图</div>
					<div @click="mapSwitchClick('2')">百度地图</div>
					<div class="iconfont iconshangjiantou shangjiantou"></div>
				</div>
			</li>
		</ul>
	</div>
</template>

<script>
export default {
	name: "SAASAMap",
	props: ['page'],
	data() {
		return {
			isTrafficState: false,
			weather: "",
			isSatelliteMap: false,
			satelliteLayer: "",
			roadNetLayer: "",
			trafficLayer: "",
			markers: [],
			address: "",
			zoom: "",
			weatherText: "",
			map: "",
			mouseTool: "",
			mapInstrumentShow: false
		};
	},
	computed: {
		monitorSkin() {
			return this.$store.state.userConfig.monitorSkin;
		}
	},
	watch: {
		monitorSkin: {
			handler(newVal) {
				if (newVal == "heiseshangwu") {
					this.map.setMapStyle("amap://styles/de358bfdbed9c341185a8ca84aa0d3e9");
				} else if (newVal == "shenhaiweilan") {
					this.map.setMapStyle("amap://styles/a20318f8ed3d53ac45bb7acb1d878f10");
				} else if (newVal == "baisejianyue" || newVal == "tiankongzhilan") {
					this.map.setMapStyle("amap://styles/normal");
				}
			}
		}
	},
	mounted() {
		this.setMap();
	},
	methods: {
		mapInstrumentClick(type) {
			switch (type) {
				case "1": {
					this.mouseTool.measureArea({
						strokeColor: "#80d8ff",
						fillColor: "#80d8ff",
						fillOpacity: 0.3
						//同 Polygon 的 Option 设置
					});
					break;
				}
				case "2": {
					this.mouseTool.rectZoomIn({
						strokeColor: "#80d8ff",
						fillColor: "#80d8ff",
						fillOpacity: 0.3
						//同 Polygon 的 Option 设置

					});
					break;
				}
				case "3": {
					this.mouseTool.rectZoomOut({
						strokeColor: "#80d8ff",
						fillColor: "#80d8ff",
						fillOpacity: 0.3
						//同 Polygon 的 Option 设置
					});
					break;
				}
				case "4": {
					this.mouseTool.rule({
						startMarkerOptions: {//可缺省
							icon: new AMap.Icon({
								size: new AMap.Size(19, 31),//图标大小
								imageSize: new AMap.Size(19, 31),
								image: "https://webapi.amap.com/theme/v1.3/markers/b/start.png"
							})
						},
						endMarkerOptions: {//可缺省
							icon: new AMap.Icon({
								size: new AMap.Size(19, 31),//图标大小
								imageSize: new AMap.Size(19, 31),
								image: "https://webapi.amap.com/theme/v1.3/markers/b/end.png"
							}),
							offset: new AMap.Pixel(-9, -31)
						},
						midMarkerOptions: {//可缺省
							icon: new AMap.Icon({
								size: new AMap.Size(19, 31),//图标大小
								imageSize: new AMap.Size(19, 31),
								image: "https://webapi.amap.com/theme/v1.3/markers/b/mid.png"
							}),
							offset: new AMap.Pixel(-9, -31)
						},
						lineOptions: {//可缺省
							strokeStyle: "solid",
							strokeColor: "#80d8ff",
							strokeOpacity: 1,
							strokeWeight: 2
						}
						//同 RangingTool 的 自定义 设置，缺省为默认样式
					});
					break;
				}
				case "5":
					this.mouseTool.close(true);
				default:
					break;
			}
		},
		mapSwitchClick(type) {
			this.$emit("mapSwitchClick", type);
		},
		trafficClick() {
			if (this.isTrafficState) {
				this.trafficLayer.hide();
				this.isTrafficState = false;
			} else {
				this.trafficLayer.show();
				this.isTrafficState = true;
			}
		},
		satelliteMap() {
			this.isSatelliteMap = !this.isSatelliteMap;
			if (this.isSatelliteMap) {
				this.map.add([this.satelliteLayer, this.roadNetLayer]);
			} else {
				this.map.remove([this.satelliteLayer, this.roadNetLayer]);
			}
		},
		// 初始化地图
		setMap() {
			let mapStyleStr = "";
			if (this.monitorSkin == "heiseshangwu") {
				mapStyleStr = "de358bfdbed9c341185a8ca84aa0d3e9";
			} else if (this.monitorSkin == "shenhaiweilan") {
				mapStyleStr = "a20318f8ed3d53ac45bb7acb1d878f10";
			} else if (this.monitorSkin == "baisejianyue" || this.monitorSkin == "tiankongzhilan") {
				mapStyleStr = "normal";
			}
			this.map = new AMap.Map("mapGaode", {
				resizeEnable: true, //是否监控地图容器尺寸变化
				zoom: 4, //初始化地图层级
				center: [113.397428, 36.90923], //初始化地图中心点
				mapStyle: "amap://styles/" + mapStyleStr
			});
			// 卫星图层
			this.satelliteLayer = new AMap.TileLayer.Satellite();
			// 路网图层
			this.roadNetLayer = new AMap.TileLayer.RoadNet();
			// 路况图层
			this.trafficLayer = new AMap.TileLayer.Traffic({
				zIndex: 10
			});
			this.trafficLayer.setMap(this.map);
			this.trafficLayer.hide();
			this.logMapinfo();
			//绑定地图移动事件
			this.map.on("moveend", this.logMapinfo);
			this.mouseTool = new AMap.MouseTool(this.map);
		},
		//获取并展示当前城市信息
		logMapinfo() {
			let self = this;
			this.map.getCity(function (info) {
				self.zoom = self.map.getZoom();
				if (!info.citycode) {
					self.address = "未知位置";
					self.weatherText = "";
					self.weather = "";
					return;
				}
				let address = info.city ? info.city : info.province;
				if (self.zoom >= 10) {
					AMap.plugin("AMap.Weather", function () {
						var weather = new AMap.Weather();
						//查询实时天气信息, 查询的城市到行政级别的城市，如朝阳区、杭州市
						weather.getLive(address, function (err, data) {
							self.address = address;
							if (!err) {
								self.weatherText = address + " " + data.weather + " " + data.temperature + "℃";
								if (data.weather.indexOf("晴") != -1) {
									self.weather = "weather-qing";
								} else if (data.weather.indexOf("霾") != -1) {
									self.weather = "weather-mai";
								} else if (data.weather.indexOf("阴") != -1) {
									self.weather = "weather-yin";
								} else if (data.weather.indexOf("雨夹雪") != -1) {
									self.weather = "weather-yujiaxue";
								} else if (data.weather.indexOf("多云") != -1) {
									self.weather = "weather-duoyun";
								} else if (data.weather.indexOf("雨") != -1) {
									self.weather = "weather-yu";
								} else if (data.weather.indexOf("雪") != -1) {
									self.weather = "weather-xue";
								} else if (data.weather.indexOf("尘") != -1) {
									self.weather = "weather-fuchen";
								} else if (data.weather.indexOf("扬沙") != -1) {
									self.weather = "weather-yangsha";
								} else if (data.weather.indexOf("冰雹") != -1) {
									self.weather = "weather-bingbao";
								} else {
									self.weather = "";
								}
							}
						});
					});
				} else if (self.zoom < 10 && self.zoom > 7) {
					self.weather = "";
					self.weatherText = info.province;
					self.address = info.province;
				} else {
					self.weather = "";
					self.weatherText = "";
					self.address = "全国";
				}
			});
		}
	}
};
</script>

<style lang="less">
.amap-logo {
	display: none !important;
}

#mapGaode {
	background: transparent !important;

	.amap-ranging-label,
	.amap-marker-content {
		color: #000;
		font-size: 10px;
	}
}

.map-operation {
	position: absolute;
	right: 20px;
	top: 50px;
	width: 7rem;
	z-index: 5;
	border-radius: 0.5rem;
	padding: 0 0.4rem;
	cursor: pointer;

	.map-instrument-container {
		height: 36px;
		position: absolute;
		left: -404px;
		bottom: 0;
		display: flex;
		align-items: center;
		padding: 4px 0;

		div {
			padding: 0 4px;
			width: 80px;
			text-align: center;
			height: 100%;
			line-height: 30px;
			font-size: 12px;

			span {
				padding-left: 4px;
			}

			&:not(:last-child) {
				border-right: 1px solid #eee;
			}
		}
	}

	.map-switch-btn:hover>.map-switch-container {
		display: block;
	}

	.map-switch-container:hover {
		display: block;
	}

	.map-switch-container {
		display: none;
		position: absolute;
		top: calc(100% + 0.6rem);
		right: 1rem;
		width: calc(100% - 2rem);
		background: rgba(0, 0, 0, 0.7);
		text-align: center;
		border-radius: 5px;
		padding: 0 0.5rem;

		div:first-child {
			border-bottom: 1px solid rgba(231, 231, 231, 0.3);
		}

		div:not(:last-child):hover {
			background: rgba(255, 255, 255, 0.2);
		}

		.shangjiantou {
			position: absolute;
			top: -1.45rem;
			color: rgba(0, 0, 0, 0.7);
			font-size: 1.2rem;
			width: calc(100% - 1rem)
		}
	}

	li {
		height: 2.4rem;
		line-height: 2.4rem;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		padding: 0 0.5rem 0 2rem;
	}

	li:not(:last-child) {
		border-bottom: 1px solid rgba(231, 231, 231, 0.3);
	}

	.traffic-show {
		background: url("../../assets/images/common/lukuang-open.png") no-repeat 10% 52%;
		background-size: 10%;
	}

	.traffic-hide {
		background: url("../../assets/images/common/lukuang-close.png") no-repeat 10% 52%;
		background-size: 10%;
	}

	.weather-qing {
		background: url("../../assets/images/common/weather/tianqi-qing.png") no-repeat 6% 52%;
		background-size: 20%;
	}

	.weather-mai {
		background: url("../../assets/images/common/weather/tianqi-mai.png") no-repeat 6% 52%;
		background-size: 20%;
	}

	.weather-yin {
		background: url("../../assets/images/common/weather/tianqi-yin.png") no-repeat 6% 52%;
		background-size: 20%;
	}

	.weather-yujiaxue {
		background: url("../../assets/images/common/weather/tianqi-yujiaxue.png") no-repeat 6% 52%;
		background-size: 20%;
	}

	.weather-duoyun {
		background: url("../../assets/images/common/weather/tianqi-duoyun.png") no-repeat 6% 52%;
		background-size: 20%;
	}

	.weather-yu {
		background: url("../../assets/images/common/weather/tianqi-yu.png") no-repeat 6% 52%;
		background-size: 20%;
	}

	.weather-xue {
		background: url("../../assets/images/common/weather/tianqi-xue.png") no-repeat 6% 52%;
		background-size: 20%;
	}

	.weather-fuchen {
		background: url("../../assets/images/common/weather/tianqi-fuchen.png") no-repeat 6% 52%;
		background-size: 20%;
	}

	.weather-zhenxue {
		background: url("../../assets/images/common/weather/tianqi-zhenxue.png") no-repeat 6% 52%;
		background-size: 20%;
	}

	.weather-leizhenyu {
		background: url("../../assets/images/common/weather/tianqi-leizhenyu.png") no-repeat 6% 52%;
		background-size: 20%;
	}

	.weather-bingbao {
		background: url("../../assets/images/common/weather/tianqi-bingbao.png") no-repeat 6% 52%;
		background-size: 20%;
	}

	.weather-baofengxue {
		background: url("../../assets/images/common/weather/tianqi-baofengxue.png") no-repeat 6% 52%;
		background-size: 20%;
	}

	.weather-yangsha {
		background: url("../../assets/images/common/weather/tianqi-yangsha.png") no-repeat 6% 52%;
		background-size: 20%;
	}
}
</style>
