import hex_sha1 from "./sha";
import checkRegular from "./checkRegular";
import store from "../store";

function setFontSize(res) {
    let clientWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    if (!clientWidth) return;
    let fontSize = clientWidth / 1920;
    return res * fontSize;
}

// 获取当天最早时间戳
function todayStart() {
    return new Date(new Date().toLocaleDateString()).getTime();
}

// 获取当天最晚时间戳
function todayEnd() {
    return new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1;
}

function prevMonth(type) {
    type = type ? type : "YYYY-MM";
    let date = new Date();
    var year = date.getFullYear();
    var month = (date.getMonth() + 1).toString();
    var day = date.getDate().toString();
    year = month == 1 ? year - 1 : year;
    month = month == 1 ? 12 : month - 1;
    if (type == "YYYY-MM") {
        return year + "-" + month;
    } else if (type == "YYYY-MM-DD") {
        return year + "-" + month + "-" + day;
    }
}

function prevWeek() {
    let date = new Date() - 3 * 24 * 3600 * 1000;
    return date;
}

function hex_sha(pass) {
    return hex_sha1.hex_sha1(pass);
}

function getDay(param) {
    return new Date(param * 1).getDate();
}

/* eslint-disable */
function utc_date(n_utc, formatText) {
    var formatText = formatText || "YYYY-MM-DD hh:mm:ss";
    var a = g_date_array(n_utc);
    var s = utc2dateEx(a, formatText);
    return s;
}

function g_date_array(n_utc) {
    var date = new Date();
    if (n_utc) {
        date.setTime(parseInt(n_utc, 10) + 8 * 3600 * 1000);
    }
    var strYear = date.getUTCFullYear();
    var strMonth = date.getUTCMonth() + 1;
    var strDay = date.getUTCDate();
    var strHour = date.getUTCHours();
    var strMinute = date.getUTCMinutes();
    var strSecond = date.getUTCSeconds();
    return [strYear, strMonth, strDay, strHour, strMinute, strSecond];
}

function formatDate(time, type, format) {
    /**
     * time 时间
     * type 类型（默认'YYYY-MM-DD hh:mm:ss'）
     * format 如果是格林尼治时间需要传true，反之不传
     */
    if (!time) return "--";
    var date = "";
    if (format) {
        date = time;
    } else {
        date = new Date(+time);
    }
    type = type ? type : "YYYY-MM-DD hh:mm:ss";
    var seperator1 = "-";
    var seperator2 = ":";
    var month = (date.getMonth() + 1).toString();
    var day = date.getDate().toString();
    var hours = date.getHours().toString();
    var minutes = date.getMinutes().toString();
    var seconds = date.getSeconds().toString();
    month = month.length === 1 ? ("0" + month) : month;
    day = day.length === 1 ? ("0" + day) : day;
    hours = hours.length === 1 ? ("0" + hours) : hours;
    minutes = minutes.length === 1 ? ("0" + minutes) : minutes;
    seconds = seconds.length === 1 ? ("0" + seconds) : seconds;
    if (type === "YYYY-MM-DD hh:mm:ss") {
        return date.getFullYear() + seperator1 + month + seperator1 + day + " " + hours + seperator2 + minutes + seperator2 + seconds;
    } else if (type === "年月日时分秒") {
        return date.getFullYear() + "年" + month + "月" + day + "日 " + hours + "时" + minutes + "分" + seconds + "秒";
    } else if (type === "YYYY-MM-DD hh:mm") {
        return date.getFullYear() + seperator1 + month + seperator1 + day + " " + hours + seperator2 + minutes;
    } else if (type === "YYYY-MM-DD") {
        return date.getFullYear() + seperator1 + month + seperator1 + day;
    } else if (type === "YYYY-MM") {
        return date.getFullYear() + seperator1 + month;
    } else if (type === "HH:mm") {
        return hours + seperator2 + minutes;
    } else if (type === "HH:mm:ss") {
        return hours + seperator2 + minutes + seperator2 + seconds;
    } else if (type === "YYMMDDhhmmss") {
        let year = date.getFullYear().toString();
        return year.substring(year.length - 2) + month + day + hours + minutes + seconds;
    } else if (type === "YYYYMMDDhhmmss") {
        let year = date.getFullYear();
        return year + month + day + hours + minutes + seconds;
    } else if (type === "MM-DD hh:mm:ss") {
        return month + seperator1 + day + " " + hours + seperator2 + minutes + seperator2 + seconds;
    } else if (type === "YYYY年MM月DD日 hh时mm分") {
        return date.getFullYear() + '年' + month + '月' + day + "日 " + hours +'时' + minutes+'分';
    }
}

function utc2dateEx(a, formatText) {
    var s = "";
    if (formatText == "YYYY-MM-DD hh:mm:ss") {
        s += a[0];
        s += "-";
        s += a[1] < 10 ? "0" + a[1] : a[1];
        s += "-";
        s += a[2] < 10 ? "0" + a[2] : a[2];
        s += " ";
        s += a[3] < 10 ? "0" + a[3] : a[3];
        s += ":";
        s += a[4] < 10 ? "0" + a[4] : a[4];
        s += ":";
        s += a[5] < 10 ? "0" + a[5] : a[5];
    } else if (formatText == "YYYY-MM-DD hh:mm") {
        s += a[0];
        s += "-";
        s += a[1] < 10 ? "0" + a[1] : a[1];
        s += "-";
        s += a[2] < 10 ? "0" + a[2] : a[2];
        s += " ";
        s += a[3] < 10 ? "0" + a[3] : a[3];
        s += ":";
        s += a[4] < 10 ? "0" + a[4] : a[4];
    } else if (formatText == "YYYY-MM-DD") {
        s += a[0];
        s += "-";
        s += a[1] < 10 ? "0" + a[1] : a[1];
        s += "-";
        s += a[2] < 10 ? "0" + a[2] : a[2];
    } else if (formatText == "YYYYMMDD") {
        s += a[0];
        s += "";
        s += a[1] < 10 ? "0" + a[1] : a[1];
        s += "";
        s += a[2] < 10 ? "0" + a[2] : a[2];
    } else if (formatText == "MM-DD hh:mm") {
        s += a[1] < 10 ? "0" + a[1] : a[1];
        s += "-";
        s += a[2] < 10 ? "0" + a[2] : a[2];
        s += " ";
        s += a[3] < 10 ? "0" + a[3] : a[3];
        s += ":";
        s += a[4] < 10 ? "0" + a[4] : a[4];
    } else if (formatText == "YYYY") {
        s += a[0];
    } else {
        var formatArr = formatText.split(/-|\/|\s|:/g);
        var s = formatText;
        var d = g_date_map_ex(a);
        for (var i = 0, len = formatArr.length; i < len; i++) {
            var it = formatArr[i];
            var v = d[it] < 10 ? "0" + d[it] : d[it];
            s = _.replace(s, it, v);
        }
    }
    return s;
}

// 毫秒转换小时-时长转换
function getDuration(my_time) {
    if (!my_time) {
        return "0秒";
    }
    var days = my_time / 1000 / 60 / 60 / 24;
    var daysRound = Math.floor(days);
    var hours = my_time / 1000 / 60 / 60 - (24 * daysRound);
    var hoursRound = Math.floor(hours);
    var minutes = my_time / 1000 / 60 - (24 * 60 * daysRound) - (60 * hoursRound);
    var minutesRound = Math.floor(minutes);
    var seconds = my_time / 1000 - (24 * 60 * 60 * daysRound) - (60 * 60 * hoursRound) - (60 * minutesRound);
    var secondRound = Math.floor(seconds);
    // var hour = hoursRound >= 10 ? hoursRound : '0' + hoursRound
    // var minute = minutesRound >= 10 ? minutesRound : '0' + minutesRound
    // var second = secondRound >= 10 ? secondRound : '0' + secondRound
    var DHMS = {
        days: daysRound ? daysRound + "天" : "",
        hours: hoursRound ? hoursRound + "时" : "",
        minutes: minutesRound ? minutesRound + "分" : "",
        seconds: (secondRound || secondRound === 0) ? secondRound + "秒" : ""
    };
    return DHMS.days + DHMS.hours + DHMS.minutes + DHMS.seconds;
}

// 毫秒转换小时-时长转换
function getDuration2(my_time) {
    if (!my_time && my_time != 0) {
        return "0分";
    }
    var days = my_time / 1000 / 60 / 60 / 24;
    var daysRound = Math.floor(days);
    var hours = my_time / 1000 / 60 / 60 - (24 * daysRound);
    var hoursRound = Math.floor(hours);
    var minutes = my_time / 1000 / 60 - (24 * 60 * daysRound) - (60 * hoursRound);
    var minutesRound = Math.floor(minutes);
    var DHMS = {
        days: daysRound ? daysRound + "天" : "",
        hours: hoursRound ? hoursRound + "时" : "",
        minutes: (minutesRound || minutesRound === 0) ? minutesRound + "分" : ""
    };
    return DHMS.days + DHMS.hours + DHMS.minutes;
}

function getTimeStr(time) {
    return time > 10 ? time : "0" + time;
}

// 毫秒转换小时-时长转换
function getDuration3(my_time) {
    if (!my_time) {
        return 0;
    }
    var days = my_time / 1000 / 60 / 60 / 24;
    var daysRound = Math.floor(days);
    var hours = my_time / 1000 / 60 / 60 - (24 * daysRound);
    var hoursRound = Math.floor(hours);
    var minutes = my_time / 1000 / 60 - (24 * 60 * daysRound) - (60 * hoursRound);
    var minutesRound = Math.floor(minutes);
    var seconds = my_time / 1000 - (24 * 60 * 60 * daysRound) - (60 * 60 * hoursRound) - (60 * minutesRound);
    var secondRound = Math.floor(seconds);
    return {
        days: daysRound ? daysRound : "0",
        hours: hoursRound ? hoursRound : "0",
        minutes: minutesRound ? minutesRound : "0",
        seconds: secondRound ? secondRound : "0"
    };
}

function getDurationTime(startTime, endTime) {
    if (!startTime || !endTime) return;
    let startArr = [],
        endArr = [],
        startStr = "",
        endStr = "";
    startTime.split("").forEach((item, index) => {
        startStr += item;
        endStr += endTime[index];
        if (index % 2) {
            startArr.push(startStr);
            endArr.push(endStr);
            startStr = "";
            endStr = "";
        }
    });
    startArr[0] = new Date().getFullYear();
    endArr[0] = new Date().getFullYear();
    let time1 = `${startArr[0]}-${startArr[1]}-${startArr[2]} ${startArr[3]}:${startArr[4]}:${startArr[5]}`;
    let time2 = `${endArr[0]}-${endArr[1]}-${endArr[2]} ${endArr[3]}:${endArr[4]}:${endArr[5]}`;
    return (new Date(time2).getTime() - new Date(time1).getTime()) / 1000;
}

function getIntOrFloat(number) {
    return (number || number === 0) ? parseFloat((number / 10).toFixed(1)) + "" : "--";
}

// 获取某天0时0分0秒时间 (默认当天)
function getYMD0(v) {
    let day = new Date();
    let y = day.getFullYear();
    let m = day.getMonth();
    let d = v ? day.getDate() - v : day.getDate();
    return new Date(y, m, d, 0, 0, 0);
}

// 获取某天23时59分59秒时间 (默认当天)
function getYMD59(v) {
    let day = new Date();
    let y = day.getFullYear();
    let m = day.getMonth();
    let d = v ? day.getDate() - v : day.getDate();
    return new Date(y, m, d, 23, 59, 59);
}
// 获取某天0时0分0秒时间  
function getStartTime(time) {
    const nowTimeDate = new Date(time)
    return nowTimeDate.setHours(0, 0, 0, 0)
}
// 获取某天23时59分59秒时间
function getEndTime(time) {
    const nowTimeDate = new Date(time)
    return nowTimeDate.setHours(23, 59, 59, 999)
}

// 获取当前年月日时间
function getYMD(v) {
    let day = new Date();
    let y = day.getFullYear();
    let m = day.getMonth();
    let d = v ? day.getDate() - v : day.getDate();
    return new Date(y, m, d);
}

// 获取时间戳
function getTime(time) {
	if(!time) return null;
    return new Date(time).getTime();
};

//获取某天下一天的时间戳
function getDayTime(time) {
    var date = new Date(time);
    var time2 = 24 * 60 * 60 * 1000;
    return date.getTime() + time2;
}

//获取一个月的第一天的0:0:0时间戳
function getMonthFirstTime(time) {
    var date = new Date(time);
    date.setDate(1);
    return date.setHours(0, 0, 0, 0);    
}

//获取一个月的最后一天的23:59:59时间戳
function getMonthLastTime(time) {
    var date = new Date(time);
    date.setDate(getDays(date.getFullYear(), date.getMonth() + 1));
    return date.setHours(23, 59, 59, 999)
}

// 获取每月的天数
function getDays(year, month) {
    return new Date(year, month, 0).getDate();
}

function deepClone(obj) {
    let result = typeof obj.splice === "function" ? [] : {};
    if (obj && typeof obj === "object") {
        for (let key in obj) {
            if (obj[key] && typeof obj[key] === "object") {
                result[key] = deepClone(obj[key]); //如果对象的属性值为object的时候，递归调用deepClone,即在吧某个值对象复制一份到新的对象的对应值中。
            } else {
                result[key] = obj[key]; //如果对象的属性值不为object的时候，直接复制参数对象的每一个键值到新的对象对应的键值对中。
            }
        }
        return result;
    }
}
function deepCloneV2(target) { // 可以复制map和set对象
    const map = new WeakMap()
    
    function isObject(target) {
        return (typeof target === 'object' && target ) || typeof target === 'function'
    }

    function clone(data) {
        if (!isObject(data)) {
            return data
        }
        if ([Date, RegExp].includes(data.constructor)) {
            return new data.constructor(data)
        }
        if (typeof data === 'function') {
            return new Function('return ' + data.toString())()
        }
        const exist = map.get(data)
        if (exist) {
            return exist
        }
        if (data instanceof Map) {
            const result = new Map()
            map.set(data, result)
            data.forEach((val, key) => {
                if (isObject(val)) {
                    result.set(key, clone(val))
                } else {
                    result.set(key, val)
                }
            })
            return result
        }
        if (data instanceof Set) {
            const result = new Set()
            map.set(data, result)
            data.forEach(val => {
                if (isObject(val)) {
                    result.add(clone(val))
                } else {
                    result.add(val)
                }
            })
            return result
        }
        const keys = Reflect.ownKeys(data)
        const allDesc = Object.getOwnPropertyDescriptors(data)
        const result = Object.create(Object.getPrototypeOf(data), allDesc)
        map.set(data, result)
        keys.forEach(key => {
            const val = data[key]
            if (isObject(val)) {
                result[key] = clone(val)
            } else {
                result[key] = val
            }
        })
        return result
    }

    return clone(target)
}

//百度坐标转高德（传入经度、纬度）
function bd_decrypt(bd_lng, bd_lat) {
    var X_PI = Math.PI * 3000.0 / 180.0;
    var x = bd_lng - 0.0065;
    var y = bd_lat - 0.006;
    var z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * X_PI);
    var theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * X_PI);
    var gg_lng = z * Math.cos(theta);
    var gg_lat = z * Math.sin(theta);
    return {
        lng: gg_lng,
        lat: gg_lat
    };
}

//高德坐标转百度（传入经度、纬度）
function bd_encrypt(gg_lng, gg_lat) {
    var X_PI = Math.PI * 3000.0 / 180.0;
    var x = gg_lng,
        y = gg_lat;
    var z = Math.sqrt(x * x + y * y) + 0.00002 * Math.sin(y * X_PI);
    var theta = Math.atan2(y, x) + 0.000003 * Math.cos(x * X_PI);
    var bd_lng = z * Math.cos(theta) + 0.0065;
    var bd_lat = z * Math.sin(theta) + 0.006;
    return {
        bd_lat: bd_lat,
        bd_lng: bd_lng
    };
}

function isHaveAuth(menuAuth, type) {
    return menuAuth.includes(type);
}

//车牌颜色
/*蓝色  1
黄色  2
黑色  3
白色  4
绿色  5
黄绿（6）
绿色（川） 14
农黄
农绿
黄绿色（93）
渐变绿
黄绿（闽） 224
白绿（闽） 225
其他  9*/
function getPlateColorText(plateColor) {
    if (plateColor == "1") {
        return "蓝色";
    } else if (plateColor == "2") {
        return "黄色";
    } else if (plateColor == "3") {
        return "黑色";
    } else if (plateColor == "4") {
        return "白色";
    } else if (plateColor == "5") {
        return "绿色";
    } else if (plateColor == "6") {
        return "黄绿";
    } else if (plateColor == "14") {
        return "绿色(川)";
    } else if (plateColor == "93") {
        return "黄绿色";
    } else if (plateColor == "224") {
        return "黄绿(闽)";
    } else if (plateColor == "225") {
        return "白绿(闽)";
    } else if (plateColor == "9") {
        return "其他";
    } else {
        return "--";
    }
}

function compressImage(file, success, error) {
    // const name = file.name; //文件名
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = e => {
        const src = e.target.result;

        const img = new Image();
        img.src = src;
        img.onload = e => {
            const w = img.width;
            const h = img.height;
            const quality = 0.8; // 默认图片质量为0.92
            // 生成canvas
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
            // 创建属性节点
            const anw = document.createAttribute("width");
            anw.nodeValue = w;
            const anh = document.createAttribute("height");
            anh.nodeValue = h;
            canvas.setAttributeNode(anw);
            canvas.setAttributeNode(anh);

            ctx.drawImage(img, 0, 0, w, h);
            // quality值越小，所绘制出的图像越模糊
            const base64 = canvas.toDataURL("image/jpeg", quality); // 图片格式jpeg或webp可以选0-1质量区间
            // 去掉url的头，并转换为byte
            const bytes = window.atob(base64.split(",")[1]);
            // 处理异常,将ascii码小于0的转换为大于0
            const ab = new ArrayBuffer(bytes.length);
            const ia = new Uint8Array(ab);
            for (let i = 0; i < bytes.length; i++) {
                ia[i] = bytes.charCodeAt(i);
            }
            file = new Blob([ab], {type: "image/jpeg"});
            file.name = name;

            success(base64);
        };
        img.onerror = e => {
            error(e);
        };
    };
    reader.onerror = e => {
        error(e);
    };
}

function compressImage2(file, success, error) {
    // const name = file.name; //文件名
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = e => {
        const src = e.target.result;
        const img = new Image();
        img.src = src;
        img.onload = e => {
            const w = img.width;
            const h = img.height;
            const quality = 0.92; // 默认图片质量为0.92
            // 生成canvas
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
            // 创建属性节点
            const anw = document.createAttribute("width");
            anw.nodeValue = w;
            const anh = document.createAttribute("height");
            anh.nodeValue = h;
            canvas.setAttributeNode(anw);
            canvas.setAttributeNode(anh);

            ctx.drawImage(img, 0, 0, w, h);
            // quality值越小，所绘制出的图像越模糊
            const base64 = canvas.toDataURL("image/png", quality); // 图片格式jpeg或webp可以选0-1质量区间
            // 去掉url的头，并转换为byte
            const bytes = window.atob(base64.split(",")[1]);
            // 处理异常,将ascii码小于0的转换为大于0
            const ab = new ArrayBuffer(bytes.length);
            const ia = new Uint8Array(ab);
            for (let i = 0; i < bytes.length; i++) {
                ia[i] = bytes.charCodeAt(i);
            }
            file = new Blob([ab], {type: "image/png"});
            file.name = name;
            success(base64);
        };
        img.onerror = e => {
            error(e);
        };
    };
    reader.onerror = e => {
        error(e);
    };
}

function getBase64Image(src) {
    return new Promise(function (resolve, reject) {
        var canvas = document.createElement("canvas"),
            ctx = canvas.getContext("2d"),
            img = new Image();
        img.crossOrigin = "Access-Control-Allow-Origin";
        img.onload = function () {
            canvas.height = img.height;
            canvas.width = img.width;
            ctx.drawImage(img, 0, 0);
            var dataURL = canvas.toDataURL("image/jpeg");
            let deleDataHeader = dataURL.split(",")[1];
            resolve(deleDataHeader);
            canvas = null;
        };
        //这个很重要
        img.src = src;
    });
}

function getBase64Video(blob) {
    return new Promise(function (resolve, reject) {
        const fileReader = new FileReader();
        fileReader.onload = (e) => {
            let deleDataHeader = e.target.result.split(",")[1];
            resolve(deleDataHeader);
        };
        // readAsDataURL
        fileReader.readAsDataURL(blob);
    });
}

/**
 * 导出
 * @param Object 参数
 * @name String  导出文件名
 * @callBack function 回调导出接口
 */
function onExport(param, name, callBack, type) {
    callBack(param).then((res) => {
		console.log(res);
        let typeName = type === "word" ? {type: "application/msword"} : {type: "application/vnd.ms-excel"};
        let fileName = type === "word" ? name + ".doc" : name + ".xlsx";
        const blob = new Blob([res], typeName);
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            // 兼容IE/Edge
            window.navigator.msSaveOrOpenBlob(blob, fileName);
        } else {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.style.display = "none";
            a.setAttribute("download", fileName);
            document.body.appendChild(a);
            a.click();
        }
    });
}
/*	
	* 不用请求接口，直接导出表格下载
	* tableData为数组
	* columns 为表头，数组 格式为[{label: '车牌号',prop: 'vehicleNo'}]
	* fileName为下载文档的名称
**/
function exportExcel(tableData,columns, fileName,worksheet='导入结果') {
	//导出前要将json转成table格式
	//列标题
	let str = '<tr>';
	columns.forEach(v=>{
		if(v.color){
			str+=`<td style="color:${v.color}">${v.label}</td>`;
		}else{
			str+=`<td>${v.label}</td>`;
		}
	});
	str += '</tr>';
	//具体数值 遍历
	for (let i = 0; i < tableData.length; i++) {
		str += '<tr>';
		for (let m=0;m<columns.length;m++) {
			var cellvalue = tableData[i][columns[m].prop]||'/';
			str += `<td style="mso-number-format:'\@';">${cellvalue}</td>`;
		}
		str += '</tr>';
	}
	var uri = 'data:application/vnd.ms-excel;base64,';
	//下载的表格模板数据
	var template = `<html xmlns:o="urn:schemas-microsoft-com:office:office"
xmlns:x="urn:schemas-microsoft-com:office:excel"
xmlns="http://www.w3.org/TR/REC-html40">
<head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet>
<x:Name>${worksheet}</x:Name>
<x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet>
</x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]-->
</head><body><table>${str}</table></body></html>`;
	//下载模板
	function base64(s) { return window.btoa(unescape(encodeURIComponent(s))) }
	//window.location.href = uri + base64(template);
	let link = document.createElement('a');
	link.style.display = 'none';
	link.href = uri + base64(template);
	link.setAttribute('download', fileName);
	document.body.appendChild(link);
	link.click();
	link.remove();
}

// 滚动到固定地方
function scrollView(refs, object) {
    for (const i in object) {
        let dom = refs[i];
        if(dom){
            // 这里是针对遍历的情况（多个输入框），取值为数组
            if (Object.prototype.toString.call(dom) !== "[object Object]") {
                dom = dom[0];
            }
            // 第一种方法（包含动画效果）
            dom.$el.scrollIntoView({ // 滚动到指定节点
                // 值有start,center,end，nearest，当前显示在视图区域中间
                block: "start",
                // 值有auto、instant,smooth，缓动动画（当前是慢速的）
                behavior: "smooth"
            });
            break;
        }
    }
}

function setMarkerContent(item, iconUrl) {
    let origin = document.location.origin;
    let hostname = document.location.hostname;
    origin = hostname == "127.0.0.1" ? "http://test.zgjiyi.com" : origin;
    let iconUrlOff = origin + "/platform/images/vehicleIcon/truck-0.png";
    let iconUrlOn = origin + "/platform/images/vehicleIcon/truck-1.png";
    let iconUrlTravel = origin + "/platform/images/vehicleIcon/truck-2.png";
    let iconUrlAlarm = origin + "/platform/images/vehicleIcon/truck-3.png";
    if (iconUrl) {
        iconUrlOff = iconUrl[0];
        iconUrlOn = iconUrl[1];
        iconUrlTravel = iconUrl[2];
        iconUrlAlarm = iconUrl[3];
    }
    // -1:从未上线,0:离线,11:在线/未定位,12:在线/停车,13:在线/停车/报警,20:在线/行驶,21:在线/行驶/报警
    let content = "";
    if (item.vbs == 13 || item.vbs == 21) {
        //13:在线/停车/报警,21:在线/行驶/报警
        content = `<div style="padding-top: 8px;">
			<div style="z-index:10;width:70px;height:20px;background:#EA4A4A;color:#fff;font-size:12px;position:absolute;left:-5px;bottom:-36px;text-align:center;line-height:20px;border-radius:3px;">
				${item.v2 || ""}
				<span style="position:absolute;top:-8px;left:31px;border-width: 4px;border-style: solid;border-color:transparent transparent #EA4A4A transparent;"></span>
			</div>
			<div style="background: url(${iconUrlAlarm}); background-size: 100%;height: 20px;width: 60px;transform: rotate(${item.h - 90}deg);"></div>
		</div>`;
    } else {
        if (item.v6 == "0" || item.vbs == 0) {
            //0:离线
            content = `<div style="padding-top: 8px;">
			<div style="z-index:10;width:70px;height:20px;background:#BFBFBF;color:#fff;font-size:12px;position:absolute;left:-5px;bottom:-36px;text-align:center;line-height:20px;border-radius:3px;">
				${item.v2 || ""}
				<span style="position:absolute;top:-8px;left:31px;border-width: 4px;border-style: solid;border-color: transparent transparent #BFBFBF transparent;"></span>
			</div>
			<div style="background: url(${iconUrlOff}); background-size: 100%;height: 20px;width: 60px;transform: rotate(${item.h - 90}deg);"></div>
			</div>`;
        } else if (item.v6 == "1" || item.vbs == 11 || item.vbs == 12) {
            content = `<div style="padding-top: 8px;">
			<div style="z-index:10;width:70px;height:20px;background:#5C9AFF;color:#fff;font-size:12px;position:absolute;left:-5px;bottom:-36px;text-align:center;line-height:20px;border-radius:3px;">
				${item.v2 || ""}
				<span style="position:absolute;top:-8px;left:31px;border-width: 4px;border-style: solid;border-color: transparent transparent #5C9AFF transparent;"></span>
			</div>
			<div style="background: url(${iconUrlOn}); background-size: 100%;height: 20px;width: 60px;transform: rotate(${item.h - 90}deg);"></div>
		</div>`;
        } else if (item.v6 == "2" || item.vbs == 20) {
            content = `<div style="padding-top: 8px;">
			<div style="background: url(${iconUrlTravel}); background-size: 100%;height: 20px;width: 60px;transform: rotate(${item.h - 90}deg);"></div>
			<div style="z-index:10;width:70px;height:20px;background:#1FB153;color:#fff;font-size:12px;position:absolute;left:-5px;bottom:-36px;text-align:center;line-height:20px;border-radius:3px;">
				${item.v2 || ""}
				<span style="position:absolute;top:-8px;left:31px;border-width: 4px;border-style: solid;border-color: transparent transparent #1FB153 transparent;"></span>
			</div>			
		</div>`;
        }
    }
    return content;
}

function getDriection(direction) {
    var desc = "",
        direction = parseFloat(direction);
    if (direction == 0 || direction == 360) //360度时也为正北方向，不算方向错误
        desc = "正北";
    else if (0 < direction && direction < 90)
        desc = "东北";
    else if (direction == 90)
        desc = "正东";
    else if (90 < direction && direction < 180)
        desc = "东南";
    else if (direction == 180)
        desc = "正南";
    else if (180 < direction && direction < 270)
        desc = "西南";
    else if (direction == 270)
        desc = "正西";
    else if (270 < direction && direction <= 359)
        desc = "西北";
    else
        desc = "未知";
    return desc;
}



/**
 * [drawFenceOverlays 每num条分割]
 * @param  {[Object]} d [数据对象]
 * @return {[type]}   [description]
 */
function splitData(data, num) {
    let fenceArr = [];
    for (let i = 0, len = data.length; i < len; i += num) {
        fenceArr.push(data.slice(i, i + num));
    }
    return fenceArr;
}

// 生成32位uuid
function uuid32() {
    var len = 32; //32长度
    var radix = 16; //16进制
    var chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz".split("");
    var uuid = [],
        i;
    radix = radix || chars.length;
    if (len) {
        for (i = 0; i < len; i++) {
            uuid[i] = chars[0 | Math.random() * radix];
        }
    } else {
        var r;
        uuid[8] = uuid[13] = uuid[18] = uuid[23] = "-";
        uuid[14] = "4";
        for (i = 0; i < 36; i++) {
            if (!uuid[i]) {
                r = 0 | Math.random() * 16;
                uuid[i] = chars[(i == 19) ? (r & 0x3) | 0x8 : r];
            }
        }
    }
    return uuid.join("");
}

function getUsableAuth(menuList, type) {
    let arr = [];
    let menuInfos = store.state.loginInfo.menuInfos;
    let authArys = menuInfos.map(v => {
        return v.menuId;
    });
    menuList.forEach(item => {
        if (item.children && item.children.length > 0) {
            item.children.forEach(itm => {
                if (authArys.indexOf(itm.menuId) != -1) {
                    let str = itm.path.substr(1);
                    arr.push(str);
                }
            });
        } else {
            if (authArys.indexOf(item.menuId) != -1) {
                let str = item.path.substr(1);
                arr.push(str);
            }
        }
    });
    if (type == "monitor" && authArys.indexOf("monitor_center_realtime") > -1) {
        return "realtimeMonitor";
    } else if (type == "user" && authArys.indexOf("user_center_user_account") > -1) {
        return "user";
    } else if (type == "data" && authArys.indexOf("data_center_alarmquery_detail") > -1) {
        return "alarmdetail";
    }
    return arr[0];
}

function hex2int(hex) {
    var len = hex.length,
        a = new Array(len),
        code;
    for (var i = 0; i < len; i++) {
        code = hex.charCodeAt(i);
        if (48 <= code && code < 58) {
            code -= 48;
        } else {
            code = (code & 0xdf) - 65 + 10;
        }
        a[i] = code;
    }

    return a.reduce(function (acc, c) {
        acc = 16 * acc + c;
        return acc;
    }, 0);
}

function isEmpty(a) {
    if (a === "") return true; //检验空字符串
    if (a === "null") return true; //检验字符串类型的null
    if (a === "undefined") return true; //检验字符串类型的 undefined
    if (!a && a !== 0 && a !== "") return true; //检验 undefined 和 null           
    if (Array.prototype.isPrototypeOf(a) && a.length === 0) return true; //检验空数组
    if (Object.prototype.isPrototypeOf(a) && Object.keys(a).length === 0) return true; //检验空对象
    return false;
}

function setIconColorStatus(vbs) {
	let icon = "",
		className = "",
		status = "";
	switch (vbs) {
		case -1:
			icon = "lixianim";
			className = "offline-time";
			status = '从未上线';
			break;
		case 0:
			icon = "daohang";
			className = "offline-time";
			status = '离线';
			break;
		case 11:
			icon = "lixianim";
			className = "online-vehicle";
			status = '在线/未定位';
			break;
		case 12:
			icon = "tingchechang1";
			className = "online-vehicle";
			status = '在线/停车';
			break;
		case 13:
			icon = "tingchechang1";
			className = "alarm-vehicle";
			status = '在线/停车/报警';
			break;
		case 20:
			icon = "daohang";
			className = "travel-vehicle";
			status = '在线/行驶';
			break;
		case 21:
			icon = "daohang";
			className = "alarm-vehicle";
			status = '在线/行驶/报警';
			break;
		default:
			break;
	}
	return {icon, className,status};
}


function translateArrToTree(data) {
    let nData = JSON.parse(JSON.stringify(data));
    let result = []
    let map = {};
    nData.forEach(item => { // 遍历数组 把每一项的引用放入map对象里
        map[item.value] = item;
        item.children = [];
    });
    nData.forEach(item => { // 再次遍历数组 决定item的去向
        let parent = map[item.pid];
        if (parent) {
        // 如果 map[item.pid] 有值 则 parent 为 item 的父级
        // 判断 parent 里有无children 如果没有则创建 如果有则直接把 item push到children里
            (parent.children || (parent.children = [])).push(item);
        } else {
            // 如果 map[item.pid] 找不到值 说明此 item 为 第一级
            result.push(item);
        }
    });
    return result;
}

export default {
    bd_decrypt,
    bd_encrypt,
    uuid32,
    setFontSize,
    todayStart,
    todayEnd,
    prevWeek,
    prevMonth,
    getDay,
    hex_sha,
    utc_date,
    formatDate,
    deepClone,
    compressImage,
    compressImage2,
    getBase64Image,
    getBase64Video,
    onExport,
    getDuration,
    getDuration2,
    getDuration3,
    getDurationTime,
    getTimeStr,
    getIntOrFloat,
    getYMD59,
    getYMD0,
    scrollView,
    getYMD,
    getTime,
    getDayTime,
    getMonthFirstTime,
    getMonthLastTime,
    setMarkerContent,
    getDriection,
    splitData,
    getUsableAuth,
    hex2int,
    isEmpty,
    ...checkRegular,
	setIconColorStatus,
	exportExcel,
    translateArrToTree,
    deepCloneV2,
    getStartTime,
    getEndTime,
};